import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../../context/Context';
import API from '../../../static/API';
import t from '../../../static/Language';
import SafeStorage from '../../../static/SafeStorage';
import 'react-modern-drawer/dist/index.css'
import DataTable from '../../../components/DataTable';
import globalStyles from '../../../static/GlobalStyles';
import FormInput from '../../../components/FormInput';
import FormSelectInput from '../../../components/FormSelectInput';
import Utils from '../../../static/Utils';
import { toast, ToastContainer } from "react-toastify"
import FormCheckboxInput from '../../../components/FormCheckboxInput';
import { useNavigate } from 'react-router-dom';
import DataSection from '../../../components/DataSection';
import Container from '../../../components/Container';
import Avatar from '../../../components/Avatar';
import NewRecordDrawer from '../../../components/NewRecordDrawer';




const CompaniesPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const privileges = JSON.parse(SafeStorage.getItem("user")).privileges


    const [companies, setCompanies] = useState([])
    const [users, setUsers] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [companyInfo, setCompanyInfo] = useState({})
    const [companyToEdit, setCompanyToEdit] = useState(null)
    const [updateInfo, setUpdateInfo] = useState({})
    const [initialContactPerson, setInitialContactPerson] = useState()


    const navigate = useNavigate()

    useEffect(() => {
        getCompanies()
        getUsers()
    }, [])

    const getUsers = () => {
        API.getUsers().then(response => { // cevap gelirse userları atıyoruz
            setUsers(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getUsers().then(resp => { // userları tekrar çekiyoruz
                        setUsers(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const getCompanies = () => {
        API.getCompanies().then(response => { // cevap gelirse şirketleri atıyoruz
            setCompanies(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getCompanies().then(resp => { // şirketleri tekrar çekiyoruz
                        setCompanies(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const createComapny = () => {
        API.createCompany(companyInfo).then(response => {
            getCompanies()
            toast(t(lang, "companiesPage.companyCreated"))
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.createCompany(companyInfo).then(resp => {
                        getCompanies()
                        toast(t(lang, "companiesPage.companyCreated"))
                    }).catch(er => {
                        toast(t(lang, "companiesPage.companyCreateError"))
                    })
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const updateCompany = () => {
        API.updateCompany(companyToEdit.id, updateInfo).then(response => {
            getCompanies()
            toast(t(lang, "companiesPage.companyUpdated"))
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.updateCompany(companyToEdit.id, updateInfo).then(resp => {
                        getCompanies()
                        toast(t(lang, "companiesPage.companyUpdated"))
                    }).catch(err => {
                        toast(t(lang, "companiesPage.companyUpdateError"))
                    })
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const deleteCompanies = (companyIds) => {
        API.deleteCompanies(companyIds).then(response => {
            toast(t(lang, "companiesPage.companyDeleted"))
            getCompanies()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteCompaniess(companyIds).then(resp => {
                        toast(t(lang, "companiesPage.companyDeleted"))
                        getCompanies()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "companiesPage.companyDeleteError"))
                    }).catch(e => {
                        if (e.response.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }


    const columns = [
        {
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: true,
                draggable: true
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "email",
            label: t(lang, "fields.email"),
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "phone",
            label: t(lang, "fields.phone"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "address",
            label: t(lang, "fields.phone"),
            options: {
                filter: true,
                sort: false
            }
        }
    ]


    const actionOptions = {
        edit: {
            onEdit: (row, id) => {
                setCompanyToEdit(row)
                let contactPerson = users.find(u => u.id === row.responsible_person)
                setInitialContactPerson(contactPerson)
                setEditOpen(true)
            }
        },
        delete: {
            onDelete: (row, id) => {
                deleteCompanies([id])
            }
        },
        onShowDetail: (row, id) => {
            navigate(`/companies/${id}`)
        }
    }

    const validate = () => {
        if (Utils.nullOrEmpty(companyInfo.name) ||
            Utils.nullOrEmpty(companyInfo.phone) ||
            Utils.nullOrEmpty(companyInfo.email)
        ) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!validate()) { //
            toast(t(lang, "companiesPage.requiredFields"))
            return
        }
        createComapny()
    }

    const correctPhone = (phone, areaCode) => {
        try {
            return phone.substr(areaCode.length, phone.length)
        } catch (ex) { console.log(ex); return phone; }
    }

    const validateUpdate = () => {
        if ((updateInfo.name && Utils.nullOrEmpty(updateInfo.name)) ||
            (updateInfo.phone && Utils.nullOrEmpty(updateInfo.phone)) ||
            (updateInfo.email && Utils.nullOrEmpty(updateInfo.email))
        ) {
            return false
        }
        return true
    }


    const handleUpdateClicked = () => {
        if (!validateUpdate()) { //
            toast(t(lang, "usersPage.requiredFields"))
            return
        }
        updateCompany()
    }


    return (

        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "gatesPage.new")}
                    onSaveClick={handleSaveClicked}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(val) => setCompanyInfo({ ...companyInfo, name: val })}
                            helperText="*"
                            helperTextType={"error"}
                        />

                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.taxNumber")}
                            onChange={(val) => setCompanyInfo({ ...companyInfo, tax_number: val })}

                        />
                        <FormInput
                            label={t(lang, "fields.taxOffice")}
                            onChange={(val) => setCompanyInfo({ ...companyInfo, tax_office: val })}

                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.phone")}
                            onChange={(val, country, e, formattedValue) => setCompanyInfo({ ...companyInfo, phone: correctPhone(val, country.dialCode), area_code: country.dialCode })}
                            type="phone"
                            helperText={'*'}
                            helperTextType="error"
                        />
                        <FormInput
                            label={t(lang, "fields.email")}
                            onChange={(val) => setCompanyInfo({ ...companyInfo, email: val })}
                            helperText={'*'}
                            helperTextType="error"
                        />

                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.address")}
                            onChange={(val) => setCompanyInfo({ ...companyInfo, address: val })}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.latitude")}
                            onChange={(val) => setCompanyInfo({ ...companyInfo, latitude: parseFloat(val) })}
                            type={"number"}
                        />
                        <FormInput
                            label={t(lang, "fields.longitude")}
                            onChange={(val) => setCompanyInfo({ ...companyInfo, longitude: parseFloat(val) })}
                            type={"number"}
                        />

                    </Row>
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.responsiblePerson")}
                            onSelect={(item) => setCompanyInfo({ ...companyInfo, responsible_person: item.id === -1 ? null : item.id })}
                            data={users}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <Avatar
                                            avatar={item.avatar}
                                            size={25}
                                            isSquare
                                            name={item.name}
                                            lastName={item.last_name}
                                        />
                                        <ItemText>{`${item.name} ${item.last_name}`}</ItemText>
                                    </div>
                                )
                            }}
                        />
                    </Row>
                </NewRecordDrawer>
                <NewRecordDrawer
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={`${companyToEdit?.name}`}
                    onSaveClick={handleUpdateClicked}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, name: val })}
                            helperText="*"
                            helperTextType={"error"}
                            initialValue={companyToEdit?.name}
                        />

                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.taxNumber")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, tax_number: val })}
                            initialValue={companyToEdit?.tax_number}

                        />
                        <FormInput
                            label={t(lang, "fields.taxOffice")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, tax_office: val })}
                            initialValue={companyToEdit?.tax_office}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.phone")}
                            onChange={(val, country, e, formattedValue) => setUpdateInfo({ ...updateInfo, phone: correctPhone(val, country.dialCode), area_code: country.dialCode })}
                            type="phone"
                            helperText={'*'}
                            helperTextType="error"
                            initialValue={companyToEdit?.phone}
                        />
                        <FormInput
                            label={t(lang, "fields.email")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, email: val })}
                            helperText={'*'}
                            helperTextType="error"
                            initialValue={companyToEdit?.email}
                        />

                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.address")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, address: val })}
                            initialValue={companyToEdit?.address}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.latitude")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, latitude: val })}
                            type={"number"}
                            initialValue={companyToEdit?.latitude}
                        />
                        <FormInput
                            label={t(lang, "fields.longitude")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, longitude: val })}
                            type={"number"}
                            initialValue={companyToEdit?.longitude}
                        />

                    </Row>

                    <Row>
                        <FormCheckboxInput
                            defaultChecked={companyToEdit?.is_active}
                            label={t(lang, "fields.active")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, is_active: val })}
                        />
                    </Row>

                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.responsiblePerson")}
                            onSelect={(item) => setUpdateInfo({ ...updateInfo, responsible_person: item.id === -1 ? null : item.id })}
                            data={users}
                            initialValue={initialContactPerson}  // userToEdit ? users?.indexOf(u => u.id === userToEdit.chief) : null
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <Avatar
                                            avatar={item.avatar}
                                            size={25}
                                            isSquare
                                            name={item.name}
                                            lastName={item.last_name}
                                        />
                                        <ItemText>{`${item.name} ${item.last_name}`}</ItemText>
                                    </div>
                                )
                            }}

                        />
                    </Row>
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={companies}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    onRowsDelete={(rows, ids) => deleteCompanies(ids)}
                    title={t(lang, "companiesPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateAnnouncements")}
                />
            </DataSection>
        </Container>
    )

}


export default CompaniesPage;




const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`



const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`