import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../context/Context';
import API from '../../../../static/API';
import t from '../../../../static/Language';
import SafeStorage from '../../../../static/SafeStorage';
import 'react-modern-drawer/dist/index.css'
import DataTable from '../../../../components/DataTable';
import globalStyles from '../../../../static/GlobalStyles';
import { ToastContainer } from "react-toastify"
import Container from '../../../../components/Container';
import DataSection from '../../../../components/DataSection';
import Avatar from '../../../../components/Avatar';



const EarlyLeaversPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const user = JSON.parse(SafeStorage.getItem("user"))
    const company = user.company


    const [data, setData] = useState([])



    useEffect(() => {
        document.title = t(lang, "menuNames.earlyLeavers")
        get()
    }, [])



    function getFirstAndLastDayOfMonth() {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();

        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);

        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }

        return {
            start_date: formatDate(firstDay),
            end_date: formatDate(lastDay)
        };
    }



    const get = () => {
        const { start_date, end_date } = getFirstAndLastDayOfMonth()
        const data = { company, start_date, end_date }
        API.getEarlyLeavers(data).then(response => { // cevap gelirse datayı atıyoruz
            setData(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getEarlyLeavers(data).then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }










    const columns = [
        {
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: true,
                draggable: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                            <Avatar
                                avatar={data[tableMeta.rowIndex]?.avatar}
                                name={data[tableMeta.rowIndex]?.name}
                                lastName={data[tableMeta.rowIndex]?.last_name}
                                size={40}
                                isSquare
                            />
                            <span style={{ marginLeft: '8px' }}>{`${value} ${data[tableMeta.rowIndex]?.last_name}`}</span>
                        </div>
                    )
                }

            }
        },
        {
            name: "shift_date",
            label: t(lang, "fields.day"),
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <text>{new Date(value).toLocaleString(lang, { year: 'numeric', month: 'short', day: '2-digit' })}</text>
                    )
                }
            }
        },
        {
            name: "end_time",
            label: t(lang, "fields.shiftEnd"),
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <text>{value.slice(0, 5)}</text>
                    )
                }
            }
        },
        {
            name: "check_out_time",
            label: t(lang, "fields.leaveTime"),
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <text>{new Date(value).toLocaleString(lang, { hour: '2-digit', minute: '2-digit' })}</text>
                    )
                }
            }
        },

    ]


    return (

        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <DataTable
                    columns={columns}
                    data={data}
                    title={t(lang, "menuNames.earlyComers")}
                    actionOptions={[]}
                />
            </DataSection>
        </Container>
    )

}


export default EarlyLeaversPage;

