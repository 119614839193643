import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-modern-drawer/dist/index.css'
import globalStyles from '../../../static/GlobalStyles';
import { Tree, TreeNode } from 'react-organizational-chart';
import HierarchyCard from '../../../components/HierarchyCard';
import html2canvas from 'html2canvas';
import { Card, Container } from '@mui/material'
import OptionButton from '../../../components/OptionButton';
import t from '../../../static/Language';
import { Context } from '../../../context/Context';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DataObjectIcon from '@mui/icons-material/DataObject';
import Loading from '../../../states/Loading';
import API from '../../../static/API';
import SafeStorage from '../../../static/SafeStorage';
import jsPDF from 'jspdf';
import Utils from '../../../static/Utils';
import { Player } from '@lottiefiles/react-lottie-player/dist/lottie-react';



const sampleTree = {
    root: {
        employeeName: "Saruhan Türkmen",
        avatar: "https://ui-avatars.com/api/?background=FFE88C&size=128&name=Saruhan+Türkmen",
        title: "Direktör",
        departmentName: "Bilgi Teknolojileri",
        depth: 1,
        nodes: [
            {
                employeeName: "Ali Demir",
                avatar: "https://ui-avatars.com/api/?background=FFE88C&size=128&name=Ali+Demir",
                title: "CBS Kıdemli Müdürü",
                departmentName: "Coğrafi Bilgi Sistemleri",
                depth: 2,
                nodes: [
                    {
                        employeeName: "Erdem Dağdeviren",
                        avatar: "https://ui-avatars.com/api/?background=FFE88C&size=128&name=Erdem+Dağdeviren",
                        title: "CBS Kıdemli Yönetmeni",
                        departmentName: "CBS Doğalgaz Yönetmenliği",
                        depth: 3,
                        nodes: [
                            {
                                employeeNname: "Özlem Tiryaki",
                                avatar: "https://ui-avatars.com/api/?background=FFE88C&size=128&name=Özlem+Tiryaki",
                                title: "CBS Uzmanı",
                                departmentName: "CBS Doğalgaz Yönetmenliği",
                                depth: 4,
                            },
                            {
                                employeeName: "Nejla Kandemir",
                                avatar: "https://ui-avatars.com/api/?background=FFE88C&size=128&name=Nejla+Kandemir",
                                title: "CBS Uzmanı",
                                departmentName: "CBS Doğalgaz Yönetmenliği",
                                depth: 4,
                            }
                        ]
                    },
                    {
                        employeeName: "Mehmet Selim Bilgin",
                        avatar: "https://ui-avatars.com/api/?background=FFE88C&size=128&name=Mehmet Selim+Bilgin",
                        title: "CBS Kıdemli Uzmanı",
                        departmentName: "CBS Yazılım",
                        depth: 3,
                        nodes: []
                    },
                    {
                        employeeName: "Çağrı Daşkın",
                        avatar: "https://ui-avatars.com/api/?background=FFE88C&size=128&name=Çağrı+Daşkın",
                        title: "CBS Kıdemli Uzmanı",
                        departmentName: "CBS Yazılım",
                        depth: 3,
                        nodes: []
                    },
                    {
                        employeeName: "Gamze Topçu",
                        avatar: "https://ui-avatars.com/api/?background=FFE88C&size=128&name=Gamze+Topçu",
                        title: "CBS Uzman Yardımcısı",
                        departmentName: "CBS Elektrik Yönetmenliği",
                        depth: 3,
                        nodes: []
                    }
                ]
            }
        ]
    }
}

const HierarchyPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const company = JSON.parse(SafeStorage.getItem("user")).company


    const [organization, setOrganization] = useState()
    const [chart, setChart] = useState(sampleTree)
    const [loading, setLoading] = useState(true)


    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [translateX, setTranslateX] = useState(0);
    const [translateY, setTranslateY] = useState(0);
    const [scale, setScale] = useState(1);



    useEffect(() => {
        document.title = t(lang, "menuNames.organizationChart")
        API.getOrganizationChart(company).then(response => {
            try {
                setOrganization(response.data.data)
                if (response?.data?.data?.length > 0) {
                    const c = toTree(response.data.data)
                    setChart(c)
                }
            } catch (error) {
                alert(error)
            }


        }).catch(err => {
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getOrganizationChart(company).then(resp => { // userları tekrar çekiyoruz
                        setOrganization(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        }).finally(() => setLoading(false))
    }, [])


    const toTree = (data) => {

        const getChildren = (id, data) => {
            return data.filter((employee) => employee.chief_id === id);
        }

        // Organizasyon şemasının kökünü oluşturmak
        const rootEmployee = data.find(item => item.depth === 1)
        const rootNode = {
            employeeName: rootEmployee?.employee_name,
            avatar: rootEmployee?.avatar,
            title: null,
            departmentName: rootEmployee?.department_name,
            depth: 1,
            nodes: [],
        };

        // Kökten başlayarak tüm çalışanları ve alt çalışanlarını dolaşmak
        const iterateEmployees = (employee, parentNode) => {
            const children = getChildren(employee.employee_id, data);


            // Çalışanın alt çalışanları varsa, onları da eklemek için tekrarla
            if (children.length > 0) {
                parentNode.nodes.push({
                    employeeName: employee.employee_name,
                    avatar: employee.avatar,
                    title: employee.title,
                    departmentName: employee.department_name,
                    depth: employee.depth + 1,
                    nodes: [],
                });

                for (const child of children) {
                    iterateEmployees(child, parentNode.nodes[parentNode.nodes.length - 1]);
                }
            } else {
                // Alt çalışanı olmayan çalışanlar için sadece bilgileri ekle
                parentNode.nodes.push({
                    employeeName: employee.employee_name,
                    avatar: employee.avatar,
                    title: employee.title,
                    departmentName: employee.department_name,
                    depth: employee.depth + 1,
                    nodes: [],
                });
            }
        }

        // Organizasyon şemasının tamamını oluşturmak için kök çalışanla başla
        iterateEmployees(rootEmployee, rootNode);

        // Oluşturulan organizasyon şemasını döndür
        rootNode.nodes = rootNode.nodes[0].nodes
        return {
            root: rootNode,
        };
    }







    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.clientX - translateX);
        setStartY(e.clientY - translateY);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        setTranslateX(e.clientX - startX);
        setTranslateY(e.clientY - startY);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleWheel = (e) => {
        //e.preventDefault();
        const zoomDirection = e.deltaY < 0 ? 1 : -1;
        setScale((prevScale) => {
            const newScale = Math.max(.1, Math.min(2.5, prevScale + zoomDirection * .02));
            return newScale;
        });
    };

    const saveAsJpeg = () => {
        const element = document.getElementById("organization")
        html2canvas(element, { useCORS: true, allowTaint: false }).then(canvas => {
            const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
            const link = document.createElement('a');
            link.download = "organizationChart.png"
            link.href = image
            link.click()
        }).catch(err => console.log(err))
    }

    const saveAsJson = (filename = 'organizationChart.json') => {
        const chartToSave = Utils.removeProperty(chart, "avatar")
        const jsonString = JSON.stringify(chartToSave, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link); // Firefox'ta çalışması için gerekli
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const saveAsCsv = () => {
        if (!organization) {
            return
        }
        const headers = ["employee_name", "title", "department_name", "depth", "parent_department_name"];
        let csvString = headers.join(",") + "\n"; // Başlıkları ekleyip yeni bir satır başlat

        organization.forEach(item => {
            let row = headers.map(fieldName => JSON.stringify(item[fieldName] || "")).join(",");
            csvString += row + "\n"; // Her bir satırı CSV string'ine ekle
        });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = "organizationChart.csv"; // İndirilecek dosyanın adı
        document.body.appendChild(link); // Firefox'ta çalışması için gerekli
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const saveAsPdf = () => {
        const divElement = document.getElementById("organization");

        html2canvas(divElement, { useCORS: true, allowTaint: false }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'p',
                unit: 'mm',
                format: 'a4',
            });
            const imgWidth = 210; // A4 kağıdının genişliği mm cinsinden
            const imgHeight = canvas.height * imgWidth / canvas.width;
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save('organizationChart.pdf');
        });
    }




    const renderChart = (node) => {
        return (
            <>
                {node.depth === 1 ? (
                    <Tree
                        lineBorderRadius='5px'
                        lineWidth='1px'
                        lineHeight='7px'
                        lineColor={globalStyles.textColor}
                        label={
                            <HierarchyCard
                                name={node.employeeName}
                                avatar={node.avatar}
                                title={node.title}
                                department={node.departmentName}
                            />
                        }>
                        {node.nodes && node.nodes.length > 0 && node.nodes.map(subNode => renderChart(subNode))}
                    </Tree>
                ) : (node.nodes?.length > 0 ? (
                    <TreeNode label={
                        <HierarchyCard
                            name={node.employeeName}
                            avatar={node.avatar}
                            title={node.title}
                            department={node.departmentName}
                        />
                    }>
                        {node.nodes && node.nodes.length > 0 && node.nodes.map(subNode => renderChart(subNode))}
                    </TreeNode>
                ) : (
                    <TreeNode label={
                        <HierarchyCard
                            name={node.employeeName}
                            avatar={node.avatar}
                            title={node.title}
                            department={node.departmentName}
                        />
                    } />
                ))}
            </>

        );
    };


    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <div style={{ padding: 15, height: '87%', overflow: 'hidden' }}>
            <ToolBar>
                <Title>{t(lang, "menuNames.organizationChart")}</Title>
                {organization?.length > 0 &&
                    <div>
                        <OptionButton
                            toolTip={t(lang, "table.downloadCsv")}
                            renderIcon={() => <DownloadForOfflineIcon style={{ width: 24, height: 24, color: globalStyles.textColor }} />}
                            onClick={() => saveAsCsv()}
                        />
                        <OptionButton
                            toolTip={t(lang, "common.saveAsPng")}
                            renderIcon={() => <ImageIcon style={{ width: 24, height: 24, color: globalStyles.textColor }} />}
                            onClick={() => saveAsJpeg()}
                        />
                        <OptionButton
                            toolTip={t(lang, "common.saveAsPdf")}
                            renderIcon={() => <PictureAsPdfIcon style={{ width: 24, height: 24, color: globalStyles.textColor }} />}
                            onClick={() => saveAsPdf()}
                        />
                        <OptionButton
                            toolTip={t(lang, "common.saveAsJson")}
                            renderIcon={() => <DataObjectIcon style={{ width: 24, height: 24, color: globalStyles.textColor }} />}
                            onClick={() => saveAsJson()}
                        />
                    </div>
                }

            </ToolBar>
            <Card elevation={0} sx={{ width: '100%', height: '100%' }}>
                {organization?.length > 0 ? (
                    <div
                        id='organization'
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                        onWheel={handleWheel}
                        style={{
                            cursor: isDragging ? 'grabbing' : 'grab',
                            transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
                            transition: 'transform .05s',
                        }}
                    >
                        {chart && renderChart(chart.root)}
                    </div>
                ) : (
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, height: '100%' }}>
                        <span
                            style={{
                                fontFamily: globalStyles.font,
                                fontSize: '24px',
                                color: "grey"
                            }}
                        >
                            {t(lang, "departmentsPage.noOrganizationalStructure")}
                        </span>
                        <Player
                            src={require('../../../assets/lottie-animations/gear.json')}
                            autoplay
                            loop
                            style={{ width: '25%' }}
                        />
                    </div>
                )}
            </Card>
        </div>

    )
}

export default HierarchyPage;



const ToolBar = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    padding-left: 14px;
    padding-right: 14px;
    border-bottom: 1px solid #eddbda;
    justify-content: space-between;
    padding-bottom: 15px
`

const Title = styled.span`
    font-family: ${globalStyles.font};
    font-weight: bold;
    color: ${globalStyles.textColor};
    font-size: 20px;
`

