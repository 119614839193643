import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import SafeStorage from "../../static/SafeStorage";
import { Grid } from "@mui/material";
import styled from "styled-components";
import API from "../../static/API";
import t from "../../static/Language";
import globalStyles from "../../static/GlobalStyles";
import FormInput from "../../components/FormInput";
import Avatar from "../../components/Avatar";



const ProfilePage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const user = JSON.parse(SafeStorage.getItem("user"))

    const [userDetails, setUserDetails] = useState()


    useEffect(() => {
        document.title = t(lang, "common.profile")
        getUserDetails()
    }, [])



    const getUserDetails = () => {
        API.getUserDetails(user.id).then(response => { // cevap gelirse userları atıyoruz
            setUserDetails(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getUserDetails(user.id).then(resp => { // userları tekrar çekiyoruz
                        setUserDetails(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }



    return (
        <Container>
            <Grid container spacing={1}>

                <Grid container xs={12} sm={12} md={12} lg={12} sx={{ alignItems: 'center', padding: '10px' }}>
                    <Avatar avatar={userDetails?.avatar} name={userDetails?.name} lastName={userDetails?.last_name} size={140} isSquare={true} />
                    <NameText>{`${userDetails?.name} ${userDetails?.last_name}`}</NameText>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.company")}
                        type="text"
                        initialValue={userDetails?.company_name}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.email")}
                        type="text"
                        initialValue={userDetails?.email}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.phone")}
                        type="phone"
                        initialValue={`${userDetails?.area_code}${userDetails?.phone}`}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.userName")}
                        type="text"
                        initialValue={userDetails?.user_name}
                        readOnly
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.title")}
                        type="text"
                        initialValue={userDetails?.title}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.citizenId")}
                        type="text"
                        initialValue={`${userDetails?.id_number}`}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.birthDate")}
                        type="text"
                        initialValue={userDetails?.date_pf_birth}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.employmentDate")}
                        type="text"
                        initialValue={userDetails?.employment_date}
                        readOnly
                    />
                </Grid>
            </Grid>
        </Container>

    )


}


export default ProfilePage;


const Container = styled.div`
	height: 100%;
	padding: 25px;
	padding-bottom: 90px
`;



const NameText = styled.text`
    color: ${globalStyles.textColor};
    font-size: 30px;
    font-weight: bold;
    font-family: ${globalStyles.font};
    padding-left: 20px
`