import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import globalStyles from '../../static/GlobalStyles';
import { Player } from "@lottiefiles/react-lottie-player"
import FormInput from '../../components/FormInput';
import FlexButton from '../../components/FlexButton';
import { Alert, Breadcrumbs, Link, Snackbar } from '@mui/material';
import t from '../../static/Language';
import { Context } from '../../context/Context';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useNavigate, useLocation } from "react-router-dom"
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import Utils from '../../static/Utils';
import API from '../../static/API';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import OtpInput from 'react-otp-input'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'


const Layout = ({ children }) => {
    const { state } = useContext(Context)
    const lang = state.appLang

    return (
        <LayoutContainer>
            <BackgroundSection >
                <Player
                    src={require('../../assets/lottie-animations/business.json')}
                    autoplay
                    loop
                    style={{ width: '70%' }}
                />
                <span
                    style={{
                        fontFamily: globalStyles.font,
                        fontSize: '24px',
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '30px'
                    }}
                >
                    {t(lang, "login.slogan")}
                </span>
                <span
                    style={{
                        fontFamily: globalStyles.font,
                        fontSize: '18px',
                        color: 'white',
                        textAlign: 'center',
                        marginTop: '20px'
                    }}
                >
                    {t(lang, "login.advertise")}
                </span>
            </BackgroundSection>
            <FormSection>
                {children}
            </FormSection>
        </LayoutContainer>
    );
};


const ConfirmationPage = () => {
    const context = useContext(Context)
    const lang = context.state.appLang

    const navigate = useNavigate()

    const { state } = useLocation()
    const company = state.company


    const [otp, setOtp] = useState("")

    const [messageOpen, setMessageOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("success")


    const onTime = () => {

    }


    const handleSubmit = () => {
        API.confirmEmail(company, otp).then(response => {
            setSeverity("success")
            setMessage(t(lang, "login.registerSuccess"))
            setMessageOpen(true)
            navigate("/login", { replace: true })

        }).catch(err => {
            setSeverity("error")
            setMessage(t(lang, "common.error"))
            setMessageOpen(true)
        })
    }

    return (
        <Layout>
            <Snackbar
                open={messageOpen}
                autoHideDuration={5000}
                onClose={() => setMessageOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert sx={{ width: '100%', fontFamily: globalStyles.font }} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                <img
                    src={require('../../assets/icon/flexbee-no-bg-dark.webp')}
                    style={{
                        width: '200px',
                        alignSelf: 'center'
                    }}
                />
                <span
                    style={{
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor,
                        alignSelf: 'center',
                        color: globalStyles.firstColor,
                        fontSize: '16px',
                        marginBottom: '20px'
                    }}
                >
                    {t(lang, "login.registrationForm")}
                </span>
                <Breadcrumbs
                    separator={<ArrowForwardIosRoundedIcon style={{ color: "#639ef7" }} />}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Person2RoundedIcon />
                        <CrumbText>{t(lang, "login.userInfo")}</CrumbText>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <ApartmentRoundedIcon />
                        <CrumbText>{t(lang, "login.companyInfo")}</CrumbText>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <HowToRegRoundedIcon />
                        <CurrentCrumbText>{t(lang, "common.mailConfirmation")}</CurrentCrumbText>
                    </div>
                </Breadcrumbs>

                <FormContainer>
                    <span style={{
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor,
                        fontSize: '16px'
                    }}>
                        {t(lang, "login.verificationMessage")}
                    </span>
                    <OtpInput
                        numInputs={6}
                        value={otp}
                        onChange={value => setOtp(value)}
                        containerStyle={{

                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 56,
                            marginTop: 25
                        }}
                        inputStyle={{
                            margin: 10,
                            height: 40,
                            width: 40,
                            fontFamily: globalStyles.font,
                            fontSize: 24,
                            borderWidth: 0,
                            borderBottomWidth: 1,
                            backgroundColor: "transparent"
                        }}
                        shouldAutoFocus
                    />
                    <TimerContainer>
                        <CountdownCircleTimer
                            isPlaying
                            duration={180}
                            colors={['#1eba5c', '#F7B801', '#96ba1e', '#ba1e1e']}
                            colorsTime={[180, 90, 30, 0]}
                            size={60}
                            strokeWidth={6}
                            onComplete={onTime}
                        >
                            {({ remainingTime }) => (<RemainingTimeText>{remainingTime}</RemainingTimeText>)}
                        </CountdownCircleTimer>
                    </TimerContainer>
                    <FlexButton
                        text={`${t(lang, "common.create")}`}
                        style={{ margin: 0 }}
                        onClick={handleSubmit}
                        disabled={otp.length < 6}
                    />
                </FormContainer>
            </div>
        </Layout>


    )
}


export default ConfirmationPage



const RemainingTimeText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-weight: bold;
`

const TimerContainer = styled.div`
    display: flex;
    padding: 25px;
    align-items: center;
    justify-content: center;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
`;



const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  background-color: ${globalStyles.firstColor};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BackgroundSection = styled.div`
  flex: 1;
  background-color: ${globalStyles.firstColor};
 
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 70px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px 0px 0px 15px;
  background-color: white;
  box-shadow: -12px -16px 21px -7px rgba(72, 77, 77, 0.5);
`;



const CrumbText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 12px;
`

const CurrentCrumbText = styled.span`
    font-family: ${globalStyles.font};
    color: black;
    font-size: 14px;
    cursor: default
`