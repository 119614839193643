import AccessPointDetailsPage from "../main-stack/pages/access-control/access-points/AccessPointDetailsPage"
import AccessPointsPage from "../main-stack/pages/access-control/access-points/AccessPointsPage"
import BuildingsPage from "../main-stack/pages/access-control/buildings/BuildingsPage"
import GateDetailsPage from "../main-stack/pages/access-control/gates/GateDetailsPage"
import GatesPage from "../main-stack/pages/access-control/gates/GatesPage"
import CompaniesPage from "../main-stack/pages/companies/CompaniesPage"
import CompanyDetailsPage from "../main-stack/pages/companies/CompanyDetailsPage"
import UserAccessPrivsPage from "../main-stack/pages/user-access-privs/UserAccessPrivsPage"
import GrantUserRolesPage from "../main-stack/pages/user-roles/grant-user-roles/GrantUserRolePage"
import UserActivitiesPage from "../main-stack/pages/access-control/user-activities/UserActivitiesPage"
import UserRolesPage from "../main-stack/pages/user-roles/UserRolesPage"
import UserDetailsPage from "../main-stack/pages/users/UserDetailsPage"
import UsersPage from "../main-stack/pages/users/UsersPage"
import AnnouncementsPage from "../main-stack/pages/announcements/AnnouncementsPage"
import AnnouncementsDetailsPage from "../main-stack/pages/announcements/AnnouncementDetailsPage"
import FeedbacksPage from "../main-stack/pages/feedbacks/FeedbacksPage"
import LatecomersPage from "../main-stack/pages/pacs/latecomers/LatecomersPage"
import EarlyLeaversPage from "../main-stack/pages/pacs/earlyleavers/EarlyLeavers"
import TimesheetPage from "../main-stack/pages/pacs/timesheet/TimesheetPage"
import TitlesPage from "../main-stack/pages/enterprise/titles/TitlesPage"
import UserGroupsPage from "../main-stack/pages/enterprise/user-groups/UserGroupsPage"
import BranchesPage from "../main-stack/pages/access-control/branches/BranchesPage"
import SurveysPage from "../main-stack/pages/survey/SurveysPage"
import CreateSurveyPage from "../main-stack/pages/survey/CreateSurveyPage"
import EditSurveyPage from "../main-stack/pages/survey/EditSurveyPage"
import SurveyDetailsPage from "../main-stack/pages/survey/SurveyDetailsPage"
import ShiftsPage from "../main-stack/pages/shifts/ShiftsPage"
import ScheduleShifttPage from "../main-stack/pages/shifts/ScheduleShiftPage"
import ShiftSupervisorsPage from "../main-stack/pages/shifts/ShiftSupervisorsPage"
import BranchDetailsPage from "../main-stack/pages/access-control/branches/BranchDetailsPage"
import CompanyPrivilegesPage from "../main-stack/pages/companies/company-privileges/CompanyPrivilegesPage"
import UserGroupDetailsPage from "../main-stack/pages/enterprise/user-groups/UserGroupDetailsPage"
import HierarchyPage from "../main-stack/pages/departments/HierarchyPage"
import DepartmentsPage from "../main-stack/pages/departments/DepartmentsPage"
import DepartmentDetailsPage from "../main-stack/pages/departments/DepartmentDetailsPage"
import CreateQuizPage from "../main-stack/pages/whistle-blower/ethic-documents/CreateQuizPage"
import QuizzesPage from "../main-stack/pages/whistle-blower/ethic-documents/QuizzesPage"
import ComplaintsPage from "../main-stack/pages/whistle-blower/complaint/ComplaintsPage"
import AssignFixedShiftPage from "../main-stack/pages/shifts/AssignFixedShiftPage"
import LeavePage from "../main-stack/pages/leave/LeavePage"
import WorkPlanPage from "../main-stack/pages/shifts/work-plans/WorkPlanPage"
import WorkPlanDetailsPage from "../main-stack/pages/shifts/work-plans/WorkPlanDetailsPage"
import AssignWorkPlanPage from "../main-stack/pages/shifts/work-plans/AssignWorkPlanPage"





const routeDefinitions = [
    { path: "/users", element: <UsersPage />, key: 3, requiredPriv: "canListUsers" },
    { path: "/roles", element: <UserRolesPage />, key: 6, requiredPriv: "canListRoles" },
    { path: '/users/:id', element: <UserDetailsPage />, key: 7, requiredPriv: "canListUsers" },
    { path: "/buildings", element: <BuildingsPage />, key: 8, requiredPriv: "canListBuildings" },
    { path: "/gates", element: <GatesPage />, key: 9, requiredPriv: "canListGates" },
    { path: "/access-points/:id", element: <AccessPointDetailsPage />, key: 10, requiredPriv: "canListAccessPoints" },
    { path: "/access-points", element: <AccessPointsPage />, key: 11, requiredPriv: "canListAccessPoints" },
    { path: "/user-access-privs/", element: <UserAccessPrivsPage />, key: 12, requiredPriv: "canListAccessPrivs" },
    { path: "/gates/:id", element: <GateDetailsPage />, key: 13, requiredPriv: "canListGates" },
    { path: "/roles/grant", element: <GrantUserRolesPage />, key: 14, requiredPriv: 'canCreateRoles' },
    { path: "/companies", element: <CompaniesPage />, key: 15, requiredPriv: "superAdmin" },
    { path: "/companies/:id", element: <CompanyDetailsPage />, key: 16, requiredPriv: "superAdmin" },
    { path: "/user-activities", element: <UserActivitiesPage />, key: 17, requiredPriv: "canListUserActivity" },
    { path: "/announcements", element: <AnnouncementsPage />, key: 18, requiredPriv: "canListAnnouncements" },
    { path: "/announcements/:id", element: <AnnouncementsDetailsPage />, key: 19, requiredPriv: "canListAnnouncements" },
    { path: "/feedbacks", element: <FeedbacksPage />, key: 20, requiredPriv: "canListFeedbacks" },
    { path: "/latecomers", element: <LatecomersPage />, key: 21, requiredPriv: "canUsePacs" },
    { path: "/earlyleavers", element: <EarlyLeaversPage />, key: 22, requiredPriv: "canUsePacs" },
    { path: "/timesheet", element: <TimesheetPage />, key: 23, requiredPriv: "canUsePacs" },
    { path: "/titles", element: <TitlesPage />, key: 24, requiredPriv: "canListTitles" },
    { path: "/user-groups", element: <UserGroupsPage />, key: 25, requiredPriv: "canListUserGroups" },
    { path: "/branches", element: <BranchesPage />, key: 26, requiredPriv: "canListBranches" },
    { path: "/surveys", element: <SurveysPage />, key: 27, requiredPriv: "canListSurveys" },
    { path: "/surveys/new", element: <CreateSurveyPage />, key: 28, requiredPriv: "canCreateSurveys" },
    { path: "/surveys/edit/:id", element: <EditSurveyPage />, key: 29, requiredPriv: "canEditSurveys" },
    { path: "/surveys/:id", element: <SurveyDetailsPage />, key: 30, requiredPriv: "canListSurveys" },
    { path: "/shifts", element: <ShiftsPage />, key: 31, requiredPriv: "canUseShifts" },
    { path: "/shifts/schedule", element: <ScheduleShifttPage />, key: 32, requiredPriv: "canScheduleShifts" },
    { path: "/shifts/supervisors", element: <ShiftSupervisorsPage />, key: 33, requiredPriv: "canCreateShifts" },
    { path: "/branches/:id", element: <BranchDetailsPage />, key: 34, requiredPriv: "canListBranches" },
    { path: "/company-privileges", element: <CompanyPrivilegesPage />, key: 35, requiredPriv: "superAdmin" },
    { path: "/user-groups/:id", element: <UserGroupDetailsPage />, key: 36, requiredPriv: "canListUserGroups" },
    { path: "/hierarchy", element: <HierarchyPage />, key: 37, requiredPriv: "canSeeHierarchy" },
    { path: "/departments", element: <DepartmentsPage />, key: 38, requiredPriv: "canListDepartments" },
    { path: "/departments/:id", element: <DepartmentDetailsPage />, key: 39, requiredPriv: "canListDepartments" },
    { path: "/ethic-documents", element: <QuizzesPage />, key: 40, requiredPriv: "canListQuizzes" },
    { path: "/ethic-documents/new", element: <CreateQuizPage />, key: 41, requiredPriv: "canCreateQuizzes" },
    { path: "/ethic-documents/edit/:id", element: <EditSurveyPage />, key: 42, requiredPriv: "canEditQuizzes" },
    { path: "/ethic-documents/:id", element: <SurveyDetailsPage />, key: 43, requiredPriv: "canListQuizzes" },
    { path: "/complaints", element: <ComplaintsPage />, key: 44, requiredPriv: "canListComplaints" },
    { path: "/shifts/assign-fixed-shift", element: <AssignFixedShiftPage />, key: 45, requiredPriv: "canScheduleShifts" },
    { path: "/leave", element: <LeavePage />, key: 46, requiredPriv: "canUseLeave" },
    { path: "/shifts/work-plans", element: <WorkPlanPage />, key: 47, requiredPriv: "canScheduleShifts" },
    { path: "/shifts/work-plans/:id", element: <WorkPlanDetailsPage />, key: 48, requiredPriv: "canScheduleShifts" },
    { path: "/shifts/work-plans/assignment", element: <AssignWorkPlanPage />, key: 49, requiredPriv: "canScheduleShifts" },

]


export default routeDefinitions