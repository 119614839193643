import { isMobile } from "react-device-detect"
import styled from "styled-components"
import Drawer from 'react-modern-drawer'
import NewRecordHeader from "./NewRecordHeader"
import { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import FlexButton from "./FlexButton"


const NewRecordDrawer = ({
    children,
    onSaveClick = () => null,
    title = "",
    open,
    onClose = () => null,
    saveButtonTitle = "Save"
}) => {


    const [_open, setOpen] = useState(open)


    useEffect(() => {
        setOpen(open)
    }, [open])

    const handleClose = () => {
        setOpen(false)
        onClose()
    }

    return (
        <Drawer
            open={_open}
            onClose={handleClose}
            direction='right'
            overlayOpacity={0.1}
            style={{
                width: isMobile ? '100%' : '40%',
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                marginBottom: "80px"
            }}
            duration={300}
        >
            <NewRecordHeader title={title} onClose={handleClose} />
            <DrawerBody>
                <Grid container spacing={0}>
                    {children}
                </Grid>
                {onSaveClick &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <FlexButton text={saveButtonTitle} onClick={onSaveClick} 
                            style={{minWidth: '120px'}}
                        />
                    </div>
                }
                <div style={{ height: '100px' }} />
            </DrawerBody>
        </Drawer>
    )
}


export default NewRecordDrawer;


const DrawerBody = styled.div`	
	height: 100%;
	border-radius: 10px;
	flex-direction: column;
	overflow-y: auto;
`