import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../main-stack/pages/Dashboard";
import NotFound from "../states/NotFound";
import routeDefinitions from "../static/RouteDefinitions";
import ProfilePage from "../main-stack/pages/ProfilePage";




const RouteCollection = ({ privileges }) => {

    const [routes, setRoutes] = useState([])

    const defaultRoutes = [
        { path: "/dashboard", element: <Dashboard />, key: 1 },
        { path: "/", element: <Navigate to={"/dashboard"} replace={true} />, key: 2 },
        { path: "*", element: <NotFound />, key: 4 },
        { path: "/login", element: <Navigate to={"/dashboard"} replace={true} />, key: 5 },
        { path: "/profile", element: <ProfilePage />, key: 999 }
    ]


    useEffect(() => {
        let _routes = [...defaultRoutes]
        routeDefinitions.forEach(r => {
            if (routeAvailable(r)) {
                _routes.push(r)
            }
        })
        setRoutes(_routes)
    }, [])


    const routeAvailable = (route) => {
        if (privileges.includes("superAdmin") || privileges.includes(route.requiredPriv)) {
            return true
        }
        return false
    }

    return (
        <Routes>
            {routes.map(r => <Route path={r.path} element={(r.element)} key={r.key} />)}
        </Routes>
    )
}


export default RouteCollection;