import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../../context/Context';
import API from '../../../static/API';
import t from '../../../static/Language';
import SafeStorage from '../../../static/SafeStorage';
import DataTable from '../../../components/DataTable';
import globalStyles from '../../../static/GlobalStyles';
import FormInput from '../../../components/FormInput';
import Utils from '../../../static/Utils';
import { toast, ToastContainer } from "react-toastify"
import FormMultiSelectInput from '../../../components/FormMultiSelectInput';
import DataSection from '../../../components/DataSection';
import Container from '../../../components/Container';
import NewRecordDrawer from '../../../components/NewRecordDrawer';




const UserRolesPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang


    const user = JSON.parse(SafeStorage.getItem("user"))
    const privileges = user.privileges
    const company = user.company

    const [roles, setRoles] = useState([])
    const [privs, setPrivs] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [roleInfo, setRoleInfo] = useState({ company })
    const [privilegeIds, setPrivilegeIds] = useState([])

    const [roleToEdit, setRoleToEdit] = useState(null)
    const [updateInfo, setUpdateInfo] = useState({})
    const [initialPrivs, setInitialPrivs] = useState([])
    const [actionOptions, setActionOptions] = useState()


    useEffect(() => {
        document.title = t(lang, "userRolesPage.title")
        prepareActionOptions()
        getUserRoles()
        getPrivs()
    }, [])


    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canEditRoles")) {
            _actionOptions["edit"] = {
                onEdit: (row, id) => {
                    setEditOpen(true)
                    setRoleToEdit(row)
                    setInitialPrivs(row.privileges)
                    setPrivilegeIds(row.privileges.map(p => p.id))
                }
            }
        }
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteRoles")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    deleteUserRoles([id])
                }
            }
        }
        setActionOptions(_actionOptions)
    }


    const getUserRoles = () => {
        API.getUserRoles().then(response => { // cevap gelirse rolleri atıyoruz
            setRoles(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getUserRoles().then(resp => { // userları tekrar çekiyoruz
                        setRoles(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const createUserRole = () => {
        API.createUserRole(roleInfo).then(response => {
            let role_id = response.data.user_role_id
            API.createUserRolePrivileges({ role_id: role_id, privilege_ids: privilegeIds }).then(r => {
                getUserRoles()
                toast(t(lang, "userRolesPage.roleCreated"))
            }).catch(err => {
                toast(t(lang, "userRolesPage.roleCreateError"))
            })
        })
    }

    const updateUserRole = () => {
        API.updateUserRole(roleToEdit.id, updateInfo).then(response => {
            let role_id = response.data.user_role_id
            API.updateUserRolePrivileges({ role_id: role_id, privilege_ids: privilegeIds }).then(r => {
                getUserRoles()
                toast(t(lang, "userRolesPage.roleUpdated"))
            }).catch(err => {
                toast(t(lang, "userRolesPage.roleUpdateError"))
            })
        })
    }

    const groupPrivsByModules = (privs) => {
        const modules = []
        privs.forEach(p => {
            const index = modules.findIndex(m => m.name === p.module_name)
            if (index === -1) {
                modules.push({
                    name: p.module_name,
                    id: p.module_id
                })
            }
        });

        const result = []
        modules.forEach(m => {
            const _privs = privs.filter(_p => _p.module_name === m.name)
            result.push({
                label: t(lang, `modules.${m.id}`),
                options: _privs.sort((a, b) => {
                    const aVal = t(lang, `privileges.${a.name}`)
                    const bVal = t(lang, `privileges.${b.name}`)
                    return aVal.localeCompare(bVal)
                })
            })
        })
        return result.sort((a, b) => a.label.localeCompare(b.label))
    }

    const getPrivs = () => {
        API.getPrivileges().then(response => {
            const grouped = groupPrivsByModules(response.data.data)
            setPrivs(grouped)
        }).catch(err => {
            console.log(err)
        })
    }

    const deleteUserRoles = (ids) => {
        API.deleteUserRoles(ids).then(response => {
            getUserRoles()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteUserRoles(ids).then(resp => {
                        getUserRoles()
                    }).catch(er => console.log(er))
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })

            }
        })
    }

    const columns = [
        {
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: true,
                draggable: true
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "description",
            label: t(lang, "fields.description"),
            options: {
                filter: true,
                sort: true
            }
        },
    ]





    const validate = () => {
        if (Utils.nullOrEmpty(roleInfo.name) || Utils.nullOrEmpty(roleInfo.description)) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!validate()) { //
            toast(t(lang, "usersPage.requiredFields"))
            return
        }
        createUserRole()
    }

    const validateUpdate = () => {
        if ((updateInfo.name && Utils.nullOrEmpty(updateInfo.name)) ||
            (updateInfo.description && Utils.nullOrEmpty(updateInfo.description))) {
            return false
        }
        return true
    }

    const handleUpdateClicked = () => {
        if (!validateUpdate()) {
            toast(t(lang, "usersPage.requiredFields"))
            return
        }
        updateUserRole()
    }

    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "userRolesPage.newRole")}
                    onSaveClick={handleSaveClicked}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(val) => setRoleInfo({ ...roleInfo, name: val })}
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.description")}
                            onChange={(val) => setRoleInfo({ ...roleInfo, description: val })}
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Row>
                    <Row>
                        <FormMultiSelectInput
                            values={privs}
                            language={lang}
                            onChange={(ids) => { setPrivilegeIds(ids) }}
                            valueSelector={(item) => t(lang, `privileges.${item.name}`)}
                            isMulti
                            closeMenuOnSelect={false}
                        />
                    </Row>
                </NewRecordDrawer>
                <NewRecordDrawer
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    ttitle={`${roleToEdit?.name}`}
                    onSaveClick={handleUpdateClicked}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, name: val })}
                            helperText="*"
                            helperTextType={"error"}
                            initialValue={roleToEdit?.name}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.description")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, description: val })}
                            helperText="*"
                            helperTextType={"error"}
                            initialValue={roleToEdit?.description}
                        />
                    </Row>

                    <Row>
                        <FormMultiSelectInput
                            isDisabled={roleToEdit?.role_type == "3"}
                            values={privs}
                            language={lang}
                            onChange={(ids) => {
                                setPrivilegeIds(ids)
                            }}
                            initialValues={initialPrivs}
                            valueSelector={(item) => t(lang, `privileges.${item.name}`)}
                        />
                    </Row>
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={roles}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    onRowsDelete={(rows, ids) => deleteUserRoles(ids)}
                    title={t(lang, "userRolesPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateRoles")}
                />
            </DataSection>
        </Container>
    )
}

export default UserRolesPage;



const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`

