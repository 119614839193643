import { Breadcrumbs, Link } from "@mui/material"
import { useContext } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from "styled-components";
import { Context } from "../../../../context/Context";
import globalStyles from "../../../../static/GlobalStyles";
import t from "../../../../static/Language";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import BranchGeneralTab from "./tab-panels/BranchGeneralTab";
import BranchUsersTab from "./tab-panels/BranchUsersTab";
import TabsContainer from "../../../../components/dashboard-components/TabsContainer";



const BranchDetailsPage = () => {
    const { state } = useContext(Context)
    const lang = state.appLang

    const navigate = useNavigate()

    const { id } = useParams()
    const location = useLocation()
    const details = location.state


    return (
        <TabsContainer>
            <Breadcrumbs
                separator={<ArrowForwardIosRoundedIcon style={{ color: "#639ef7" }} />}
            >
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} onClick={() => navigate("/branches")}>
                    <CrumbText>{t(lang, "branchesPage.title")}</CrumbText>
                </Link>
                <CurrentCrumbText>{t(lang, "branchesPage.details")}</CurrentCrumbText>
            </Breadcrumbs>
            <TabsSection>
                <Tabs style={{ height: 'calc(100% - 35px)' }}>
                    <TabList>
                        <Tab>
                            <TabTitle>{t(lang, "common.general")}</TabTitle>
                        </Tab>
                        <Tab>
                            <TabTitle>{t(lang, "branchUsersPage.title")}</TabTitle>
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <BranchGeneralTab details={details} />
                    </TabPanel>
                    <TabPanel style={{ height: '100%' }} >
                        <BranchUsersTab branchId={id} />
                    </TabPanel>
                </Tabs>
            </TabsSection>
        </TabsContainer>
    )
}

export default BranchDetailsPage;




const TabTitle = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
`

const TabsSection = styled.div`
    margin-top: 15px;
    display: flex;
	flex-direction: column;
	height: 100%
`

const CrumbText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 12px;
    cursor: pointer
`

const CurrentCrumbText = styled.span`
    font-family: ${globalStyles.font};
    color: black;
    font-size: 14px;
    cursor: default
`