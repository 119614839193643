import MUIDataTable from "mui-datatables";

import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { IconButton, Tooltip } from "@mui/material";
import globalStyles from "../static/GlobalStyles";
import { Context } from "../context/Context";
import t from "../static/Language";
import { useContext, useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import MessageBox from "./MessageBox";
import { isMobile } from "react-device-detect";
import FlexButton from "./FlexButton";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import PlusOneRoundedIcon from '@mui/icons-material/PlusOneRounded';
import CampaignIcon from '@mui/icons-material/Campaign';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';




const AddButton = ({ lang, onClick = () => null }) => {

    const handleClick = () => {
        onClick()
    }

    return (
        <FlexButton
            onClick={handleClick}
            text={t(lang, "table.newRecord")}
            style={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0, height: '32px', overflow: 'hidden' }}
            renderIcon={() => <div
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '4px',
                    height: '100%'
                }}>
                <PlusOneRoundedIcon style={{ fontSize: '24px', color: "white" }} />
            </div>}
        />
    )
}


const DataTable = ({ columns, data, onRowsDelete, onNewButtonClick, actionOptions, title, customIdSelector, customDeleteRowFields, canCreateNewRecord, onFilterChange }) => {
    const { state } = useContext(Context)
    const lang = state.appLang

    const [selectedRows, setSelectedRows] = useState([])
    const [messageBoxOpen, setMessageBoxOpen] = useState(false)
    const [cols, setCols] = useState()

    const tableRef = useRef()
    const parentRef = useRef(null)


    const [tableHeight, setTableHeight] = useState(0)

    useEffect(() => {
        if (parentRef.current) {
            const parent = parentRef.current.parentNode
            setTableHeight(parent.clientHeight)
        }
        prepareColumns()
    }, [columns])

    const handleEdit = (row, id) => {
        actionOptions?.edit?.onEdit(row, id)
    }

    const handleDelete = (row, id) => {
        actionOptions?.delete?.onDelete(row, id)
    }

    const handleDetails = (row, id) => {
        if (actionOptions?.onShowDetail) {
            actionOptions.onShowDetail(row, id)
        }
    }

    const handleApprove = (row, id) => {
        actionOptions?.approve?.onApprove(row, id)
    }

    const handleReject = (row, id) => {
        actionOptions?.reject?.onReject(row, id)
    }

    const handlePublish = (row, id) => {
        actionOptions?.publish?.onClick(row, id)
    }


    const handleShowStatistics = (row, id) => {
        actionOptions?.statistics?.onShowStatistics(row, id)
    }


    const prepareColumns = () => {
        let _cols = []
        if (actionOptions?.onShowDetail || actionOptions?.edit || actionOptions?.delete || actionOptions?.approve || actionOptions?.publish || actionOptions?.statistics) {
            _cols = [...columns,
            {
                name: t(lang, "table.actions"),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    print: false,

                    customBodyRenderLite: (dataIndex) => {
                        return (
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                                {actionOptions?.statistics &&
                                    < Tooltip title={t(lang, "table.statistics")}>
                                        <IconButton onClick={() => handleShowStatistics(data[dataIndex], data[dataIndex].id)} >
                                            <EqualizerRoundedIcon sx={{ height: 18, color: '#1173cf' }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {actionOptions?.onShowDetail &&
                                    <Tooltip title={t(lang, "table.showDetails")}>
                                        <IconButton onClick={() => handleDetails(data[dataIndex], data[dataIndex].id)} >
                                            <RemoveRedEyeRoundedIcon sx={{ height: 18, color: '#347deb' }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    actionOptions?.edit &&
                                    <Tooltip title={t(lang, "table.edit")}>
                                        <IconButton onClick={() => handleEdit(data[dataIndex], data[dataIndex].id)}>
                                            <EditRoundedIcon sx={{ height: 18, color: globalStyles.textColor }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    actionOptions?.delete &&
                                    <Tooltip title={t(lang, "table.delete")}>
                                        <IconButton onClick={() => handleDelete(data[dataIndex], data[dataIndex].id)}>
                                            <DeleteForeverRoundedIcon sx={{ height: 18, color: '#eb4034' }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    actionOptions?.approve &&
                                    <Tooltip title={t(lang, "common.approve")}>
                                        <IconButton onClick={() => handleApprove(data[dataIndex], data[dataIndex].id)}>
                                            <CheckCircleRoundedIcon sx={{ height: 18, color: "#09b030" }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    actionOptions?.reject &&
                                    <Tooltip title={t(lang, "common.reject")}>
                                        <IconButton onClick={() => handleReject(data[dataIndex], data[dataIndex].id)}>
                                            <BlockRoundedIcon sx={{ height: 18, color: "red" }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    actionOptions?.publish &&
                                    <Tooltip title={t(lang, "announcementsPage.publish")}>
                                        <IconButton onClick={() => handlePublish(data[dataIndex], data[dataIndex].id)}>
                                            <CampaignIcon sx={{ height: 18, color: "green" }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div >
                        )
                    }
                }
            }]
        } else {
            _cols = [...columns]
        }
        setCols(_cols)
    }



    const textLabels = {
        body: {
            noMatch: t(lang, "table.noMatch"),
            toolTip: t(lang, "table.toolTip"),
        },
        pagination: {
            next: t(lang, "table.next"),
            previous: t(lang, "table.previous"),
            rowsPerPage: t(lang, "table.rowsPerPage"),
            displayRows: t(lang, "table.displayRows"),
            jumpToPage: t(lang, "table.jumpToPage"),
        },
        toolbar: {
            search: t(lang, "table.search"),
            downloadCsv: t(lang, "table.downloadCsv"),
            print: t(lang, "table.print"),
            viewColumns: t(lang, "table.viewColumns"),
            filterTable: t(lang, "table.filterTable")
        },
        filter: {
            all: t(lang, "table.all"),
            title: t(lang, "table.fTitle"),
            reset: t(lang, "table.reset")
        },
        viewColumns: {
            title: t(lang, "table.title"),
            titleAria: t(lang, "table.titleAria")
        },
        selectedRows: {
            text: t(lang, "table.text"),
            delete: t(lang, "table.delete"),
            deleteAria: t(lang, "table.deleteAria")
        }
    }



    const tableTheme = createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        width: '100%',
                    },


                }
            },
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                    },

                }
            },

            MUIDataTablePagination: {
                styleOverrides: {
                    root: {
                        '@media print': {
                            display: 'none'
                        },
                    },
                }
            },
            MUIDataTableToolbar: {
                styleOverrides: {
                    root: {
                        '@media print': {
                            display: 'none'
                        }
                    },

                }
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        //backgroundColor: "#f0f5fc !important",
                        borderBottomWidth: 1,
                        borderBottomColor: globalStyles.firstColor,
                        fontFamily: globalStyles.font,
                        fontWeight: 'bold',
                        textAlign: 'left',
                    },
                    body: {
                        fontFamily: globalStyles.font,
                        textAlign: 'left'
                    },

                }
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    },
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                        fontWeight: 'bold',
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    },
                    h6: {
                        fontWeight: 'bold'
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font
                    },
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiTablePagination: {
                styleOverrides: {
                    selectLabel: {
                        fontFamily: globalStyles.font
                    },
                    displayedRows: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        fontFamily: globalStyles.font
                    },
                    standard: {
                        fontFamily: globalStyles.font
                    },
                    multiple: {
                        fontFamily: globalStyles.font
                    },
                    nativeInput: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    labelMedium: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.secondColor
                    },
                    root: {
                        borderRadius: '8px',
                    },
                    deleteIcon: {
                        color: globalStyles.secondColor
                    },

                }
            },
        }
    })


    const handleNewButtonClicked = () => {
        if (onNewButtonClick) {
            onNewButtonClick()
        }
    }

    const handleRowsDelete = () => {
        let idSelector = customIdSelector ?? "id"
        let ids = []
        let customRows = []
        selectedRows.forEach(row => {
            ids.push(data[row.dataIndex][idSelector])
            if (customDeleteRowFields) {
                let customRowObj = {}
                customDeleteRowFields.forEach(f => {
                    customRowObj[f] = data[row.dataIndex][f]
                })
                customRows.push(customRowObj)
            }
        });

        let rows = customDeleteRowFields ? customRows : selectedRows
        if (onRowsDelete) {
            onRowsDelete(rows, ids)
        }

    }




    return (
        <div ref={parentRef} style={{ height: '100%', width: '100%' }}>
            <MessageBox
                title={`${t(lang, "common.warning")}`}
                message={t(lang, "common.deletionWarning")}
                open={messageBoxOpen}
                actions={[
                    {
                        name: t(lang, "common.yes"),
                        onClick: () => {
                            setMessageBoxOpen(false)
                            handleRowsDelete()
                        },
                        style: {
                            backgroundColor: globalStyles.secondColor,
                            ':hover': {
                                backgroundColor: "#08080a",
                            },
                            marginRight: 0
                        }
                    },
                    {
                        name: t(lang, "common.cancel"),
                        onClick: () => setMessageBoxOpen(false)
                    }
                ]}
            />

            <ThemeProvider theme={tableTheme}>
                <MUIDataTable
                    ref={parentRef}
                    innerRef={tableRef}
                    title={title}
                    data={data}
                    columns={cols}
                    options={{
                        onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                            if (onFilterChange) {
                                onFilterChange(changedColumn, filterList, type, changedColumnIndex, displayData)
                            }
                        },
                        selectableRows: actionOptions?.delete ? "multiple" : "none",
                        onRowSelectionChange: (curRowSelected, allRowsSelected) => { setSelectedRows(allRowsSelected) },
                        fixedHeader: true,
                        jumpToPage: true,
                        responsive: isMobile ? "simple" : "standard",
                        tableBodyMaxHeight: tableHeight - 138,
                        filterType: "multiselect",
                        textLabels: textLabels,
                        customToolbar: () => canCreateNewRecord ? <AddButton lang={lang} onClick={handleNewButtonClicked} /> : null,
                        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                            <div style={{ paddingRight: 15 }}>
                                <IconButton onClick={() => setMessageBoxOpen(true)}>
                                    <DeleteForeverRoundedIcon sx={{ color: '#f54266', fontSize: 40 }} />
                                </IconButton>
                            </div>
                        ),
                        rowsPerPageOptions: [10, 20, 50],

                    }}
                    components={{
                        icons: {
                            SearchIcon: () => { return (<ManageSearchRoundedIcon sx={{ color: globalStyles.textColor }} />) },
                            PrintIcon: () => { return (<PrintRoundedIcon sx={{ color: globalStyles.textColor }} />) },
                            DownloadIcon: () => { return (<DownloadRoundedIcon sx={{ color: globalStyles.textColor }} />) },
                            FilterIcon: () => { return (<FilterAltRoundedIcon sx={{ color: globalStyles.textColor }} />) },
                            ViewColumnIcon: () => { return (<ViewWeekRoundedIcon sx={{ color: globalStyles.textColor }} />) }
                        },
                    }}
                />
            </ThemeProvider>
        </div>
    )
}


export default DataTable


