import { useContext, useEffect, useState } from "react";
import API from "../../../../static/API";
import { Context } from "../../../../context/Context";
import Loading from "../../../../states/Loading";
import DataTable from "../../../../components/DataTable";
import t from "../../../../static/Language";
import SafeStorage from "../../../../static/SafeStorage";
import FormMultiSelectInput from "../../../../components/FormMultiSelectInput";
import Container from "../../../../components/Container";
import Avatar from "../../../../components/Avatar";
import NewRecordDrawer from "../../../../components/NewRecordDrawer";




const DepartmentUsersTab = ({ departmentInfo }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const departmentId = departmentInfo.id
    const companyId = departmentInfo.company

    const [users, setUsers] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [loading, setLoading] = useState(true)
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [actionOptions, setActionOptions] = useState()
    const [selectedUserIds, setSelectedUserIds] = useState([])


    useEffect(() => {
        get()
        getAllUsers()
        prepareActionOptions()
    }, [])


    const prepareActionOptions = () => {
        let _actionOptions = {}
        _actionOptions["delete"] = {
            onDelete: (row, id) => {
                _delete([id])
            }
        }
        setActionOptions(_actionOptions)
    }

    const get = () => {
        API.getDepartmentUsers(departmentId).then(response => { // cevap gelirse userları atıyoruz
            setUsers(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getDepartmentUsers(departmentId).then(resp => { // userları tekrar çekiyoruz
                        setUsers(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        }).finally(() => setLoading(false))
    }

    const getAllUsers = () => {
        API.getUsersOfCompany(companyId).then(response => { // cevap gelirse userları atıyoruz
            setAllUsers(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getUsersOfCompany(companyId).then(resp => { // userları tekrar çekiyoruz
                        setAllUsers(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const create = (ids) => {
        const data = {
            department_id: departmentId,
            user_ids: ids
        }
        API.createDepartmentUserRelations(data).then(response => {
            get()
        })

    }

    const _delete = (ids) => {
        const data = []
        ids.forEach(id => {
            data.push({
                user_id: id,
                department_id: departmentId
            })
        })
        API.deleteDepartmentUsers(data).then(response => {
            // eğer cevap başarılı ise localdeki listeden de departman kullanıcılarını kaldırıyoruz
            const newUsers = users.filter(u => ids.includes(u.id) === false)
            setUsers(newUsers)
        })
    }


    const columns = [
        {
            name: "user_name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: true,
                draggable: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                            <Avatar
                                avatar={users[rowIndex]?.avatar}
                                name={users[rowIndex]?.name}
                                lastName={users[rowIndex]?.last_name}
                                size={40}
                                isSquare
                            />
                            <span style={{ marginLeft: '8px' }}>{`${users[rowIndex]?.user_name}`}</span>
                        </div>
                    );
                }
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }
        }
    ]



    const handleSaveClicked = () => {
        create(selectedUserIds)
    }


    const deleteUsers = () => {

    }

    if (loading) {
        return <Loading />
    }



    return (
        <Container>
            <NewRecordDrawer
                open={newRecordOpen}
                onClose={() => setNewRecordOpen(false)}
                saveButtonTitle={t(lang, "common.save")}
                title={t(lang, "departmentsPage.departmentUsers")}
                onSaveClick={handleSaveClicked}
            >
                <FormMultiSelectInput
                    language={lang}
                    values={allUsers}
                    onChange={ids => setSelectedUserIds(ids)}
                />
            </NewRecordDrawer>
            <DataTable
                columns={columns}
                data={users}
                actionOptions={actionOptions}
                onNewButtonClick={() => setNewRecordOpen(true)}
                onRowsDelete={(rows, ids) => deleteUsers(ids)}
                canCreateNewRecord={true}
            />
        </Container>
    )

}

export default DepartmentUsersTab;


