import { useContext } from "react"
import styled from "styled-components"
import { Context } from "../../../../context/Context"
import Nodata from "../../../../states/Nodata"
import t from "../../../../static/Language"
import DataTable from "../../../../components/DataTable"
import Container from "../../../../components/Container"
import Avatar from "../../../../components/Avatar"






const UserStaffTab = ({ staff }) => {
    const { state } = useContext(Context)
    const lang = state.appLang



    if (!staff || staff?.length === 0) {
        return <Nodata msg={t(lang, "common.noData")} />
    }



    const columns = [
        {
            name: "avatar",
            label: t(lang, "fields.profilePicture"),
            options: {
                filter: true,
                sort: false,
                print: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                            <Avatar
                                avatar={value}
                                size={40}
                                isSquare
                                name={staff[tableMeta.rowIndex]?.name}
                                lastName={staff[tableMeta.rowIndex]?.last_name}
                            />
                        </div>
                    )
                }
            }
        },
        {
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: true,
                draggable: true,

            }
        },
        {
            name: "last_name",
            label: t(lang, "fields.lastName"),
            options: {
                filter: true,
                sort: true,
                draggable: true,

            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                draggable: true,

            }
        }
    ]

    return (
        <Container>
            <DataTable
                columns={columns}
                data={staff}
            />
        </Container>
    )
}

export default UserStaffTab




