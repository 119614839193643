import { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/Context";
import SafeStorage from "../../../static/SafeStorage";
import { useNavigate } from "react-router-dom";
import API from "../../../static/API";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import globalStyles from "../../../static/GlobalStyles";
import { Grid } from "@mui/material";
import Utils from "../../../static/Utils";
import FormInput from "../../../components/FormInput";
import t from "../../../static/Language";
import DataTable from "../../../components/DataTable";
import FormSelectInput from "../../../components/FormSelectInput";
import DataSection from "../../../components/DataSection";
import Container from "../../../components/Container";
import NewRecordDrawer from "../../../components/NewRecordDrawer";



const DepartmentsPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const user = JSON.parse(SafeStorage.getItem("user"))
    const privileges = user.privileges

    const [data, setData] = useState([])
    const [actionOptions, setActionOptions] = useState()
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [objInfo, setObjInfo] = useState()
    const [updateInfo, setUpdateInfo] = useState({})
    const [editOpen, setEditOpen] = useState(false)
    const [objToEdit, setObjToEdit] = useState(null)
    const [company, setCompany] = useState(null)
    const [parentDepartment, setParentDepartment] = useState(null)
    const [companies, setCompanies] = useState([])

    const navigate = useNavigate()


    let _companies = []
    let _departments = []

    useEffect(() => {
        document.title = t(lang, "menuNames.departments")
        get()
        prepareActionOptions()
        getOthers()
    }, [])



    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canEditDepartments")) {
            _actionOptions["edit"] = {
                onEdit: (row, id) => {
                    let comp = _companies.find(c => c.id === row.company)
                    let parent = _departments.find(d => d.id === row.parent_department)
                    setObjToEdit(row)
                    setCompany(comp)
                    setParentDepartment(parent)
                    setEditOpen(true)

                }
            }
        }
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteDepartments")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([id])
                }
            }
        }
        _actionOptions.onShowDetail = (row, id) => {
            //alert(`${row} ${id} detay görüntülenecek`)
            navigate(`/departments/${id}`, { state: row })
        }

        setActionOptions(_actionOptions)
    }


    const get = () => {
        API.getDepartments().then(response => { // cevap gelirse datayı atıyoruz
            setData(response.data.data)
            _departments = response.data.data
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getDepartments().then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                        _departments = resp.data.data
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const getOthers = () => {
        // eğer sistem admini ise şirketleri çekeceğiz
        if (!Utils.isSystemAdmin()) {
            return
        }
        API.getCompanies().then(response => {
            setCompanies(response.data.data)
            _companies = response.data.data
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getCompanies().then(resp => { // userları tekrar çekiyoruz
                        setCompanies(resp.data.data)
                        _companies = resp.data.data
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const create = (obj) => {
        const _obj = obj ?? objInfo
        if (Utils.isSystemAdmin()) {
            if (Utils.nullOrEmpty(_obj.company)) {
                toast(t(lang, "departmentsPage.requiredFields"))
                return
            }
        } else {
            _obj.company = user.company
        }
        API.createDepartment(_obj).then(response => {
            get()
            toast(t(lang, "departmentsPage.created"))
        }).catch(err => { // hata alınırsa
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.createDepartment(_obj).then(resp => { // userları tekrar çekiyoruz
                        get()
                        toast(t(lang, "departmentsPage.created"))
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const update = (obj) => {
        const _obj = obj ?? updateInfo
        if (Utils.isSystemAdmin()) {
            if (Utils.nullOrEmpty(_obj.company)) {
                toast(t(lang, "departmentsPage.requiredFields"))
                return
            }
        }
        API.updateDepartment(objToEdit.id, _obj).then(() => {
            toast(t(lang, "departmentsPage.updated"))
            get()
        }).catch(err => {
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.updateDepartment(objToEdit.id, _obj).then(resp => { // userları tekrar çekiyoruz
                        toast(t(lang, "departmentsPage.updated"))
                        get()
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const _delete = (ids) => {
        API.deleteDepartments(ids).then(() => {
            toast(t(lang, "departmentsPage.deleted"))
            get()
        }).catch(err => {
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteDepartments(ids).then(resp => { // userları tekrar çekiyoruz
                        toast(t(lang, "departmentsPage.deleted"))
                        get()
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: true,
                draggable: true
            }
        },
        {
            name: "parent_department_name",
            label: t(lang, "fields.parentDepartment"),
            options: {
                filter: true,
                sort: true,
                draggable: true
            }
        }
    ]


    const validate = (obj) => {
        if (Utils.nullOrEmpty(obj.name)) {
            return false
        }
        return true
    }

    const validateUpdate = (obj) => {
        if ((Utils.nullOrEmpty(obj.name))) {
            return false
        }
        return true
    }


    const handleSaveClicked = () => {
        const obj = { ...objInfo }
        if (!validate(obj)) {
            toast(t(lang, "departmentsPage.requiredFields"))
            return
        }
        create(obj)
    }

    const handleUpdateClicked = () => {
        const obj = { ...updateInfo }
        if (!validateUpdate(obj)) {
            toast(t(lang, "departmentsPage.requiredFields"))
            return
        }
        update(obj)
    }

    const handleMultipleDelete = (ids) => {
        _delete(ids)
    }


    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "departmentsPage.new")}
                    onSaveClick={handleSaveClicked}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(text) => setObjInfo({ ...objInfo, name: text })}
                            helperText={'*'}
                            helperTextType={'error'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormSelectInput
                            label={t(lang, "fields.parentDepartment")}
                            onSelect={(item) => setObjInfo({ ...objInfo, parent_department: item.id === -1 ? null : item.id })}
                            data={data}
                            itemComponent={(item, index) => {
                                return (
                                    <ItemText>{`${item.name}`}</ItemText>
                                )
                            }}
                        />
                    </Grid>
                    {Utils.isSystemAdmin() &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormSelectInput
                                label={t(lang, "fields.company")}
                                onSelect={(item) => setObjInfo({ ...objInfo, company: item.id === -1 ? null : item.id })}
                                data={companies}
                                helperText={'*'}
                                itemComponent={(item, index) => {
                                    return (
                                        <ItemText>{`${item.name}`}</ItemText>
                                    )
                                }}
                            />
                        </Grid>
                    }
                </NewRecordDrawer>
                <NewRecordDrawer
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={objToEdit?.name}
                    onSaveClick={handleSaveClicked}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(text) => setUpdateInfo({ ...updateInfo, name: text })}
                            helperText={'*'}
                            helperTextType={'error'}
                            initialValue={objToEdit?.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormSelectInput
                            label={t(lang, "fields.parentDepartment")}
                            onSelect={(item) => setUpdateInfo({ ...updateInfo, parent_department: item.id === -1 ? null : item.id })}
                            data={data}
                            initialValue={parentDepartment}
                            itemComponent={(item, index) => {
                                return (
                                    <ItemText>{`${item.name}`}</ItemText>
                                )
                            }}
                        />
                    </Grid>
                    {Utils.isSystemAdmin() &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormSelectInput
                                label={t(lang, "fields.company")}
                                onSelect={(item) => setUpdateInfo({ ...updateInfo, company: item.id === -1 ? null : item.id })}
                                helperText={'*'}
                                data={companies}
                                initialValue={null}
                                itemComponent={(item, index) => {
                                    return (
                                        <ItemText>{`${item.name}`}</ItemText>
                                    )
                                }}
                            />
                        </Grid>
                    }
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    title={t(lang, "departmentsPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateDepartments")}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    actionOptions={actionOptions}
                    onRowsDelete={(rows, ids) => handleMultipleDelete(ids)}
                />
            </DataSection>
        </Container>
    )

}


export default DepartmentsPage;





const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`