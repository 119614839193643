import styled from "styled-components";


const TabsContainer = styled.div`
    display: flex;
    height: calc(100% - 40px);
    flex-direction: column; 
    margin: 20px;
    overflow: hidden
`

export default TabsContainer