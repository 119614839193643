import { useContext, useState } from "react"
import styled from "styled-components"
import { Context } from "../../context/Context"
import t from "../../static/Language"

import bg from '../../assets/media/bg.jpg'
import { Alert, Card, IconButton, Snackbar } from "@mui/material"
import globalStyles from "../../static/GlobalStyles"

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import tr from 'react-phone-input-2/lang/tr.json'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useNavigate } from "react-router-dom"
import API from "../../static/API"
import SafeStorage from "../../static/SafeStorage"



const PhoneConfirmationPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const navigate = useNavigate()

    const [phone, setPhone] = useState("")
    const [dialCode, setDialCode] = useState("")
    const [messageOpen, setMessageOpen] = useState(false)


    const handleSubmit = (e) => {
        e.preventDefault();
        API.startRefreshPasswordSMSFlow(phone, dialCode).then(response => {
            const userId = response.data.user_id
            SafeStorage.setItem("userId", userId)
            navigate('/otp', { state: { canGo: true } })
        }).catch(err => {
            setMessageOpen(true)
        })

    }

    const handleChange = (v, c, e, f) => {
        setDialCode(c.dialCode)
        setPhone(v)
    }

    return (
        <Container onSubmit={e => handleSubmit(e)}>
            <Snackbar
                open={messageOpen}
                autoHideDuration={3000}
                onClose={() => setMessageOpen(false)}
            >
                <Alert sx={{ width: '100%' }} severity={"error"}>
                    {t(lang, "login.wrongPhoneNumber")}
                </Alert>
            </Snackbar>
            <LoginCard>
                <WelcomeText>
                    {t(lang, "login.phoneNumberMsg")}
                </WelcomeText>
                <PhoneInput
                    onlyCountries={['tr', 'en', 'us', 'ru']}
                    localization={tr}
                    country={'tr'}
                    value={phone}
                    onChange={(value, country, e, formattedValue) => handleChange(value, country, e, formattedValue)}
                    containerStyle={{ marginTop: 50, marginBottom: 25, width: '80%', alignSelf: 'center' }}
                    inputStyle={{
                        fontFamily: globalStyles.font,
                        fontSize: 18,
                        color: globalStyles.textColor,
                        fontWeight: 'bold',
                        width: '100%',
                        borderWidth: 0,
                        borderBottomWidth: 1,
                        backgroundColor: 'transparent'
                    }}
                    buttonStyle={{
                        borderWidth: 0,
                        fontFamily: globalStyles.font,
                        fontSize: 16,
                        color: globalStyles.textColor,
                        backgroundColor: 'transparent'
                    }}

                    inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true
                    }}
                />
                <SubmitButton type="submit" >
                    <LoginText>
                        {t(lang, "common.validate")}
                    </LoginText>
                    <ArrowForwardIosRoundedIcon style={{ width: 16 }} />

                </SubmitButton>
            </LoginCard>

        </Container>
    )
}


export default PhoneConfirmationPage;




const SubmitButton = styled(IconButton)`
    align-self: center;
    margin-top: 24px !important;
    border-radius: 15px !important;
    border-width: 0px;
	background-color: ${props => props.disabled ? '#b8b4ab' : '#FFE88C'} !important;
    padding: 5px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;

`




const Container = styled.form`
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #FFE88C;
    background-image: url(${bg});
    background-size: cover
`

const LoginCard = styled.div`
    display: flex;
    padding: 20px;
    border-radius: 24px !important;
    flex-direction: column;
    padding-top: 30px;
    background-color: transparent;
    border: solid 2px white;
    backdrop-filter: blur(15px);
`

const Logo = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 12px;
`

const WelcomeText = styled.text`
    color: #353535;
    font-size: 20px;
    margin-top: 15px;
    font-family: ${globalStyles.font};
    font-weight: bold
`

const LoginText = styled.text`
    color: #353535;
    font-size: 16px;
    font-family: ${globalStyles.font};
`


