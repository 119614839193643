import React, { useContext, useEffect, useState } from 'react';
import 'react-modern-drawer/dist/index.css'
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from 'react-router-dom';
import API from '../../../static/API';
import t from '../../../static/Language';
import SafeStorage from '../../../static/SafeStorage';
import globalStyles from '../../../static/GlobalStyles';
import DataTable from '../../../components/DataTable';
import { Context } from '../../../context/Context';
import Container from '../../../components/Container';
import DataSection from '../../../components/DataSection';



const SurveysPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const privileges = JSON.parse(SafeStorage.getItem("user")).privileges


    const [data, setData] = useState([])
    const [actionOptions, setActionOptions] = useState()

    const navigate = useNavigate()


    useEffect(() => {
        document.title = t(lang, "menuNames.surveys")
        get()
        prepareActionOptions()

    }, [])





    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canEditSurveys")) {
            _actionOptions["edit"] = {
                onEdit: (row, id) => {

                    //setEditOpen(true)
                    navigate(`/surveys/edit/${id}`)
                }
            }
        }
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteSurveys")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([id])
                }
            }
        }
      

        _actionOptions.statistics = {
            onShowStatistics: (row, id) => {
                //alert(`${row} ${id} detay görüntülenecek`)
                navigate(`/surveys/${id}`)
            }
        }

        setActionOptions(_actionOptions)
    }


    const get = () => {
        API.getSurveys().then(response => { // cevap gelirse datayı atıyoruz
            setData(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getSurveys().then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }



    const _delete = (ids) => {
        API.deleteSurveys(ids).then(response => {
            get()
        })
    }








    const columns = [
        {
            name: "title",
            label: t(lang, "fields.title"),
            options: {
                filter: true,
                sort: true,
                draggable: true
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }
        }
    ]


    return (

        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <DataTable
                    columns={columns}
                    data={data}
                    title={t(lang, "menuNames.surveys")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateSurveys")}
                    onNewButtonClick={() => navigate(`/surveys/new`)}
                    actionOptions={actionOptions}
                />
            </DataSection>
        </Container>
    )

}


export default SurveysPage;

