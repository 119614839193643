import { forwardRef, useState } from "react";
import t from "../../static/Language";
import { Box, Grid } from "@mui/material";
import FormInput from "../FormInput";
import Utils from "../../static/Utils";
import QuestionCard from "./QuestionCard";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FlexButton from "../FlexButton";
import RobotButton from "../RobotButton";
import OpenAI from 'openai';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { Player } from "@lottiefiles/react-lottie-player/dist/lottie-react";
import styled from "styled-components";
import globalStyles from "../../static/GlobalStyles";
import { toast, ToastContainer } from "react-toastify"



const openai = new OpenAI({ apiKey: "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", dangerouslyAllowBrowser: true })


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const aiSpeech = [
    "Hemen başlıyorum...",
    "Kağıt kalem bulayım...",
    "Kardeşim defterimi karalamış...",
    "Ne sorsam, ne sorsam...",
    "Tamam buldum sanırım, hemen hazırlıyorum!",
    "Silgimi kaybettim...",
    "Yok bu içime sinmedi...",
    "Çok güzel soru...",
    "Yemeğe nereye gideriz?",
    "Maaşlar yatmış bu arada...",
    "Kahve makinesi tamirden geldi mi?",
    "O değil de kedim hastalandı...",
    "Şirkete kreş yapacaklarmış...",
    "Bence şampiyon biziz...",
    "Bu sene bayram tatili 9 gün mü?",
    "Bence soğanlı olmalı...",
    "Hamsiköy sütlacı olsaydı keşke...",
    "Şekeri bırakmayı düşünüyorum...",
    "Akşam n'apıyoruz?",
    "Çok can alıcı bir soru...",
    "Bunu sormaktan vazgeçtim...",
    "Bunu sanki söylemiştim...",
    "Harika bir soru buldum...",
    "Temel de demiş ki...",
    "İndirimden ayakkabı aldım...",
    "Bu soru çok can yakar...",
    "Bizim takım Maxim Tsigalko'yu almış",
    "Dans kursuna yazıldım...",
    "Çok güzel bir anket...",
    "Kafam karıştı...",
    "Lokmacı mı açsak?",
    "Çok az kaldı..."
]

const questionTypeMapping = {
    "single_choice": 1,
    "multiple_choice": 2,
    "multiple_choice_alternate": 3,
    "free_text": 4
}

const SurveyCreator = ({
    onQuestionsChange = (questions) => null,
    onTitleChange = (title) => null,
    lang = "tr",
    initialQuestions = [],
    initialTitle = ""
}) => {

    const [_questions, setQuestions] = useState(initialQuestions)
    const [_title, setTitle] = useState(initialTitle)
    const [aiIstructions, setAiInstructions] = useState("")
    const [loading, setLoading] = useState(false)
    const [aiSays, setAiSays] = useState(aiSpeech[0])
    //const [speechInterval, setSpeechInterval] = useState()
    let speechInterval;

    const getAISurvey = async () => {
        if (Utils.nullOrEmpty(aiIstructions)) {
            return
        }
        try {
            setLoading(true)
            startAISpeech()
            setQuestions([])
            onQuestionsChange([])
            const assistant = await openai.beta.assistants.retrieve("asst_ru5HsXE0p31Ume3FGGlZz9h7")
            console.log("assistant initialized")
            const thread = await openai.beta.threads.create()
            await openai.beta.threads.messages.create(thread.id, {
                role: 'user',
                content: aiIstructions
            })
            const run = await openai.beta.threads.runs.create(thread.id, {
                assistant_id: assistant.id
            })
            let runStatus = await openai.beta.threads.runs.retrieve(
                thread.id,
                run.id
            );

            while (runStatus.status !== "completed") {
                console.log("run not completed")

                await new Promise((resolve) => setTimeout(resolve, 1000));
                runStatus = await openai.beta.threads.runs.retrieve(thread.id, run.id);
            }
            const messages = await openai.beta.threads.messages.list(thread.id);
            const aiResponse =  tryParseJSON(messages.data[0].content[0].text.value)
            if (aiResponse.status === 'fail') {
                throw aiResponse.message
            }
            

            const survey = aiResponse.survey
            setTitle(survey.survey_title)
            onTitleChange(survey.survey_title)

            const aiQuestions = survey.questions
            const transformedQuestions = transformAIQuestions(aiQuestions)
            console.log(transformedQuestions)
            setQuestions(transformedQuestions)
            onQuestionsChange(transformedQuestions)

        } catch (error) {
            console.log(error)
            toast(error)
        } finally {
            setLoading(false)
            stopAISpeech()
        }

    }


    const tryParseJSON  = (value) => {
        try {
            return JSON.parse(value)
        } catch (error) {
            console.log("error in step 1", error)
            try {
                return JSON.parse(value.replace("```json", "").replace("```", ""))
            } catch (error) {
                console.log("error in step 2", error)
                throw error
            }
        }
    }

    const transformAIQuestions = (aiQuestions) => {
        let newQuestions = []
        aiQuestions.forEach(q => {
            let answerSet = []
            q.answers.forEach(a => {
                answerSet.push({
                    calculation_value: a.calculation_value ?? 0,
                    description: a.answer,
                    id: Utils.uuid4()
                })
            })
            newQuestions.push({
                title: q.question,
                question_type: questionTypeMapping[q.question_type],
                id: Utils.uuid4(),
                choices: answerSet
            })
        })
        return newQuestions
    }

    const startAISpeech = () => {
        const max = aiSpeech.length
        const min = 1

        speechInterval = setInterval(() => {
            const index = Math.floor(Math.random() * (max - min) + min);
            setAiSays(aiSpeech[index])
        }, 2500)
    }


    const stopAISpeech = () => {
        clearInterval(speechInterval)
    }


    return (

        <Box>
            <ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				pauseOnHover
				theme="light"
				toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
			/>
            <Dialog
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                open={loading}
            >
                <DialogContent
                    sx={{ alignItems: 'center', justifyContent: 'center', width: '300px' }}
                >
                    <RobotSpeech>
                        {aiSays}
                    </RobotSpeech>
                    <Player
                        src={require('../../assets/lottie-animations/assistant-progress.json')}
                        autoplay
                        loop
                        style={{ width: "200px", height: "200px" }}
                    />
                </DialogContent>
            </Dialog>
            <Grid
                item
                xs={12} sm={12} md={12} lg={12}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderWidth: 1,
                    borderColor: "black"
                }}
            >
                <RobotButton />
                <FormInput
                    label={"Merhaba!"}
                    type="text"
                    multiline={true}
                    onChange={(text) => {
                        setAiInstructions(text)
                    }}
                    onEnterPress={e => getAISurvey()}
                />

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormInput
                    label={t(lang, "surveysPage.surveyTitle")}
                    type="text"
                    initialValue={initialTitle}
                    onChange={(text) => {
                        setTitle(text)
                        onTitleChange(text)
                    }}
                />
            </Grid>

            {_questions.map((q, i) => {
                return (
                    <QuestionCard
                        key={i}
                        lang={lang}
                        question={q}
                        onDelete={() => {
                            const newQuestions = [..._questions]
                            newQuestions.splice(i, 1)
                            setQuestions(newQuestions)
                            onQuestionsChange(newQuestions)
                        }}
                        onChange={(question) => {
                            const index = _questions.findIndex((_q) => _q.id === question.id);
                            const updated = { ..._questions[index], ...question };
                            const newQuestions = [..._questions];
                            newQuestions[index] = updated
                            setQuestions(newQuestions)
                            onQuestionsChange(newQuestions)
                        }}
                    />
                )
            })}

            <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                <FlexButton
                    onClick={() => {
                        let qs = [
                            ..._questions,
                            { id: Utils.uuid4(), question_type: 1 }
                        ]
                        setQuestions(qs)
                    }}
                    size="small"
                    renderIcon={() => <AddRoundedIcon />}
                    text={t(lang, "surveysPage.newQuestion")}
                />

            </Box>

        </Box>
    )

}

export default SurveyCreator;

const RobotSpeech = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 14px
`
