import { Player } from "@lottiefiles/react-lottie-player"
import { Card, Grid, IconButton, Tooltip as Tip } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Context } from "../../context/Context"
import API from "../../static/API"
import t from "../../static/Language"
import ChartHeader from "./ChartHeader"
import { Chart as ChartJS, defaults, ArcElement, Tooltip, Legend } from 'chart.js/auto'
import globalStyles from "../../static/GlobalStyles"
import { Doughnut } from 'react-chartjs-2';
import TrafficRoundedIcon from '@mui/icons-material/TrafficRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';


ChartJS.register(ArcElement, Tooltip, Legend);




defaults.font.family = globalStyles.font


const GateDistributionChart = () => {
    const { state } = useContext(Context)
    const lang = state.appLang
    const [data, setData] = useState()
    const [labels, setLabels] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        get()
    }, [])

    const get = () => {
        API.getGateActivity().then(response => {
            prepareData(response.data.data)
        })
    }


    const prepareData = (list) => {
        let _data = []
        let _labels = []
        list.forEach(d => {
            _data.push(d.count)
            _labels.push(d.name)
        });
        setData(_data)
        setLabels(_labels)
    }

    return (
        <Grid item xs={12} sm={12} md={4} lg={4} >
            <Card elevation={4} sx={{ padding: "10px", height: 400, display: 'flex', flexDirection: 'column' }}>
                {data ? (
                    <>
                        <ChartHeader
                            title={t(lang, "dashboard.gateTraffic")}
                            detailsComponent={
                                <Tip title={t(lang, "common.details")} >
                                    <IconButton
                                        style={{ alignSelf: 'center' }}
                                        onClick={() => navigate("/gates")}
                                    >
                                        <OpenInNewRoundedIcon style={{ color: "grey", fontSize: 18 }} />
                                    </IconButton>
                                </Tip>
                            }
                            icon={<TrafficRoundedIcon style={{ fontSize: 30, color: globalStyles.textColor }} />}
                        />
                        <div style={{ display: 'flex', flex: 1,alignItems: 'center', justifyContent: 'center' }}>
                            <Doughnut
                                options={{
                                    cutout: '90%',
                                    radius: '60%',
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: true,
                                        }
                                    }
                                }}
                                data={{
                                    labels: labels,
                                    datasets: [{
                                        data: data,
                                        borderWidth: 0,
                                    }]
                                }}
                            />
                        </div>

                    </>
                ) : (
                    <Body>
                        <Player
                            src={require('../../assets/lottie-animations/loading.json')}
                            autoplay
                            loop
                            style={{ width: 60 }}
                        />
                    </Body>
                )}
            </Card>
        </Grid>
    )

}


export default GateDistributionChart

const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`