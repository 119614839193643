import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../../../context/Context';
import API from '../../../../static/API';
import t from '../../../../static/Language';
import SafeStorage from '../../../../static/SafeStorage';
import 'react-modern-drawer/dist/index.css'
import DataTable from '../../../../components/DataTable';
import globalStyles from '../../../../static/GlobalStyles';
import FormInput from '../../../../components/FormInput';
import FormSelectInput from '../../../../components/FormSelectInput';
import Utils from '../../../../static/Utils';
import { toast, ToastContainer } from "react-toastify"
import DataSection from '../../../../components/DataSection';
import Container from '../../../../components/Container';
import NewRecordDrawer from '../../../../components/NewRecordDrawer';




const BuildingsPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const currentUser = JSON.parse(SafeStorage.getItem("user"))
    const privileges = currentUser.privileges
    const userCompany = currentUser.company


    const [buildings, setBuildings] = useState([])
    const [branches, setBranches] = useState([])
    const [companies, setCompanies] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [buildingInfo, setBuildingInfo] = useState(privileges.includes("superAdmin") ? {} : { company: userCompany })
    const [buildingToEdit, setBuildingToEdit] = useState(null)
    const [updateInfo, setUpdateInfo] = useState({})
    const [actionOptions, setActionOptions] = useState()
    const [initialCompany, setInitialCompany] = useState()
    const [initialBranch, setInitialBranch] = useState()


    useEffect(() => {
        document.title = t(lang, "buildingsPage.title")
        prepareActionOptions()
        getBuildings()
        getBranches()
    }, [])


    let _branches = []
    let _comps = []


    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canEditBuildings")) {
            _actionOptions["edit"] = {
                onEdit: (row, id) => {
                    setBuildingToEdit(row)
                    let _branch = _branches.find(u => u.id === row.branch)
                    let comp = _comps.find(c => c.id === row.company)
                    setInitialBranch(_branch)
                    setInitialCompany(comp)
                    setEditOpen(true)
                }
            }
        }
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteBuildings")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    deleteBuildings([id])
                }
            }
        }
        setActionOptions(_actionOptions)
    }



    const getBranches = () => {
        API.getBranches().then(response => {
            setBranches(response.data.data)
        })
    }

    const getBranchesByCompany = (companyId) => {
        API.queryBranch(`eq[company]=${companyId}`).then(response => {
            setBranches(response.data.data)
        })
    }

    const getCompanies = () => {
        API.getCompanies().then(response => {
            setCompanies(response.data.data)
            /* if (Utils.isSystemAdmin()) {
                setBranches([])
                getBranches()
            } */
        })
    }

    const getBuildings = () => {
        API.getBuildings().then(response => { // cevap gelirse şirketleri atıyoruz
            setBuildings(response.data.data)
            getCompanies()
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getBuildings().then(resp => { // şirketleri tekrar çekiyoruz
                        setBuildings(resp.data.data)
                        getCompanies()
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const createBuilding = () => {
        API.createBuilding(buildingInfo).then(response => {
            getBuildings()
            toast(t(lang, "buildingsPage.buildingCreated"))
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.createBuilding(buildingInfo).then(resp => {
                        getBuildings()
                        toast(t(lang, "buildingsPage.buildingCreated"))
                    }).catch(er => {
                        toast(t(lang, "buildingsPage.buildingCreateError"))
                    })
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const updateBuilding = () => {
        API.updateBuilding(buildingToEdit.id, updateInfo).then(response => {
            getCompanies()
            toast(t(lang, "buildingsPage.buildingUpdated"))
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.updateBuilding(buildingToEdit.id, updateInfo).then(resp => {
                        getBuildings()
                        toast(t(lang, "buildingsPage.buildingUpdated"))
                    }).catch(err => {
                        toast(t(lang, "buildingsPage.buildingUpdateError"))
                    })
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const deleteBuildings = (buildingIds) => {
        API.deleteBuildings(buildingIds).then(response => {
            toast(t(lang, "buildingsPage.buildingDeleted"))
            getBuildings()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteBuildings(buildingIds).then(resp => {
                        toast(t(lang, "buildingsPage.buildingDeleted"))
                        getBuildings()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "buildingsPage.buildingDeleteError"))
                    }).catch(e => {
                        if (e.response.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }


    const columns = [
        {
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: true,
                draggable: true
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "address",
            label: t(lang, "fields.address"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "branch_name",
            label: t(lang, "fields.branch"),
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "latitude",
            label: t(lang, "fields.latitude"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "longitude",
            label: t(lang, "fields.longitude"),
            options: {
                filter: true,
                sort: false
            }
        }
    ]




    const validate = () => {
        if (Utils.nullOrEmpty(buildingInfo.name) ||
            Utils.nullOrEmpty(buildingInfo.latitude) ||
            Utils.nullOrEmpty(buildingInfo.longitude) ||
            Utils.nullOrEmpty(buildingInfo.company)
        ) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!validate()) { //
            toast(t(lang, "buildingsPage.requiredFields"))
            return
        }
        createBuilding()
    }


    const handleCompanySelected = (comp) => {
        setBuildingInfo({ ...buildingInfo, company: comp.id === -1 ? null : comp.id })
        let newBld = buildingInfo
        delete newBld.branch
        setBuildingInfo(newBld)
        setBranches([])
        if (comp.id !== -1) {
            getBranchesByCompany(comp.id)
        }


    }


    const validateUpdate = () => {
        if ((updateInfo.name && Utils.nullOrEmpty(updateInfo.name)) ||
            (updateInfo.latitude && Utils.nullOrEmpty(updateInfo.latitude)) ||
            (updateInfo.longitude && Utils.nullOrEmpty(updateInfo.longitude)) ||
            (updateInfo.company && Utils.nullOrEmpty(updateInfo.company))
        ) {
            return false
        }
        return true
    }


    const handleUpdateClicked = () => {
        if (!validateUpdate()) { //
            toast(t(lang, "buildingsPage.requiredFields"))
            return
        }
        updateBuilding()
    }


    return (

        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    title={t(lang, "buildingsPage.newBuilding")}
                    onSaveClick={handleSaveClicked}
                    saveButtonTitle={t(lang, "common.save")}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(val) => setBuildingInfo({ ...buildingInfo, name: val })}
                            helperText="*"
                            helperTextType={"error"}
                        />

                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.latitude")}
                            onChange={(val) => setBuildingInfo({ ...buildingInfo, latitude: val })}
                            type={"number"}
                            helperText='*'
                            helperTextType={"error"}
                        />
                        <FormInput
                            label={t(lang, "fields.longitude")}
                            onChange={(val) => setBuildingInfo({ ...buildingInfo, longitude: val })}
                            type={"number"}
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.address")}
                            onChange={(val) => setBuildingInfo({ ...buildingInfo, address: val })}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.description")}
                            onChange={(val) => setBuildingInfo({ ...buildingInfo, description: val })}
                        />
                    </Row>
                    {Utils.isSystemAdmin() &&
                        <Row>
                            <FormSelectInput
                                label={t(lang, "fields.company")}
                                onSelect={(item) => handleCompanySelected(item)}
                                data={companies}
                                itemComponent={(item, index) => {
                                    return (
                                        <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                            <ItemText>{`${item.name}`}</ItemText>
                                        </div>
                                    )
                                }}
                            />
                        </Row>
                    }
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.branch")}
                            onSelect={(item) => setBuildingInfo({ ...buildingInfo, branch: item.id === -1 ? null : item.id })}
                            data={branches}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{`${item.name}`}</ItemText>
                                    </div>
                                )
                            }}
                        />
                    </Row>
                </NewRecordDrawer>
                <NewRecordDrawer
                    saveButtonTitle={t(lang, "common.save")}
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    title={`${buildingToEdit?.name}`}
                    onSaveClick={handleUpdateClicked}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(val) => setBuildingInfo({ ...buildingInfo, name: val })}
                            helperText="*"
                            helperTextType={"error"}
                            initialValue={`${buildingToEdit?.name}`}
                        />

                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.latitude")}
                            onChange={(val) => setBuildingInfo({ ...buildingInfo, latitude: val })}
                            type={"number"}
                            helperText="*"
                            helperTextType={"error"}
                            initialValue={`${buildingToEdit?.latitude}`}
                        />
                        <FormInput
                            label={t(lang, "fields.longitude")}
                            onChange={(val) => setBuildingInfo({ ...buildingInfo, longitude: val })}
                            type={"number"}
                            helperText="*"
                            helperTextType={"error"}
                            initialValue={`${buildingToEdit?.longitude}`}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.address")}
                            onChange={(val) => setBuildingInfo({ ...buildingInfo, address: val })}
                            initialValue={`${buildingToEdit?.address}`}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.description")}
                            onChange={(val) => setBuildingInfo({ ...buildingInfo, description: val })}
                            initialValue={`${buildingToEdit?.description}`}
                        />
                    </Row>
                    {Utils.isSystemAdmin() &&
                        <Row>
                            <FormSelectInput
                                label={t(lang, "fields.company")}
                                onSelect={(item) => {
                                    setUpdateInfo({ ...updateInfo, company: item.id === -1 ? null : item.id })
                                    let newBld = updateInfo
                                    delete newBld.branch
                                    setUpdateInfo(newBld)
                                    setBranches([])
                                    if (item.id !== -1) {
                                        getBranchesByCompany(item.id)
                                    }

                                }}
                                data={companies}
                                itemComponent={(item, index) => {
                                    return (
                                        <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                            <ItemText>{`${item.name}`}</ItemText>
                                        </div>
                                    )
                                }}
                                initialValue={initialCompany}
                            />
                        </Row>
                    }
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.branch")}
                            onSelect={(item) => setBuildingToEdit({ ...buildingToEdit, branch: item.id === -1 ? null : item.id })}
                            data={branches}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{`${item.name}`}</ItemText>
                                    </div>
                                )
                            }}
                            initialValue={initialBranch}
                        />
                    </Row>
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={buildings}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    onRowsDelete={(rows, ids) => deleteBuildings(ids)}
                    title={t(lang, "buildingsPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateBuildings")}
                />
            </DataSection>
        </Container>
    )

}


export default BuildingsPage;



const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`


const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`