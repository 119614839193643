import { Route, Routes, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import OtpPage from "./pages/OtpPage";
import PasswordCreationPage from "./pages/PasswordCreationPage";
import PhoneConfirmationPage from "./pages/PhoneConfirmationPage";
import UserFormPage from "../register-stack/pages/UserFormPage";
import CompanyFormPage from "../register-stack/pages/CompanyFormPage";
import ConfirmationPage from "../register-stack/pages/ConfirmationPage";



const Login = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={"/login"} replace={true} />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/creation" element={<PasswordCreationPage />} />
            <Route path="/phone" element={<PhoneConfirmationPage />} />
            <Route path="/otp" element={<OtpPage />} />
            <Route path="*" element={<Navigate to={'/login'} replace={true} />} />
            <Route path="/register/user-form" element={<UserFormPage />} />
            <Route path="/register/company-form" element={<CompanyFormPage />} />
            <Route path="/register/confirmation" element={<ConfirmationPage />} />
        </Routes>
    )
}


export default Login;