import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ActivityChart from '../../components/dashboard-components/ActivityChart';
import BirthdaysChart from '../../components/dashboard-components/BirthdaysChart';
import GateDistributionChart from '../../components/dashboard-components/GateDistributionChart';
import StatsCard from '../../components/dashboard-components/StatsCard';
import { Context } from '../../context/Context';
import t from '../../static/Language';
import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import TrafficRoundedIcon from '@mui/icons-material/TrafficRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import { useNavigate } from 'react-router-dom';
import RecentActivitiesChart from '../../components/dashboard-components/RecentActivitiesChart';
import API from '../../static/API';



const Dashboard = () => {
	const { state } = useContext(Context)
	const lang = state.appLang

	const [summary, setSummary] = useState()
	const navigate = useNavigate()

	useEffect(() => {
		document.title = t(lang, "dashboard.title")
		get()
	}, [])


	const get = () => {
		let _sum = {}
		API.getSummary().then(response => {
			let data = response.data.data[0]
			_sum["total_access_points"] = { key: t(lang, "dashboard.totalAccessPoints"), value: data.total_access_point }
			_sum["total_gates"] = { key: t(lang, "dashboard.totalGates"), value: data.total_gate }
			_sum["total_users"] = { key: t(lang, "dashboard.totalUsers"), value: data.total_user }
			_sum["total_activity"] = { key: t(lang, "dashboard.totalActivity"), value: data.total_activity }
			setSummary(_sum)
		})
	}

	return (
		<Container >
			<Grid container spacing={1} >
				<StatsCard
					data={1}
					background='linear-gradient(109.2deg, rgb(254, 3, 104) 9.3%, rgb(103, 3, 255) 89.5%);'
					mainStatistic={summary?.total_users}
					icon={<Person2RoundedIcon sx={{ fontSize: 70, color: 'white' }} />}
					onDetailsClick={() => navigate("/users")}
				/>
				<StatsCard
					data={1}
					background='linear-gradient(91.1deg, rgb(57, 31, 105) -2.3%, rgb(115, 43, 155) 44.4%, rgb(231, 75, 184) 103.4%);'
					mainStatistic={summary?.total_activity}
					icon={<TrafficRoundedIcon sx={{ fontSize: 70, color: 'white' }} />}
					onDetailsClick={() => navigate("/user-activities")}
				/>
				<StatsCard
					data={1}
					background='linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));'
					mainStatistic={summary?.total_gates}
					icon={<MeetingRoomRoundedIcon sx={{ fontSize: 70, color: 'white' }} />}
					onDetailsClick={() => navigate("/gates")}
				/>
				<StatsCard
					data={1}
					background='linear-gradient(110.2deg, rgb(111, 71, 133) 3.6%, rgb(232, 129, 166) 50.3%, rgb(237, 237, 183) 97.4%);'
					mainStatistic={summary?.total_access_points}
					icon={<PlaceRoundedIcon sx={{ fontSize: 70, color: 'white' }} />}
					onDetailsClick={() => navigate("/users")}
				/>
				<ActivityChart />
				<GateDistributionChart />
				<BirthdaysChart />
				<RecentActivitiesChart />
			</Grid>
		</Container >
	);
}

export default Dashboard;




const Container = styled.div`
	height: 100%;
	padding: 10px;
	padding-bottom: 90px
`;

