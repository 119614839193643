import { Breadcrumbs, Card, Link } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from "styled-components";
import { Context } from "../../../../context/Context";
import API from "../../../../static/API";
import globalStyles from "../../../../static/GlobalStyles";
import t from "../../../../static/Language";
import SafeStorage from "../../../../static/SafeStorage";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import GateGeneralTab from "./tab-panels/GateGeneralTab";
import GateAccessPointsTab from "./tab-panels/GateAccessPointsTab";
import TabsContainer from "../../../../components/dashboard-components/TabsContainer";
import GateAccessPrivsTab from "./tab-panels/GateAccessPrivsTab";




const GateDetailsPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const navigate = useNavigate()

    const { id } = useParams()


    const [details, setDetails] = useState()


    useEffect(() => {
        getDetails()
    }, [])



    const getDetails = () => {
        API.getGateDetails(id).then(response => { // cevap gelirse userları atıyoruz
            setDetails(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getGateDetails(id).then(resp => { // userları tekrar çekiyoruz
                        setDetails(resp.data.data[0])
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }




    return (
        <TabsContainer>
            <Breadcrumbs
                separator={<ArrowForwardIosRoundedIcon style={{ color: "#639ef7" }} />}
            >
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} onClick={() => navigate("/gates")}>
                    <CrumbText>{t(lang, "gatesPage.title")}</CrumbText>
                </Link>
                <CurrentCrumbText>{t(lang, "gatesPage.details")}</CurrentCrumbText>

            </Breadcrumbs>
            <TabsSection>
                <Tabs style={{ height: 'calc(100% - 35px)' }}>
                    <TabList>
                        <Tab>
                            <TabTitle>{t(lang, "common.general")}</TabTitle>
                        </Tab>
                        <Tab>
                            <TabTitle>{t(lang, "accessPointsPage.title")}</TabTitle>
                        </Tab>
                        <Tab>
                            <TabTitle>{t(lang, "menuNames.accessPrivileges")}</TabTitle>
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <GateGeneralTab details={details} />
                    </TabPanel>
                    <TabPanel style={{ height: '100%' }}>
                        <GateAccessPointsTab accessPoints={details?.access_points} gateId={id} />
                    </TabPanel>
                    <TabPanel style={{ height: '100%' }}>
                        <GateAccessPrivsTab gateId={id} />
                    </TabPanel>
                </Tabs>
            </TabsSection>

        </TabsContainer>
    )


}

export default GateDetailsPage


const TabTitle = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
`

const TabsSection = styled.div`
    margin-top: 15px;
    display: flex;
	flex-direction: column;
	height: 100%
`

const CrumbText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 12px;
    cursor: pointer
`

const CurrentCrumbText = styled.span`
    font-family: ${globalStyles.font};
    color: black;
    font-size: 14px;
    cursor: default
`
