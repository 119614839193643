import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl } from "@mui/material";
import { useState } from 'react';
import t from '../static/Language';
import globalStyles from '../static/GlobalStyles';
import dayjs from 'dayjs';


const MonthPicker = ({
    onSelect = (month, start, end) => null,
    locale = "tr"
}) => {



    const [value, setValue] = useState()


    const handleChange = (val) => {
        setValue(val)
        const start = val.startOf('month').format('YYYY-MM-DD');
        const end = val.endOf('month').format('YYYY-MM-DD')
        const month = val.format('YYYY-MM')
        onSelect(month, start, end)
    }

    const theme = createTheme({
        typography: {
            fontFamily: globalStyles.font, // İstediğiniz fontu burada belirleyin
        },
        MuiClockNumber: {
            styleOverrides: {
                root: {
                    color: globalStyles.textColor, // Saat numaralarının rengi
                },
            },
        },
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    },
                }
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    },

                },
            },
            MuiPickersYear: {
                styleOverrides: {
                    yearButton: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiDayCalendar: {
                styleOverrides: {
                    weekDayLabel: {
                        fontFamily: globalStyles.font
                    },
                    weekNumber: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiPickersCalendarHeader: {
                styleOverrides: {
                    label: {
                        fontFamily: globalStyles.font
                    }
                }
            }
        }
    })



    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider
                adapterLocale={locale}
                dateAdapter={AdapterDayjs}
                localeText={{
                    okButtonLabel: t(locale, "common.ok"),
                    cancelButtonLabel: t(locale, "common.cancel"),
                }}
            >
                <FormControl fullWidth sx={{ flexDirection: 'row', justifyContent: "flex-start", alignItems: "flex-start" }}>
                    <DatePicker
                        views={['year', 'month']}
                        openTo="month"
                        onAccept={val => handleChange(val)}
                        defaultValue={dayjs(Date.now())}
                        slotProps={{
                            textField: {
                                size: 'small'
                            }
                        }}
                    />
                </FormControl>
            </LocalizationProvider>
        </ThemeProvider>

    )

}


export default MonthPicker;