import styled from "styled-components";
import globalStyles from "../static/GlobalStyles";
import Avatar from "./Avatar";



const HierarchyCard = ({ name, title, avatar, department }) => {

    return (
        <CardBody >
            <div style={{ display: 'flex', flexDirection: 'column', overflow: 'visible' }}>
                <Avatar
                    avatar={avatar}
                    name={name?.trim()}
                    lastName={name?.split(' ').at(-1)}
                    size={110}
                />
                <PersonalInfoContainer>
                    <NameText>{name}</NameText>
                    <TitleText>{title}</TitleText>
                </PersonalInfoContainer>
            </div>
            <DepartmentContainer>
                <DepartmentText>{department}</DepartmentText>
            </DepartmentContainer>
        </CardBody>
    )
}

export default HierarchyCard;


const CardBody = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center; /* Orta yerine içerik genişliğine göre hizala */
    width: 111px;
    border: 1px solid ${globalStyles.firstColor};
    border-radius: 5px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    overflow: hidden;
`

const PersonalInfoContainer = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const DepartmentContainer = styled.div`
    padding: 4px;
    background-color: ${globalStyles.firstColor};
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center
    
`

const DepartmentText = styled.span`
    font-family: ${globalStyles.font};
    color: white;
    font-size: 12px
`


const NameText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 12px;
    font-weight: 600
`

const TitleText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 12px;
`