import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Context } from "../../../../context/Context"
import Nodata from "../../../../states/Nodata"
import API from "../../../../static/API"
import globalStyles from "../../../../static/GlobalStyles"
import t from "../../../../static/Language"
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import SwapHorizontalCircleRoundedIcon from '@mui/icons-material/SwapHorizontalCircleRounded';
import DataTable from "../../../../components/DataTable"
import Container from "../../../../components/Container"


const UserActivityTab = ({ userId }) => {
    const { state } = useContext(Context)
    const lang = state.appLang

    const [activity, setActivity] = useState()



    useEffect(() => {
        get()
    }, [])

    const get = () => {
        API.getActivitiesOfUser(userId).then(response => {
            setActivity(response.data.data)
        })
    }


    const dateOptions = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', second: '2-digit' }


    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: true,
                sort: false
            }
        },
        {
            name: "building_name",
            label: t(lang, "fields.buildingName"),
            options: {
                filter: true,
                sort: true
            }
        },

        {
            name: "gate_name",
            label: t(lang, "fields.gate"),

        },
        {
            name: "check_time",
            label: t(lang, "fields.date"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span>{new Date(value).toLocaleString(lang, dateOptions)}</span>
                    )
                }
            }
        },
        {
            name: "check_type",
            label: t(lang, "fields.direction"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            {value == 1 ? (
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                                    <ArrowCircleRightRoundedIcon style={{ color: "#3fd473" }} />
                                    <text>{t(lang, "gatesPage.entry")}</text>
                                </div>
                            ) : (value == 2 ? (
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                                    <ArrowCircleLeftRoundedIcon style={{ color: "#d43f3f" }} />
                                    <text>{t(lang, "gatesPage.exit")}</text>
                                </ div>
                            ) : (
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                                    <SwapHorizontalCircleRoundedIcon style={{ color: "#3f8cd4" }} />
                                    <text>{t(lang, "gatesPage.bidirectional")}</text>
                                </ div>
                            ))}
                        </div>
                    )
                }
            }
        }
    ]


    if (!activity || activity?.length === 0) {
        return (
            <Nodata msg={t(lang, "common.noData")} />
        )

    }


    return (

        <Container>
            <DataTable
                columns={columns}
                data={activity}
            />
        </Container>


    )
}

export default UserActivityTab



