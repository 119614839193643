import { useContext, useEffect, useState } from "react";
import DataTable from "../../../../../components/DataTable";
import { Context } from "../../../../../context/Context";
import globalStyles from "../../../../../static/GlobalStyles";
import SafeStorage from "../../../../../static/SafeStorage";
import t from "../../../../../static/Language";
import 'react-modern-drawer/dist/index.css'
import API from "../../../../../static/API";
import FormSelectInput from "../../../../../components/FormSelectInput";
import { toast, ToastContainer } from "react-toastify"
import styled from "styled-components";
import Avatar from "../../../../../components/Avatar"
import Container from "../../../../../components/Container"
import DataSection from "../../../../../components/DataSection"
import NewRecordDrawer from "../../../../../components/NewRecordDrawer";



const BranchUsersTab = ({ branchId }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const currentUser = JSON.parse(SafeStorage.getItem("user"))
    const company = currentUser.company

    const [data, setData] = useState([])
    const [actionOptions, setActionOptions] = useState()
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState()


    useEffect(() => {
        get()
        prepareActionOptions()
        getOthers()
    }, [])


    const prepareActionOptions = () => {
        let _actionOptions = {}
        _actionOptions["delete"] = {
            onDelete: (row, id) => {
                _delete([{
                    branch: branchId,
                    user_id: row.id
                }])
            }
        }
        setActionOptions(_actionOptions)
    }

    const getOthers = () => {
        API.getUsers().then(response => {
            setUsers(response.data.data)
        })
    }

    const get = () => {
        API.getBranchUsers(branchId).then(response => { // cevap gelirse datayı atıyoruz
            setData(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getBranchUsers(branchId).then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            } else {
                console.log(err)
            }
        })
    }


    const create = (userBranchRels) => {
        API.assignUsersToBranch(userBranchRels).then(response => {
            toast(t(lang, "branchUsersPage.created"))
            get()
        }).catch(err => {
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.assignUsersToBranch(userBranchRels).then(resp => { // şirketleri tekrar çekiyoruz
                        toast(t(lang, "branchUsersPage.created"))
                        get()
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
            toast(t(lang, "branchUsersPage.createError"))
        })

    }
    // rows user_id, branch_id çiflerinden oluşan obje listesi
    const _delete = (rows) => {
        API.removeUsersFromBranch(rows).then(response => {
            toast(t(lang, "branchUsersPage.deleted"))
            get()
        }).catch(err => {
            if (err.response?.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.removeUsersFromBranch(rows).then(resp => {
                        toast(t(lang, "branchUsersPage.deleted"))
                        get()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "branchUsersPage.deleteError"))
                    }).catch(e => {
                        if (e.response?.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }


    const handleMultipleDelete = (rows) => {
        let data = []
        rows.forEach(row => {
            data.push({
                branch: branchId,
                user_id: row.id
            })
        });
        _delete(data)
    }


    const handleSaveClicked = () => {
        if (selectedUser.id === -1) { //
            toast(t(lang, "branchUsersPage.requiredFields"))
            return
        }
        create([{
            branch: branchId,
            user_id: selectedUser.id,
            company: company
        }])
    }

    const columns = [
        {
            name: "user",
            label: t(lang, "fields.user"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                            <Avatar
                                avatar={data[tableMeta.rowIndex]?.avatar}
                                name={data[tableMeta.rowIndex]?.name}
                                lastName={data[tableMeta.rowIndex]?.last_name}
                                isSquare
                                size={44}
                            />
                            <span style={{ marginLeft: 8 }}>{`${data[tableMeta.rowIndex]?.name} ${data[tableMeta.rowIndex]?.last_name}`}</span>
                        </div>
                    )
                }

            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false,
                display: false,

            }
        }

    ]

    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    title={t(lang, "branchUsersPage.new")}
                    onSaveClick={handleSaveClicked}
                    saveButtonTitle={t(lang, "common.save")}
                    onClose={() => setNewRecordOpen(false)}
                >
                    <FormSelectInput
                        label={t(lang, "fields.user")}
                        onSelect={(item) => setSelectedUser(item)}
                        data={users}
                        itemComponent={(item, index) => {
                            return (
                                <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                    <Avatar
                                        avatar={item.avatar}
                                        name={item.name}
                                        lastName={item.last_name}
                                        isSquare
                                        size={25}
                                    />
                                    <ItemText>{`${item.name} ${item.last_name}`}</ItemText>
                                </div>
                            )
                        }}
                    />
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    canCreateNewRecord={true}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    actionOptions={actionOptions}
                    onRowsDelete={(rows, ids) => handleMultipleDelete(rows)}
                    customDeleteRowFields={["id"]}
                />
            </DataSection>
        </Container>
    )


}

export default BranchUsersTab;


const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`

