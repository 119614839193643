import { Player } from "@lottiefiles/react-lottie-player"
import styled from "styled-components"


const Loading = () => {
    return (
        <Container>

            <Player
                src={require('../assets/lottie-animations/loading.json')}
                autoplay
                loop
                style={{width: 75}}
                
            />

        </Container>
    )
}

export default Loading

const Container = styled.div`
	display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    height: 100vh
	`

