import { Card, Grid, IconButton, Tooltip } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import API from "../../static/API"
import { Player } from "@lottiefiles/react-lottie-player"
import styled from "styled-components"
import globalStyles from "../../static/GlobalStyles"
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { useNavigate } from "react-router-dom"
import { Context } from "../../context/Context"
import t from "../../static/Language"
import ChartHeader from "./ChartHeader"
import CakeRoundedIcon from '@mui/icons-material/CakeRounded';
import Avatar from "../Avatar"



const BirthdayRow = ({ item, lang }) => {

    const navigate = useNavigate()

    const options = { month: 'short', day: 'numeric' };

    return (
        <div style={{
            flexDirection: 'row',
            padding: 4,
            alignItems: 'center',
            display: 'flex',
            marginBottom: '5px'
        }}>
            <Avatar avatar={item.avatar} name={item.name} lastName={item.last_name} size={32} isSquare />
            <NameText>{`${item.name} ${item.last_name}`}</NameText>

            <div
                style={{
                    justifyContent: 'flex-end',
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <BirthdayText>{new Date(item.date_of_birth).toLocaleString(lang, options)}</BirthdayText>
                <IconButton
                    onClick={() => navigate(`/users/${item.id}`)}
                >
                    <OpenInNewRoundedIcon style={{ color: "grey", fontSize: 18 }} />
                </IconButton>
            </div>
        </div>
    )
}





const BirthdaysChart = () => {
    const { state } = useContext(Context)
    const lang = state.appLang


    const [data, setData] = useState()
    const navigate = useNavigate()



    useEffect(() => {
        get()
    }, [])

    const get = () => {
        API.getApproachingBirthdays().then(response => { // cevap gelirse şirketleri atıyoruz
            setData(response.data.data)
        })
    }






    return (
        <Grid item xs={12} sm={12} md={4} lg={4}>
            <Card elevation={4} sx={{ padding: "10px", height: 300, marginBottom: '10px', overflowY: 'hidden' }}>
                {data ? (
                    <div style={{ height: '100%' }}>
                        <ChartHeader
                            title={t(lang, "dashboard.approachingBirthdays")}
                            detailsComponent={
                                <Tooltip title={t(lang, "common.details")}>
                                    <IconButton
                                        style={{ alignSelf: 'center' }}
                                        onClick={() => navigate("/users")}
                                    >
                                        <OpenInNewRoundedIcon style={{ color: "grey", fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            }
                            icon={<CakeRoundedIcon style={{ fontSize: 30, color: globalStyles.textColor }} />}

                        />
                        <div style={{ overflowY: 'auto', display: 'flex', height: '100%', flexDirection: 'column' }}>
                            {data.map((item, i) => {
                                return (
                                    <BirthdayRow key={i} item={item} lang={lang} />
                                )
                            })}
                        </div>

                    </div>
                ) : (
                    <Body>
                        <Player
                            src={require('../../assets/lottie-animations/loading.json')}
                            autoplay
                            loop
                            style={{ width: 60 }}
                        />
                    </Body>
                )}
            </Card>
        </Grid>
    )


}

export default BirthdaysChart;


const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`


const NameText = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    margin-left: 8px
`

const BirthdayText = styled.text`
    font-family: ${globalStyles.font};
    color: grey;
    font-size: 14px;
    margin-left: 8px

`
