import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import FormInput from "../../../../components/FormInput";
import { Context } from "../../../../context/Context";
import Nodata from "../../../../states/Nodata";
import API from "../../../../static/API";
import t from "../../../../static/Language";
import SafeStorage from "../../../../static/SafeStorage";


const CompanyGeneralTab = ({ details }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    
    return (
        <Container>
            <Row>
                <FormInput
                    label={t(lang, "fields.name")}
                    type="text"
                    initialValue={details?.name}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.email")}
                    type="text"
                    initialValue={details?.email}
                    readOnly
                />
                <FormInput
                    label={t(lang, "fields.phone")}
                    type="phone"
                    initialValue={`${details?.area_code}${details?.phone}`}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.address")}
                    type="text"
                    initialValue={details?.address}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.taxOffice")}
                    type="text"
                    initialValue={details?.tax_office}
                    readOnly
                />
                <FormInput
                    label={t(lang, "fields.taxNumber")}
                    type="text"
                    initialValue={`${details?.tax_number}`}
                    readOnly
                />
            </Row>
        </Container>

    )


}


export default CompanyGeneralTab;


const ProfilePicture = styled.img`
    width: 120px;
    height: 120px:;
    border: 1px solid black;
    border-radius: 5px;
    margin: 15px;
`

const Container = styled.div`
	flex-direction: column;
`

const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`