import styled from "styled-components"
import globalStyles from "../../static/GlobalStyles"




const ChartHeader = ({ title, optionsComponent, detailsComponent, icon }) => {


    return (
        <Body>
            {icon &&
                <Icon>
                    {icon}
                </Icon>
            }

            <Title>{title}</Title>
            <Options>
                {optionsComponent}
                {detailsComponent}
            </Options>
        </Body>
    )

}

export default ChartHeader


const Body = styled.div`
    padding: 4px;
    border-bottom: solid 1px #d8e6db;
    margin: 10px;
    flex-direction: row;
    display: flex;
    align-items: center
`

const Options = styled.div`
    justify-content: flex-end;
    flex: 1;
    display: flex;
`

const Title = styled.text`
    font-family: ${globalStyles.font};
`

const Icon = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
`