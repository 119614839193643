import { useParams } from "react-router-dom"
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-modern-drawer/dist/index.css'
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from 'react-router-dom';
import API from '../../../static/API';
import t from '../../../static/Language';
import SafeStorage from '../../../static/SafeStorage';
import globalStyles from '../../../static/GlobalStyles';
import { Context } from '../../../context/Context';
import { Breadcrumbs, Card, Grid, Link } from "@mui/material"
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Loading from '../../../states/Loading'
import { Bar, Pie } from "react-chartjs-2";


const dummyData = {
    assigned: 100,
    completed: 80,
    questionStats: [
        { question: "Şirket politikalarından ne kadar memnunsunuz?", responses: 75, percentages: 93.75 },
        { question: "Servislerden ne kadar memnunsunuz?", responses: 70, percentages: 87.5 },
        { question: "Yöneticinizle iletişiminiz nasıl?", responses: 80, percentages: 100 },
    ],
    freeTextResponses: [
        "Daha şeffaf olunmalı",
        "Talep süreçleri çok uzun",
        "Servisler yetersiz",
    ],
    ratingQuestionStats: [
        { question: "Şirket politikalarından ne kadar memnunsunuz?", average: 4.2 },
        { question: "Servislerden ne kadar memnunsunuz?", average: 3.8 },
        { question: "Yöneticinizle iletişiminiz nasıl?", average: 4.5 },
    ]
};


const SurveyDetailsPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const { id } = useParams()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [_data, setData] = useState([])


    useEffect(() => {
        get()
    }, [])

    const get = () => {
        API.getSurveyStats(id).then(response => { // cevap gelirse datayı atıyoruz
            setData(response.data.data)
            console.log(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getSurveyStats(id).then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            } else {
                console.log(err)
            }
        }).finally(() => setLoading(false))
    }


    const data = dummyData

    const assignmentData = {
        labels: ['Bekleyen', 'Tamamlayan'],
        datasets: [
            {
                label: 'Yüzde',
                data: [data.assigned, data.completed],
                backgroundColor: ['#36A2EB', '#FFCE56'],
            },
        ],
    };

    // Soru cevap oranları için veriler
    const questionData = {
        labels: data.questionStats.map(q => q.question),
        datasets: [
            {
               label: 'Cevaplama yüzdeleri',
                data: data.questionStats.map(q => q.responses),
                backgroundColor: '#FF6384',
            },
        ],
    };

    // Dereceli soruların ortalama puanları için veriler
    const ratingData = {
        labels: data.ratingQuestionStats.map(q => q.question),
        datasets: [
            {
                label: 'Ortalama Puan',
                data: data.ratingQuestionStats.map(q => q.average),
                backgroundColor: '#4BC0C0',
            },
        ],
    };


    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <div style={{ flex: 1, padding: 15, overflowY: "auto", justifyContent: 'center', alignItems: 'center' }}>
            <Breadcrumbs
                separator={<ArrowForwardIosRoundedIcon style={{ color: "#639ef7" }} />}
            >
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} onClick={() => navigate("/surveys")}>
                    <CrumbText>{t(lang, "surveysPage.title")}</CrumbText>
                </Link>
                <CurrentCrumbText>{t(lang, "surveysPage.surveyStats")}</CurrentCrumbText>

            </Breadcrumbs>
            <TabsSection elevation={0}>
                <Grid >
                    {/*  <img 
                    width={200} 
                    height={150} 
                    src={}></img> */}
                    <Title>
                        {_data.survey.title}
                    </Title>

                    <div >


                        {/* Atama ve Tamamlanma Grafiği */}
                        <div style={{ width: '300px', height: '300px' }}>

                            <Pie data={assignmentData} />
                        </div>

                        {/* Soru Cevaplama Oranları Grafiği */}
                        <div style={{ width: '600px', height: '300px', marginTop: 15 }}>
                            <StatsText >Soru Cevaplanma Yüzdeleri</StatsText>
                            <Bar data={questionData} />
                        </div>

                        {/* Dereceli Soruların Ortalama Puanları Grafiği */}
                        <div style={{ width: '600px', marginTop: 15 }}>
                            <StatsText>Ortalama Puanlar</StatsText>
                            <Bar data={ratingData} />
                        </div>

                        {/* Free Text Cevapları Listesi */}
                        <div>
                            <StatsText>Görüşler</StatsText>
                            <ul>
                                {data.freeTextResponses.map((response, index) => (
                                    <li key={index}>
                                        <CrumbText>
                                            {response}
                                        </CrumbText>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </Grid>

            </TabsSection>

        </div>
    )

}


export default SurveyDetailsPage


const TabTitle = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
`

const TabsSection = styled(Card)`
    margin-top: 15px;
    
`

const CrumbText = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 12px;
    cursor: pointer
`

const CurrentCrumbText = styled.text`
    font-family: ${globalStyles.font};
    color: black;
    font-size: 14px;
    cursor: default
`

const StatsText = styled.text`
font-family: ${globalStyles.font};
font-size: 14px;
color: ${globalStyles.textColor};
font-weight: bold;
margin-bottom: 15px
`

const Title = styled.text`
    font-family: ${globalStyles.font};
    font-size: 18px;
    color: ${globalStyles.textColor};
    font-weight: bold;
    margin-bottom: 15px
`