import { FormControl, InputLabel, MenuItem } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import t from "../../static/Language";
import globalStyles from "../../static/GlobalStyles";
import styled from "styled-components";

import Select from 'react-select';
import { isMobile } from "react-device-detect";

const QuestionTypeSelector = ({
    onSelect = (questionType) => null,
    defaultType = 1
}) => {
    const { state } = useContext(Context)
    const lang = state.appLang

    const data = [
        { value: 1, label: t(lang, "surveysPage.singleChoice") },
        { value: 2, label: t(lang, "surveysPage.multipleChoice") },
        { value: 3, label: t(lang, "surveysPage.multipleAlternate") },
        { value: 4, label: t(lang, "surveysPage.freeText") }
    ]

    const [value, setValue] = useState(data.find(d => d.value === defaultType))


    useEffect(() => {
        setValue(data.find(d => d.value === defaultType) ?? data[0])
    }, [defaultType])




    return (
        <div style={{ margin: 15, flex: 1 }}>
            <FormControl sx={{ minWidth: isMobile ? 0 : '270px' }}>
                <InputLabel
                    id="demo-simple-select-label"
                    style={{ backgroundColor: 'white', paddingRight: 12, paddingLeft: 8, left: -5, fontFamily: globalStyles.font, color: globalStyles.textColor }}
                    shrink={true}
                >
                    {t(lang, "surveysPage.questionType")}
                </InputLabel>
                <Select
                    options={data}
                    onChange={e => onSelect(e.value)}
                    value={value}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                        }),

                    }}
                />

                {/* <Select
                    labelId="demo-simple-select-label"
                   // onChange={(e) => handleChange(e.target.value)}
                    SelectDisplayProps={{ style: { fontFamily: globalStyles.font } }}
                    defaultValue={value}
                    variant="outlined"
                    size="small"
                >
                    {data.map((item, index) => {
                        return (
                            <MenuItem value={item}>
                                <ItemText>{item.value}</ItemText>
                            </MenuItem>
                        )
                    })}
                </Select> */}

            </FormControl>
        </div >
    )

}

export default QuestionTypeSelector;

const ItemText = styled.text`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`