import { useContext } from "react";
import styled from "styled-components";
import FormInput from "../../../../../components/FormInput";
import { Context } from "../../../../../context/Context";
import t from "../../../../../static/Language";



const GateGeneralTab = ({ details }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang


    return (
        <Container>
            <Row>
                <FormInput
                    label={t(lang, "fields.name")}
                    type="text"
                    initialValue={details?.name}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.description")}
                    type="text"
                    initialValue={details?.description}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={"ID"}
                    type="text"
                    initialValue={details?.id}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.buildingName")}
                    type="text"
                    initialValue={details?.building_name}
                    readOnly
                />
            </Row>

            <Row>
                <FormInput
                    label={t(lang, "fields.latitude")}
                    type="text"
                    initialValue={details?.latitude}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.longitude")}
                    type="text"
                    initialValue={details?.longitude}
                    readOnly
                />
            </Row>
        </Container>
    )
}


export default GateGeneralTab;


const Container = styled.div`
	flex-direction: column;
`

const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`