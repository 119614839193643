import { IconButton } from "@mui/material"
import styled from "styled-components"
import globalStyles from "../static/GlobalStyles"
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { Tooltip } from "@mui/material";

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import t from "../static/Language";
import { useContext } from "react";
import { Context } from "../context/Context";

const NewRecordHeader = ({ title, onSave, onClose }) => {
    const { state } = useContext(Context)
    const lang = state.appLang

    const handleSave = () => {
        if (onSave) {
            onSave()
        }
    }

    const handleClose = () => {
        if (onClose) {
            onClose()
        }
    }

    return (
        <Body>
            <Title>
                {title}
            </Title>
            <ButtonsSection>

                <Tooltip title={t(lang, "common.close")}>
                    <Button onClick={handleClose}>
                        <CloseRoundedIcon sx={{ fontSize: 32 }} />
                    </Button>
                </Tooltip>
              {/*   <Tooltip title={t(lang, "common.save")}>
                    <Button onClick={handleSave}>
                        <SaveRoundedIcon sx={{ fontSize: 32, color: "#3975ed" }} />
                    </Button>
                </Tooltip> */}

                {/* <Tooltip title="Temizle">
                    <Button>
                        <DeleteOutlineRoundedIcon />
                    </Button>
                </Tooltip> */}
            </ButtonsSection>

        </Body>
    )
}



export default NewRecordHeader;


const Button = styled(IconButton)`
    float: right
`

const Body = styled.div`
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: solid 1px #e5e6e1;
    flex-direction: row;
    display: flex;
    align-items: center
`

const Title = styled.text`
    font-family: ${globalStyles.font};
    font-weight: bold;
    color: ${globalStyles.textColor}

`

const ButtonsSection = styled.div`
    flex-direction: row;
    flex: 1;
    float: right;
`
