import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import globalStyles from '../../static/GlobalStyles';
import { Player } from "@lottiefiles/react-lottie-player"
import FormInput from '../../components/FormInput';
import FlexButton from '../../components/FlexButton';
import { Alert, Breadcrumbs, Link, Snackbar } from '@mui/material';
import t from '../../static/Language';
import { Context } from '../../context/Context';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useNavigate, useLocation } from "react-router-dom"
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import Utils from '../../static/Utils';
import API from '../../static/API';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';



const Layout = ({ children }) => {
    const { state } = useContext(Context)
    const lang = state.appLang

    return (
        <LayoutContainer>
            <BackgroundSection >
                <Player
                    src={require('../../assets/lottie-animations/business.json')}
                    autoplay
                    loop
                    style={{ width: '70%' }}
                />
                <span
                    style={{
                        fontFamily: globalStyles.font,
                        fontSize: '24px',
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '30px'
                    }}
                >
                    {t(lang, "login.slogan")}
                </span>
                <span
                    style={{
                        fontFamily: globalStyles.font,
                        fontSize: '18px',
                        color: 'white',
                        textAlign: 'center',
                        marginTop: '20px'
                    }}
                >
                    {t(lang, "login.advertise")}
                </span>
            </BackgroundSection>
            <FormSection>
                {children}
            </FormSection>
        </LayoutContainer>
    );
};

const CompanyFormPage = () => {
    const context = useContext(Context)
    const lang = context.state.appLang

    const navigate = useNavigate()

    const { state } = useLocation()
    const user = state.user


    const [company, setCompany] = useState({})

    const [messageOpen, setMessageOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("success")


    const correctPhone = (phone, areaCode) => {
        try {
            return phone.substr(areaCode.length, phone.length)
        } catch (ex) { console.log(ex); return phone; }
    }


    const uploadPP = (userId) => {
        if (user.avatar_file) {
            //API.uploadFile(profilePicture)
            API.uploadFile(user.avatar_file).then(response => {
                const ppUrl = response.data.file_url
                console.log(response.data)
                API.updateRegister({ user: userId, avatar: ppUrl }).catch(err => {
                    console.log(err)
                })
            }).catch(err => {
                setSeverity("error")
                setMessage("Cannot upload profile picture")
                setMessageOpen(true)
            })
        }
    }

    const handleSubmit = () => {
        if (Utils.nullOrEmpty(company.name) ||
            Utils.nullOrEmpty(company.address) ||
            Utils.nullOrEmpty(company.phone)
        ) {
            setSeverity("error")
            setMessage(t(lang, "usersPage.requiredFields"))
            setMessageOpen(true)
            return
        }
        const registerInfo = {
            user: {
                name: user.name,
                last_name: user.last_name,
                password: user.password,
                phone: user.phone,
                area_code: user.area_code,
                email: user.email
            },
            company: company
        }

        API.register(registerInfo).then(response => {
            const userId = response.data.user_id
            const companyId = response.data.company_id
            uploadPP(userId)
            navigate("/register/confirmation",  { state: { company: companyId } })

        }).catch(err => {
            setSeverity("error")
            if (err.response?.data?.msg?.includes("Phone already exists")) {
                setMessage(t(lang, "usersPage.phoneExistsError"))
            } else if (err.response?.data?.msg?.includes("Email already exists")) {
                setMessage(t(lang, "usersPage.emailExistsError"))
            } else {
                setMessage(t(lang, "common.error"))
            }
            setMessageOpen(true)
        })



    }

    return (
        <Layout>
            <Snackbar
                open={messageOpen}
                autoHideDuration={5000}
                onClose={() => setMessageOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert sx={{ width: '100%', fontFamily: globalStyles.font }} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                <img
                    src={require('../../assets/icon/flexbee-no-bg-dark.webp')}
                    style={{
                        width: '200px',
                        alignSelf: 'center'
                    }}
                />
                <span
                    style={{
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor,
                        alignSelf: 'center',
                        color: globalStyles.firstColor,
                        fontSize: '16px',
                        marginBottom: '20px'
                    }}
                >
                    {t(lang, "login.registrationForm")}
                </span>
                <Breadcrumbs
                    separator={<ArrowForwardIosRoundedIcon style={{ color: "#639ef7" }} />}
                >
                    <Link
                        onClick={() => navigate('/register/user-form')}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                    >
                        <Person2RoundedIcon />
                        <CrumbText>{t(lang, "login.userInfo")}</CrumbText>
                    </Link>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <ApartmentRoundedIcon />
                        <CurrentCrumbText>{t(lang, "login.companyInfo")}</CurrentCrumbText>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <HowToRegRoundedIcon />
                        <CrumbText>{t(lang, "common.mailConfirmation")}</CrumbText>
                    </div>
                </Breadcrumbs>

                <FormContainer>
                    <FormInput
                        label={t(lang, "fields.companyName")}
                        style={{ margin: 0 }}
                        onChange={(val) => setCompany({ ...company, name: val })}
                    />
                    <FormInput
                        label={t(lang, "fields.address")}
                        style={{ margin: 0 }}
                        onChange={(val) => setCompany({ ...company, address: val })}

                    />
                    <FormInput
                        label={t(lang, "fields.phone")}
                        type={"phone"}
                        style={{ margin: 0 }}
                        onChange={(val, country, e, formattedValue) => setCompany({ ...company, phone: correctPhone(val, country.dialCode), area_code: country.dialCode })}

                    />
                    <FlexButton
                        text={`${t(lang, "common.create")}`}
                        style={{ margin: 0 }}
                        onClick={handleSubmit}
                    />
                </FormContainer>
            </div>
        </Layout>


    )
}




export default CompanyFormPage






const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
`;



const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  background-color: ${globalStyles.firstColor};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BackgroundSection = styled.div`
  flex: 1;
  background-color: ${globalStyles.firstColor};
 
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 70px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px 0px 0px 15px;
  background-color: white;
  box-shadow: -12px -16px 21px -7px rgba(72, 77, 77, 0.5);
`;



const CrumbText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 12px;
`

const CurrentCrumbText = styled.span`
    font-family: ${globalStyles.font};
    color: black;
    font-size: 14px;
    cursor: default
`