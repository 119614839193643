import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../../context/Context";
import SafeStorage from "../../../../static/SafeStorage";
import API from "../../../../static/API";
import Loading from "../../../../states/Loading";
import { toast, ToastContainer } from "react-toastify"
import globalStyles from "../../../../static/GlobalStyles";
import { Grid } from '@mui/material';
import FormInput from "../../../../components/FormInput";
import DataTable from "../../../../components/DataTable";
import Select, { createFilter } from "react-select";
import Utils from "../../../../static/Utils";
import t from "../../../../static/Language";
import { useNavigate } from 'react-router-dom';
import DataSection from "../../../../components/DataSection";
import Container from "../../../../components/Container";
import NewRecordDrawer from "../../../../components/NewRecordDrawer";



const WeekDayShiftPicker = ({
    shifts = [],
    dayOfWeek = 1,
    onSelect = (shift) => shift,
    defaultShift,
    locale = "tr",
}) => {


    const [value, setValue] = useState(defaultShift)

    useEffect(() => {
        setValue(defaultShift)
    }, [defaultShift])

    const getDayName = () => {
        const pseudoDate = new Date(1970, 5, dayOfWeek)
        return pseudoDate.toLocaleString(locale, { weekday: 'long' })
    }

    return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '15px' }}>
            <text
                style={{
                    fontFamily: globalStyles.font,
                    color: globalStyles.textColor,
                    fontSize: '16px',
                    marginBottom: '5px'
                }}
            >
                {getDayName()}
            </text>
            <Select
                options={shifts}
                getOptionValue={o => o.id}
                getOptionLabel={o => `${o.shift_name}`}
                isSearchable
                isClearable
                closeMenuOnSelect={true}
                placeholder={t(locale, "fields.weekHoliday")}
                noOptionsMessage={() => t(locale, "surveysPage.allSelected")}
                onChange={(value) => {
                    setValue(value)
                    onSelect(value)
                }}
                value={value}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }),
                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor,
                        zIndex: 1000
                    }),
                }}
            />
        </div>
    )
}


const WorkPlanPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const user = JSON.parse(SafeStorage.getItem("user"))
    const company = user.company
    const privileges = user.privileges

    const [data, setData] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [objInfo, setObjInfo] = useState({ company: company })
    const [updateInfo, setUpdateInfo] = useState({})
    const [editOpen, setEditOpen] = useState(false)
    const [actionOptions, setActionOptions] = useState()
    const [objToEdit, setObjToEdit] = useState(null)
    const [loading, setLoading] = useState(true)
    const [companies, setCompanies] = useState([])
    const [shifts, setShifts] = useState([])
    const schedule = [
        { day_of_week: 1, is_holiday: true, shift_id: null },
        { day_of_week: 2, is_holiday: true, shift_id: null },
        { day_of_week: 3, is_holiday: true, shift_id: null },
        { day_of_week: 4, is_holiday: true, shift_id: null },
        { day_of_week: 5, is_holiday: true, shift_id: null },
        { day_of_week: 6, is_holiday: true, shift_id: null },
        { day_of_week: 7, is_holiday: true, shift_id: null }
    ]

    const [scheduleToUpdate, setScheduleToUpdate] = useState([])


    const navigate = useNavigate()

    useEffect(() => {
        document.title = t(lang, "workPlanPage.title")
        get()
        getCompanies()
        getShifts()
        prepareActionOptions()
    }, [])


    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canEditShifts")) {
            _actionOptions["edit"] = {
                onEdit: (row, id) => {
                    setObjToEdit(row)
                    setScheduleToUpdate(row.schedule)
                    setEditOpen(true)
                }
            }
        }
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteShifts")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([id])
                }
            }
        }

        _actionOptions.onShowDetail = (row, id) => {
            navigate(`/shifts/work-plans/${id}`, { state: row })
        }
        setActionOptions(_actionOptions)
    }

    const logout = () => {
        SafeStorage.removeItem("token")
        SafeStorage.removeItem("refreshToken")
        dispatch({ type: 'LOGIN', login: false })
    }

    const get = () => {
        API.getWorkPlans().then(response => {
            setData(response.data.data)
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }).finally(() => setLoading(false))
    }

    const getCompanies = () => {
        API.getCompanies2().then(response => {
            setCompanies(response.data.data)
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }).finally(() => setLoading(false))
    }

    const getShifts = () => {
        API.getShifts().then(response => {
            setShifts(response.data.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const create = (obj) => {
        const _obj = obj ?? objInfo

        API.cereateWorkPlan(_obj).then(response => {
            get()
            toast(t(lang, "workPlanPage.created"))
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }).finally(() => setLoading(false))
    }


    const update = (obj) => {
        const _obj = obj ?? updateInfo
        API.updateWorkPlan(objToEdit.id, _obj).then(response => {
            get()
            toast(t(lang, "workPlanPage.updated"))
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }).finally(() => setLoading(false))
    }


    const _delete = (ids) => {
        API.deleteWorkPlans(ids).then(response => {
            get()
            toast(t(lang, "workPlanPage.deleted"))
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }).finally(() => setLoading(false))
    }


    const columns = [
        {
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "company_name",
            label: t(lang, "fields.company"),
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "company",
            options: {
                display: false,
            }
        },
        {
            name: "description",
            label: t(lang, "fields.description"),
            options: {
                filter: true,
                sort: true
            }
        }
    ]


    const handleSaveClicked = () => {
        const createObj = {
            ...objInfo,
            schedule: schedule
        }
        create(createObj)
    }

    const handleUpdateClicked = () => {
        const updateObj = {
            ...updateInfo,
            schedule: scheduleToUpdate
        }
        update(updateObj)
    }


    const findDefaultShift = (day) => {
        const def = scheduleToUpdate.find(s => parseInt(s.day_of_week) === day)
        return def
    }

    if (loading) {
        return <Loading />
    }

    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "workPlanPage.new")}
                    onSaveClick={handleSaveClicked}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormInput
                            label={t(lang, "fields.name")}
                            multiline={true}
                            onChange={(text) => setObjInfo({ ...objInfo, name: text })}
                            maxLength={50}
                            rows={2}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormInput
                            label={t(lang, "fields.description")}
                            multiline={true}
                            onChange={(text) => setObjInfo({ ...objInfo, description: text })}
                            maxLength={50}
                            rows={2}
                        />
                    </Grid>
                    {Utils.isSystemAdmin() &&
                        <Grid item xs={12} sm={12} md={12} lg={12} margin={'15px'}>
                            <Select
                                options={companies}
                                getOptionValue={o => o.id}
                                getOptionLabel={o => `${o.name}`}
                                isSearchable
                                isClearable
                                closeMenuOnSelect={true}
                                placeholder={t(lang, "fields.company")}
                                noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                                onChange={(value) => setObjInfo({ ...objInfo, company: value.id })}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontFamily: globalStyles.font,
                                        color: globalStyles.textColor
                                    }),
                                    menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontFamily: globalStyles.font,
                                        color: globalStyles.textColor,
                                        zIndex: 1000
                                    }),
                                }}
                                components={{
                                    Option: (props) => <div {...props.innerProps} style={{
                                        padding: 8,
                                        backgroundColor: props.isSelected ? globalStyles.firstColor : props.isFocused ? "#d7e9f5" : 'white'
                                    }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center' }}>
                                            <text style={{
                                                cursor: "default",
                                                color: props.isSelected ? 'white' : globalStyles.textColor,

                                            }}>{`${props.data.name}`}</text>
                                        </div>
                                    </div>
                                }}
                            />
                        </Grid>
                    }
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        {Array.from({ length: 7 }, (_, i) => i + 1).map(i => {
                            return (
                                <WeekDayShiftPicker
                                    key={i}
                                    dayOfWeek={i}
                                    shifts={shifts}
                                    onSelect={(shift) => {
                                        const index = schedule.findIndex(s => s.day_of_week == i)
                                        if (index !== -1) {
                                            schedule[index].shift_id = shift?.id ?? null
                                            schedule[index].is_holiday = shift ? false : true
                                        }
                                    }}
                                    locale={lang}
                                />
                            )
                        })}
                    </div>

                </NewRecordDrawer>

                <NewRecordDrawer
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={`${objToEdit?.name}`}
                    onSaveClick={handleUpdateClicked}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormInput
                            label={t(lang, "fields.name")}
                            multiline={true}
                            onChange={(text) => setUpdateInfo({ ...updateInfo, name: text })}
                            maxLength={50}
                            rows={2}
                            initialValue={objToEdit?.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormInput
                            label={t(lang, "fields.description")}
                            multiline={true}
                            onChange={(text) => setUpdateInfo({ ...updateInfo, description: text })}
                            maxLength={50}
                            rows={2}
                            initialValue={objToEdit?.description}
                        />
                    </Grid>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        {Array.from({ length: 7 }, (_, i) => i + 1).map(i => {
                            return (
                                <WeekDayShiftPicker
                                    key={i}
                                    dayOfWeek={i}
                                    shifts={shifts}
                                    onSelect={(shift) => {
                                        const copy = [...scheduleToUpdate]
                                        const index = copy.findIndex(s => parseInt(s.day_of_week) === i)
                                        copy[index].shift_id = shift?.id ?? null
                                        copy[index].is_holiday = shift ? false : true
                                        setScheduleToUpdate(copy)
                                    }}
                                    locale={lang}
                                    defaultShift={findDefaultShift(i)}
                                />
                            )
                        })}
                    </div>
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    title={t(lang, "workPlanPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateShifts")}
                    onRowsDelete={(rows, ids) => { _delete(ids) }}
                />
            </DataSection>
        </Container>
    )
}

export default WorkPlanPage;





