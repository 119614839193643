
import globalStyles from "../static/GlobalStyles";



const Avatar = ({
    name = "Name",
    lastName = "Lastname",
    avatar = null,
    size = 64,
    isSquare = false
}) => {


    const forbiddenWords = ["AM", "İT"]

    const getInitials = () => {
        const initials = `${name.charAt(0)}${lastName.charAt(0)}`.toLocaleUpperCase()
        return forbiddenWords.includes(initials) ? initials.charAt(0) : initials
    }

    return (
        <div
            style={{
                width: `${size}px`,
                height: `${size}px`,
                borderRadius: isSquare ? `${size / 5}px` : '50%',
                backgroundColor: globalStyles.firstColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden'
            }}
        >
            {avatar ? (
                <img
                    src={avatar}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}
                />
            ) : (
                <span
                    style={{
                        fontFamily: globalStyles.font,
                        fontSize: `${parseInt(size / 2.2)}px`,
                        color: 'white'
                    }}
                >
                    {getInitials()}
                </span>
            )}

        </div>

    )

}

export default Avatar



