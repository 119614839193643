import { Button, FormControl, Tooltip } from "@mui/material";
import { TimeField } from "@mui/x-date-pickers";
import t from "../../static/Language";
import globalStyles from "../../static/GlobalStyles";
import { useState } from "react";
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import styled from "styled-components";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { InputAdornment } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';


const BreakDescriber = ({
    start,
    end,
    id,
    onDelete = (id) => null,
    lang = "tr",
    onChange = (id, start, end) => null,
    title = "Break"
}) => {

    const [_start, setStart] = useState(start)
    const [_end, setEnd] = useState(end)


    const theme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    },

                }
            }
        }
    })


    return (
        <div style={{ border: "1px solid #e5e6e1", borderRadius: "5px", margin: "5px", padding: "5px" }}>
            <ThemeProvider theme={theme}>
                <FormControl fullWidth sx={{ flexDirection: 'row', justifyContent: "space-between" }}>
                    <Title>
                        {title}
                    </Title>
                    <Tooltip title={t(lang, "table.delete")}>
                        <Button
                            sx={{ color: 'red', height: "25px", width: "25px", minWidth: 0 }}
                            onClick={() => onDelete(id)}
                        >
                            <RemoveCircleRoundedIcon sx={{ width: '20px' }} />
                        </Button>
                    </Tooltip>
                </FormControl>


                <FormControl fullWidth sx={{ flexDirection: 'row', justifyContent: "flex-start", alignItems: "flex-start", marginTop: "7px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>

                        <TimeField
                            size='small'
                            label={t(lang, "fields.startTime")}
                            format='HH:mm'
                            helperText='*'
                            value={start}
                            sx={{ margin: "3px", display: 'flex', flex: 1 }}
                            FormHelperTextProps={{
                                style: {
                                    color: 'red',
                                    fontFamily: globalStyles.font
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <AccessTimeIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(value) => {
                                setStart(value)
                                onChange(id, value, end)
                            }}
                            ampm={false}
                        />


                        <TimeField
                            size='small'
                            label={t(lang, "fields.endTime")}
                            format='HH:mm'
                            helperText='*'
                            value={end}
                            sx={{ margin: "3px", display: 'flex', flex: 1 }}
                            FormHelperTextProps={{
                                style: {
                                    color: 'red',
                                    fontFamily: globalStyles.font
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <AccessTimeIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(value) => {
                                setEnd(value)
                                onChange(id, start, value)
                            }}
                            ampm={false}

                        />

                    </LocalizationProvider>


                </FormControl>
            </ThemeProvider>

        </div>
    )
}

export default BreakDescriber;

const Title = styled.text`
    font-family: ${globalStyles.font};
    font-size: 16px;
    color: ${globalStyles.textColor};

`