import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import t from "../../static/Language";
import { Context } from "../../context/Context";
import { useContext, useEffect, useState } from "react";
import globalStyles from "../../static/GlobalStyles";

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { isMobile } from "react-device-detect";
import styled from 'styled-components';
import dayjs from "dayjs";
import { useRef } from "react";
import Avatar from '../Avatar'
import IconButton from '@mui/material/IconButton';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Tooltip from '@mui/material/Tooltip';


const TimesheetTable = ({
    data = [],
    month,
    onCellClick = (rowIndex, columnIndex, cell) => null
}) => {
    const { state } = useContext(Context)
    const lang = state.appLang

    const [columns, setColumns] = useState([])


    const [width, setWidth] = useState()
    const [heigth, setHeight] = useState()
    const parentRef = useRef(null)


    useEffect(() => {
        if (parentRef.current) {
            const parent = parentRef.current.parentNode; // Get the parent element
            setWidth(parent.clientWidth)
            setHeight(parent.clientHeight)
        }
    }, []);

    useEffect(() => {

        prepareColumns()
    }, [month])




    const textLabels = {
        body: {
            noMatch: t(lang, "table.noMatch"),
            toolTip: t(lang, "table.toolTip"),
        },
        pagination: {
            next: t(lang, "table.next"),
            previous: t(lang, "table.previous"),
            rowsPerPage: t(lang, "table.rowsPerPage"),
            displayRows: t(lang, "table.displayRows"),
            jumpToPage: t(lang, "table.jumpToPage"),
        },
        toolbar: {
            search: t(lang, "table.search"),
            downloadCsv: t(lang, "table.downloadCsv"),
            print: t(lang, "table.print"),
            viewColumns: t(lang, "table.viewColumns"),
            filterTable: t(lang, "table.filterTable")
        },
        filter: {
            all: t(lang, "table.all"),
            title: t(lang, "table.fTitle"),
            reset: t(lang, "table.reset")
        },
        viewColumns: {
            title: t(lang, "table.title"),
            titleAria: t(lang, "table.titleAria")
        },
        selectedRows: {
            text: t(lang, "table.text"),
            delete: t(lang, "table.delete"),
            deleteAria: t(lang, "table.deleteAria")
        }
    }





    const prepareColumns = () => {
        // Ayın ilk günü
        const startDate = dayjs(`${month}-01`);

        // Ayın son günü
        const endDate = startDate.endOf('month');

        let result = []
        result.push({
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                setCellProps: () => ({
                    style: { minWidth: "150px", position: "sticky", left: 0, backgroundColor: "#fff", zIndex: 2, padding: 0 }
                }),
                setCellHeaderProps: () => ({
                    style: { minWidth: "150px", position: "sticky", left: 0, backgroundColor: "#fff", zIndex: 3, maxHeight: '50px' }
                }),
                filter: true,
                sort: true,
                draggable: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>

                            <Avatar
                                avatar={data[tableMeta.rowIndex]?.avatar}
                                name={value?.split(" ")[0]}
                                lastName={value?.split(" ")[1]}
                                size={32}
                                isSquare
                            />
                            <span style={{ marginLeft: '8px' }}>{`${value?.split(" ")[0]} ${value?.split(" ")[1][0]}.`}</span>
                        </div>
                    )
                }

            }
        })



        result.push({
            name: "department",
            label: t(lang, "fields.department"),
            options: {
                setCellProps: () => ({
                    style: { minWidth: "100px", position: "sticky", left: '120px', backgroundColor: "#fff", zIndex: 2, padding: 0 }
                }),
                setCellHeaderProps: () => ({
                    style: { minWidth: "100px", position: "sticky", left: '120px', backgroundColor: "#fff", zIndex: 3, maxHeight: '50px' }
                }),
                filter: true,
                sort: true,
                draggable: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <text>{value}</text>
                    )
                }

            }
        })


        let currentDate = startDate;
        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
            result.push({
                name: currentDate.toDate().toLocaleDateString(lang, { day: '2-digit', weekday: 'short' }),
                options: {
                    viewColumns: false,
                    filter: false,
                    sort: false,
                    setCellProps: () => ({
                        style: { minWidth: "40px", maxWidth: "40px", zIndex: -40, border: '1px solid #e9ede4', padding: '5px' }
                    }),
                    setCellHeaderProps: () => ({
                        style: { minWidth: "40px", maxWidth: "40px", zIndex: 0, borderLeft: '1px solid #e9ede4', textAlign: 'left', maxHeight: '50px' }
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let reducedDays = tableMeta.rowData.at(-1) ?? []
                        let overtimeDays = tableMeta.rowData.at(-2) ?? []
                        const bgColor = reducedDays?.includes(tableMeta.columnData.name) ?
                            "#eb8f34" : overtimeDays?.includes(tableMeta.columnData.name) ?
                                "#0da317" : value === 'X' ? '#eb4034' : 'white'

                        return (
                            <div
                                style={{
                                    backgroundColor: bgColor ?? "yellow",
                                    padding: '3px',
                                    borderRadius: '4px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    cursor: "pointer"
                                }}
                                onClick={() => console.log(tableMeta)}
                            >
                                <text style={{ color: "white" }}>{value === 'X' ? "X" : value?.slice(0, 5)}</text>
                            </div>

                        )
                    },

                }
            })
            currentDate = currentDate.add(1, 'day');
        }


        result.push({
            name: "total_working_days",
            label: t(lang, "fields.totalWorkingDays"),
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: { minWidth: "110px", maxWidth: "110px", padding: '5px', fontWeight: 'bold', textAlign: 'right' }
                }),
                setCellHeaderProps: () => ({
                    style: { minWidth: "110px", maxWidth: "110px" }
                }),

            }
        })


        result.push({
            name: "total_working_hours",
            label: t(lang, "fields.totalWorkingHours"),
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: { minWidth: "110px", maxWidth: "110px", padding: '5px', fontWeight: 'bold', textAlign: 'right' }
                }),
                setCellHeaderProps: () => ({
                    style: { minWidth: "110px", maxWidth: "110px" }
                }),

            }
        })


        result.push({
            name: "total_absences",
            label: t(lang, "fields.totalAbsences"),
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: { minWidth: "110px", maxWidth: "110px", padding: '5px', fontWeight: 'bold', textAlign: 'right' }
                }),
                setCellHeaderProps: () => ({
                    style: { minWidth: "110px", maxWidth: "110px" }
                }),

            }
        })

        result.push({
            name: "overtime_days",
            label: t(lang, "fields.overtimeDays"),
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: { minWidth: "110px", maxWidth: "110px", padding: '5px', fontWeight: 'bold', textAlign: 'right' }
                }),
                setCellHeaderProps: () => ({
                    style: { minWidth: "110px", maxWidth: "110px" }
                }),

            }
        })

        result.push({
            name: "total_overtime",
            label: t(lang, "fields.totalOvertime"),
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: { minWidth: "110px", maxWidth: "110px", padding: '5px', fontWeight: 'bold', textAlign: 'right' }
                }),
                setCellHeaderProps: () => ({
                    style: { minWidth: "110px", maxWidth: "110px", maxHeight: '50px' }
                }),

            }
        })


        result.push({
            name: "overtime_days_collection",
            options: {
                filter: false,
                sort: true,
                display: 'excluded'
            }
        })

        result.push({
            name: "reduced_days_collection",
            options: {
                filter: false,
                sort: true,
                display: 'excluded'
            }
        })

        setColumns(result)
    }



    const tableTheme = createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                    },

                }
            },
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                    },

                }
            },

            MUIDataTablePagination: {
                styleOverrides: {
                    root: {
                        '@media print': {
                            display: 'none'
                        },
                    },
                }
            },
            MUIDataTableToolbar: {
                styleOverrides: {
                    root: {
                        '@media print': {
                            display: 'none'
                        }
                    },

                }
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                        backgroundColor: "#fff",

                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    head: {
                        height: '32px', // Başlık satırının yüksekliği
                        maxHeight: '32px'
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        fontFamily: globalStyles.font,
                        fontSize: '15px',
                        color: globalStyles.firstColor,
                        textAlign: 'left',
                        padding: '5px'
                    },
                    body: {
                        fontFamily: globalStyles.font,

                    },

                }
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                    },
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                        fontWeight: 'bold',
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    },
                    h6: {
                        fontWeight: 'bold'
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font
                    },
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiTablePagination: {
                styleOverrides: {
                    selectLabel: {
                        fontFamily: globalStyles.font
                    },
                    displayedRows: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        fontFamily: globalStyles.font
                    },
                    standard: {
                        fontFamily: globalStyles.font
                    },
                    multiple: {
                        fontFamily: globalStyles.font
                    },
                    nativeInput: {
                        fontFamily: globalStyles.font
                    }
                }
            },


            MuiChip: {
                styleOverrides: {
                    labelMedium: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.secondColor
                    },
                    root: {
                        borderRadius: '8px',
                    },
                    deleteIcon: {
                        color: globalStyles.secondColor
                    },

                }
            },
        }
    })



    return (
        <ThemeProvider theme={tableTheme}>
            <div ref={parentRef} style={{ overflowX: 'auto', height: heigth, width: width, overflowY: 'hidden' }}>
                <MUIDataTable
                    title={t(lang, "menuNames.attendance")}
                    data={data}
                    columns={columns}



                    components={{
                        icons: {
                            SearchIcon: () => { return (<SearchRoundedIcon sx={{ color: globalStyles.textColor }} />) },
                            PrintIcon: () => { return (<PrintRoundedIcon sx={{ color: globalStyles.textColor }} />) },
                            DownloadIcon: () => { return (<DownloadForOfflineRoundedIcon sx={{ color: globalStyles.textColor }} />) },
                            FilterIcon: () => { return (<FilterListRoundedIcon sx={{ color: globalStyles.textColor }} />) },
                            ViewColumnIcon: () => { return (<ViewWeekRoundedIcon sx={{ color: globalStyles.textColor }} />) }
                        },
                    }}

                    options={{
                        print: false,
                        downloadOptions: {
                            filename: `${month}-${t(lang, "menuNames.attendance")}.csv`
                        },
                        fixedHeader: true,
                        pagination: false,
                        rowsPerPage: data.length,
                        rowsPerPageOptions: [data.length],
                        selectableRows: "none",
                        jumpToPage: true,
                        responsive: isMobile ? "simple" : "standard",
                        filterType: 'multiselect',
                        textLabels: textLabels,
                        tableBodyHeight: heigth - 50,

                        customToolbar: () => {
                            const handleDownloadPdf = () => {
                                const doc = new jsPDF('landscape'); // Yatay mod
                                
                                doc.autoTable({
                                    head: [columns.map(col => col.label)],
                                    body: data,
                                    theme: 'grid'
                                });

                                doc.save(`${month}-timesheet.pdf`);
                            };

                            return (
                                <Tooltip title={t(lang, "common.saveAsPdf")}>
                                    <IconButton onClick={handleDownloadPdf}>
                                        <PictureAsPdfRoundedIcon style={{ color: globalStyles.textColor }} />
                                    </IconButton>
                                </Tooltip>

                            );
                        }

                        //onCellClick: (cellData, cellMeta) => console.log({ cellData, cellMeta })
                    }}
                />
            </div>



        </ThemeProvider>
    )
}


export default TimesheetTable;

