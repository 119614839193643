import { FormControl, Checkbox, FormControlLabel } from "@mui/material"
import { useEffect, useState } from "react"
import styled from "styled-components"
import globalStyles from "../static/GlobalStyles"



const FormCheckboxInput = ({ defaultChecked, onChange, disabled, label }) => {



    const handleChange = (e) => {
       
        if (onChange) {
            onChange(e.target.checked)
        }
    }

    return (
        <Container fullWidth disabled={disabled ?? false}>
            <Checkbox
                defaultChecked={defaultChecked}
                onChange={e => handleChange(e)}
                color="primary"
            />
            <Label>{label}</Label>
        </Container>
    )


}



export default FormCheckboxInput


const Container = styled(FormControl)`
    flex-direction: row !important;
    display: flex;
    align-items: center;
    margin-left: 5px !important;
`


const Label = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-weight: bold;
`