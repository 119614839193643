import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import FormInput from "../../../../components/FormInput";
import { Context } from "../../../../context/Context";
import Nodata from "../../../../states/Nodata";
import API from "../../../../static/API";
import t from "../../../../static/Language";
import SafeStorage from "../../../../static/SafeStorage";
import Avatar from "../../../../components/Avatar";


const UserGeneralTab = ({ userDetails }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang


    return (
        <Container>
            <Grid container spacing={1} >
                <Grid item xs={12} sm={12} md={12} lg={12} margin={'20px'}>
                    {/* <ProfilePicture src={userDetails?.avatar} /> */}
                    <Avatar 
                        avatar={userDetails?.avatar}
                        name={userDetails?.name}
                        lastName={userDetails?.last_name}
                        size={140}
                        isSquare={true}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.name")}
                        type="text"
                        initialValue={userDetails?.name}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.lastName")}
                        type="text"
                        initialValue={userDetails?.last_name}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.email")}
                        type="text"
                        initialValue={userDetails?.email}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.phone")}
                        type="phone"
                        initialValue={`${userDetails?.area_code}${userDetails?.phone}`}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.company")}
                        type="text"
                        initialValue={userDetails?.company_name}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.citizenId")}
                        type="text"
                        initialValue={`${userDetails?.id_number}`}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormInput
                        label={t(lang, "fields.address")}
                        type="text"
                        initialValue={userDetails?.address}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.chief")}
                        type="text"
                        initialValue={userDetails?.chief ?? " "}
                        readOnly
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormInput
                        label={t(lang, "fields.userName")}
                        type="text"
                        initialValue={`${userDetails?.user_name}`}
                        readOnly
                    />
                </Grid>
            </Grid>
        </Container>
    )
}


export default UserGeneralTab;



const Container = styled.div`
	flex-direction: column;
`