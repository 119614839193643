import { Player } from "@lottiefiles/react-lottie-player"
import styled from "styled-components"
import globalStyles from "../static/GlobalStyles"






const NotFound = () => {


    return (
        <Container>

            <Player
                src={require('../assets/lottie-animations/notfound.json')}
                autoplay
                loop
                style={{width: 400}}
            />

        </Container>
    )
}

export default NotFound

const Container = styled.div`
	display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh
	`

const ErrorText = styled.text`
    font-family: ${globalStyles.font};
    font-weight: bold;
    font-size: 100px;
    color: ${globalStyles.textColor};
    margin-top: 80px
`