import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DateTimePicker as DTPicker } from '@mui/x-date-pickers/DateTimePicker';
import { useState } from 'react';
import globalStyles from '../static/GlobalStyles';
import { FormControl } from "@mui/material";
import t from '../static/Language';



const DateTimePicker = ({
    onChange = (value) => null,
    locale = "tr",
    label = "Date and time",
    timeDisabled = false,
    isRequired = true
}) => {


    const [value, setValue] = useState(Date.now())


    const theme = createTheme({
        typography: {
            fontFamily: globalStyles.font, // İstediğiniz fontu burada belirleyin
        },
        MuiClockNumber: {
            styleOverrides: {
                root: {
                    color: globalStyles.textColor, // Saat numaralarının rengi
                },
            },
        },
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    },
                }
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    },

                },
            },
            MuiPickersYear: {
                styleOverrides: {
                    yearButton: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiDayCalendar: {
                styleOverrides: {
                    weekDayLabel: {
                        fontFamily: globalStyles.font
                    },
                    weekNumber: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiPickersCalendarHeader: {
                styleOverrides: {
                    label: {
                        fontFamily: globalStyles.font
                    }
                }
            }
        }
    })



    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locale}
                localeText={{
                    okButtonLabel: t(locale, "common.ok"),
                    cancelButtonLabel: t(locale, "common.cancel"),
                }}>

                <FormControl fullWidth sx={{ flexDirection: 'row', justifyContent: "flex-start", alignItems: "flex-start" }}>
                    <DTPicker
                        views={!timeDisabled ? ["year", "day", "hours", "minutes"] : ["year", "day"]}
                        label={label}
                        sx={{ margin: "3px", display: 'flex', flex: 1 }}
                        slotProps={{
                            textField: {
                                size: 'small',
                                helperText:  isRequired ? '*' : null,
                                FormHelperTextProps: {
                                    style: {
                                        fontFamily: globalStyles.font,
                                        color: 'red'
                                    }
                                }
                            }
                        }}
                        onChange={(value) => {
                            setValue(value.toDate().toISOString())
                            onChange(value.toDate().toISOString())
                        }}
                        onAccept={(value) => {
                            setValue(value.toDate().toISOString())
                            onChange(value.toDate().toISOString())
                        }}
                    />

                </FormControl>
            </LocalizationProvider>

        </ThemeProvider>
    )
}

export default DateTimePicker