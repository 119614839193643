import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../../../context/Context';
import API from '../../../../static/API';
import t from '../../../../static/Language';
import SafeStorage from '../../../../static/SafeStorage';
import 'react-modern-drawer/dist/index.css'
import DataTable from '../../../../components/DataTable';
import globalStyles from '../../../../static/GlobalStyles';
import FormInput from '../../../../components/FormInput';
import FormSelectInput from '../../../../components/FormSelectInput';
import Utils from '../../../../static/Utils';
import { toast, ToastContainer } from "react-toastify"
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import SwapHorizontalCircleRoundedIcon from '@mui/icons-material/SwapHorizontalCircleRounded';
import { useNavigate } from 'react-router-dom';
import DataSection from '../../../../components/DataSection';
import Container from '../../../../components/Container';
import NewRecordDrawer from '../../../../components/NewRecordDrawer';



const GatesPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const privileges = JSON.parse(SafeStorage.getItem("user")).privileges


    const [data, setData] = useState([])
    const [buildings, setBuildings] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [objInfo, setObjInfo] = useState({})
    const [objToEdit, setObjToEdit] = useState(null)
    const [updateInfo, setUpdateInfo] = useState({})
    const [initialBuilding, setInitialBuilding] = useState()
    const [initialDirection, setInitialDirection] = useState()
    const [directionData, setDirectionData] = useState([])
    const [actionOptions, setActionOptions] = useState()

    const navigate = useNavigate()


    useEffect(() => {
        document.title = t(lang, "gatesPage.title")
        setDirectionData([
            { id: 1, name: t(lang, "gatesPage.entry") },
            { id: 2, name: t(lang, "gatesPage.exit") },
            { id: 3, name: t(lang, "gatesPage.bidirectional") }
        ])
        prepareActionOptions()
        get()
        getBuildings()
    }, [])



    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canEditGates")) {
            _actionOptions["edit"] = {
                onEdit: (row, id) => {
                    setObjToEdit(row)
                    let direction = directionData.find(d => d.id == row.direction)
                    let bld = buildings.find(c => c.id === row.building)
                    setInitialDirection(direction)
                    setInitialBuilding(bld)
                    setEditOpen(true)
                }
            }
        }
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteGates")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([id])
                }
            }
        }
        _actionOptions.onShowDetail = (row, id) => {
            //alert(`${row} ${id} detay görüntülenecek`)
            navigate(`/gates/${id}`)
        }
        setActionOptions(_actionOptions)
    }




    const getBuildings = () => {
        API.getBuildings().then(response => {
            setBuildings(response.data.data)
        })
    }

    const get = () => {
        API.getGates().then(response => { // cevap gelirse şirketleri atıyoruz
            setData(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getGates().then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const create = () => {
        API.createGate(objInfo).then(response => {
            get()
            toast(t(lang, "gatesPage.created"))
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.createGate(objInfo).then(resp => {
                        get()
                        toast(t(lang, "gatesPage.created"))
                    }).catch(er => {
                        toast(t(lang, "gatesPage.createError"))
                    })
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const update = () => {
        API.updateGate(objToEdit.id, updateInfo).then(response => {
            get()
            toast(t(lang, "gatesPage.updated"))
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.updateBuilding(objToEdit.id, updateInfo).then(resp => {
                        get()
                        toast(t(lang, "gatesPage.updated"))
                    }).catch(err => {
                        toast(t(lang, "gatesPage.updateError"))
                    })
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const _delete = (ids) => {
        API.deleteGates(ids).then(response => {
            toast(t(lang, "gatesPage.deleted"))
            get()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteGates(ids).then(resp => {
                        toast(t(lang, "gatesPage.deleted"))
                        get()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "gatesPage.deleteError"))
                    }).catch(e => {
                        if (e.response.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }





    const columns = [
        {
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: true,
                draggable: true,

            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "building_name",
            label: t(lang, "fields.buildingName"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "company_name",
            label: t(lang, "fields.company"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "direction",
            label: t(lang, "fields.direction"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    //console.log(value)
                    //console.log(tableMeta)
                    return (

                        <div>
                            {value == 1 ? (
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                                    <ArrowCircleRightRoundedIcon style={{ color: "#3fd473" }} />
                                    <text>{t(lang, "gatesPage.entry")}</text>
                                </div>
                            ) : (value == 2 ? (
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                                    <ArrowCircleLeftRoundedIcon style={{ color: "#d43f3f" }} />
                                    <text>{t(lang, "gatesPage.exit")}</text>
                                </ div>
                            ) : (
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                                    <SwapHorizontalCircleRoundedIcon style={{ color: "#3f8cd4" }} />
                                    <text>{t(lang, "gatesPage.bidirectional")}</text>
                                </ div>
                            ))}
                        </div>
                    )
                }
            }
        }
    ]




    const validate = () => {
        if (Utils.nullOrEmpty(objInfo.name) ||
            Utils.nullOrEmpty(objInfo.building) ||
            Utils.nullOrEmpty(objInfo.direction) ||
            Utils.nullOrEmpty(objInfo.latitude) ||
            Utils.nullOrEmpty(objInfo.longitude)
        ) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!validate()) { //
            toast(t(lang, "gatesPage.requiredFields"))
            return
        }
        create()
    }



    const validateUpdate = () => {
        if ((updateInfo.name && Utils.nullOrEmpty(updateInfo.name)) ||
            (updateInfo.building && Utils.nullOrEmpty(updateInfo.building)) ||
            (updateInfo.direction && Utils.nullOrEmpty(updateInfo.direction)) ||
            (updateInfo.latitude && Utils.nullOrEmpty(updateInfo.latitude)) ||
            (updateInfo.longitude && Utils.nullOrEmpty(updateInfo.longitude)) 
        ) {
            return false
        }
        return true
    }


    const handleUpdateClicked = () => {
        if (!validateUpdate()) { //
            toast(t(lang, "gatesPage.requiredFields"))
            return
        }
        update()
    }


    return (

        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "gatesPage.new")}
                    onSaveClick={handleSaveClicked}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(val) => setObjInfo({ ...objInfo, name: val })}
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.description")}
                            onChange={(val) => setObjInfo({ ...objInfo, description: val })}
                        />
                    </Row>
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.buildingName")}
                            onSelect={(item) => setObjInfo({ ...objInfo, building: item.id === -1 ? null : item.id })}
                            data={buildings}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{`${item.name}`}</ItemText>
                                    </div>
                                )
                            }}
                        />
                    </Row>
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.direction")}
                            onSelect={(item) => setObjInfo({ ...objInfo, direction: item.id === -1 ? null : item.id })}
                            data={directionData}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{item.name}</ItemText>
                                    </div>
                                )
                            }}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.latitude")}
                            onChange={(val) => setObjInfo({ ...objInfo, latitude: val })}
                            type="number"
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.longitude")}
                            onChange={(val) => setObjInfo({ ...objInfo, longitude: val })}
                            type="number"
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Row>
                </NewRecordDrawer>
                <NewRecordDrawer
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={`${objToEdit?.name}`}
                    onSaveClick={handleUpdateClicked}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, name: val })}
                            helperText="*"
                            helperTextType={"error"}
                            initialValue={objToEdit?.name}
                        />

                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.description")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, description: val })}
                            initialValue={objToEdit?.description}
                        />

                    </Row>
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.buildingName")}
                            onSelect={(item) => setUpdateInfo({ ...updateInfo, building: item.id === -1 ? null : item.id })}
                            data={buildings}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{`${item.name}`}</ItemText>
                                    </div>
                                )
                            }}
                            initialValue={initialBuilding}
                        />
                    </Row>
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.direction")}
                            onSelect={(item) => setUpdateInfo({ ...updateInfo, direction: item.id === -1 ? null : item.id })}
                            data={directionData}
                            initialValue={initialDirection}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{item.name}</ItemText>
                                    </div>
                                )
                            }}

                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.latitude")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, latitude: val })}
                            initialValue={objToEdit?.latitude}
                            type="number"
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.longitude")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, longitude: val })}
                            initialValue={objToEdit?.longitude}
                            type="number"
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Row>
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    onRowsDelete={(rows, ids) => _delete(ids)}
                    title={t(lang, "gatesPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateGates")}
                />
            </DataSection>
        </Container>
    )

}


export default GatesPage;



const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`


const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`