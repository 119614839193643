import { Grid } from "@mui/material";
import styled from "styled-components";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useEffect, useState } from "react";
import globalStyles from "../../static/GlobalStyles";
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import Utils from "../../static/Utils";
import ShiftPicker from "./ShiftPicker";


const MIN_CELL_WITH = "80px"



const UserShiftCard = ({
    onShiftAdd = (shift, date, user) => null,
    onShiftDelete = (userShift) => null,
    shiftDate,
    userShiftsOnDay = [],
    shiftDescriptions = [],
    user
}) => {




    const [shiftsOnDay, setShiftsOnDay] = useState(userShiftsOnDay)
    const [pickerOpen, setPickerOpen] = useState(false)


    useEffect(() => {
        setShiftsOnDay(userShiftsOnDay)
    }, [userShiftsOnDay]) // userShiftsOnDay


    const handleDelete = (shift) => {
        //const _sOnDay = shiftsOnDay.filter(s => s.shift.id !== shift.shift.id)
        //setShiftsOnDay(_sOnDay)
        onShiftDelete(shift)
    }

    const handleAdd = (shift) => {
        /* const _sOnDay = [
            ...shiftsOnDay,
            {
                user: user,
                shift: shift,
                date: shiftDate
            }
        ] */
        // setShiftsOnDay(_sOnDay)
        setPickerOpen(false)
        onShiftAdd(shift, shiftDate, user)
    }


    const shiftsToDisplay = () => {
        //console.log(shiftsOnDay)
        if (shiftsOnDay.length === 1) {
            return [...shiftsOnDay]
        }
        return shiftsOnDay.filter(s => s.id !== null)
        
    }

    return (

        <Grid minHeight={"80px"} minWidth={MIN_CELL_WITH} container sx={{ borderLeft: `1px solid #ebe9e6` }} >
            <ShiftPicker
                open={pickerOpen}
                shifts={shiftDescriptions}
                onSelect={(shift) => handleAdd(shift)}
                title={`${user?.name} ${user?.last_name[0]}. - ${shiftDate.localeStringShort}`}
                onOutsideClick={() => setPickerOpen(false)}
            />
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', }}  >
                {shiftsToDisplay().map((s, i) => {
                    return (
                        <ShiftLabel
                            key={i}
                            style={{ backgroundColor: s.shift.color_code, }}
                        >
                            <CellText style={{ marginLeft: '5px', color: Utils.isDarkColor(s.shift.color_code) ? "white" : "black" }}>
                                {s.shift.short_code}
                            </CellText>
                            <button
                                style={{ display: 'contents', cursor: 'pointer' }}
                                onClick={() => handleDelete(s)}
                            >
                                {s.id !== null && // sihft_assignment_id null ise bu vardiya haftalık çalışma planından geliyor demektir ve silinemez
                                    <CloseRoundedIcon
                                        sx={{ width: '15px', marginRight: '5px', color: Utils.isDarkColor(s.shift.color_code) ? "white" : "black" }}
                                    />
                                }
                            </button>
                        </ShiftLabel>
                    )
                }

                )}
                <button
                    onClick={() => setPickerOpen(true)}
                    style={{ display: "flex", cursor: 'pointer', alignSelf: 'center', backgroundColor: 'transparent', borderWidth: 0 }}
                >
                    <AddBoxRoundedIcon sx={{ color: "#5e9ef7", width: '18px' }} />
                </button>
            </div>
        </Grid>
    )
}

export default UserShiftCard;


const CellText = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    cursor: default;
    font-size: 14px
`

const ShiftLabel = styled.div`
    height: 32px;
    border-radius: 3px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px;
    padding-left: 5px,
    
`