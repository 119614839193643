import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import FormInput from "../../../../../components/FormInput";
import { Context } from "../../../../../context/Context";
import Nodata from "../../../../../states/Nodata";
import t from "../../../../../static/Language";
import SafeStorage from "../../../../../static/SafeStorage";
import { QRCode } from 'react-qrcode-logo';
import API from "../../../../../static/API";
import { Button } from "@mui/material";
import logo from "../../../../../assets/icon/bee-turqoise-1024.png"

const AccessPointGeneralTab = ({ details }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang




    const downLoad = () => {
        const canvas = document.getElementById("qrcode")
        let url = canvas.toDataURL()
        const link = document.createElement('a')
        link.href = url;
        link.download = "qrcode.png"
        link.click()
    }



    return (
        <Container>

            <div style={{ width: 0, height: 0, opacity: 0 }}>
                <QRCode
                    id={"qrcode"}
                    value={details?.id}
                    logoImage={logo}
                    removeQrCodeBehindLogo
                    size={1024}
                    quietZone={20}
                    logoPadding={60}
                    eyeRadius={100}
                />
            </div>

            <Row>
                {/* <ProfilePicture src={details?.avatar} /> */}

                <QRCode
                    value={details?.id}
                    logoImage={logo}
                    size={300}
                    quietZone={20}
                    qrStyle="dots"
                    logoPadding={11}
                    eyeRadius={20}
                    removeQrCodeBehindLogo
                />
                <Button onClick={() => downLoad()} style={{ textTransform: "none" }}>{t(lang, "common.download")}</Button>
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.ip")}
                    type="text"
                    initialValue={details?.ip}
                    readOnly
                />
                <FormInput
                    label={t(lang, "fields.gate")}
                    type="text"
                    initialValue={details?.gate_name}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.company")}
                    type="text"
                    initialValue={details?.company_name}
                    readOnly
                />
            </Row>
        </Container>
    )
}


export default AccessPointGeneralTab;


const ProfilePicture = styled.img`
    width: 120px;
    height: 120px:;
    border: 1px solid black;
    border-radius: 5px;
    margin: 15px;
`

const Container = styled.div`
	flex-direction: column;
`

const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`