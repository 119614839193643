import { useContext } from "react";
import styled from "styled-components";
import FormInput from "../../../../../components/FormInput";
import { Context } from "../../../../../context/Context";
import t from "../../../../../static/Language";
import globalStyles from "../../../../../static/GlobalStyles";
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Container from "../../../../../components/Container";


const DayCard = ({ locale, day }) => {

    const getDayName = () => {
        const pseudoDate = new Date(1970, 5, parseInt(day.day_of_week))
        return pseudoDate.toLocaleString(locale, { weekday: 'long' })
    }


    const Field = ({ title, value, style = {} }) => {
        return (
            <div style={{ display: 'flex', flex: 1, padding: '8px', flexDirection: 'column', borderRight: '1px solid #dce0e0', ...style }}>
                <FieldText>
                    {title}
                </FieldText>
                <ValueText>
                    {value}
                </ValueText>
            </div>
        )
    }

    return (
        <div style={{ border: '1px solid #c5c9c9', borderRadius: '5px', margin: '15px', padding: '10px' }}>
            <text style={{ fontFamily: globalStyles.font, color: globalStyles.firstColor, }}>
                {getDayName()}
            </text>
            {day?.is_holiday ? (
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', marginTop: '8px' }}>
                    <BeachAccessIcon />
                </div>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', marginTop: '8px' }}>
                    <Field title={t(locale, "fields.shortCode")} value={day?.short_code} />
                    <Field title={t(locale, "fields.shiftStart")} value={day?.start_time?.slice(0, 5)} />
                    <Field title={t(locale, "fields.shiftEnd")} value={day?.end_time?.slice(0, 5)} />
                    <Field title={t(locale, "shiftsPage.earlyLeaveTolerance")} value={day?.early_leave_tolerance} />
                    <Field title={t(locale, "shiftsPage.lateEntryTolerance")} value={day?.late_entry_tolerance} style={{ border: 'none' }} />
                </div>
            )
            }


        </div >
    )
}


const WorkPlanGeneralTab = ({ details }) => {
    const { state } = useContext(Context)
    const lang = state.appLang


    return (
        <Container style={{ paddingBottom: '400px' }}>
            <Row>
                <FormInput
                    label={t(lang, "fields.name")}
                    type="text"
                    initialValue={details?.name}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.description")}
                    type="text"
                    initialValue={details?.description}
                    readOnly
                />
            </Row>
            {details?.schedule?.sort((a, b) => parseInt(a.day_of_week) > parseInt(b.day_of_week)).map(d => {
                return (
                    <DayCard locale={lang} day={d} />
                )
            })}

        </Container>
    )


}


export default WorkPlanGeneralTab;


const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`

const FieldText = styled.span`
    font-family: ${globalStyles.font};
    font-size: 17px;
    color: ${globalStyles.textColor}
`

const ValueText = styled.span`
    font-family: ${globalStyles.font};
    font-size: 14px;
    color: ${globalStyles.textColor}
`
