
import React, { useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Card, IconButton, Snackbar } from '@mui/material';
import styled from 'styled-components';


import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import bg from '../../assets/media/bg.jpg'
import globalStyles from '../../static/GlobalStyles';
import t from '../../static/Language';
import { Context } from '../../context/Context';

import API from '../../static/API';
import OtpInput from 'react-otp-input'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'




const OtpPage = () => {
    const context = useContext(Context)
    const lang = context.state.appLang

    const [otp, setOtp] = useState("")

    const { state } = useLocation()

    const navigate = useNavigate()


    const [messageOpen, setMessageOpen] = useState(false)


    useEffect(() => {
        if (!state?.canGo) {
            navigate('/login', { replace: true })
        }
    }, [])

    const onTime = () => {
        navigate('/phone', { replace: true })
    }

    const handleChange = (val) => {
        setOtp(val)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        API.validateVerificationCode(otp).then(response => {
            const secretKey = response.data.secret_key
            navigate('/creation', { state: { canGo: true, secretKey: secretKey, verificationCode: otp } })

        }).catch(err => {
            setMessageOpen(true)
        })

    }

    return (
        <Container onSubmit={e => handleSubmit(e)}>
            <Snackbar
                open={messageOpen}
                autoHideDuration={3000}
                onClose={() => setMessageOpen(false)}
            >
                <Alert sx={{ width: '100%' }} severity={"error"}>
                    {t(lang, "login.invalidVerificationCode")}
                </Alert>
            </Snackbar>
            <LoginCard>
                <WelcomeText>
                    {t(lang, "login.verificationCode")}
                </WelcomeText>
                <OtpInput
                    numInputs={6}
                    value={otp}
                    onChange={handleChange}
                    containerStyle={{

                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 56,
                        marginTop: 25
                    }}
                    inputStyle={{
                        margin: 10,
                        height: 40,
                        width: 40,
                        fontFamily: globalStyles.font,
                        fontSize: 24,
                        borderWidth: 0,
                        borderBottomWidth: 1,
                        backgroundColor: "transparent"
                    }}
                    shouldAutoFocus
                />

                <TimerContainer>
                    <CountdownCircleTimer
                        isPlaying
                        duration={180}
                        colors={['#1eba5c', '#F7B801', '#96ba1e', '#ba1e1e']}
                        colorsTime={[180, 90, 30, 0]}
                        size={60}
                        strokeWidth={6}
                        onComplete={onTime}
                    >
                        {({ remainingTime }) => (<RemainingTimeText>{remainingTime}</RemainingTimeText>)}
                    </CountdownCircleTimer>
                </TimerContainer>

                <SubmitButton
                    onClick={handleSubmit}
                    disabled={otp.length < 6}
                >
                    <LoginText>
                        {t(lang, "common.validate")}
                    </LoginText>
                    <ArrowForwardIosRoundedIcon style={{ width: 16 }} />

                </SubmitButton>
            </LoginCard>

        </Container>
    )


}

export default OtpPage;

const TimerContainer = styled.div`
    display: flex;
    padding: 25px;
    align-items: center;
    justify-content: center;
`

const RemainingTimeText = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-weight: bold;
`

const SubmitButton = styled(IconButton)`
	align-self: center;
	margin-top: 24px !important;
	border-radius: 15px !important;
	border-width: 0px;
	background-color: ${props => props.disabled ? '#b8b4ab' : '#FFE88C'} !important;
	padding: 5px !important;
	padding-right: 15px !important;
	padding-left: 15px !important;
	
`


const Container = styled.form`
	display: flex;
	height: 100vh;
	justify-content: center;
	align-items: center;
	background-color: #FFE88C;
	background-image: url(${bg});
	background-size: cover
`

const LoginCard = styled.div`
	display: flex;
	padding: 20px;
	border-radius: 24px !important;
	flex-direction: column;
	padding-top: 30px;
    background-color: transparent;
    border: solid 2px white;
    backdrop-filter: blur(15px);
`


const WelcomeText = styled.text`
	color: #353535;
	font-size: 24px;
	margin-top: 15px;
	font-family: ${globalStyles.font};
	font-weight: bold
`

const LoginText = styled.text`
	color: #353535;
	font-size: 16px;
	font-family: ${globalStyles.font};
`

