import { Box, Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";


import styled from "styled-components";
import globalStyles from "../../../static/GlobalStyles";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const ReorderQuestionsPage = ({
    questions = [],
    onReorder = (questions) => null
}) => {

    const [items, setItems] = useState(questions)




    useEffect(() => {
        setItems(questions)
    }, [questions])

    const grid = 8;

    const getListStyle = isDraggingOver => ({
        //background: isDraggingOver ? "lightblue" : "lightgrey",
        // padding: grid,
        marginTop: '15px',
        marginRight: '10px',
        marginLeft: '10px',

    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? globalStyles.firstColor : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const itemsCopy = [...items]
        moveItem(itemsCopy, result.source.index, result.destination.index)
        setItems(itemsCopy)
        onReorder(itemsCopy)
    }

    const moveItem = (arr, fromIndex, toIndex) => {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    return (
        <Box sx={{ minHeight: questions.length * 80 }}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => {
                        return (
                            <Grid
                                xs={12} xl={12}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {items?.map((item, index) => {
                                    return (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => {
                                                return (
                                                    <Card
                                                        sx={{ flexDirection: 'column', display: 'flex' }}
                                                        elevation={2}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <Text>{item.title}</Text>
                                                        <DescriptionText>{`${item.choices?.length ?? 0} seçenek`}</DescriptionText>
                                                    </Card>
                                                )
                                            }}
                                        </Draggable>
                                    )
                                })}
                            </Grid>
                        )
                    }}
                </Droppable>
            </DragDropContext>
        </Box>



    )
}

export default ReorderQuestionsPage;


const Text = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor}
`

const DescriptionText = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 12px
`

