import * as React from 'react';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { ThemeProvider, styled, createTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, FormControl, Grid } from '@mui/material';
import Utils from '../../static/Utils';
import globalStyles from '../../static/GlobalStyles';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import t from '../../static/Language';

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})(({ theme, isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
        backgroundColor: globalStyles.textColor,
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: globalStyles.textColor,
        },
    }),
    ...(isHovered && {
        backgroundColor: globalStyles.firstColor,
        '&:hover, &:focus': {
            backgroundColor: globalStyles.firstColor,
        },
    }),
    ...(day.day() === 1 && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(day.day() === 0 && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
}));

const isInSameWeek = (dayA, dayB) => {
    if (dayB == null) {
        return false;
    }

    return dayA.isSame(dayB, 'week');
};

function Day(props) {
    const { day, selectedDay, hoveredDay, ...other } = props;

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
        />
    );
}

function ButtonField(props) {
    const {
        setOpen,
        label,
        id,
        disabled,
        InputProps: { ref } = {},
        inputProps: { 'aria-label': ariaLabel } = {},
    } = props;

    return (
        <Button
            variant="standard"
            id={id}
            disabled={disabled}
            ref={ref}
            aria-label={ariaLabel}
            onClick={() => setOpen?.((prev) => !prev)}
            sx={{
                textTransform: 'none',
                color: globalStyles.textColor,
                borderRight: `1px solid ${globalStyles.textColor}`,
                borderLeft: `1px solid ${globalStyles.textColor}`,
                borderRadius: "0px"
            }}
        >
            {label}
        </Button>
    );
}

export default function WeekSelector({
    locale = "tr",
    onSelect = (weekRange) => null,
    disabled = false
}) {
    const [hoveredDay, setHoveredDay] = React.useState(null);
    const [value, setValue] = React.useState(dayjs(new Date()));
    const [open, setOpen] = React.useState(false)
    const [label, setLabel] = React.useState()


    React.useEffect(() => {
        const range = Utils.getWeekRangeOfGivenDate(locale, new Date())
        setLabel(`${range.start.localeStringShort} - ${range.end.localeStringShort}`)
    }, [])


    const handleChange = (value) => {
        const date = value.toDate()
        const range = Utils.getWeekRangeOfGivenDate(locale, date)
        setLabel(`${range.start.localeStringShort} - ${range.end.localeStringShort}`)
        setValue(value)
        setOpen(false)
        onSelect(range)
    }


    const theme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiPickersYear: {
                styleOverrides: {
                    yearButton: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiDayCalendar: {
                styleOverrides: {
                    weekDayLabel: {
                        fontFamily: globalStyles.font
                    },
                    weekNumber: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiPickersCalendarHeader: {
                styleOverrides: {
                    label: {
                        fontFamily: globalStyles.font
                    }
                }
            }
        }
    })


    const getNextWeek = () => {
        const date = value.toDate()
        const oneWeekLater = date.setDate(date.getDate() + 7)
        setValue(dayjs(oneWeekLater))
        const range = Utils.getWeekRangeOfGivenDate(locale, date)
        setLabel(`${range.start.localeStringShort} - ${range.end.localeStringShort}`)
        onSelect(range)
    }

    const getLastWeek = () => {
        const date = value.toDate()
        const oneWeekAgo = date.setDate(date.getDate() - 7)
        setValue(dayjs(oneWeekAgo))
        const range = Utils.getWeekRangeOfGivenDate(locale, date)
        setLabel(`${range.start.localeStringShort} - ${range.end.localeStringShort}`)
        onSelect(range)
    }

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignSelf: 'center',
                    alignItems: 'center',
                    border: `1px solid ${globalStyles.textColor}`,
                    borderRadius: '5px',
                    maxWidth: '200px',
                    minWidth: '200px',
                    marginLeft: '10px',
                    marginTop: '5px'
                }}
                >
                    <button
                        style={{ display: 'contents', cursor: 'pointer' }}
                        onClick={getLastWeek}
                        disabled={disabled}
                    >
                        <ChevronLeftRoundedIcon sx={{ color: globalStyles.textColor }} />
                    </button>

                    <DatePicker
                        disabled={disabled}
                        open={open}
                        value={value}
                        onChange={(newValue) => handleChange(newValue)}
                        showDaysOutsideCurrentMonth
                        displayWeekNumber
                        slots={{
                            day: Day,
                            field: ButtonField
                        }}
                        slotProps={{
                            day: (ownerState) => ({
                                selectedDay: value,
                                hoveredDay,
                                onPointerEnter: () => setHoveredDay(ownerState.day),
                                onPointerLeave: () => setHoveredDay(null),
                            }),
                            field: { setOpen, label: label ?? t(locale, "shiftsPage.selectWeek") }
                        }}
                    />
                    <button
                        style={{ display: 'contents', cursor: 'pointer' }}
                        onClick={getNextWeek}
                        disabled={disabled}
                    >
                        <ChevronRightRoundedIcon sx={{ color: globalStyles.textColor }} />
                    </button>
                </div>
            </LocalizationProvider>
        </ThemeProvider>
    );
}