import { useContext, useEffect, useState } from "react"
import { Context } from "../../context/Context"
import t from "../../static/Language"
import bg from '../../assets/media/bg.jpg'
import { Card, FormControl, IconButton, Input, InputAdornment } from "@mui/material"
import globalStyles from "../../static/GlobalStyles"

import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';

import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import styled from "styled-components"
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useLocation, useNavigate } from "react-router-dom"
import API from "../../static/API"
import { toast, ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';


const PasswordCreationPage = () => {
    const context = useContext(Context)
    const lang = context.state.appLang

    const [pss, setPss] = useState("")
    const [pssAgain, setPssAgain] = useState("")

    const [pssVisible, setPasswordVisible] = useState(false)
    const [pssAgainVisible, setPssAgainVisible] = useState(false)
   
    const { state } = useLocation()
    const navigate = useNavigate()



    useEffect(() => {
        if (!state?.canGo) {
            navigate('/login', { replace: true })
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        API.resetPassword(state?.verificationCode, state?.secretKey, pss, pssAgain).then(response => {
            toast(t(lang, "login.passwordCreatedSuccessfully"))
            navigate('/login', { replace: true })
        }).catch(err => {
            toast(JSON.stringify(err.response?.data?.msg))
            
        })
    }

    return (

        <Container onSubmit={e => handleSubmit(e)}>
            <LoginCard>
                <WelcomeText>{t(lang, "login.createPassword")}</WelcomeText>

                <FormControl variant="standard" style={{ marginBottom: 24, marginTop: 24 }}>
                    <Input
                        onChange={e => setPss(e.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <VpnKeyRoundedIcon style={{ color: '#353535' }} />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={() => setPasswordVisible(!pssVisible)}
                                >
                                    {pssVisible ? (
                                        <VisibilityRoundedIcon style={{ color: '#353535' }} />
                                    ) : (
                                        <VisibilityOffRoundedIcon style={{ color: '#353535' }} />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        inputProps={{
                            style: {
                                color: '#353535',
                                fontFamily: 'Ubuntu'
                            }
                        }}
                        type={pssVisible ? 'text' : 'password'}
                        placeholder={t(lang, "login.passwordPlaceholder")}
                    />
                </FormControl>

                <FormControl variant="standard">
                    <Input
                        onChange={e => setPssAgain(e.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <VpnKeyRoundedIcon style={{ color: '#353535' }} />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={() => setPssAgainVisible(!pssAgainVisible)}
                                >
                                    {pssAgainVisible ? (
                                        <VisibilityRoundedIcon style={{ color: '#353535' }} />
                                    ) : (
                                        <VisibilityOffRoundedIcon style={{ color: '#353535' }} />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        inputProps={{
                            style: {
                                color: '#353535',
                                fontFamily: 'Ubuntu'
                            }
                        }}
                        type={pssAgainVisible ? 'text' : 'password'}
                        placeholder={t(lang, "login.passwordAgainPlaceholder")}
                    />
                </FormControl>
                <SubmitButton
                    onClick={handleSubmit}
                    disabled={pss.length > 0 && pss !== pssAgain || pss.length < 6}
                >
                    <LoginText>
                        {t(lang, "common.create")}
                    </LoginText>
                    <ArrowForwardIosRoundedIcon style={{ width: 16 }} />

                </SubmitButton>

            </LoginCard>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
                /* icon={() => (<CheckCircleRoundedIcon style={{ color: "#08c243" }} />)} */
            />
        </Container>

    )
}

export default PasswordCreationPage;




const SubmitButton = styled(IconButton)`
    align-self: center;
    margin-top: 24px !important;
    border-radius: 15px !important;
    border-width: 0px;
	background-color: ${props => props.disabled ? '#b8b4ab' : '#FFE88C'} !important;
    padding: 5px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;

`

const Container = styled.form`
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #FFE88C;
    background-image: url(${bg});
    background-size: cover
`

const LoginCard = styled.div`
    display: flex;
    padding: 20px;
    border-radius: 24px !important;
    flex-direction: column;
    padding-top: 30px;
    background-color: transparent;
    border: solid 2px white;
    backdrop-filter: blur(15px);
`

const Logo = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 12px;
`

const WelcomeText = styled.text`
    color: #353535;
    font-size: 20px;
    margin-top: 15px;
    font-family: ${globalStyles.font};
    font-weight: bold
`

const LoginText = styled.text`
    color: #353535;
    font-size: 16px;
    font-family: ${globalStyles.font};
`
