import { forwardRef, useState } from "react";
import t from "../../static/Language";
import { Box, Grid } from "@mui/material";
import FormInput from "../FormInput";
import Utils from "../../static/Utils";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FlexButton from "../FlexButton";
import RobotButton from "../RobotButton";
//import OpenAI from 'openai';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { Player } from "@lottiefiles/react-lottie-player/dist/lottie-react";
import styled from "styled-components";
import globalStyles from "../../static/GlobalStyles";
import { toast, ToastContainer } from "react-toastify"
import QuizQuestionCard from "./QuizQuestionCard";
import DocumentUploader from "../DocumentUploader";


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const QuizCreator = ({
    onQuestionsChange = (questions) => null,
    onTitleChange = (title) => null,
    lang = "tr",
    initialQuestions = [],
    initialTitle = "",
    onDocumentSelect = (document) => null 
}) => {

    const [_questions, setQuestions] = useState(initialQuestions)
    const [_title, setTitle] = useState(initialTitle)
    const [loading, setLoading] = useState(false)


    const handleFileSizeError = (err, maxSize) => {
        toast.error(`${t(lang, "quizzesPage.maxSizeError")} ${maxSize}mb`)
    }

    const handleFileTypeError = (err) => {
        toast.error(t(lang, "quizzesPage.fileTypeError"))
    }


   


    return (

        <Box>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            {/* <Dialog
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                open={loading}
            >
                <DialogContent
                    sx={{ alignItems: 'center', justifyContent: 'center', width: '300px' }}
                >
                    <RobotSpeech>
                        {aiSays}
                    </RobotSpeech>
                    <Player
                        src={require('../../assets/lottie-animations/assistant-progress.json')}
                        autoplay
                        loop
                        style={{ width: "200px", height: "200px" }}
                    />
                </DialogContent>
            </Dialog> */}
            {/*  <Grid
                item
                xs={12} sm={12} md={12} lg={12}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderWidth: 1,
                    borderColor: "black"
                }}
            >
                <RobotButton />
                <FormInput
                    label={"Merhaba!"}
                    type="text"
                    multiline={true}
                    onChange={(text) => {
                        setAiInstructions(text)
                    }}
                    onEnterPress={e => getAISurvey()}
                />

            </Grid> */}
            <DocumentUploader
                tip={t(lang, "usersPage.ppTip")}
                onFileSelect={file => onDocumentSelect(file)}
                maxFileSizeInMB={5}
                onFileSizeError={(err, maxSize) => handleFileSizeError(err, maxSize)}
                onTypeError={(err) => handleFileTypeError(err)}
            />
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormInput
                    label={t(lang, "quizzesPage.documentName")}
                    type="text"
                    initialValue={initialTitle}
                    onChange={(text) => {
                        setTitle(text)
                        onTitleChange(text)
                    }}
                />
            </Grid>

            {_questions.map((q, i) => {
                return (
                    <QuizQuestionCard
                        lang={lang}
                        question={q}
                        onDelete={() => {
                            const newQuestions = [..._questions]
                            newQuestions.splice(i, 1)
                            setQuestions(newQuestions)
                            onQuestionsChange(newQuestions)
                        }}
                        onChange={(question) => {
                            const index = _questions.findIndex((_q) => _q.id === question.id);
                            const updated = { ..._questions[index], ...question };
                            const newQuestions = [..._questions];
                            newQuestions[index] = updated
                            setQuestions(newQuestions)
                            onQuestionsChange(newQuestions)
                        }}
                    />
                )
            })}

            <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                <FlexButton
                    onClick={() => {
                        let qs = [
                            ..._questions,
                            { id: Utils.uuid4(), question_type: 1 }
                        ]
                        setQuestions(qs)
                    }}
                    size="small"
                    renderIcon={() => <AddRoundedIcon />}
                    text={t(lang, "surveysPage.newQuestion")}
                />

            </Box>

        </Box>
    )
}

export default QuizCreator;