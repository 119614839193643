import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';
import { forwardRef, memo, useState } from "react";
import globalStyles from '../../static/GlobalStyles';
import { Button } from '@mui/material';
import Utils from '../../static/Utils';




const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const ShiftPicker = ({
    shifts = [],
    open,
    title = "",
    onSelect = (shift) => null,
    onOutsideClick = () => null,
    onClose = () => null
}) => {


    const [cursorOutside, setCursorOutside] = useState(true)


    const handleSelect = (shift) => {
        onSelect(shift)
    }


    return (
        <div >
            <Dialog
                onClick={() => {
                    if (cursorOutside) {
                        onOutsideClick()
                    }
                }}
                open={open}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                onClose={onClose}
            >
                <DialogContent
                    sx={{ maxHeight: "300px", minWidth: '300px' }}
                    onMouseEnter={() => setCursorOutside(false)}
                    onMouseLeave={() => setCursorOutside(true)}
                >
                    <Title>
                        {title}
                    </Title>
                    {shifts.map((s, i) => {
                        const textColor = Utils.isDarkColor(s.color_code) ? 'white' : globalStyles.textColor
                        return (
                            <ShiftButton
                                key={i}
                                onClick={() => handleSelect(s)}
                                style={{ backgroundColor: s.color_code }}
                            >
                                <Message style={{ color: textColor }}>
                                    {s.short_code}
                                </Message>
                                <Message style={{ color: textColor }}>
                                    {`${s.start_time.slice(0, 5)} - ${s.end_time.slice(0, 5)}`}
                                </Message>
                            </ShiftButton>
                        )
                    })}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ShiftPicker;




const Title = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 16px;
`

const ActionText = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 16px;
`

const Message = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 16px;
`

const Action = styled(Button)`
    border: solid 1px wheat !important;
`

const ShiftButton = styled.button`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 42px;
    border-radius: 5px;
    border: none;
    margin-top: 7px;
    cursor: pointer;
    justify-content: space-between;
`