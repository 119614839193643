import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import globalStyles from "../static/GlobalStyles"


import PhoneInput from 'react-phone-input-2'

import tr from 'react-phone-input-2/lang/tr.json'
import ru from 'react-phone-input-2/lang/ru.json'


const localeMapping = {
    tr: tr,
    ru: ru,
    en: undefined
}


const FormInput = ({
    label,
    onChange,
    helperText,
    tabIndex,
    type,
    helperTextType,
    initialValue,
    readOnly,
    multiline,
    variant = "outlined",
    autoFocus = false,
    onEnterPress = (e) => e,
    min,
    max,
    maxLength = 0,
    rows = 0,
    style = { margin: 15 },
    language = 'tr'
}) => {


    const [_type, setType] = useState(type ?? "search")
    const [value, setValue] = useState("")
    const [locale, setLocale] = useState(localeMapping[language])

 
    useEffect(() => {
        setValue(initialValue || initialValue === 0 ? initialValue : "")
    }, [initialValue])

    useEffect(() => {
        setLocale(localeMapping[language])
    }, [language])



    const handleChange = (e) => {
        let _val = e.target.value
        if (maxLength) {
            _val = e.target.value.slice(0, maxLength)
        }
        if (type === "number") {

            if (max && parseInt(e.target.value) > max) {
                _val = max
            }
            if ((min || min === 0) && parseInt(e.target.value) < min) {
                _val = min
            }
        }
        setValue(_val)
        if (onChange) {
            onChange(_val)
        }
    }


    const handlePhoneChanged = (value, country, e, formattedValue) => {
        setValue(value)
        if (onChange) {
            onChange(value, country, e, formattedValue)
        }
    }

    return (
        <div style={{ flex: 1, ...style }}>
            {type === "phone" ? (
                <div>
                    <PhoneInput
                        onEnterKeyPress={e => onEnterPress(e)}
                        disabled={readOnly}
                        onlyCountries={['tr', 'en', 'us', 'ru']}
                        country={'tr'}
                        onChange={(value, country, e, formattedValue) => handlePhoneChanged(value, country, e, formattedValue)}
                        value={value}
                        containerStyle={{ flex: 1, height: '40px' }}
                        localization={locale}
                        inputStyle={{
                            fontFamily: globalStyles.font,
                            color: globalStyles.textColor,
                            width: '100%',
                            height: '100%',
                            fontSize: 16,
                        }}
                        buttonStyle={{
                            borderColor: 'transparent',
                            backgroundColor: 'transparent',
                            fontFamily: globalStyles.font,
                            fontSize: 16,
                            color: globalStyles.textColor,
                        }}
                    />
                    {helperText &&
                        <text style={{
                            fontFamily: globalStyles.font, fontSize: 12,
                            color: helperTextType && helperTextType === "error" ? "red" : "#3975ed",
                            marginLeft: 14
                        }}>
                            {helperText}
                        </text>
                    }

                </div>

            ) : (
                <>
                    <TextField
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                                onEnterPress(e)
                            }
                        }}
                        multiline={multiline}
                        rows={rows}
                        disabled={readOnly}
                        helperText={helperText}
                        style={{ flex: 1, display: 'flex' }}
                        tabIndex={tabIndex}
                        name="name"
                        value={value}
                        variant={variant}
                        type={_type}
                        label={label}
                        onChange={(e) => handleChange(e)}
                        size="small"
                        autoFocus={autoFocus}
                        InputLabelProps={{
                            style: {
                                fontFamily: globalStyles.font,
                                color: globalStyles.textColor
                            }
                        }}
                        InputProps={{
                            style: {
                                fontFamily: globalStyles.font,
                                color: globalStyles.textColor,
                                fontSize: 16
                            },
                            inputProps: { max, min }
                        }}
                        FormHelperTextProps={{
                            style: {
                                fontFamily: globalStyles.font,
                                color: helperTextType && helperTextType === "error" ? "red" : "#3975ed"
                            }
                        }}
                    />
                    {maxLength > 0 &&
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end', paddingTop: "4px" }}>
                            <text style={{ fontFamily: globalStyles.font, color: globalStyles.textColor, fontSize: "14px" }}>
                                {`${value.length}/${maxLength}`}
                            </text>
                        </div>
                    }
                </>


            )}
        </div>
    )
}

export default FormInput