import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../../context/Context';
import API from '../../../static/API';
import t from '../../../static/Language';
import SafeStorage from '../../../static/SafeStorage';
import 'react-modern-drawer/dist/index.css'
import DataTable from '../../../components/DataTable';
import globalStyles from '../../../static/GlobalStyles';
import FormInput from '../../../components/FormInput';
import Utils from '../../../static/Utils';
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from 'react-router-dom';
import FormImageInput from '../../../components/FormImageInput';
import DataSection from '../../../components/DataSection';
import Container from '../../../components/Container';
import NewRecordDrawer from '../../../components/NewRecordDrawer';
import Select, { createFilter } from 'react-select';
import { Grid } from '@mui/material';
import BeasyChechbox from '../../../components/BeasyCheckbox';



const AnnouncementsPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const privileges = JSON.parse(SafeStorage.getItem("user")).privileges

    const navigate = useNavigate()


    const [data, setData] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [objInfo, setObjInfo] = useState({})
    const [updateInfo, setUpdateInfo] = useState({})
    const [editOpen, setEditOpen] = useState(false)
    const [actionOptions, setActionOptions] = useState()
    const [profilePicture, setProfilePicture] = useState()
    const [objToEdit, setObjToEdit] = useState(null)
    const [publishOpen, setPublishOpen] = useState(false)
    const [announcementToPublish, setAnnouncementToPublish] = useState(null)

    const [groups, setGroups] = useState()
    const [users, setUsers] = useState()
    const [iGroups, setIGroups] = useState([])
    const [iUsers, setIUsers] = useState([])
    const [sendNotification, setSendNotification] = useState(false)



    const dateOptions = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', second: '2-digit' }


    useEffect(() => {
        document.title = t(lang, "announcementsPage.title")
        get()
        getUserGroups()
        getUsers()
        prepareActionOptions()
    }, [])


    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canEditAnnouncements")) {
            _actionOptions["edit"] = {
                onEdit: (row, id) => {
                    setObjToEdit(row)
                    setEditOpen(true)
                }
            }
        }
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteAnnouncements")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([id])
                }
            }
        }
        _actionOptions.onShowDetail = (row, id) => {
            //alert(`${row} ${id} detay görüntülenecek`)
            navigate(`/announcements/${id}`)
        }

        if (privileges.includes("superAdmin") || privileges.includes("canCreateAnnouncements")) {
            _actionOptions["publish"] = {
                onClick: (row, id) => {
                    setSendNotification(false)
                    setIUsers([])
                    setIGroups([])
                    setAnnouncementToPublish(row)
                    setPublishOpen(true)
                }
            }
        }

        setActionOptions(_actionOptions)
    }


    const getUserGroups = () => {
        API.getUserGroups().then(response => { // cevap gelirse datayı atıyoruz
            setGroups(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getUserGroups().then(resp => { // userları tekrar çekiyoruz
                        setGroups(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const getUsers = () => {
        API.getUsers().then(response => { // cevap gelirse userları atıyoruz
            setUsers(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getUsers().then(resp => { // userları tekrar çekiyoruz
                        setUsers(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const get = () => {
        API.getAnnouncements().then(response => {
            setData(response.data.data.filter(a => !a.is_global))
        }).catch(err => {
            if (err === "expired credentials") {
                SafeStorage.removeItem("token")
                SafeStorage.removeItem("refreshToken")
                dispatch({ type: 'LOGIN', login: false })
            } else {
                console.log(err)
            }
        })
    }

    const create = () => {
        API.createAnnouncement(objInfo).then(response => {
            uploadImage(response.data.announcement_id)
            get()
            toast(t(lang, "announcementsPage.created"))
        }).catch(err => { // hata alınırsa
            if (err === "expired credentials") {
                SafeStorage.removeItem("token")
                SafeStorage.removeItem("refreshToken")
                dispatch({ type: 'LOGIN', login: false })
            } else {
                console.log(err)
            }
        })
    }

    const update = () => {
        API.updateAnnouncement(objToEdit.id, updateInfo).then(() => {
            toast(t(lang, "announcementsPage.updated"))
            get()
        }).catch(err => {
            if (err === "expired credentials") {
                SafeStorage.removeItem("token")
                SafeStorage.removeItem("refreshToken")
                dispatch({ type: 'LOGIN', login: false })
            } else {
                console.log(err)
            }
        })
    }

    const _delete = (ids) => {
        API.deleteAnnouncements(ids).then(() => {
            toast(t(lang, "announcementsPage.deleted"))
            get()
        }).catch(err => {

            if (err === "expired credentials") {
                SafeStorage.removeItem("token")
                SafeStorage.removeItem("refreshToken")
                dispatch({ type: 'LOGIN', login: false })
            } else {
                console.log(err)
            }
        })
    }

    const uploadImage = (userId) => {
        if (profilePicture) {
            //API.uploadFile(profilePicture)
            API.uploadFile(profilePicture).then(response => {
                const ppUrl = response.data.file_url
                API.updateAnnouncement(userId, { banner: ppUrl }).catch(err => {
                    console.log(err)
                })
            }).catch(err => toast("cannot upload image"))
        }
    }

    const columns = [
        {
            name: "title",
            label: t(lang, "fields.title"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "description",
            label: t(lang, "fields.description"),
            options: {
                filter: true,
                sort: false
            }

        },
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                sort: false
            }
        },
        {
            name: "created_user_name",
            label: t(lang, "fields.createdBy"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "created_at",
            label: t(lang, "fields.creationTime"),
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <text>{new Date(value).toLocaleString(lang, dateOptions)}</text>
                    )
                },
            }
        }
    ]




    const validate = () => {
        if (Utils.nullOrEmpty(objInfo.title) ||
            Utils.nullOrEmpty(objInfo.description)
        ) {
            return false
        }
        return true
    }

    const validateUpdate = () => {
        if ((updateInfo.title && Utils.nullOrEmpty(updateInfo.title)) ||
            (updateInfo.description && Utils.nullOrEmpty(updateInfo.description))
        ) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!validate()) { //
            toast(t(lang, "announcementsPage.requiredFields"))
            return
        }
        create()
    }

    const handleUpdateClicked = () => {
        if (!validateUpdate()) { //
            toast(t(lang, "announcementsPage.requiredFields"))
            return
        }
        update()
    }


    const handlePublishClicked = () => {
        const publishObject = {
            user_ids: iUsers.map(x => x.id),
            group_ids: iGroups.map(x => x.id),
            announcement_id: announcementToPublish.id,
            company_id: announcementToPublish.company,
            send_notification: sendNotification
        }
        API.publishAnnouncement(publishObject).then(response => {
            toast(t(lang, "announcementsPage.published"))
        }).catch(e => {
            if (e.message === "expired credentials") {
                SafeStorage.removeItem("token")
                SafeStorage.removeItem("refreshToken")
                dispatch({ type: 'LOGIN', login: false })
            } else {
                console.log(e)
            }
        })

    }

    const filter = createFilter({ ignoreCase: true })


    return (

        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "announcementsPage.new")}
                    onSaveClick={handleSaveClicked}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.title")}
                            onChange={(val) => setObjInfo({ ...objInfo, title: val })}
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.description")}
                            onChange={(val) => setObjInfo({ ...objInfo, description: val })}
                            helperText="*"
                            helperTextType={"error"}
                            multiline={true}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.externalLink")}
                            onChange={(val) => setObjInfo({ ...objInfo, external_link: val })}
                        />
                    </Row>
                    <FormImageInput
                        label={t(lang, "announcementsPage.banner")}
                        tip={t(lang, "usersPage.ppTip")}
                        onFileSelect={(file) => setProfilePicture(file)}
                    />
                </NewRecordDrawer>
                <NewRecordDrawer
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={`${objToEdit?.title}`}
                    onSaveClick={handleUpdateClicked}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.title")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, title: val })}
                            helperText="*"
                            helperTextType={"error"}
                            initialValue={objToEdit?.title}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.description")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, description: val })}
                            helperText="*"
                            helperTextType={"error"}
                            initialValue={objToEdit?.description}
                            multiline
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.externalLink")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, external_link: val })}
                            initialValue={objToEdit?.external_link}
                        />
                    </Row>
                    <FormImageInput
                        label={t(lang, "announcementsPage.banner")}
                        tip={t(lang, "usersPage.ppTip")}
                        onFileSelect={(file) => setProfilePicture(file)}
                        defaultSrc={objToEdit?.banner}
                    />
                </NewRecordDrawer>

                <NewRecordDrawer
                    open={publishOpen}
                    onClose={() => setPublishOpen(false)}
                    saveButtonTitle={t(lang, "announcementsPage.publish")}
                    title={t(lang, "announcementsPage.publish")}
                    onSaveClick={handlePublishClicked}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.title")}
                            initialValue={announcementToPublish?.title}
                            readOnly={true}
                        />
                    </Row>

                    <Grid item padding={'15px'} xs={12}>
                        <Select
                            options={groups}

                            getOptionValue={o => o.id}
                            getOptionLabel={o => o.name}
                            isMulti
                            isSearchable
                            isClearable
                            closeMenuOnSelect={false}
                            placeholder={t(lang, "surveysPage.toGroup")}
                            filterOption={filter}
                            noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                            onChange={(value) => setIGroups(value)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: globalStyles.font,
                                    marginBottom: '15px'
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: globalStyles.font,
                                }),
                            }}
                        />
                    </Grid>

                    <Grid item padding={'15px'} xs={12}>
                        <Select

                            options={users}
                            defaultValue={iUsers}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => `${o.name} ${o.last_name}`}
                            isMulti
                            isSearchable
                            isClearable
                            closeMenuOnSelect={false}
                            placeholder={t(lang, "surveysPage.toUser")}
                            filterOption={filter}
                            noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                            onChange={(value) => setIUsers(value)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: globalStyles.font,
                                    width: '100%'
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: globalStyles.font,
                                }),
                            }}
                        />
                    </Grid>

                    <Grid item padding={'15px'} xs={12}>
                        <BeasyChechbox
                            size="large"
                            label={t(lang, "announcementsPage.sendPushNotif")}
                            onChange={val => setSendNotification(val)}
                        />
                    </Grid>

                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    onRowsDelete={(rows, ids) => _delete(ids)}
                    title={t(lang, "announcementsPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateAnnouncements")}
                />
            </DataSection>
        </Container>
    )
}

export default AnnouncementsPage;


const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%;
`


