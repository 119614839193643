import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../../../context/Context';
import API from '../../../../static/API';
import t from '../../../../static/Language';
import SafeStorage from '../../../../static/SafeStorage';
import 'react-modern-drawer/dist/index.css'
import DataTable from '../../../../components/DataTable';
import globalStyles from '../../../../static/GlobalStyles';
import FormInput from '../../../../components/FormInput';
import FormSelectInput from '../../../../components/FormSelectInput';
import Utils from '../../../../static/Utils';
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from 'react-router-dom';
import DataSection from '../../../../components/DataSection';
import Container from '../../../../components/Container';
import NewRecordDrawer from '../../../../components/NewRecordDrawer';




const AccessPointsPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const privileges = JSON.parse(SafeStorage.getItem("user")).privileges


    const [data, setData] = useState([])
    const [gates, setGates] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [objInfo, setObjInfo] = useState({})
    const [objToEdit, setObjToEdit] = useState(null)
    const [updateInfo, setUpdateInfo] = useState({})
    const [initialGate, setInitialGate] = useState()
    const [actionOptions, setActionOptions] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        document.title = t(lang, "accessPointsPage.title")
        prepareActionOptions()
        get()
        getGates()
    }, [])

    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canEditAccessPoints")) {
            _actionOptions["edit"] = {
                onEdit: (row, id) => {
                    setObjToEdit(row)
                    let gate = gates.find(d => d.id == row.gate)
                    setInitialGate(gate)
                    setEditOpen(true)
                }
            }
        }
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteAccessPoints")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([id])
                }
            }
        }
        _actionOptions.onShowDetail = (row, id) => {
            navigate(`/access-points/${id}`)
        }
        setActionOptions(_actionOptions)
    }




    const getGates = () => {
        API.getGates().then(response => {
            setGates(response.data.data)
        })
    }

    const get = () => {
        API.getAccessPoints().then(response => { // cevap gelirse şirketleri atıyoruz
            setData(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getAccessPoints().then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const create = () => {
        API.createAccessPoint(objInfo).then(response => {
            get()
            toast(t(lang, "accessPointsPage.created"))
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.createAccessPoint(objInfo).then(resp => {
                        get()
                        toast(t(lang, "accessPointsPage.created"))
                    }).catch(er => {
                        toast(t(lang, "accessPointsPage.createError"))
                    })
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const update = () => {
        API.updateAccessPoint(objToEdit.id, updateInfo).then(response => {
            get()
            toast(t(lang, "accessPointsPage.updated"))
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.updateAccessPoint(objToEdit.id, updateInfo).then(resp => {
                        get()
                        toast(t(lang, "accessPointsPage.updated"))
                    }).catch(err => {
                        toast(t(lang, "accessPointsPage.updateError"))
                    })
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const _delete = (ids) => {
        API.deleteAccessPoints(ids).then(response => {
            toast(t(lang, "accessPointsPage.deleted"))
            get()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteAccessPoints(ids).then(resp => {
                        toast(t(lang, "accessPointsPage.deleted"))
                        get()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "accessPointsPage.deleteError"))
                    }).catch(e => {
                        if (e.response.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }





    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "gate_name",
            label: t(lang, "fields.gate"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "ip",
            label: t(lang, "fields.ip"),
            options: {
                filter: true,
                sort: false
            }
        },

    ]




    const validate = () => {
        if (Utils.nullOrEmpty(objInfo.gate)) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!validate()) { //
            toast(t(lang, "accessPointsPage.requiredFields"))
            return
        }
        create()
    }



    const validateUpdate = () => {
        if ((updateInfo.gate && Utils.nullOrEmpty(updateInfo.gate))) {
            return false
        }
        return true
    }


    const handleUpdateClicked = () => {
        if (!validateUpdate()) { //
            toast(t(lang, "accessPointsPage.requiredFields"))
            return
        }
        update()
    }


    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    title={t(lang, "usersPage.newUser")}
                    onSaveClick={handleSaveClicked}
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                >
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.gate")}
                            onSelect={(item) => setObjInfo({ ...objInfo, gate: item.id === -1 ? null : item.id })}
                            data={gates}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{`${item.name}`}</ItemText>
                                    </div>
                                )
                            }}
                            helperText={"*"}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.ip")}
                            onChange={(val) => setObjInfo({ ...objInfo, ip: val })}
                        />
                    </Row>
                </NewRecordDrawer>
                <NewRecordDrawer
                    title={`${objToEdit?.name}`}
                    onSaveClick={handleUpdateClicked}
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                >
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.gate")}
                            onSelect={(item) => setObjInfo({ ...updateInfo, gate: item.id === -1 ? null : item.id })}
                            data={gates}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{`${item.name}`}</ItemText>
                                    </div>
                                )
                            }}
                            initialValue={initialGate}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.ip")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, ip: val })}
                            initialValue={objToEdit?.ip}
                        />
                    </Row>
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    onRowsDelete={(rows, ids) => _delete(ids)}
                    title={t(lang, "accessPointsPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateAccessPoints")}

                />
            </DataSection>
        </Container>
    )
}


export default AccessPointsPage;



const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`



const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`