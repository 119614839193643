import styled from "styled-components";


const DataSection = styled.div`
	margin: 5px;
	display: flex;
	height: 100%
`


export default DataSection;