import { useContext, useState } from "react";
import { Context } from "../../../../context/Context";
import t from "../../../../static/Language";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import globalStyles from "../../../../static/GlobalStyles";
import { Box, Breadcrumbs, Button, Card, Container, Link, Step, StepLabel, Stepper } from '@mui/material';
import { isMobile } from 'react-device-detect';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ReorderQuestionsPage from "./ReorderQuestionsPage";
import AssignQuizPage from "./AssignQuizPage";
import QuizCreator from "../../../../components/quiz-components/QuizCreator";
import { ToastContainer, toast } from "react-toastify";
import Utils from "../../../../static/Utils";


const CreateQuizPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const navigate = useNavigate()

    const steps = [
        t(lang, "surveysPage.questions"),
        t(lang, "surveysPage.order"),
        t(lang, "surveysPage.assignment")
    ]


    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const [title, setTitle] = useState("")
    const [questions, setQuestions] = useState([])
    const [document, setDocument] = useState()

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        if (activeStep === 2) {
            return
        }
        if (!questionsValid()) {
            return
        }
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            return
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const surveyIsValid = () => {
        return title !== ""
            && questions.length > 0
            && document
            
    }

    const questionsValid = () => {
        questions.forEach(q => {
            if (Utils.nullOrEmpty(q.title)) {
                toast(t(lang, "quizzesPage.questionTitleError"))
                return false
            }
            var correctChoices = q.choices.filter(c => c.calculation_value > 0)
            if (correctChoices.length != 1) {
                toast(t(lang, "quizzesPage.choiceError"))
                return false
            }
        })
        return true
    }

    return (
        <Container sx={{ padding: '15px', }} maxWidth={false}>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <Breadcrumbs
                separator={<ArrowForwardIosRoundedIcon style={{ color: "#639ef7" }} />}
            >
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} onClick={() => navigate("/ethic-documents")}>
                    <CrumbText>{t(lang, "quizzesPage.title")}</CrumbText>
                </Link>
                <CurrentCrumbText>{t(lang, "quizzesPage.new")}</CurrentCrumbText>
            </Breadcrumbs>
            <Stepper
                sx={{ marginRight: "25px", marginLeft: "25px", marginTop: "35px", }}
                alternativeLabel={isMobile}
                activeStep={activeStep}
            >
                {steps.map((label, index) => {
                    const stepProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel StepIconProps={{
                                style: {
                                    width: activeStep === index ? '35px' : '25px',
                                    height: activeStep === index ? '35px' : '25px',
                                    borderRadius: '20px',
                                    color: activeStep == index ? globalStyles.firstColor : globalStyles.secondColor,
                                }
                            }}>
                                <Label>{label}</Label>
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <TabsSection elevation={0} style={{ overflow: 'visible' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', pt: 3, }}>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ textTransform: 'none' }}
                    >
                        <ArrowBackIosNewRoundedIcon />
                        <ButtonText>{t(lang, "common.back")}</ButtonText>
                    </Button>
                    <Button
                        disabled={activeStep === 2 || !surveyIsValid()}
                        onClick={handleNext}
                        sx={{ textTransform: 'none' }}
                    >
                        <ButtonText>{t(lang, "common.next")}</ButtonText>
                        <ArrowForwardIosRoundedIcon />
                    </Button>
                </Box>
                {activeStep === 0 &&
                    <QuizCreator
                        onTitleChange={title => setTitle(title)}
                        onQuestionsChange={questions => setQuestions(questions)}
                        initialQuestions={questions}
                        initialTitle={title}
                        onDocumentSelect={document => setDocument(document)}
                    />
                }
                {activeStep === 1 &&
                    <Box>
                        <ReorderQuestionsPage
                            questions={questions}
                            onReorder={(questions) => setQuestions(questions)}
                        />
                    </Box>

                }
                {activeStep === 2 &&
                    <AssignQuizPage
                        questions={questions}
                        title={title}
                        file={document}
                    />
                }
            </TabsSection>
        </Container>
    )



}

export default CreateQuizPage;


const Label = styled.text`
    font-family: ${globalStyles.font};
`

const ButtonText = styled.text`
    font-family: ${globalStyles.font};
    font-weight: semi-bold;
`

const TabsSection = styled(Card)`
    margin-top: 15px;
`

const CrumbText = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 12px;
    cursor: pointer
`

const CurrentCrumbText = styled.text`
    font-family: ${globalStyles.font};
    color: black;
    font-size: 14px;
    cursor: default
`