import { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/Context";
import API from "../../../static/API";
import SafeStorage from "../../../static/SafeStorage";
import { Box, FormControl, Grid } from "@mui/material";
import Select, { createFilter } from 'react-select';
import styled from "styled-components";
import globalStyles from "../../../static/GlobalStyles";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import t from "../../../static/Language";
import FlexButton from "../../../components/FlexButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast, ToastContainer } from "react-toastify"
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'


import "dayjs/locale/tr"
import "dayjs/locale/en"

import { createTheme, ThemeProvider } from '@mui/material/styles';


import { useNavigate } from "react-router-dom";
import FormImageInput from "../../../components/FormImageInput";
import Utils from "../../../static/Utils";
import dayjs from "dayjs";
dayjs.extend(utc)
dayjs.extend(timezone)


 

const AssignSurveyPage = ({
    questions = [],
    title = "",
    includedGroups = [],
    includedUsers = [],
    excludedGroups = [],
    excludedUsers = []
}) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const navigate = useNavigate()


    const [_questions, setQuestions] = useState(questions)
    const [_title, setTitle] = useState(title)
    const [groups, setGroups] = useState()
    const [users, setUsers] = useState()
    const [iGroups, setIGroups] = useState(includedGroups)
    const [iUsers, setIUsers] = useState(includedUsers)
    const [xGroups, setXGroups] = useState(excludedGroups)
    const [xUsers, setXUsers] = useState(excludedUsers)
    const [expireDate, setExpireDate] = useState(null)
    const [banner, setBanner] = useState()
    
    const tz = dayjs.tz.guess()

    useEffect(() => {
        setQuestions(_questions)
    }, [questions])

    useEffect(() => {
        setTitle(title)
    }, [title])

    useEffect(() => {
        getUserGroups()
        getUsers()
    }, [])


    const filter = createFilter({ ignoreCase: true })

    const getUserGroups = () => {
        API.getUserGroups().then(response => { // cevap gelirse datayı atıyoruz
            setGroups(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getUserGroups().then(resp => { // userları tekrar çekiyoruz
                        setGroups(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const getUsers = () => {
        API.getUsers().then(response => { // cevap gelirse userları atıyoruz
            setUsers(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getUsers().then(resp => { // userları tekrar çekiyoruz
                        setUsers(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const calendarTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font
                    },

                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiPickersCalendarHeader: {
                styleOverrides: {
                    label: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },

            MuiDayCalendar: {
                styleOverrides: {
                    weekDayLabel: {
                        fontFamily: globalStyles.font
                    }
                }
            },


        },


    })


    const prepareSurveyObject = async () => {
        const company = JSON.parse(SafeStorage.getItem("user")).company
        let surveyObject = {
            tz: tz,
            company: company,
            title: _title,
            expire_date: expireDate,
            questions: _questions.map((q, i) => { return { ...q, question_order: i + 1 } }),
            assignment: {
                groups: iGroups.map(x => x.id),
                users: iUsers.map(x => x.id),
                groups_x: xGroups.map(x => x.id),
                users_x: xUsers.map(x => x.id)
            }
        }
        if (Utils.nullOrEmpty(banner)) {
            return surveyObject
        }
        try {
            const uploadResponse = await API.uploadFile(banner)
            const fileUrl = uploadResponse.data.file_url
            surveyObject.media = fileUrl
            return surveyObject
        } catch (error) {
            toast("cannot upload image")
        } finally {
            return surveyObject
        }
    }



    const validate = () => {
        if (iGroups.length === 0 && iUsers.length === 0) {
            toast(t(lang, "surveysPage.noAssignment"))
            return false
        }
        return true
    }

    const createSurvey = async () => {
        if (!validate()) {
            return
        }
        const survey = await prepareSurveyObject()
     
        API.createSurvey(survey).then(response => {
            navigate("/surveys")
        }).catch(err => {
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.createSurvey().then(resp => { // userları tekrar çekiyoruz
                        navigate("/surveys")
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })

    }


    return (
        <Grid container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <FormImageInput
                label={t(lang, "announcementsPage.banner")}
                tip={t(lang, "usersPage.ppTip")}
                onFileSelect={(file) => setBanner(file)}
            />
            <FormControl fullWidth sx={{ padding: '15px', marginTop: '10px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"tr"}>
                    <ThemeProvider theme={calendarTheme} >
                        <DatePicker
                            label={t(lang, "surveysPage.expireDate")}
                            slotProps={{
                                field: { clearable: true, onClear: () => null },
                                textField: { size: 'small' },
                                desktopPaper: {
                                    sx: {
                                        "& .MuiPickersDay-root": {
                                            "&.Mui-selected": {
                                                backgroundColor: globalStyles.firstColor,
                                                color: globalStyles.textColor
                                            }
                                        },
                                        "& .MuiPickersYear-yearButton": {
                                            "&.Mui-selected": {
                                                backgroundColor: globalStyles.firstColor,
                                                color: globalStyles.textColor
                                            }
                                        },
                                    }
                                },
                            }}
                            onChange={(value) =>  {
                                setExpireDate(value.toDate().toISOString())
                            } }
                        />
                    </ThemeProvider>

                </LocalizationProvider>
            </FormControl>

            <Grid item flexDirection={'column'} display={'flex'} padding={'15px'} xs={12} md={6} justifyContent={'flex-start'}>
                <div style={{ alignItems: 'stretch', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                    <Title>{t(lang, "surveysPage.included")}</Title>
                    <AddRoundedIcon color={globalStyles.textColor} />
                </div>
                <Select
                    options={groups}
                    defaultValue={iGroups}
                    getOptionValue={o => o.id}
                    getOptionLabel={o => o.name}
                    isMulti
                    isSearchable
                    isClearable
                    closeMenuOnSelect={false}
                    placeholder={t(lang, "surveysPage.toGroup")}
                    filterOption={filter}
                    noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                    onChange={(value) => setIGroups(value)}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                            marginBottom: '15px'
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                        }),
                    }}
                />
                <Select
                    options={users}
                    defaultValue={iUsers}
                    getOptionValue={o => o.id}
                    getOptionLabel={o => `${o.name} ${o.last_name}`}
                    isMulti
                    isSearchable
                    isClearable
                    closeMenuOnSelect={false}
                    placeholder={t(lang, "surveysPage.toUser")}
                    filterOption={filter}
                    noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                    onChange={(value) => setIUsers(value)}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                            marginBottom: '15px'
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                        }),
                    }}
                />
            </Grid >
            <Grid item flexDirection={'column'} display={'flex'} padding={'15px'} xs={12} md={6} justifyContent={'flex-start'}>
                <div style={{ alignItems: 'stretch', justifyContent: 'space-between', display: 'flex', flexDirection: 'row', }}>
                    <Title>{t(lang, "surveysPage.excluded")}</Title>
                    <RemoveRoundedIcon color={globalStyles.textColor} />
                </div>
                {/* <Select
                    options={groups}
                    defaultValue={xGroups}
                    getOptionValue={o => o.id}
                    getOptionLabel={o => o.name}
                    isMulti
                    isSearchable
                    isClearable
                    closeMenuOnSelect={false}
                    placeholder={t(lang, "surveysPage.toGroup")}
                    filterOption={filter}
                    noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                    onChange={(value) => setXGroups(value)}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                            marginBottom: '15px'
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                        }),
                    }}

                /> */}
                <Select
                    options={users}
                    defaultValue={xUsers}
                    getOptionValue={o => o.id}
                    getOptionLabel={o => `${o.name} ${o.last_name}`}
                    isMulti
                    isSearchable
                    isClearable
                    closeMenuOnSelect={false}
                    placeholder={t(lang, "surveysPage.toUser")}
                    filterOption={filter}
                    noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                    onChange={(value) => setXUsers(value)}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                            marginBottom: '15px'
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                        }),
                    }}
                />
            </Grid >
            <Box sx={{ justifyContent: 'flex-end', display: 'flex', flex: 1 }}>
                <FlexButton
                    text={t(lang, "surveysPage.new")}
                    onClick={createSurvey}
                />
            </Box>

        </Grid>
    )

}

export default AssignSurveyPage;

const Title = styled.text`
    font-family: ${globalStyles.font};
    font-size: 18px;
    color: ${globalStyles.textColor};
    font-weight: bold;
    margin-bottom: 15px
`
