import styled from "styled-components";


const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%
`


export default Container;