
import { useContext } from "react";
import styled from "styled-components";
import FormInput from "../../../../../components/FormInput";
import { Context } from "../../../../../context/Context";
import t from "../../../../../static/Language";


const UserGroupGeneral = ({ details }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang


    return (
        <Container>
            <Row>
                <FormInput
                    label={t(lang, "fields.name")}
                    type="text"
                    initialValue={details?.name}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.company")}
                    type="text"
                    initialValue={details?.company_name}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={"ID"}
                    type="text"
                    initialValue={details?.id}
                    readOnly
                />
            </Row>
        </Container>
    )

}

export default UserGroupGeneral;

const Container = styled.div`
	flex-direction: column;
`

const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`