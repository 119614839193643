import { useContext } from "react";
import styled from "styled-components";
import FormInput from "../../../../components/FormInput";
import { Context } from "../../../../context/Context";
import t from "../../../../static/Language";




const DepartmentGeneralTab = ({ details }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang


    return (
        <Container>
            <Row>
                <FormInput
                    label={t(lang, "fields.name")}
                    type="text"
                    initialValue={details?.department_name}
                    readOnly
                />
            </Row>
            
            <Row>
                <FormInput
                    label={"ID"}
                    type="text"
                    initialValue={details?.id}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.company")}
                    type="text"
                    initialValue={details?.company_name}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.buildingName")}
                    type="text"
                    initialValue={details?.building_name}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.parentDepartment")}
                    type="text"
                    initialValue={details?.parent_department_name}
                    readOnly
                />
            </Row>
        </Container>
    )
}


export default DepartmentGeneralTab;


const Container = styled.div`
	flex-direction: column;
`

const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`