import Select from 'react-select';
import { useEffect, useState } from "react";
import globalStyles from "../../static/GlobalStyles";
import t from '../../static/Language';



const BranchSelector = ({
    branches = [],
    lang = "tr",
    onSelect = (branch) => null,
    defaultValue = null,
    disabled = false
}) => {


    const [selectedBranch, setSelectedBranch] = useState(defaultValue)
    const [_default, setDefault] = useState(defaultValue)

    useEffect(() => {
        setSelectedBranch(defaultValue)
    }, [defaultValue])

    const handleSelect = (branch) => {
        setSelectedBranch(branch)
        onSelect(branch)
    }

    return (
        <Select
            isDisabled={disabled}
            options={branches}
            value={selectedBranch}
            getOptionValue={o => o.id}
            getOptionLabel={o => o.name}
            isSearchable
            closeMenuOnSelect={true}
            placeholder={t(lang, "fields.branch")}
            noOptionsMessage={() => t(lang, "common.noOptions")}
            onChange={(value) => handleSelect(value)}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontFamily: globalStyles.font,
                    marginLeft: '10px',
                    minWidth: '200px',
                    maxWidth: '200px',
                    marginTop: '5px',
                    borderColor: globalStyles.textColor,
                }),
                menu: (baseStyles, state) => ({
                    ...baseStyles,
                    fontFamily: globalStyles.font,
                    marginLeft: '10px',
                    minWidth: '200px',
                    maxWidth: '200px',
                }),
            }}

        />
    )
}

export default BranchSelector;

