import { useState } from 'react';
import styled from 'styled-components';
import { FileUploader } from "react-drag-drop-files";
import globalStyles from '../static/GlobalStyles';
import { Button, Grid } from '@mui/material';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';

const DocumentUploader = ({
    tip = "Drag - drop or click",
    label = "Upload file",
    onFileSelect = (file) => null,
    maxFileSizeInMB = 5,
    onFileSizeError = (file, maxSize) => null,
    onTypeError = (err) => null
}) => {

    const fileTypes = ["PDF", "DOC", "DOCX", "JSON", "XML", "XLS", "XLSX", "TXT"];

    const [_file, setFile] = useState(null);
    const [_label, setLabel] = useState(label)

    const handleChange = (file) => {
        if (maxFileSizeInMB && maxFileSizeInMB > 0) {
            if (file.size > maxFileSizeInMB * 1048576) {
                onFileSizeError(file, maxFileSizeInMB)
                return
            }
        }
        setLabel(file.name)
        setFile(file)
        onFileSelect(file)
    };

    return (
        <Grid item sx={{ margin: "15px" }}>
            <FileUploader
                style={{ height: 0 }}
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                children={
                    <DragSection style={{ textTransform: 'none' }}>
                        <Label>{_label}</Label>
                        <UploadRoundedIcon sx={{ fontSize: 35 }} />
                        <Tip>{tip}</Tip>
                    </DragSection>
                }
                onTypeError={(err) => onTypeError(err)}
                maxSize={maxFileSizeInMB ?? 5}
                dropMessageStyle={{ display: "none" }}
            />
        </Grid>
    );
}



export default DocumentUploader;


const Label = styled.text`
    font-family: ${globalStyles.font};
    font-size: 14px;
    position: absolute;
    background-color: white;
    top: -12px;
    left: 10px;
    padding-left: 10px;
    padding-right: 12px;
    color: ${globalStyles.textColor}
`

const Tip = styled.text`
    font-family: ${globalStyles.font};
    font-size: 16px;
    color: ${globalStyles.font};
`




const DragSection = styled(Button)`
    border-radius: 5px !important;
    border: solid 2px aliceblue !important;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    width: 100%;
    justify-content: center !important;
`