

import { useEffect, useState } from 'react';
import Select from 'react-select';
import globalStyles from '../static/GlobalStyles';
import t from '../static/Language';



const FormMultiSelectInput = ({
    values,
    onChange,
    language,
    initialValues,
    style,
    isMulti = true,
    closeMenuOnSelect = false,
    keySelector = (item) => item.id ?? item.key,
    valueSelector = (item) => item.value ?? item.name ?? item.description ?? item.label,
    isDisabled = false
}) => {

    const [value, setValue] = useState()

    useEffect(() => {
        setValue(initialValues)
    }, [initialValues])

    const handleChange = (e) => {
        setValue(e)
        if (onChange) {
            onChange(e.map(i => keySelector(i)))
        }
    }

    return (
        <div style={{ flex: 1, margin: 15 }}>
            <Select
                value={value}
                getOptionValue={option => keySelector(option)}
                getOptionLabel={option => valueSelector(option)}
                onChange={(e) => handleChange(e)}
                noOptionsMessage={() => t(language, "common.noOptions")}
                placeholder={t(language, "common.select")}
                options={values}
                isDisabled={isDisabled}
                styles={{
                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        fontFamily: globalStyles.font,
                    }),
                    menuList: (baseStyles, state) => ({
                        ...baseStyles,
                        fontFamily: globalStyles.font,
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontFamily: globalStyles.font,
                        ...style
                    }),
                    valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        fontFamily: globalStyles.font,
                        maxHeight: 200,
                        overflowY: 'auto',
                        ...style
                    }),
                    noOptionsMessage: (baseStyles, state) => ({
                        ...baseStyles,
                        fontFamily: globalStyles.font
                    }),
                }}
                isClearable
                isMulti={isMulti}
                isSearchable
                closeMenuOnScroll
                closeMenuOnSelect={closeMenuOnSelect}
                
            />
        </div>

    )
}

export default FormMultiSelectInput