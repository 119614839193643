import { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/Context";
import API from "../../../static/API";
import t from "../../../static/Language";
import Loading from "../../../states/Loading";
import SafeStorage from "../../../static/SafeStorage";
import { toast, ToastContainer } from "react-toastify"
import globalStyles from "../../../static/GlobalStyles";
import { Grid } from '@mui/material';
import FormInput from "../../../components/FormInput";
import DataTable from "../../../components/DataTable";
import MessageDialog from "../../../components/MessageDialog";
import Select, { createFilter } from "react-select";
import LeaveRangePicker from "../../../components/leave-components/LeaveRangePicker";
import dayjs from "dayjs";
import Utils from "../../../static/Utils";
import Avatar from "../../../components/Avatar";
import DataSection from "../../../components/DataSection";
import Container from "../../../components/Container";
import NewRecordDrawer from "../../../components/NewRecordDrawer";


const LeavePage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const user = JSON.parse(SafeStorage.getItem("user"))
    const privileges = user.privileges


    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [leaveTypes, setLeaveTypes] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [objInfo, setObjInfo] = useState({})
    const [objectToEdit, setObjectToEdit] = useState(null)
    const [updateInfo, setUpdateInfo] = useState({})
    const [actionOptions, setActionOptions] = useState()
    const [users, setUsers] = useState([])
    const [appMsgVisible, setAppMsgVisible] = useState(false)
    const [rejMsgVisible, setRejMsgVisible] = useState(false)


    const [selectedLeave, setSelectedLeave] = useState()


    const dateOptions = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', second: '2-digit' }

    const leaveStatus = [{ key: 1, value: "waiting" }, { key: 2, value: "rejected" }, { key: 3, value: "approved" }]


    useEffect(() => {
        document.title = t(lang, "leavePage.title")
        prepareActionOptions()
        get()
        getOthers()
    }, [])

    const columns = [
        {
            name: "name",
            label: t(lang, "fields.user"),
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                            <Avatar
                                name={value}
                                lastName={data[tableMeta.rowIndex]?.last_name}
                                size={32}
                                avatar={data[tableMeta.rowIndex]?.avatar}
                                isSquare
                            />
                            <span style={{ marginLeft: '8px' }}>{`${data[tableMeta.rowIndex]?.name} ${data[tableMeta.rowIndex]?.last_name}`}</span>
                        </div>
                    )
                }
            }
        },
        {
            name: "leave_type",
            label: t(lang, "fields.leaveType"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <text>{t(lang, `leaveTypes.${value}`)}</text>
                    )
                },
                customFilterListOptions: {
                    render: v => {
                        return t(lang, `leaveTypes.${v}`)
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }
                        return filterList;
                    },
                },
                filterOptions: {
                    renderValue: val => {
                        return t(lang, `leaveTypes.${val}`)
                    }
                }
            }
        },
        {
            name: "leave_start",
            label: t(lang, "fields.leaveStart"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <text>{new Date(value).toLocaleString(lang, dateOptions)}</text>
                    )
                },
                customFilterListOptions: {
                    render: v => {
                        return new Date(v).toLocaleString(lang, dateOptions)
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }
                        return filterList;
                    },
                },
                filterOptions: {
                    renderValue: val => {
                        return new Date(val).toLocaleString(lang, dateOptions)
                    }
                }
            }
        },
        {
            name: "leave_end",
            label: t(lang, "fields.leaveEnd"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <text>{new Date(value).toLocaleString(lang, dateOptions)}</text>
                    )
                },
                customFilterListOptions: {
                    render: v => {
                        return new Date(v).toLocaleString(lang, dateOptions)
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }
                        return filterList;
                    },
                },
                filterOptions: {
                    renderValue: val => {
                        return new Date(val).toLocaleString(lang, dateOptions)
                    },

                }

            }
        },
        {
            name: "status",
            label: t(lang, "fields.status"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    let color = value === "approved" ? "#179634" : value === "rejected" ? "#e64322" : "#ccb8b4"

                    return (
                        <div style={{ backgroundColor: color, padding: '7px', borderRadius: "4px" }}>
                            <span style={{ color: 'white' }}>
                                {t(lang, `leaveStatus.${value}`)}
                            </span>
                        </div>
                    )
                },
                customFilterListOptions: {
                    render: v => { t(lang, `leaveStatus.${v}`) }
                },
                filterOptions: {
                    renderValue: val => {
                        return t(lang, `leaveStatus.${val}`)
                    }
                }
            }
        },
        {
            name: "user_notes",
            label: t(lang, "fields.userNotes"),
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "manager_notes",
            label: t(lang, "fields.managerNotes"),
            options: {
                filter: false,
                sort: false
            }
        },


    ]


    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canEditLeave")) {
            _actionOptions["approve"] = {
                onApprove: (row, id) => {
                    if (row.status === "approved") {
                        toast(t(lang, "leavePage.alreadyApproved"))
                        return
                    }
                    setSelectedLeave(row)
                    setAppMsgVisible(true)
                }
            }
        }
        if (privileges.includes("superAdmin") || privileges.includes("canEditLeave")) {
            _actionOptions["reject"] = {
                onReject: (row, id) => {
                    if (row.status === "rejected") {
                        toast(t(lang, "leavePage.alreadyRejected"))
                        return
                    }
                    setSelectedLeave(row)
                    setRejMsgVisible(true)
                }
            }
        }

        setActionOptions(_actionOptions)
    }

    const logout = () => {
        SafeStorage.removeItem("token")
        SafeStorage.removeItem("refreshToken")
        dispatch({ type: 'LOGIN', login: false })
    }


    const getOthers = () => {
        API.getUsers2().then(response => {
            setUsers(response.data.data)
            API.getLeaveTypes().then(resp => {
                setLeaveTypes(resp.data.data)
            })
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        })

    }


    const get = () => {
        API.getLeaveRecords().then(response => {
            setData(response.data.data)
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }).finally(() => setLoading(false))
    }

    const create = () => {
        API.createLeaveRecord(objInfo).then(response => {
            toast(t(lang, "leavePage.created"))
            get()
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
                toast(t(lang, "leavePage.createError"))
            }
        })
    }

    const update = () => {
        API.updateLeaveRecord(objectToEdit.id, updateInfo).then(response => {
            toast(t(lang, "leavePage.updated"))
            get()
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
                toast(t(lang, "leavePage.updateError"))
            }
        })
    }


    const approve = (message) => {
        API.updateLeaveRecord(selectedLeave.id, { status: "approved", manager_notes: message }).then(response => {
            toast(t(lang, "leavePage.updated"))
            setAppMsgVisible(false)
            get()
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
                toast(t(lang, "leavePage.updateError"))
            }
        })
    }

    const reject = (message) => {
        API.updateLeaveRecord(selectedLeave.id, { status: "rejected", manager_notes: message }).then(response => {
            toast(t(lang, "leavePage.updated"))
            setRejMsgVisible(false)
            get()
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
                toast(t(lang, "leavePage.updateError"))
            }
        })
    }


    const timesAreValid = (start, end) => {
        if (!start || !end || dayjs(start) > dayjs(end)) {
            return false
        }
        return true
    }

    const validate = () => {
        const { user_id, leave_type } = objInfo
        if (Utils.nullOrEmpty(user_id) || Utils.nullOrEmpty(leave_type)) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!timesAreValid(objInfo.leave_start, objInfo.leave_end)) {
            toast(t(lang, "leavePage.invalidTimes"))
            return
        }
        if (!validate()) {
            toast(t(lang, "leavePage.requiredFields"))
            return
        }
        create()

    }

    if (loading) {
        return <Loading />
    }


    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <MessageDialog
                title={t(lang, "fields.managerNotes") + " - " + t(lang, "common.approve")}
                labels={{ ok: t(lang, "common.ok"), cancel: t(lang, "common.cancel") }}
                open={appMsgVisible}
                onSubmit={(message) => approve(message)}
                onCancel={() => setAppMsgVisible(false)}
                maxMessageLength={90}
            />
            <MessageDialog
                title={t(lang, "fields.managerNotes") + " - " + t(lang, "common.reject")}
                labels={{ ok: t(lang, "common.ok"), cancel: t(lang, "common.cancel") }}
                open={rejMsgVisible}
                onSubmit={(message) => reject(message)}
                onCancel={() => setRejMsgVisible(false)}
                maxMessageLength={90}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "leavePage.new")}
                    onSaveClick={handleSaveClicked}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} margin={'15px'}>
                        <Select
                            options={users}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => `${o.name} ${o.last_name}`}
                            isSearchable
                            isClearable
                            closeMenuOnSelect={true}
                            placeholder={t(lang, "fields.user")}
                            noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                            onChange={(value) => setObjInfo({ ...objInfo, user_id: value.id })}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: globalStyles.font,
                                    color: globalStyles.textColor

                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: globalStyles.font,
                                    color: globalStyles.textColor,
                                    zIndex: 1000
                                }),
                            }}

                            components={{
                                Option: (props) => <div {...props.innerProps} style={{
                                    padding: 8,
                                    backgroundColor: props.isSelected ? globalStyles.firstColor : props.isFocused ? "#d7e9f5" : 'white'
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center' }}>

                                        <Avatar
                                            isSquare
                                            avatar={props.data.avatar}
                                            name={props.data.name}
                                            lastName={props.data.last_name}
                                            size={32}
                                        />
                                        <text style={{
                                            cursor: "default",
                                            color: props.isSelected ? 'white' : globalStyles.textColor,
                                            marginLeft: '4px'

                                        }}>{`${props.data.name} ${props.data.last_name}`}</text>
                                    </div>
                                </div>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} margin={'15px'}>
                        <Select
                            options={leaveTypes}
                            getOptionValue={o => o.leave_type}
                            getOptionLabel={o => t(lang, `leaveTypes.${o.leave_type}`)}
                            isSearchable
                            isClearable
                            closeMenuOnSelect={true}
                            placeholder={t(lang, "fields.leaveType")}
                            noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                            onChange={(value) => {
                                setObjInfo({ ...objInfo, leave_type: value.leave_type })
                            }}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: globalStyles.font,
                                    color: globalStyles.textColor
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: globalStyles.font,
                                    color: globalStyles.textColor,
                                    zIndex: 10000
                                }),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingRight: '12px', paddingLeft: '12px' }} >
                        <LeaveRangePicker
                            mode={"datetime"}
                            onChange={(start, end) => setObjInfo({ ...objInfo, leave_start: start, leave_end: end })}
                            locale={lang}
                            labels={{ start: t(lang, "fields.leaveStart"), end: t(lang, "fields.leaveEnd") }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} margin={'15px'}>
                        <Select
                            options={leaveStatus}
                            getOptionValue={o => o.value}
                            getOptionLabel={o => t(lang, `leaveStatus.${o.value}`)}
                            closeMenuOnSelect={true}
                            placeholder={t(lang, "fields.status")}
                            noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                            onChange={(value) => {
                                setObjInfo({ ...objInfo, status: value.value })
                            }}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: globalStyles.font,
                                    color: globalStyles.textColor
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: globalStyles.font,
                                    color: globalStyles.textColor,
                                    zIndex: 10000
                                }),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormInput
                            label={t(lang, "fields.managerNotes")}
                            multiline={true}
                            onChange={(text) => setObjInfo({ ...objInfo, manager_notes: text })}
                            maxLength={90}
                            rows={2}
                        />
                    </Grid>
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    title={t(lang, "leavePage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateLeave")}
                />
            </DataSection>
        </Container>
    )
}

export default LeavePage;






