import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import { FileUploader } from "react-drag-drop-files";
import globalStyles from '../static/GlobalStyles';
import { Button } from '@mui/material';



const FormImageInput = ({ tip, label, onFileSelect, defaultSrc, style = {} }) => {

    const [imgData, setImgData] = useState()

    useEffect(() => {
        setImgData(defaultSrc)
    }, [defaultSrc])

    const fileTypes = ["JPG", "PNG", "WEBP"];

    const reader = new FileReader()
    
    reader.onload = e => {
        setImgData(e.target.result)
    }

    const [_file, setFile] = useState(null);

    const handleChange = (file) => {
        setFile(file)
        reader.readAsDataURL(file)
        if (onFileSelect) {
            onFileSelect(file)
        }

    };

    return (
        <Container style={style}>
            <FileUploader
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                children={
                    <DragSection style={{ textTransform: 'none' }}>
                        <Label>{label}</Label>
                        <ImageRoundedIcon sx={{ color: 'aqua', fontSize: 25 }} />
                        <Tip>{tip}</Tip>
                    </DragSection>
                }
                dropMessageStyle={{ display: "none" }}
            />
            <Preview src={imgData} />

        </Container>

    );
}



export default FormImageInput;


const Label = styled.span`
    font-family: ${globalStyles.font};
    font-size: 12px;
    position: absolute;
    background-color: white;
    top: -12px;
    left: 10px;
    padding-left: 7px;
    padding-right: 11px;
    color: ${globalStyles.textColor}
`

const Tip = styled.span`
    font-family: ${globalStyles.font};
    font-size: 12px;
    color: ${globalStyles.firstColor};
`

const Preview = styled.img`
    height: 100%;
    border-radius: 5px
`

const Container = styled.div`
    margin-top: 15px;
    flex-direction: row;
    font-family: ${globalStyles.font};
    height: 50px;
    display: flex;
    margin-left: 15px;
    align-items: center
`

const DragSection = styled(Button)`
    border-radius: 5px !important;
    border: dashed 1px blueviolet !important;
    height: 50px;
    width: 200px;
    margin-right: 10px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important
`