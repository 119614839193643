import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components";
import globalStyles from "../../static/GlobalStyles";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import Utils from "../../static/Utils";
import WeekSelector from "./WeekSelector";
import t from '../../static/Language';
import FlexButton from '../FlexButton'
import UserShiftCard from "./UserShiftCard";
import Loading from '../../states/Loading'
import MessageBox from "../MessageBox";
import BranchSelector from "./BranchSelector";
import API from "../../static/API";
import SafeStorage from "../../static/SafeStorage";
import { Container } from "@mui/material";
import nodata from '../../assets/media/nodata.gif'
import Avatar from "../Avatar";
import C from "../Container";

const NoData = ({ message }) => {
    return (
        <Container container sx={{ paddingTop: '45px', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <NoDataText>
                {message}
            </NoDataText>
            <img src={nodata} width={200} />
        </Container>
    )
}


const TotalHoursText = ({
    totalHours = 0,
    hoursLocale = "saat"
}) => {

    const getColor = () => {
        if (totalHours <= 45) {
            return globalStyles.textColor
        } else {
            return "#b52222"
        }
    }

    const color = getColor()

    return (
        <>
            <text
                style={{
                    fontFamily: globalStyles.font,
                    color: color,
                    fontSize: '16px',
                }}
            >
                {totalHours}
            </text>
            <text
                style={{
                    fontFamily: globalStyles.font,
                    color: color,
                    fontSize: '14px',
                }}
            >
                {hoursLocale}
            </text>
        </>

    )

}



const MIN_CELL_WITH = "80px"

const FlexSchedule = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang


    const [weekDays, setWeekDays] = useState(Utils.getCurrentWeekDays(lang))
    const [userShifts, setUserShifts] = useState([])
    const [loading, setLoading] = useState(true)
    const [messageOpen, setMessageOpen] = useState(false)
    const [branches, setBranches] = useState([])
    //const [defaultBranch, setDefaultBranch] = useState()
    const [branchUsers, setBranchUsers] = useState([])
    const [selectedBranch, setSelectedBranch] = useState()
    const [shiftDdescriptions, setShiftDescriptions] = useState([])
    const [gettingShifts, setGettingShifts] = useState(true)


    useEffect(() => {
        const days = Utils.getCurrentWeekDays(lang)
        setWeekDays(days)
        getBranchesAndShifts(days[0].date.toISOString(), days[6].date.toISOString())
        getShiftDescriptions()
    }, [])



    const getBranchesAndShifts = (startDay, endDay) => {
        API.getSupervisorsBranches().then(response => {
            setBranches(response.data.data)
            //console.log(response.data.data)
            if (response.data.data.length > 0) {
                setSelectedBranch(response.data.data[0])
                getBranchUsers(response.data.data[0].id)
                getShifts(startDay, endDay, response.data.data[0].id)

            } else {
                setLoading(false)
                setGettingShifts(false)
            }
        }).catch(err => {
            if (err.response?.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.getSupervisorsBranches().then(response => {
                        setBranches(response.data.data)
                        if (response.data.data.length > 0) {
                            setSelectedBranch(response.data.data[0])
                            getShifts(startDay, endDay, response.data.data[0].id)
                        } else {
                            setLoading(false)
                            setGettingShifts(false)
                        }
                    })
                }).catch(e => {
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            } else {
                console.log(err)
            }
        })
    }


    const getShiftDescriptions = () => {
        API.getShifts().then(response => {
            setShiftDescriptions(response.data.data)
        })
    }

    const getBranchUsers = (branchId) => {
        API.getBranchUsers(branchId).then(response => {
            setBranchUsers(response.data.data)
        })
    }

    const getShifts = (startDate, endDate, branch) => {
        // API ile verilen zaman aralığındaki shiftler alınacak
        API.queryShiftAssignments(startDate.split('T')[0], endDate.split('T')[0], branch).then(response => {
            //console.log({ userShifts: response.data.data })
            setUserShifts(response.data.data)
            setLoading(false)
            setGettingShifts(false)
        }).catch(err => {
            console.log(err)
        })

    }


    const getUserShiftsById = (id) => {
        return userShifts.filter(u => u.user.id === id)
    }


    const getShiftsOnDay = (userShifts, day) => {
        const shiftsOnDay = userShifts.filter(s => s.date.split("T")[0] === day.date.toISOString().split("T")[0])
        return shiftsOnDay
    }

    const calculateWeeklyHours = (userShifts) => {
        let result = 0
        userShifts.forEach(s => {
            result += s.shift.duration % 10 === 9 ? s.shift.duration + 1 : s.shift.duration
        })
        return result / 60
    }




    const handleBranchSelected = (branch) => {
        //alert(branch.id)
        getBranchUsers(branch.id)
        queryShiftAssignments(weekDays[0].date.toISOString(), weekDays[6].date.toISOString(), branch.id)
        setSelectedBranch(branch)
    }

    const handleWeekSelect = (weekRange) => {
        //alert(selectedBranch.id)
        queryShiftAssignments(weekRange.start.date.toISOString(), weekRange.end.date.toISOString(), selectedBranch.id)
        const days = Utils.getWeekDaysOfGivenDate(lang, weekRange.start.date)
        setWeekDays(days)
        console.log(days)
    }

    const queryShiftAssignments = (startDate, endDate, branchId) => {
        setGettingShifts(true)
        API.queryShiftAssignments(startDate.split('T')[0], endDate.split('T')[0], branchId).then(response => {
            console.log(response.data.data)
            setUserShifts(response.data.data)
        }).finally(() => setGettingShifts(false))
    }

    if (loading) {
        return <Loading />
    }

    if (branches.length === 0) {
        return <NoData message={t(lang, "shiftsPage.noBranch")} />
    }

    return (
        <C style={{ padding: '15px', overflow: 'hidden' }}>
            <MessageBox
                open={messageOpen}
                title={`${t(lang, "common.warning")}`}
                message={t(lang, "shiftsPage.confirmClear")}
                actions={[
                    {
                        name: t(lang, "common.yes"),
                        onClick: () => {
                            setMessageOpen(false)
                            setUserShifts([])
                        },
                        style: {
                            backgroundColor: globalStyles.textColor
                        }
                    },
                    {
                        name: t(lang, "common.cancel"),
                        onClick: () => setMessageOpen(false),
                    }
                ]}
            />

            <ToolBar style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <WeekSelector
                        locale={lang}
                        onSelect={(weekRange) => handleWeekSelect(weekRange)}
                        disabled={gettingShifts}
                    />
                    <BranchSelector
                        onSelect={(branch) => handleBranchSelected(branch)}
                        branches={branches}
                        defaultValue={selectedBranch}
                        disabled={gettingShifts}
                    />
                </div>
                <div >
                    {/*   <FlexButton
                        text={t(lang, "common.clear")}
                        style={{ marginRight: "0px" }}
                        onClick={() => setMessageOpen(true)}
                    /> */}
                    <FlexButton text={t(lang, "shiftsPage.notify")} style={{ marginRight: "0px" }} />
                </div>
            </ToolBar>

            {gettingShifts ? (
                <Loading />
            ) : (
                <Box sx={{ overflowX: 'auto' }}>
                    <Grid flexDirection={'row'} display={'flex'} flex={1}>
                        <Grid container sx={{ minWidth: '200px', maxWidth: '200px', }} />
                        <Grid container sx={{ minWidth: '60px', maxWidth: '60px', }} />
                        {weekDays.map((d, i) => {
                            return (
                                <Grid
                                    key={i}
                                    container
                                    sx={{
                                        minWidth: MIN_CELL_WITH,
                                        borderLeft: `1px solid #ebe9e6`,
                                        justifyContent: 'center',
                                        backgroundColor: d.isCurrentDay ? globalStyles.firstColor : "white",
                                        paddingTop: "5px"
                                    }}
                                    display={'flex'}
                                    flexDirection={'column'}
                                >
                                    <ColumnTitleText style={{ color: d.isCurrentDay ? "white" : undefined }}>
                                        {d.dayOfWeekName}
                                    </ColumnTitleText>
                                    <ColumnTitleDayText style={{ color: d.isCurrentDay ? "white" : undefined }}>
                                        {d.localeStringShort}
                                    </ColumnTitleDayText>
                                </Grid>
                            )
                        })}
                    </Grid>

                    {branchUsers.map(bu => {
                        const id = bu.id
                        const _uShifts = getUserShiftsById(id)
                        return (
                            <Grid flexDirection={'row'} display={'flex'} flex={1} sx={{ borderTop: `1px solid #ebe9e6` }}>
                                <Grid container sx={{ minWidth: '200px', maxWidth: '200px', alignItems: 'center' }}>
                                    <Avatar
                                        avatar={bu.avatar}
                                        size={50}
                                        name={bu.name}
                                        lastName={bu.last_name}
                                        isSquare
                                    />
                                    <ColumnTitleDayText>
                                        {`${bu.name} ${bu.last_name[0]}.`}
                                    </ColumnTitleDayText>
                                </Grid>
                                <Grid container sx={{ minWidth: '60px', maxWidth: '60px', borderLeft: `1px solid #ebe9e6`, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >  {/* burası toplam saat kolonu */}
                                    <TotalHoursText
                                        totalHours={calculateWeeklyHours(_uShifts)}
                                        hoursLocale={t(lang, "common.hours")}
                                    />
                                </Grid>
                                {[...Array(7).keys()].map(i => {
                                    return (
                                        <UserShiftCard
                                            userShiftsOnDay={getShiftsOnDay(_uShifts, weekDays[i])}
                                            shiftDate={weekDays[i]}
                                            onShiftAdd={(shift, date, user) => {
                                                API.assignShiftToUserOnDay(shift.id, user.id, date.date.toISOString().split('T')[0])
                                                    .then(response => {
                                                        const s = {
                                                            date: date.date.toISOString(),
                                                            user: user,
                                                            shift: shift
                                                        }
                                                        let ss = [...userShifts, s]
                                                        setUserShifts(ss)
                                                    }).catch(err => {
                                                        console.log(err)
                                                    })

                                            }}
                                            onShiftDelete={(userShift) => {
                                                API.removeUserShiftOnDay(userShift.shift.id, userShift.user.id, userShift.date.split('T')[0])
                                                    .then(response => {
                                                        const ss = userShifts.filter(s =>
                                                            !(s.shift.id === userShift.shift.id &&
                                                                s.date.split('T')[0] === userShift.date.split('T')[0] && //.date.toISOString()
                                                                s.user.id === userShift.user.id)
                                                        )
                                                        setUserShifts(ss)
                                                    }).catch(err => {
                                                        console.log(err)
                                                    })
                                            }}
                                            shiftDescriptions={shiftDdescriptions}
                                            user={bu}
                                        />
                                    )
                                })}
                            </Grid>
                        )
                    })}

                </Box>
            )
            }


        </C >

    )
}

export default FlexSchedule;


const ColumnTitleText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-weight: bold;
    margin-left: 10px;
    font-size: 13px
`

const ColumnTitleDayText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    margin-left: 10px;
    margin-bottom: 5px;
    font-size: 13px
`




const NoDataText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 18px;
    font-weight: bold
`


const ToolBar = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    border-bottom: 1px solid #d8e6db;
    padding: 10px;
    padding-right: 0px;
    padding-left: 0px;
`



