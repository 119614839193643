/* question object:
    {
        id,
        title,
        type: singleChoice, multipleChoice, freeText, multipleAlternate
        choices: [{
            id,
            description, // seçeneğin text değeri
            value, // seçeneğin matemetiksel değeri, 1-5 aralığı gibi (optional)
        }]
    }

*/

import { Box, Button, Card, FormControl, Grid } from "@mui/material"
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { useEffect, useRef, useState } from "react";
import t from "../../static/Language";
import FormInput from "../FormInput";
import Choice from "./Choice";
import Utils from "../../static/Utils";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import QuestionTypeSelector from "./QuestionTypeSelector";
import FlexButton from "../FlexButton";



const QuestionCard = ({
    lang,
    question,
    onChange = (question) => null,
    onDelete = () => null
}) => {


    

    const [_question, setQuestion] = useState(question)
    const [choices, setChoices] = useState(question?.choices ?? [])
    const [defaultType, setDefaultType] = useState()

    

    useEffect(() => {
        setQuestion(question)
        setChoices(question?.choices ?? [])
    }, [question])


    useEffect(() => {
        setQuestion(question)
        setDefaultType(getDefaultType())
    }, [question])

    const handleTypeSelect = (qType) => {
        let _q = { ..._question, question_type: qType }
        setQuestion(_q)
        onChange(_q)
    }


    const getDefaultType = () => {
        return question?.question_type ?? 1
    }

    return (
        <Card
            elevation={3}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
                marginLeft: '15px',
                marginRight: '15px',
                marginBottom: '25px',
                overflow: 'visible'
            }}
        >
            <Grid
                sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                <FormControl fullWidth>
                    <QuestionTypeSelector
                        onSelect={(qType) => handleTypeSelect(qType)}
                        defaultType={defaultType}
                    />
                </FormControl>
                <Button
                    sx={{ color: 'red' }}
                    onClick={onDelete}
                >
                    <RemoveCircleOutlineRoundedIcon />
                </Button>
            </Grid>

            <FormInput
                label={t(lang, "surveysPage.question")}
                variant="standard"
                initialValue={_question?.title}
                onChange={(text) => {
                    let q = { ..._question, title: text }
                    setQuestion(q)
                    onChange(q)
                }}
                autoFocus={true}
            />
            {choices.map((c, i) => {
                return (
                    <Choice
                        lang={lang}
                        description={c.description}
                        value={c.calculation_value}
                        onDescriptionChange={(description) => {
                            const index = choices.findIndex(x => x.id === c.id)
                            const updated = { ...choices[index], description: description }
                            const newChoices = [...choices]
                            newChoices[index] = updated
                            setChoices(newChoices)
                            const updatedQuestion = { ...question, choices: newChoices }
                            setQuestion(updatedQuestion)
                            onChange(updatedQuestion)
                        }}
                        onValueChange={(value) => {
                            const index = choices.findIndex(x => x.id === c.id)
                            const updated = { ...choices[index], calculation_value: value }
                            const newChoices = [...choices]
                            newChoices[index] = updated
                            setChoices(newChoices)
                            const updatedQuestion = { ...question, choices: newChoices }
                            setQuestion(updatedQuestion)
                            onChange(updatedQuestion)
                        }}
                        onDelete={() => {
                            const newChoices = choices.filter(_c => _c.id !== c.id)
                            setChoices(newChoices)
                            const newQuestion = { ..._question, choices: newChoices }
                            setQuestion(newQuestion)
                            onChange(newQuestion)
                        }}
                    />
                )
            })}


            <Box sx={{ justifyContent: 'flex-end', display: 'flex', minHeight: "40px" }}>
                {_question.question_type !== 4 &&
                    <FlexButton
                        onClick={() => {
                            let cs = [
                                ...choices,
                                { id: Utils.uuid4(), calculation_value: 0 }
                            ]
                            setChoices(cs)
                        }}
                        size="small"
                        renderIcon={() => <AddRoundedIcon />}
                        text={t(lang, "surveysPage.newChoice")}
                    />
                }
            </Box>


        </Card>
    )
}

export default QuestionCard;


