import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../../../context/Context';
import API from '../../../../static/API';
import t from '../../../../static/Language';
import SafeStorage from '../../../../static/SafeStorage';
import 'react-modern-drawer/dist/index.css'
import globalStyles from '../../../../static/GlobalStyles';

import { ToastContainer } from "react-toastify"
import MonthPicker from '../../../../components/MonthPicker';
import dayjs from 'dayjs';
import FlexButton from '../../../../components/FlexButton';
import TimesheetTable from '../../../../components/timesheet-components/TimesheetTable';
import Container from '../../../../components/Container'
import DataSection from '../../../../components/DataSection'


const TimesheetPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const user = JSON.parse(SafeStorage.getItem("user"))
    const company = user.company

    const [selectedMonth, setSelectedMonth] = useState({
        start: dayjs().startOf('month').format('YYYY-MM-DD'),
        end: dayjs().endOf('month').format('YYYY-MM-DD'),
        month: dayjs().format('YYYY-MM')
    })

    const [data, setData] = useState([])

    const [users, setUsers] = useState([])


    useEffect(() => {
        document.title = t(lang, "menuNames.attendance")
        getUsers()
        const now = dayjs(Date.now())
        const start = now.startOf('month').format('YYYY-MM-DD');
        const end = now.endOf('month').format('YYYY-MM-DD')
        const month = now.format('YYYY-MM')
        setSelectedMonth({ month, start, end })
    }, [])



    const logout = () => {
        SafeStorage.removeItem("token")
        SafeStorage.removeItem("refreshToken")
        dispatch({ type: 'LOGIN', login: false })
    }

    const getUsers = () => {
        API.getUsers2().then(response => {
            setUsers(response.data.data)
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        })
    }

    const get = () => {
        const data = { company: company, start_date: selectedMonth.start, end_date: selectedMonth.end }
        API.getAttendance(data).then(response => { // cevap gelirse datayı atıyoruz
            setData(response.data.data)
            prepareTimesheet(response.data.data)
        }).catch(err => { // hata alınırsa
            console.log(err)
            if (err?.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getAttendance(data).then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                        prepareTimesheet(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e?.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const timeStringToMinutes = (timeString) => {
        let parts = timeString.split(':')
        return parseInt(parts[0]) * 60 + parseInt(parts[1])
    }


    const prepareTimesheet = (timesheetData) => {
        let result = []
        users.forEach(user => {
            let userInfo = {
                name: `${user.name} ${user.last_name}`,
                deaprtment: user.department,
                id: user.id,
                avatar: user.avatar,
                department: user.department,
                overtime_days: 0,
                total_overtime: 0,
                total_absences: 0,
                total_working_days: 0,
                total_working_hours: 0,
                week_leave_days_collection: [],
                leave_days_collection: [],
                overtime_days_collection: [],
                reduced_days_collection: []
            }

            let startDate = dayjs(selectedMonth.start)
            let endDate = dayjs(selectedMonth.end)

            while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
                const dayOfInterest = timesheetData.find(ts => ts.user_id === user.id && dayjs(ts.day).month() === startDate.month() && dayjs(ts.day).date() === startDate.date())

                //X: absent, O: overtime, R: eksik çalışma

                let columnName = startDate.toDate().toLocaleDateString(lang, { day: '2-digit', weekday: 'short' })
                userInfo[columnName] = dayOfInterest?.actual_working_hours ?? "X"

                if (dayOfInterest) {
                    const totalWorkingMinutes = timeStringToMinutes(dayOfInterest.total_working_hours)
                    const actualWorkingMinutes = timeStringToMinutes(dayOfInterest.actual_working_hours)
                    userInfo.total_working_days = userInfo.total_working_days + 1
                    userInfo.total_working_hours = userInfo.total_working_hours + timeStringToMinutes(dayOfInterest.actual_working_hours)
                    userInfo.total_overtime = userInfo.total_overtime + actualWorkingMinutes - totalWorkingMinutes
                    if (actualWorkingMinutes > totalWorkingMinutes) {
                        userInfo.overtime_days = userInfo.overtime_days + 1
                        userInfo.overtime_days_collection = [...userInfo.overtime_days_collection, columnName]

                    } else {
                        userInfo.reduced_days_collection = [...userInfo.reduced_days_collection, columnName]

                    }
                } else {
                    userInfo.total_absences = userInfo.total_absences + 1
                }

                startDate = startDate.add(1, 'day'); // Tarihi bir gün ileri taşı
            }

            userInfo.total_overtime = Math.ceil(userInfo.total_overtime / 60, 1)
            userInfo.total_working_hours = Math.ceil(userInfo.total_working_hours / 60, 1)

            result.push(userInfo)
        })
        setData(result)

    }







    return (

        <Container style={{ paddingRight: '15px', paddingLeft: '15px' }} >
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />

            <ToolBar >
                <MonthPicker
                    locale={lang}
                    onSelect={(month, start, end) => setSelectedMonth({ month, start, end })}
                />
                <FlexButton
                    text={t(lang, "common.create")}
                    onClick={() => get()}
                />
            </ToolBar>
            <DataSection>
                <TimesheetTable
                    data={data}
                    month={selectedMonth.month}
                //onCellClick={(rowIndex, columnIndex, cell) => console.log({ rowIndex, columnIndex, cell })}
                />
            </DataSection>
        </Container>
    )

}


export default TimesheetPage;






const ToolBar = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    margin-top: 25px;
    border-bottom: 1px solid ${globalStyles.firstColor};
    padding: 10px;
    padding-right: 0px;
    padding-left: 0px;
`




