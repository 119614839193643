import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../../context/Context';
import API from '../../../static/API';
import t from '../../../static/Language';
import SafeStorage from '../../../static/SafeStorage';
import 'react-modern-drawer/dist/index.css'
import DataTable from '../../../components/DataTable';
import globalStyles from '../../../static/GlobalStyles';
import FormInput from '../../../components/FormInput';
import FormSelectInput from '../../../components/FormSelectInput';
import Utils from '../../../static/Utils';
import { toast, ToastContainer } from "react-toastify"
import FormImageInput from '../../../components/FormImageInput';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import Avatar from '../../../components/Avatar';
import DateTimePicker from '../../../components/DateTimePicker';
import NewRecordDrawer from '../../../components/NewRecordDrawer';




const UsersPage = () => {
	const { state, dispatch } = useContext(Context)
	const lang = state.appLang
	const user = JSON.parse(SafeStorage.getItem("user"))
	const privileges = user.privileges
	const navigate = useNavigate()


	const [users, setUsers] = useState([])
	const [newRecordOpen, setNewRecordOpen] = useState(false)
	const [editOpen, setEditOpen] = useState(false)
	const [userInfo, setUserInfo] = useState({ company: user.company })
	const [initialChiefValue, setInitialChiefValue] = useState()
	const [initialCompValue, setInitialCompValue] = useState()
	const [initialDepartmentValue, setInitialDepartmentValue] = useState()
	const [initialDateOfBirth, setInitialDateOfBirth] = useState()
	const [companies, setCompanies] = useState([])
	const [userToEdit, setUserToEdit] = useState(null)
	const [updateInfo, setUpdateInfo] = useState({})
	const [profilePicture, setProfilePicture] = useState()
	const [actionOptions, setActionOptions] = useState()
	const [departments, setDepartments] = useState([])



	useEffect(() => {
		document.title = t(lang, "usersPage.title")
		prepareActionOptions()
		getUsers()
		getCompanies()
		getDepartments()
	}, [])


	let _users = []
	let _comps = []
	let _departments = []


	const prepareActionOptions = () => {
		let _actionOptions = {}
		if (privileges.includes("superAdmin") || privileges.includes("canEditUsers")) {
			_actionOptions["edit"] = {
				onEdit: (row, id) => {
					setUserToEdit(row)
					let chief = _users.find(u => u.id === row.chief)
					let comp = _comps.find(c => c.id === row.company)
					let dep = _departments.find(d => d.id === row.department)
					setInitialChiefValue(chief)
					setInitialCompValue(comp)
					setInitialDepartmentValue(dep)
					setInitialDateOfBirth(row.date_of_birth)
					setEditOpen(true)
				}
			}
		}
		if (privileges.includes("superAdmin") || privileges.includes("canDeleteUsers")) {
			_actionOptions["delete"] = {
				onDelete: (row, id) => {
					deleteUsers([id])
				}
			}
		}
		_actionOptions.onShowDetail = (row, id) => {
			//alert(`${row} ${id} detay görüntülenecek`)
			navigate(`/users/${id}`)
		}
		setActionOptions(_actionOptions)
	}




	const getUsers = () => {
		API.getUsers().then(response => { // cevap gelirse userları atıyoruz
			setUsers(response.data.data)
			_users = response.data.data
		}).catch(err => { // hata alınırsa
			if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
				API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
					SafeStorage.setItem("token", r.data.token)
					API.getUsers().then(resp => { // userları tekrar çekiyoruz
						setUsers(resp.data.data)
					}).catch(er => console.log(er))
				}).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
					if (e.response.status === 401) {
						SafeStorage.removeItem("token")
						SafeStorage.removeItem("refreshToken")
						dispatch({ type: 'LOGIN', login: false })
					}
				})
			}
		})
	}


	const getDepartments = () => {
		console.log("getting departments")
		API.getDepartments().then(response => {
			console.log("departments:", response.data)
			try {
				console.log(response.data.data)
				setDepartments(response.data.data)
				_departments = response.data.data
			} catch (error) {
				console.log(error)
			}

		}).catch(ex => console.log(ex))
	}

	const getCompanies = () => {
		API.getCompanies().then(response => {
			setCompanies(response.data.data)
			_comps = response.data.data
		})
	}


	const createUser = () => {
		API.createUser(userInfo).then(response => { // cevap gelirse userları yeniden çekiyoruz
			uploadPP(response.data.user_id)
			getUsers()
			toast(t(lang, "usersPage.userCreated"))
		}).catch(err => { // hata alınırsa
			if (err.response?.status === 401) {
				API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
					SafeStorage.setItem("token", r.data.token)
					API.createUser(userInfo).then(resp => {
						uploadPP(resp.data.data.user_id)
						getUsers()
						toast(t(lang, "usersPage.userCreated"))
					}).catch(er => {
						console.log(er)
						toast(t(lang, "usersPage.userCreateError"))
					})
				}).catch(e => {
					if (e.response.status === 401) {
						SafeStorage.removeItem("token")
						SafeStorage.removeItem("refreshToken")
						dispatch({ type: 'LOGIN', login: false })
					}
				})
			} else {
				console.log(err)
				toast(t(lang, "usersPage.userCreateError"))
			}
		})
	}


	const uploadPP = (userId) => {
		if (profilePicture) {
			//API.uploadFile(profilePicture)
			API.uploadFile(profilePicture).then(response => {
				const ppUrl = response.data.file_url
				API.updateUser(userId, { avatar: ppUrl }).catch(err => {
					console.log(err)
				})
			}).catch(err => toast("cannot upload profile picture"))
		}
	}

	const updateUser = () => {
		if (Object.keys(updateInfo).length === 0 && profilePicture) {
			uploadPP(userToEdit.id)
			toast(t(lang, "usersPage.userUpdated"))
			return
		}
		API.updateUser(userToEdit.id, updateInfo).then(response => {
			if (profilePicture) {
				uploadPP(userToEdit.id)
			}
			getUsers()
			toast(t(lang, "usersPage.userUpdated"))
			setEditOpen(false)
		}).catch(err => {
			if (err.response.status === 401) {
				API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
					SafeStorage.setItem("token", r.data.token)
					API.updateUser(userToEdit.id, updateInfo).then(resp => {
						getUsers()
						toast(t(lang, "usersPage.userUpdated"))
						setEditOpen(false)
					}).catch(er => {
						console.log(er)
						toast(t(lang, "usersPage.userUpdateError"))
					})
				}).catch(e => {
					if (e.response.status === 401) {
						SafeStorage.removeItem("token")
						SafeStorage.removeItem("refreshToken")
						dispatch({ type: 'LOGIN', login: false })
					}
				})
			}
		})
	}


	const deleteUsers = (userIds) => {
		API.deleteUsers(userIds).then(response => {
			toast(t(lang, "usersPage.userDeleted"))
			getUsers()
		}).catch(err => {
			if (err.response.status === 401) {
				API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
					SafeStorage.setItem("token", r.data.token)
					API.deleteUsers(userIds).then(resp => {
						toast(t(lang, "usersPage.userDeleted"))
						getUsers()
					}).catch(er => {
						console.log(er)
						toast(t(lang, "usersPage.userDeleteError"))
					}).catch(e => {
						if (e.response.status === 401) {
							SafeStorage.removeItem("token")
							SafeStorage.removeItem("refreshToken")
							dispatch({ type: 'LOGIN', login: false })
						}
					})
				})
			}
		})
	}


	const columns = [
		{
			name: "name",
			label: t(lang, "fields.name"),
			options: {
				filter: true,
				sort: true,
				draggable: true
			}
		},
		{
			name: "last_name",
			label: t(lang, "fields.lastName"),
			options: {
				filter: true,
				sort: true,
				draggable: true
			}
		},
		{
			name: "id",
			label: "ID",
			options: {
				filter: true,
				sort: false
			}
		},
		{
			name: "email",
			label: t(lang, "fields.email"),
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "phone",
			label: t(lang, "fields.phone"),
			options: {
				filter: true,
				sort: false
			}
		}
	]




	const validate = () => {
		if (Utils.nullOrEmpty(userInfo.name) ||
			Utils.nullOrEmpty(userInfo.last_name) ||
			Utils.nullOrEmpty(userInfo.email) ||
			Utils.nullOrEmpty(userInfo.phone)
		) { return false }
		return true
	}


	const correctPhone = (phone, areaCode) => {
		try {
			return phone.substr(areaCode.length, phone.length)
		} catch (ex) { console.log(ex); return phone; }
	}

	const handleSaveClicked = () => {
		if (!validate()) { //
			toast(t(lang, "usersPage.requiredFields"))
			return
		}

		if (privileges.includes("superAdmin")) { // eğer süper admin değilse şirket olarak oluşturan kullanıcının şirketini atıyoruz
			setUserInfo({ ...userInfo, company: user.company })
		}

		createUser()

		//uploadPP()
	}


	const validateUpdate = () => {
		if ((updateInfo.name && Utils.nullOrEmpty(updateInfo.name)) ||
			(updateInfo.last_name && Utils.nullOrEmpty(updateInfo.last_name)) ||
			(updateInfo.phone && Utils.nullOrEmpty(updateInfo.phone)) ||
			(updateInfo.email && Utils.nullOrEmpty(updateInfo.email))
		) {
			return false
		}
		return true
	}

	const handleUpdateClicked = () => {
		if (!validateUpdate()) { //
			toast(t(lang, "usersPage.requiredFields"))
			return
		}
		updateUser()

	}





	return (
		<Container>
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				pauseOnHover
				theme="light"
				toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
			/>
			<DataSection>
				<NewRecordDrawer
					title={t(lang, "usersPage.newUser")}
					onSaveClick={handleSaveClicked}
					open={newRecordOpen}
					onClose={() => setNewRecordOpen(false)}
					saveButtonTitle={t(lang, "common.save")}
				>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<FormInput
							label={t(lang, "fields.name")}
							onChange={(val) => setUserInfo({ ...userInfo, name: val })}
							helperText="*"
							helperTextType={"error"}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<FormInput
							label={t(lang, "fields.lastName")}
							onChange={(val) => setUserInfo({ ...userInfo, last_name: val })}
							helperText="*"
							helperTextType={"error"}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<FormInput
							label={t(lang, "fields.citizenId")}
							onChange={(val) => setUserInfo({ ...userInfo, id_number: val })}
							type="number"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<FormInput
							label={t(lang, "fields.userName")}
							onChange={(val) => setUserInfo({ ...userInfo, user_name: val })}
							helperText={t(lang, "usersPage.userNameDesc")}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FormInput
							label={t(lang, "fields.address")}
							onChange={(val) => setUserInfo({ ...userInfo, address: val })}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginRight: '12px', marginLeft: '12px' }}>
						<DateTimePicker
							timeDisabled={true}
							isRequired={false}
							locale={lang}
							label={t(lang, "fields.birthDate")}
							onChange={(value) => setUserInfo({ ...userInfo, date_of_birth: value })}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<FormInput
							label={t(lang, "fields.phone")}
							onChange={(val, country, e, formattedValue) => setUserInfo({ ...userInfo, phone: correctPhone(val, country.dialCode), area_code: country.dialCode })}
							type="phone"
							helperText={'*'}
							helperTextType="error"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<FormInput
							label={t(lang, "fields.email")}
							onChange={(val) => setUserInfo({ ...userInfo, email: val })}
							helperText={'*'}
							helperTextType="error"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FormSelectInput
							label={t(lang, "fields.chief")}
							onSelect={(item) => setUserInfo({ ...userInfo, chief: item.id === -1 ? null : item.id })}
							data={users}
							itemComponent={(item, index) => {
								return (
									<div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
										{/* <Avatar src={item.avatar} alt="image" /> */}
										<Avatar name={item.name} lastName={item.last_name} size={24} avatar={item.avatar} />

										<ItemText>{`${item.name} ${item.last_name}`}</ItemText>
									</div>
								)
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FormSelectInput
							label={t(lang, "fields.department")}
							onSelect={(item) => setUserInfo({ ...userInfo, department: item.id === -1 ? null : item.id })}
							data={departments}
							itemComponent={(item, index) => {
								return (
									<div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
										<ItemText>{`${item.name}`}</ItemText>
									</div>
								)
							}}
						/>
					</Grid>
					{Utils.isSystemAdmin() &&
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<FormSelectInput
								label={t(lang, "fields.company")}
								onSelect={(item) => setUserInfo({ ...userInfo, company: item.id === -1 ? null : item.id })}
								data={companies}
								itemComponent={(item, index) => {
									return (
										<div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
											<ItemText>{`${item.name}`}</ItemText>
										</div>
									)
								}}
							/>
						</Grid>
					}
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FormImageInput
							label={t(lang, "usersPage.profilePicture")}
							tip={t(lang, "usersPage.ppTip")}
							onFileSelect={(file) => setProfilePicture(file)}
						/>
					</Grid>
				</NewRecordDrawer>
				<NewRecordDrawer
					title={`${userToEdit?.name} ${userToEdit?.last_name}`}
					onSaveClick={handleUpdateClicked}
					open={editOpen}
					onClose={() => setEditOpen(false)}
					saveButtonTitle={t(lang, "common.save")}
				>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<FormInput
							label={t(lang, "fields.name")}
							onChange={(val) => setUpdateInfo({ ...updateInfo, name: val })}
							helperText="*"
							helperTextType={"error"}
							initialValue={userToEdit?.name}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<FormInput
							label={t(lang, "fields.lastName")}
							onChange={(val) => setUpdateInfo({ ...updateInfo, last_name: val })}
							helperText="*"
							helperTextType={"error"}
							initialValue={userToEdit?.last_name}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<FormInput
							label={t(lang, "fields.citizenId")}
							onChange={(val) => setUpdateInfo({ ...updateInfo, id_number: val })}
							type="number"
							initialValue={userToEdit?.id_number}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<FormInput
							label={t(lang, "fields.userName")}
							onChange={(val) => setUpdateInfo({ ...updateInfo, user_name: val })}
							helperText={t(lang, "usersPage.usrNameDesc")}
							initialValue={userToEdit?.user_name}
						/>
					</Grid>


					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FormInput
							label={t(lang, "fields.address")}
							onChange={(val) => setUpdateInfo({ ...updateInfo, address: val })}
							initialValue={userToEdit?.address}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginRight: '12px', marginLeft: '12px' }}>
						<DateTimePicker
							timeDisabled={true}
							isRequired={false}
							locale={lang}
							label={t(lang, "fields.birthDate")}
							onChange={(value) => setUpdateInfo({ ...userInfo, date_of_birth: value })}

						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<FormInput
							label={t(lang, "fields.phone")}
							onChange={(val, country, e, formattedValue) => setUpdateInfo({ ...updateInfo, phone: correctPhone(val, country.dialCode), area_code: country.dialCode })}
							type="phone"
							helperText={'*'}
							helperTextType="error"
							initialValue={`${userToEdit?.area_code}${userToEdit?.phone}`}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<FormInput
							label={t(lang, "fields.email")}
							onChange={(val) => setUpdateInfo({ ...updateInfo, email: val })}
							helperText={'*'}
							helperTextType="error"
							initialValue={userToEdit?.email}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FormSelectInput
							label={t(lang, "fields.chief")}
							onSelect={(item) => setUpdateInfo({ ...updateInfo, chief: item.id === -1 ? null : item.id })}
							data={users}
							initialValue={initialChiefValue}
							itemComponent={(item, index) => {
								return (
									<div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
										<Avatar name={item.name} lastName={item.last_name} size={24} avatar={item.avatar} />
										<ItemText>{`${item.name} ${item.last_name}`}</ItemText>
									</div>
								)
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FormSelectInput
							label={t(lang, "fields.department")}
							onSelect={(item) => setUpdateInfo({ ...updateInfo, department: item.id === -1 ? null : item.id })}
							data={departments}
							initialValue={initialDepartmentValue}  // userToEdit ? users?.indexOf(u => u.id === userToEdit.chief) : null
							itemComponent={(item, index) => {
								return (
									<div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
										{/* <Avatar src={item.avatar} alt="image" /> */}
										<ItemText>{`${item.name}`}</ItemText>
									</div>
								)
							}}

						/>
					</Grid>
					{Utils.isSystemAdmin() &&
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<FormSelectInput
								label={t(lang, "fields.company")}
								onSelect={(item) => setUpdateInfo({ ...updateInfo, company: item.id === -1 ? null : item.id })}
								data={companies}
								initialValue={initialCompValue}  // userToEdit ? users?.indexOf(u => u.id === userToEdit.chief) : null
								itemComponent={(item, index) => {
									return (
										<div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
											<ItemText>{`${item.name}`}</ItemText>
										</div>
									)
								}}

							/>
						</Grid>
					}
					{/* <Grid item xs={12} sm={12} md={12} lg={12}>

								<BeasyChechbox
									defaultChecked={userActive}
									label={t(lang, "fields.active")}
									onChange={(e) => console.log(e)}
									ref={checkoxRef}
								/>
							</Grid> */}


					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FormImageInput
							label={t(lang, "usersPage.profilePicture")}
							tip={t(lang, "usersPage.ppTip")}
							onFileSelect={(file) => setProfilePicture(file)}
							defaultSrc={userToEdit?.avatar}
						/>
					</Grid>
				</NewRecordDrawer>

				<DataTable
					columns={columns}
					data={users}
					actionOptions={actionOptions}
					onNewButtonClick={() => setNewRecordOpen(true)}
					onRowsDelete={(rows, ids) => deleteUsers(ids)}
					title={t(lang, "usersPage.title")}
					canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateUsers")}
				/>
			</DataSection>
		</Container>
	)
}

export default UsersPage;

const DataSection = styled.div`
	margin: 5px;
	display: flex;
	height: 100%
`
const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%
`


const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`
