import { useContext, useEffect, useState } from "react";
import FormInput from "../../../../../components/FormInput";
import { Context } from "../../../../../context/Context";
import t from "../../../../../static/Language";

import DataTable from "../../../../../components/DataTable";
import { toast, ToastContainer } from "react-toastify"
import globalStyles from "../../../../../static/GlobalStyles";
import SafeStorage from "../../../../../static/SafeStorage";
import API from "../../../../../static/API";
import Container from "../../../../../components/Container";
import DataSection from "../../../../../components/DataSection";
import NewRecordDrawer from "../../../../../components/NewRecordDrawer";




const GateAccessPointsTab = ({ accessPoints, gateId }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [actionOptions, setActionOptions] = useState()
    const [objInfo, setObjInfo] = useState({})
    const [data, setData] = useState(accessPoints)
    const [_gate, setGate] = useState(gateId)

    
    useEffect(() => {
        prepareActionOptions()
    }, [])



    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "ip",
            label: t(lang, "fields.ip"),
            options: {
                filter: true,
                sort: true,
                draggable: true,

            }
        }
    ]


    const prepareActionOptions = () => {
        let _actionOptions = {}
        _actionOptions["delete"] = {
            onDelete: (row, id) => {
                _delete([id])
            }
        }
        setActionOptions(_actionOptions)
    }

    const get = () => {
        API.getGateDetails(_gate).then(response => { // cevap gelirse şirketleri atıyoruz
            setData(response.data.data.access_points)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getGateDetails(_gate).then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data.access_points)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const create = () => {
        const _data = {
            ...objInfo,
            gate: gateId
        }

        API.createAccessPoint(_data).then(response => {
            get()
            toast(t(lang, "accessPointsPage.created"))
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.createAccessPoint(_data).then(resp => {
                        get()
                        toast(t(lang, "accessPointsPage.created"))
                    }).catch(er => {
                        toast(t(lang, "accessPointsPage.createError"))
                    })
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const _delete = (ids) => {
        API.deleteAccessPoints(ids).then(response => {
            toast(t(lang, "accessPointsPage.deleted"))
            get()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteAccessPoints(ids).then(resp => {
                        toast(t(lang, "accessPointsPage.deleted"))
                        get()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "accessPointsPage.deleteError"))
                    }).catch(e => {
                        if (e.response.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }

    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />

            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "accessPointsPage.new")}
                    onSaveClick={create}
                >
                    <FormInput
                        label={t(lang, "fields.ip")}
                        onChange={(val) => setObjInfo({ ...objInfo, ip: val })}
                    />
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    canCreateNewRecord={true}
                    onNewButtonClick={() => {
                        setNewRecordOpen(true)
                    }}
                    actionOptions={actionOptions}
                />
            </DataSection>

        </Container>
    )
}


export default GateAccessPointsTab;

