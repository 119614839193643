

import { Box, Breadcrumbs, Button, Link, Input } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Context } from '../../../context/Context';
import globalStyles from '../../../static/GlobalStyles';
import FlexSchedule from '../../../components/shift-components/FlexSchedule';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import t from '../../../static/Language';
import Container from '../../../components/Container';



const ScheduleShifttPage = ({

}) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang


    useEffect(() => {
        document.title = t(lang, "menuNames.shifts")
    }, [])

    const navigate = useNavigate()



    return (
        <Container>
            {/* <Breadcrumbs >
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} onClick={() => navigate("/shifts")}>
                    <CrumbText>{t(lang, "shiftsPage.title")}</CrumbText>
                </Link>
                <CurrentCrumbText>{t(lang, "shiftsPage.schedule")}</CurrentCrumbText>
            </Breadcrumbs> */}
            <FlexSchedule />
        </Container>
    )

}

export default ScheduleShifttPage;

