import React, { useEffect, useState } from 'react';

import { ProSidebarProvider } from 'react-pro-sidebar';
import Main from './main-stack/Main';
import Login from './login-stack/Login';
import Reducer from './context/Reducer';
import { Context } from './context/Context';
import Utils from './static/Utils';
import SafeStorage from './static/SafeStorage';
import API from './static/API';
import Loading from './states/Loading';

import './App.css';

const App = () => {
	const [state, dispatch] = React.useReducer(Reducer, {})
	const [loading, setLoading] = useState(true)


	useEffect(() => {
		initialize()
	}, [])


	const initialize = () => {
		let lang = Utils.getLanguage()
		SafeStorage.setItem("appLang", lang)
		dispatch({
			type: "LANG",
			appLang: lang
		})
		

		let token = SafeStorage.getItem("token")
		
		if (!token) {
			dispatch({ type: "LOGIN", login: false })
			setLoading(false)
			return
		}
		API.validateToken(token).then(response => { // token geçerli ise uygulamaya giriyoruz
			dispatch({ type: "LOGIN", login: true })
			setLoading(false)
		}).catch(er => { // token geçerli değil ise refresh token ile token'ı yenilemeye çalışıyoruz
			console.log("invalid token. refreshing...")
			let refreshToken = SafeStorage.getItem("refreshToken")
			if (!refreshToken) {
				dispatch({ type: "LOGIN", login: false })
				setLoading(false)
				return
			}
			API.refreshToken(refreshToken).then(r => { // eğer refresh token geçerli ise yeni alınan tokenı storage'a ekliyoruz
				console.log("token refreshed")
				let t = r.data.token
				SafeStorage.setItem("token", t)
				dispatch({ type: "LOGIN", login: true })
				setLoading(false)
			}).catch(er => { // eğer refresh token da geçerli değilse login ekranına yönlendiriyoruz
				console.log("invalid refresh token")
				dispatch({ type: "LOGIN", login: false })
				setLoading(false)
			})
		})
	}


	if (loading) {
		return (
			<Loading />
		)
	}


	return (
		<Context.Provider value={{ state, dispatch }}>
			<ProSidebarProvider>
				{state.login ? (
					<Main />
				) : (
					<Login />
				)}
			</ProSidebarProvider>
		</Context.Provider>

	);
}

export default App;
