import { useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FormControl, InputAdornment } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import globalStyles from "../../static/GlobalStyles";
import { TimeField } from "@mui/x-date-pickers";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";



const LeaveRangePicker = ({
    mode = "datetime",
    onChange = (start, end) => null,
    locale = "tr",
    labels = { start: "Start", end: "End" }
}) => {




    const [start, setStart] = useState()
    const [end, setEnd] = useState()




    const theme = createTheme({
        typography: {
            fontFamily: globalStyles.font, // İstediğiniz fontu burada belirleyin
        },
        MuiClockNumber: {
            styleOverrides: {
                root: {
                    color: globalStyles.textColor, // Saat numaralarının rengi
                },
            },
        },
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    },
                }
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    },

                },
            },
            MuiPickersYear: {
                styleOverrides: {
                    yearButton: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiDayCalendar: {
                styleOverrides: {
                    weekDayLabel: {
                        fontFamily: globalStyles.font
                    },
                    weekNumber: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiPickersCalendarHeader: {
                styleOverrides: {
                    label: {
                        fontFamily: globalStyles.font
                    }
                }
            }
        }
    })


    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locale}
                localeText={{
                    okButtonLabel: 'Tamam',
                    cancelButtonLabel: 'İptal',
                }}
            >
                {mode === "datetime" ? (
                    <FormControl fullWidth sx={{ flexDirection: 'row', justifyContent: "flex-start", alignItems: "flex-start" }}>
                        <DateTimePicker
                            label={labels.start}
                            sx={{ margin: "3px", display: 'flex', flex: 1 }}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    helperText: '*',
                                    FormHelperTextProps: {
                                        style: {
                                            fontFamily: globalStyles.font,
                                            color: 'red'
                                        }
                                    }
                                }
                            }}
                            onAccept={(value) => {
                                setStart(value.toISOString())
                                onChange(value.toISOString(), end)
                            }}
                            maxDate={dayjs(end)}
                        />
                        <DateTimePicker
                            label={labels.end}
                            sx={{ margin: "3px", display: 'flex', flex: 1 }}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    helperText: '*',
                                    FormHelperTextProps: {
                                        style: {
                                            fontFamily: globalStyles.font,
                                            color: 'red'
                                        }
                                    }
                                }
                            }}
                            onAccept={(value) => {
                                setEnd(value.toISOString())
                                onChange(start, value.toISOString())
                            }}
                            minDate={dayjs(start)}
                        />
                    </FormControl>
                ) : (
                    <FormControl fullWidth sx={{ flexDirection: 'row', justifyContent: "flex-start", alignItems: "flex-start", marginTop: "7px" }}>
                        <TimeField
                            size='small'
                            label={labels.start}
                            format='HH:mm'
                            helperText='*'
                            value={start}
                            sx={{ margin: "3px", display: 'flex', flex: 1 }}
                            FormHelperTextProps={{
                                style: {
                                    color: 'red',
                                    fontFamily: globalStyles.font
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <AccessTimeIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(value) => {
                                setStart(value)
                                onChange(value, end)
                            }}
                            ampm={false}
                        />
                        <TimeField
                            size='small'
                            label={labels.end}
                            format='HH:mm'
                            helperText='*'
                            value={end}
                            sx={{ margin: "3px", display: 'flex', flex: 1 }}
                            FormHelperTextProps={{
                                style: {
                                    color: 'red',
                                    fontFamily: globalStyles.font
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <AccessTimeIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(value) => {
                                setEnd(value)
                                onChange(start, value)
                            }}
                            ampm={false}
                        />
                    </FormControl>
                )}
            </LocalizationProvider>
        </ThemeProvider>


    )

}

export default LeaveRangePicker;