import { useNavigate } from "react-router-dom";
import { useContext } from "react";


import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar, sidebarClasses } from 'react-pro-sidebar';
import RouteCollection from "../components/RouteCollection";
import { Context } from "../context/Context";
import t from "../static/Language";
import styled from "styled-components";
import globalStyles from "../static/GlobalStyles";
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import RoomPreferencesRoundedIcon from '@mui/icons-material/RoomPreferencesRounded';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import DepartureBoardRoundedIcon from '@mui/icons-material/DepartureBoardRounded';
import ScheduleSendRoundedIcon from '@mui/icons-material/ScheduleSendRounded';
import FunctionsRoundedIcon from '@mui/icons-material/FunctionsRounded';
import SafeStorage from "../static/SafeStorage";
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import DoorSlidingRoundedIcon from '@mui/icons-material/DoorSlidingRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import LocalPoliceRoundedIcon from '@mui/icons-material/LocalPoliceRounded';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded';
import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import Diversity2RoundedIcon from '@mui/icons-material/Diversity2Rounded';
import logo from "../assets/icon/flexbee-writing.svg"
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import BallotRoundedIcon from '@mui/icons-material/BallotRounded';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
import CalendarViewWeekRounded from '@mui/icons-material/CalendarViewWeekRounded';
import InsertInvitationRoundedIcon from '@mui/icons-material/InsertInvitationRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import AddModeratorRoundedIcon from '@mui/icons-material/AddModeratorRounded';
import PeopleIcon from '@mui/icons-material/People';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import SportsRoundedIcon from '@mui/icons-material/SportsRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import BeachAccessRoundedIcon from '@mui/icons-material/BeachAccessRounded';
import BlindsClosedRoundedIcon from '@mui/icons-material/BlindsClosedRounded';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';


import { isMobile } from "react-device-detect";


const Layout = () => {
    const { state } = useContext(Context)
    const lang = state.appLang

    const navigate = useNavigate()
    const { collapseSidebar, collapsed } = useProSidebar()

    const privileges = JSON.parse(SafeStorage.getItem("user")).privileges
    const isSuperAdmin = privileges.includes("superAdmin")


    const isActive = (pathName, exact) => {
        if (exact) {
            return window.location.pathname === pathName
        }
        return window.location.pathname.startsWith(pathName)
    }

    return (
        <LayoutContainer>
            <Sidebar
                backgroundColor={globalStyles.sidebarBackColor}
                style={{ margin: 4, borderRadius: 15, overflow: 'hidden', boxShadow: "2px 2px 2px #dbdbdb" }}
                defaultCollapsed={isMobile}
            /*  rootStyles={{
                 [`.${sidebarClasses.container}`]: {
                     overflowY: 'unset'
                 },
             }} */
            >
                <Menu
                    menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                            return {
                                backgroundColor: active ? globalStyles.sidebarSelectColor : globalStyles.sidebarBackColor,
                                color: active ? "white" : undefined,
                                fontWeight: active ? "bold !important" : undefined,
                                borderRadius: "0px",
                                borderTopLeftRadius: "38px",
                                borderBottomLeftRadius: "38px",
                                "&:hover": {
                                    backgroundColor: globalStyles.firstColor,
                                    color: "white",
                                    borderRadius: "0px",
                                    borderTopLeftRadius: "38px",
                                    borderBottomLeftRadius: "38px",
                                    fontWeight: "bold !important",
                                },
                            };
                        }
                    }}
                >
                    <MenuHeader onClick={() => { collapseSidebar() }}
                        icon={<Logo src={require("../assets/icon/bee-dark-1024.png")} />}
                    >
                        {!collapsed &&
                            < img
                                src={logo}
                                width={100}
                                style={{ top: 20, left: 55, position: 'absolute' }}
                            />
                        }
                    </MenuHeader>
                    <StyledMenuItem active={isActive('/dashboard')} icon={<DashboardCustomizeRoundedIcon />} onClick={() => navigate('/dashboard')}>Dashboard</StyledMenuItem>

                    {(isSuperAdmin || privileges.includes("canListUsers")) &&
                        <StyledSubMenu icon={<Person2RoundedIcon />} label={t(lang, "menuNames.users")}>
                            <StyledMenuItem active={isActive('/users')} icon={<Groups2RoundedIcon />} onClick={() => navigate('/users')}>{t(lang, "menuNames.userList")}</StyledMenuItem>
                            {/* <StyledMenuItem icon={<StyleRoundedIcon />} onClick={() => navigate('/users')}>{t(lang, "menuNames.appPrivileges")}</StyledMenuItem> */}
                            <StyledMenuItem active={isActive('/user-access-privs')} icon={<RoomPreferencesRoundedIcon />} onClick={() => navigate('/user-access-privs')}>{t(lang, "menuNames.accessPrivileges")}</StyledMenuItem>
                        </StyledSubMenu>
                    }



                    {privileges.includes("superAdmin") &&
                        <StyledSubMenu icon={<BusinessRoundedIcon />} label={t(lang, "menuNames.companies")}>
                            <StyledMenuItem active={isActive('/companies')} icon={<FormatListNumberedRoundedIcon />} onClick={() => navigate('/companies')}>{t(lang, "menuNames.companyList")}</StyledMenuItem>
                            <StyledMenuItem active={isActive('/company-privileges')} icon={<AddModeratorRoundedIcon />} onClick={() => navigate('/company-privileges')}>{t(lang, "menuNames.companyPrivileges")}</StyledMenuItem>
                        </StyledSubMenu>
                    }


                    {(isSuperAdmin || (privileges.includes("canListBuildings") || privileges.includes("canListGates") || privileges.includes("canListAccessPoints") || privileges.includes("canListUserActivity"))) &&
                        <StyledSubMenu icon={<SupervisedUserCircleRoundedIcon />} label={t(lang, "menuNames.accessControl")}>
                            {(isSuperAdmin || privileges.includes("canListBranches")) &&
                                <StyledMenuItem active={isActive('/branches')} icon={<DeviceHubRoundedIcon />} onClick={() => navigate('/branches')}>{t(lang, "menuNames.branches")}</StyledMenuItem>
                            }
                            {(isSuperAdmin || privileges.includes("canListBuildings")) &&
                                <StyledMenuItem active={isActive('/buildings')} icon={<MapsHomeWorkRoundedIcon />} onClick={() => navigate('/buildings')}>{t(lang, "menuNames.buildings")}</StyledMenuItem>
                            }

                            {(isSuperAdmin || privileges.includes("canListGates")) &&
                                <StyledMenuItem active={isActive('/gates')} icon={<DoorSlidingRoundedIcon />} onClick={() => navigate('/gates')}>{t(lang, "menuNames.gates")}</StyledMenuItem>
                            }
                            {(isSuperAdmin || privileges.includes("canListAccessPoints")) &&
                                <StyledMenuItem active={isActive('/access-points')} icon={<PlaceRoundedIcon />} onClick={() => navigate('/access-points')}>{t(lang, "menuNames.accessPoints")}</StyledMenuItem>
                            }
                            {(isSuperAdmin || privileges.includes("canListUserActivity")) &&
                                <StyledMenuItem active={isActive('/user-activities')} icon={<CompareArrowsRoundedIcon />} onClick={() => navigate('/user-activities')}>{t(lang, "menuNames.userActivity")}</StyledMenuItem>
                            }

                        </StyledSubMenu>
                    }



                    {(isSuperAdmin || privileges.includes("canUsePacs")) &&
                        <StyledSubMenu icon={<PendingActionsRoundedIcon />} label={t(lang, "menuNames.pacs")}>
                            <StyledMenuItem active={isActive('/latecomers')} icon={<DepartureBoardRoundedIcon />} onClick={() => navigate('/latecomers')}>{t(lang, "menuNames.lateComers")}</StyledMenuItem>
                            <StyledMenuItem active={isActive('/earlyleavers')} icon={<ScheduleSendRoundedIcon />} onClick={() => navigate('/earlyleavers')}>{t(lang, "menuNames.earlyLeavers")}</StyledMenuItem>
                            {/*    <StyledMenuItem icon={<LocalHotelRoundedIcon />} onClick={() => navigate('/users')}>{t(lang, "menuNames.absentees")}</StyledMenuItem> */}
                            <StyledMenuItem active={isActive('/timesheet')} icon={<FunctionsRoundedIcon />} onClick={() => navigate('/timesheet')}>{t(lang, "menuNames.attendance")}</StyledMenuItem>
                        </StyledSubMenu>
                    }

                    {(isSuperAdmin || privileges.includes("canUseLeave")) &&
                        <StyledSubMenu icon={<BeachAccessRoundedIcon />} label={t(lang, "menuNames.leaveManagement")}>
                            <StyledMenuItem active={isActive('/leave')} icon={<BlindsClosedRoundedIcon />} onClick={() => navigate('/leave')}>{t(lang, "menuNames.leave")}</StyledMenuItem>
                        </StyledSubMenu>
                    }


                    {(isSuperAdmin || privileges.includes("canListRoles")) &&
                        <StyledSubMenu icon={<LocalPoliceRoundedIcon />} label={t(lang, "menuNames.privilegeOptions")}>
                            <StyledMenuItem active={isActive('/roles', true)} icon={<PostAddRoundedIcon />} onClick={() => navigate('/roles')}>{t(lang, "menuNames.userRoles")}</StyledMenuItem>
                            <StyledMenuItem active={isActive('/roles/grant')} icon={< AddCardRoundedIcon />} onClick={() => navigate('/roles/grant')}>{t(lang, "menuNames.grantRolesToUsers")}</StyledMenuItem>
                        </StyledSubMenu>
                    }

                    {(isSuperAdmin || privileges.includes("canUseWhistleBlower")) &&
                        <StyledSubMenu icon={<SportsRoundedIcon />} label={t(lang, "menuNames.whistleBlower")}>
                            {(isSuperAdmin || privileges.includes("canListQuizzes")) &&
                                <StyledMenuItem active={isActive('/ethic-documents')} icon={<AssignmentTurnedInRoundedIcon />} onClick={() => navigate('/ethic-documents')}>{t(lang, "menuNames.quizzes")}</StyledMenuItem>
                            }
                            {(isSuperAdmin || privileges.includes("canListComplaints")) &&
                                <StyledMenuItem active={isActive('/complaints')} icon={<PanToolRoundedIcon />} onClick={() => navigate('/complaints')}>{t(lang, "complaintsPage.title")}</StyledMenuItem>
                            }

                        </StyledSubMenu>
                    }

                    {(isSuperAdmin || privileges.includes("canListAnnouncements")) &&
                        <StyledMenuItem active={isActive('/announcements')} icon={<CampaignRoundedIcon />} onClick={() => navigate('/announcements')}>{t(lang, "menuNames.announcements")}</StyledMenuItem>
                    }

                    {(isSuperAdmin || privileges.includes("canListFeedbacks")) &&
                        <StyledMenuItem active={isActive('/feedbacks')} icon={<ThumbUpAltIcon />} onClick={() => navigate('/feedbacks')}>{t(lang, "menuNames.feedbacks")}</StyledMenuItem>
                    }

                    {(isSuperAdmin || privileges.includes("canListTitles") || privileges.includes("canListUserGroups")) &&
                        <StyledSubMenu icon={<SettingsApplicationsRoundedIcon />} label={t(lang, "menuNames.enterprise")}>
                            {(isSuperAdmin || privileges.includes("canListTitles")) &&
                                <StyledMenuItem active={isActive('/titles')} icon={<ClassRoundedIcon />} onClick={() => navigate('/titles')}>{t(lang, "menuNames.titles")}</StyledMenuItem>
                            }
                            {(isSuperAdmin || privileges.includes("canListUserGroups")) &&
                                <StyledMenuItem active={isActive('/user-groups')} icon={<Diversity2RoundedIcon />} onClick={() => navigate('/user-groups')}>{t(lang, "menuNames.userGroups")}</StyledMenuItem>
                            }

                        </StyledSubMenu>
                    }

                    {(isSuperAdmin || privileges.includes("canListSurveys")) &&
                        <StyledSubMenu icon={<InventoryRoundedIcon />} label={t(lang, "menuNames.surveys")}>
                            {(isSuperAdmin || privileges.includes("canListSurveys")) &&
                                <StyledMenuItem active={isActive('/surveys')} icon={<BallotRoundedIcon />} onClick={() => navigate('/surveys')}>{t(lang, "menuNames.surveys")}</StyledMenuItem>
                            }
                            {(isSuperAdmin || privileges.includes("canListUsergroups")) &&
                                <StyledMenuItem active={isActive('/user-groups')} icon={<Diversity2RoundedIcon />} onClick={() => navigate('/user-groups')}>{t(lang, "menuNames.userGroups")}</StyledMenuItem>
                            }

                        </StyledSubMenu>
                    }

                    {(isSuperAdmin || privileges.includes("canUseShifts")) &&
                        <StyledSubMenu icon={<CalendarViewWeekRounded />} label={t(lang, "menuNames.shifts")}>
                            <StyledMenuItem active={isActive('/shifts', true)} icon={<MoreTimeRoundedIcon />} onClick={() => navigate('/shifts')}>{t(lang, "menuNames.shiftList")}</StyledMenuItem>
                            <StyledMenuItem active={isActive('/shifts/supervisors', true)} icon={<AssignmentIndRoundedIcon />} onClick={() => navigate('/shifts/supervisors')}>{t(lang, "menuNames.shiftSupervisors")}</StyledMenuItem>
                            {(isSuperAdmin || privileges.includes("canScheduleShifts")) &&  
                                <StyledMenuItem active={isActive('/shifts/schedule', true)} icon={<TransferWithinAStationIcon />} onClick={() => navigate('/shifts/schedule')}>{t(lang, "menuNames.scheduleShift")}</StyledMenuItem>
                            }
                            <StyledMenuItem active={isActive('/shifts/work-plans', true)} icon={<InsertInvitationRoundedIcon />} onClick={() => navigate("/shifts/work-plans")}>{t(lang, "menuNames.workPlan")}</StyledMenuItem>
                            {(isSuperAdmin || privileges.includes("canScheduleShifts")) &&
                                <StyledMenuItem active={isActive('/shifts/work-plans/assignment', true)} icon={<DateRangeRoundedIcon />} onClick={() => navigate("/shifts/work-plans/assignment")}>{t(lang, "menuNames.assignWorkPlan")}</StyledMenuItem>
                            }
                        </StyledSubMenu>
                    }

                    {(isSuperAdmin || privileges.includes("canListDepartments")) &&
                        <StyledSubMenu icon={<CorporateFareRoundedIcon />} label={t(lang, "menuNames.organization")}>
                            <StyledMenuItem active={isActive('/departments', true)} icon={<PeopleIcon />} onClick={() => navigate('/departments')}>{t(lang, "menuNames.departments")}</StyledMenuItem>
                            {(isSuperAdmin || privileges.includes("canSeeHierarchy")) &&
                                <StyledMenuItem active={isActive('/hierarchy')} icon={<AccountTreeRoundedIcon />} onClick={() => navigate('/hierarchy')}>{t(lang, "menuNames.organizationChart")}</StyledMenuItem>
                            }
                        </StyledSubMenu>
                    }




                </Menu>
            </Sidebar>
            <div style={{ flex: 1, display: "flex", flexDirection: 'column' }}>
                <Header />
                <RootConatiner >
                    <RouteCollection privileges={privileges} />
                </RootConatiner>
                <Footer />
            </div>

        </LayoutContainer >
    );

}


export default Layout

const LayoutContainer = styled.div`
    display: flex;
    flex: 1;
    height: 100vh;
    overflow-y: hidden;
    width: 100%;
    flex-direction: row
`


const RootConatiner = styled.div`
    flex-grow: 1;
    overflow-y: auto;
`


const StyledMenuItem = styled(MenuItem)`
    color: ${globalStyles.sidebarTextColor};
    font-family: ${globalStyles.font};
    font-size: 16px;
`

const StyledSubMenu = styled(SubMenu)`
    color: ${globalStyles.sidebarTextColor};
    font-family: ${globalStyles.font};
    font-size: 16px;
`

const MenuHeader = styled(MenuItem)`
    padding-top: 12px;
    padding-bottom: 24px;
`


const Logo = styled.img`
	width: 36px;
	height: 36px;
	border-radius: 12px;
`
