import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-modern-drawer/dist/index.css'
import { toast, ToastContainer } from "react-toastify"
import API from '../../../static/API';
import t from '../../../static/Language';
import SafeStorage from '../../../static/SafeStorage';
import globalStyles from '../../../static/GlobalStyles';
import DataTable from '../../../components/DataTable';
import { Context } from '../../../context/Context';
import { Box, Grid } from '@mui/material';
import FormInput from '../../../components/FormInput';
import { MuiColorInput } from 'mui-color-input'
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Utils from '../../../static/Utils';
import dayjs from 'dayjs';
import { Card } from '@mui/material';
import BreakDescriber from '../../../components/shift-components/BreakDescriber';
import FlexButton from '../../../components/FlexButton';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { InputAdornment } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BeasyChechbox from '../../../components/BeasyCheckbox';
import DataSection from '../../../components/DataSection';
import Container from '../../../components/Container';
import NewRecordDrawer from '../../../components/NewRecordDrawer';


const ShiftsPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const user = JSON.parse(SafeStorage.getItem("user"))
    const privileges = user.privileges

    const [data, setData] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [objInfo, setObjInfo] = useState({ company: user.company })
    const [updateInfo, setUpdateInfo] = useState({})
    const [editOpen, setEditOpen] = useState(false)
    const [actionOptions, setActionOptions] = useState()
    const [objToEdit, setObjToEdit] = useState(null)
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const [breaks, setBreaks] = useState([])

    const [color, setColor] = useState(Utils.randomColor())

    useEffect(() => {
        document.title = t(lang, "menuNames.shifts")
        get()
        prepareActionOptions()
    }, [])


    const parseTime = (time) => {
        const parts = time.split(':')
        const hour = parseInt(parts[0])
        const minute = parseInt(parts[1])
        const now = new Date()
        now.setHours(hour)
        now.setMinutes(minute)
        now.setSeconds(0)
        now.setMilliseconds(0)
        return dayjs(now)
    }

    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canEditShifts")) {
            _actionOptions["edit"] = {
                onEdit: (row, id) => {
                    setColor(row.color_code)
                    setStart(parseTime(row.start_time))
                    setEnd(parseTime(row.end_time))
                    setObjToEdit(row)
                    setBreaks(row.breaks)
                    setEditOpen(true)
                }
            }
        }
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteShifts")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([id])
                }
            }
        }


        setActionOptions(_actionOptions)
    }


    const get = () => {
        API.getShifts().then(response => {
            setData(response.data.data)
        }).catch(err => {
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getShifts().then(resp => { // userları tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const create = (obj) => {
        const _obj = obj ?? objInfo
        API.createShift(_obj).then(response => {
            get()
            toast(t(lang, "shiftsPage.created"))
        }).catch(err => { // hata alınırsa
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.createShift(_obj).then(resp => { // userları tekrar çekiyoruz
                        get()
                        toast(t(lang, "shiftsPage.created"))
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const update = (obj) => {
        const _obj = obj ?? updateInfo
        API.updateShift(objToEdit.id, _obj).then(() => {
            toast(t(lang, "shiftsPage.updated"))
            get()
        }).catch(err => {
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.updateShift(objToEdit.id, _obj).then(resp => { // userları tekrar çekiyoruz
                        toast(t(lang, "shiftsPage.updated"))
                        get()
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const _delete = (ids) => {
        API.deleteShifts(ids).then(() => {
            toast(t(lang, "shiftsPage.deleted"))
            get()
        }).catch(err => {
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteShifts(ids).then(resp => { // userları tekrar çekiyoruz
                        toast(t(lang, "shiftsPage.deleted"))
                        get()
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const columns = [
        {
            name: "shift_name",
            label: t(lang, "fields.title"),
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "start_time",
            label: t(lang, "fields.shiftStart"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span>{value.slice(0, 5)}</span>
                    )
                }
            }
        },
        {
            name: "end_time",
            label: t(lang, "fields.shiftEnd"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <text>{value.slice(0, 5)}</text>
                    )
                }
            }
        },
        {
            name: "short_code",
            label: t(lang, "fields.shortCode")
        },
        {
            name: "color_code",
            label: t(lang, "fields.colorCode"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                            <Card elevation={3} sx={{ backgroundColor: value, width: '20px', height: '20px', marginRight: '8px' }} />
                            <span>{value}</span>
                        </div>
                    )
                }
            }

        },

    ]


    const validate = (obj) => {
        if (Utils.nullOrEmpty(obj.start_time)
            || Utils.nullOrEmpty(obj.end_time)
            || Utils.nullOrEmpty(obj.short_code)
            || Utils.nullOrEmpty(obj.shift_name)
            || Utils.nullOrEmpty(obj.color_code)
        ) {
            return false
        }
        return true
    }

    const validateUpdate = (obj) => {
        if ((obj.start_time && Utils.nullOrEmpty(obj.start_time))
            || (obj.end_time && Utils.nullOrEmpty(obj.end_time))
            || (obj.short_code && Utils.nullOrEmpty(obj.short_code))
            || (obj.shift_name && Utils.nullOrEmpty(obj.shift_name))
            || (obj.color_code && Utils.nullOrEmpty(obj.color_code))
        ) {
            return false
        }
        return true
    }

    const validateTimes = (obj) => {
        try {
            const endMinutes = parseInt(obj.end_time.split(':')[0]) * 60 + parseInt(obj.end_time.split(':')[1])
            const startMinutes = parseInt(obj.start_time.split(':')[0]) * 60 + parseInt(obj.start_time.split(':')[1])
            if (endMinutes - startMinutes <= 0) {
                return false
            }
            for (let index = 0; index < breaks.length; index++) {
                const b = breaks[index];
                if (!b.break_start_time || !b.break_end_time) {
                    return false
                }
            }
            return true
        } catch (ex) {
            return false
        }
    }



    const handleSaveClicked = () => {
        const obj = { ...objInfo, color_code: color, breaks: breaks }

        if (!validateTimes(obj)) {
            toast(t(lang, "shiftsPage.invalidTimes"))
            return
        }
        if (!validate(obj)) {
            toast(t(lang, "shiftsPage.requiredFields"))
            return
        }
        create(obj)
    }

    const handleUpdateClicked = () => {
        const obj = {
            ...updateInfo,
            color_code: color,
            start_time: start.toDate().toLocaleTimeString(lang),
            end_time: end.toDate().toLocaleTimeString(lang),
            breaks: breaks
        }
        if (!validateTimes(obj)) {
            toast(t(lang, "shiftsPage.invalidTimes"))
            return
        }
        if (!validateUpdate(obj)) {
            toast(t(lang, "shiftsPage.requiredFields"))
            return
        }
        update(obj)
    }

    const handleMultipleDelete = (ids) => {
        _delete(ids)
    }





    const theme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    },

                }
            }
        }
    })

    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "shiftsPage.new")}
                    onSaveClick={handleSaveClicked}
                >

                    <ThemeProvider theme={theme}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormInput
                                label={t(lang, "fields.description")}
                                onChange={(text) => setObjInfo({ ...objInfo, shift_name: text })}
                                helperText={'*'}
                                helperTextType={'error'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormInput
                                label={t(lang, "fields.shortCode")}
                                onChange={(text) => setObjInfo({ ...objInfo, short_code: text })}
                                helperText={'*'}
                                helperTextType={'error'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} margin={'15px'}>
                            <ColorPicker
                                value={color}
                                onChange={(color) => setColor(color)}
                                format='hex'
                                size="small"
                                variant="outlined"
                                sx={{ width: '100%' }}
                                isAlphaHidden
                                label={t(lang, "fields.colorCode")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: '15px', paddingBottom: "0px" }} >
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang} >
                                <TimeField
                                    label={t(lang, "fields.startTime")}
                                    size='small'
                                    format='HH:mm'
                                    helperText='*'
                                    FormHelperTextProps={{
                                        style: {
                                            color: 'red',
                                            fontFamily: globalStyles.font
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AccessTimeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(value) => setObjInfo({ ...objInfo, start_time: value.toDate().toLocaleTimeString() })}
                                    ampm={false}
                                    sx={{ display: 'flex', flex: 1 }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: '15px', paddingBottom: "0px" }} >
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
                                <TimeField
                                    size='small'
                                    label={t(lang, "fields.endTime")}
                                    format='HH:mm'
                                    helperText='*'
                                    FormHelperTextProps={{
                                        style: {
                                            color: 'red',
                                            fontFamily: globalStyles.font
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AccessTimeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(value) => setObjInfo({ ...objInfo, end_time: value.toDate().toLocaleTimeString() })}
                                    ampm={false}
                                    sx={{ display: 'flex', flex: 1 }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </ThemeProvider>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormInput
                            label={t(lang, "shiftsPage.lateEntryTolerance")}
                            onChange={(text) => setObjInfo({ ...objInfo, late_entry_tolerance: text })}
                            type={"number"}
                            max={60}
                            min={0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormInput
                            label={t(lang, "shiftsPage.earlyLeaveTolerance")}
                            onChange={(text) => setObjInfo({ ...objInfo, early_leave_tolerance: text })}
                            type={"number"}
                            max={60}
                            min={0}
                        />
                    </Grid>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {breaks.map((b, i) => {
                            return (
                                <div key={i} style={{ flex: 1, margin: 15 }}>
                                    <BreakDescriber
                                        lang={lang}
                                        start={b.break_start_time ? parseTime(b.break_start_time) : null}
                                        end={b.break_end_time ? parseTime(b.break_end_time) : null}
                                        id={b.id}
                                        title={`${t(lang, "shiftsPage.break")} ${i + 1}`}
                                        onChange={(id, start, end) => {
                                            const index = breaks.findIndex((_q) => _q.id === id);
                                            const updated = { id: id, break_start_time: start?.toDate().toLocaleTimeString(), break_end_time: end?.toDate().toLocaleTimeString() };
                                            const newBreaks = [...breaks];
                                            newBreaks[index] = updated
                                            setBreaks(newBreaks)
                                        }}
                                        onDelete={(id) => {
                                            const newBreaks = [...breaks]
                                            newBreaks.splice(i, 1)
                                            setBreaks(newBreaks)
                                        }}
                                    />
                                </div>
                            )
                        })}
                        <Box sx={{ justifyContent: 'flex-start', display: 'flex' }}>
                            <FlexButton
                                variant='text'
                                onClick={() => {
                                    let bs = [
                                        ...breaks,
                                        { id: Utils.uuid4() }
                                    ]
                                    setBreaks(bs)
                                }}
                                size="small"
                                renderIcon={() => <AddRoundedIcon />}
                                text={t(lang, "shiftsPage.newBreak")}
                            />

                        </Box>
                    </div>

                </NewRecordDrawer>
                <NewRecordDrawer
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={objToEdit?.shift_name}
                    onSaveClick={handleUpdateClicked}
                >
                    <ThemeProvider theme={theme}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormInput
                                label={t(lang, "fields.description")}
                                onChange={(text) => setUpdateInfo({ ...updateInfo, shift_name: text })}
                                helperText={'*'}
                                helperTextType={'error'}
                                initialValue={objToEdit?.shift_name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormInput
                                label={t(lang, "fields.shortCode")}
                                onChange={(text) => setUpdateInfo({ ...updateInfo, short_code: text })}
                                helperText={'*'}
                                helperTextType={'error'}
                                initialValue={objToEdit?.short_code}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} margin={'15px'}>
                            <ColorPicker
                                value={color ?? "#fefefe"}
                                onChange={(color) => setColor(color)}
                                format='hex'
                                size="small"
                                variant="outlined"
                                sx={{ width: '100%' }}
                                isAlphaHidden
                                label={t(lang, "fields.colorCode")}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: '15px', paddingBottom: "0px" }}  >
                            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={lang} >
                                <TimeField
                                    label={t(lang, "fields.startTime")}
                                    size='small'
                                    format='HH:mm'
                                    helperText='*'
                                    FormHelperTextProps={{
                                        style: {
                                            color: 'red',
                                            fontFamily: globalStyles.font
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AccessTimeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={start}
                                    onChange={(value) => setStart(value)}
                                    sx={{ display: "flex", flex: 1 }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: '15px', paddingBottom: "0px" }} >
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
                                <TimeField
                                    size='small'
                                    label={t(lang, "fields.endTime")}
                                    format='HH:mm'
                                    helperText='*'
                                    FormHelperTextProps={{
                                        style: {
                                            color: 'red',
                                            fontFamily: globalStyles.font
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AccessTimeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={end}
                                    onChange={(value) => setEnd(value)}
                                    minTime={start}
                                    sx={{ display: "flex", flex: 1 }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </ThemeProvider>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormInput
                            label={t(lang, "shiftsPage.lateEntryTolerance")}
                            onChange={(text) => setUpdateInfo({ ...updateInfo, late_entry_tolerance: text })}
                            type={"number"}
                            max={60}
                            min={0}
                            initialValue={objToEdit?.late_entry_tolerance}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormInput
                            label={t(lang, "shiftsPage.earlyLeaveTolerance")}
                            onChange={(text) => setUpdateInfo({ ...updateInfo, early_leave_tolerance: text })}
                            type={"number"}
                            max={60}
                            min={0}
                            initialValue={objToEdit?.early_leave_tolerance}
                        />
                    </Grid>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {breaks.map((b, i) => {
                            return (
                                <div key={i} style={{ flex: 1, margin: 15 }}>
                                    <BreakDescriber
                                        lang={lang}
                                        start={b.break_start_time ? parseTime(b.break_start_time) : null}
                                        end={b.break_end_time ? parseTime(b.break_end_time) : null}
                                        id={b.id} title={`${t(lang, "shiftsPage.break")} ${i + 1}`}
                                        onChange={(id, start, end) => {
                                            const index = breaks.findIndex((_q) => _q.id === id);
                                            const updated = { id: id, break_start_time: start?.toDate().toLocaleTimeString(), break_end_time: end?.toDate().toLocaleTimeString() };
                                            const newBreaks = [...breaks];
                                            newBreaks[index] = updated
                                            setBreaks(newBreaks)
                                        }}
                                        onDelete={(id) => {
                                            const newBreaks = [...breaks]
                                            newBreaks.splice(i, 1)
                                            setBreaks(newBreaks)
                                        }}
                                    />
                                </div>
                            )
                        })}

                        <Box sx={{ justifyContent: 'flex-start', display: 'flex' }}>
                            <FlexButton
                                variant='text'
                                onClick={() => {
                                    let bs = [
                                        ...breaks,
                                        { id: Utils.uuid4() }
                                    ]
                                    setBreaks(bs)
                                }}
                                size="small"
                                renderIcon={() => <AddRoundedIcon />}
                                text={t(lang, "shiftsPage.newBreak")}
                            />
                        </Box>
                    </div>
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    title={t(lang, "shiftsPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateShifts")}
                    onNewButtonClick={() => {
                        setBreaks([])
                        setNewRecordOpen(true)
                    }}
                    actionOptions={actionOptions}
                    onRowsDelete={(rows, ids) => handleMultipleDelete(ids)}
                />
            </DataSection>
        </Container>
    )

}

export default ShiftsPage;



const ColorPicker = styled(MuiColorInput)`
  & .MuiColorInput-TextField {
    font-family: ${globalStyles.font};
    backgroud-color: green;
  }
`

