import Layout from "./Layout";

const Main = () => {
    return (
        <Layout />
    );
}

export default Main;

