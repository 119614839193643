import { Player } from "@lottiefiles/react-lottie-player"
import styled from "styled-components"
import globalStyles from "../static/GlobalStyles"

import nodata from '../assets/media/nodata.gif'

const Nodata = ({ msg }) => {
    return (
        <Container>
            <Message>{msg}</Message>
            {/* <Player
                src={require('../assets/lottie-animations/nodata.json')}
                autoplay
                loop
                style={{ width: "100px", height: "100px" }}
            /> */}
            <img src={nodata} width={150} />

        </Container>
    )
}

export default Nodata

const Container = styled.div`
	display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
	`

const Message = styled.text`
    font-family: ${globalStyles.font};
    color: grey;
    font-size: 24px;
    margin-top: 20px;
`
