import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-modern-drawer/dist/index.css'
import { toast, ToastContainer } from "react-toastify"
import API from '../../../static/API';
import t from '../../../static/Language';
import SafeStorage from '../../../static/SafeStorage';
import globalStyles from '../../../static/GlobalStyles';
import DataTable from '../../../components/DataTable';
import { Context } from '../../../context/Context';
import 'react-modern-drawer/dist/index.css'
import { Grid } from '@mui/material';
import FormSelectInput from '../../../components/FormSelectInput';
import Avatar from '../../../components/Avatar';
import DataSection from '../../../components/DataSection';
import Container from '../../../components/Container';
import NewRecordDrawer from '../../../components/NewRecordDrawer';



const ShiftSupervisorsPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const user = JSON.parse(SafeStorage.getItem("user"))
    const privileges = user.privileges


    const [data, setData] = useState([])
    const [actionOptions, setActionOptions] = useState()
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [objInfo, setObjInfo] = useState(privileges.includes("superAdmin") ? {} : { company: user.company })
    const [users, setUsers] = useState([])
    const [branches, setBranches] = useState([])



    useEffect(() => {
        document.title = t(lang, "shiftSupervisorsPage.surveys")
        get()
        prepareActionOptions()
        getOthers()
    }, [])


    const getOthers = () => {
        API.getUsers().then(response => {
            setUsers(response.data.data)
        })
        API.getBranches().then(response => {
            setBranches(response.data.data)
        })
    }


    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canCreateShifts")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([{
                        branch: row["branch"],
                        user_id: row["user_id"]
                    }])
                }
            }
        }
        setActionOptions(_actionOptions)
    }


    const get = () => {
        API.getShiftSupervisors().then(response => { // cevap gelirse datayı atıyoruz
            setData(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getShiftSupervisors().then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const create = () => {
        API.assginShiftSupervisor(objInfo).then(response => {
            toast(t(lang, "shiftSupervisorsPage.created"))
            get()
        }).catch(err => {
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.assginShiftSupervisor(objInfo).then(resp => { // şirketleri tekrar çekiyoruz
                        toast(t(lang, "shiftSupervisorsPage.created"))
                        get()
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
            toast(t(lang, "shiftSupervisorsPage.createError"))
        })

    }
    // rows user_id, branch_id çiflerinden oluşan obje listesi
    const _delete = (rows) => {
        API.removeSupervisorAssignments(rows).then(response => {
            toast(t(lang, "shiftSupervisorsPage.deleted"))
            get()
        }).catch(err => {
            if (err.response?.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.removeSupervisorAssignments(rows).then(resp => {
                        toast(t(lang, "shiftSupervisorsPage.deleted"))
                        get()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "shiftSupervisorspage.deleteError"))
                    }).catch(e => {
                        if (e.response.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }


    const handleMultipleDelete = (rows) => {
        let data = []
        rows.forEach(row => {
            data.push({
                branch: row.branch,
                user_id: row.user_id
            })
        });
        _delete(data)
    }



    const columns = [
        {
            name: "name",
            label: t(lang, "shiftSupervisorsPage.supervisor"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                            <Avatar
                                avatar={data[tableMeta.rowIndex]?.avatar}
                                name={data[tableMeta.rowIndex]?.name}
                                lastName={data[tableMeta.rowIndex]?.last_name}
                                size={40}
                                isSquare
                            />
                            <span style={{ marginLeft: '8px' }}>{`${value} ${data[tableMeta.rowIndex]?.last_name}`}</span>
                        </div>
                    )
                },


            }
        },
        {
            name: "branch_name",
            label: t(lang, "fields.branch"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "user_id",
            label: "ID",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "branch",
            label: t(lang, "fields.branch"),
            options: {
                filter: true,
                sort: false
            }
        },
    ]


    const validate = () => {
        if (objInfo.user_id === -1 || objInfo.branch === -1) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!validate()) { //
            toast(t(lang, "gatesPage.requiredFields"))
            return
        }
        create()
    }



    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "shiftSupervisorsPage.new")}
                    onSaveClick={handleSaveClicked}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormSelectInput
                            label={t(lang, "fields.user")}
                            onSelect={(item) => setObjInfo({ ...objInfo, user_id: item.user_id === -1 ? null : item.id })}
                            data={users}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                        <Avatar
                                            avatar={item.avatar}
                                            name={item.name}
                                            lastName={item.last_name}
                                            size={32}
                                            isSquare
                                        />
                                        <ItemText>{`${item.name} ${item.last_name}`}</ItemText>
                                    </div>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormSelectInput
                            label={t(lang, "fields.branch")}
                            onSelect={(item) => setObjInfo({ ...objInfo, branch: item.branch === -1 ? null : item.id })}
                            data={branches}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{`${item.name}`}</ItemText>
                                    </div>
                                )
                            }}
                        />
                    </Grid>
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    title={t(lang, "shiftSupervisorsPage.supervisor")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateShifts")}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    actionOptions={actionOptions}
                    onRowsDelete={(rows, ids) => handleMultipleDelete(rows)}
                    customDeleteRowFields={["user_id", "branch"]}
                />
            </DataSection>
        </Container>
    )

}


export default ShiftSupervisorsPage;



const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`


