import axios from "axios"
import SafeStorage from "./SafeStorage";


//const BASE_URL = "http://localhost:8080/api"
const BASE_URL = "https://api.flexbeeapp.com/api"  //"https://lobster-app-edqn6.ondigitalocean.app/api"  //const BASE_URL = "https://sample-flask-api.herokuapp.com/api"



class API {


    headers(token) {
        return {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        }
    }

    request(config) {
        return new Promise((resolve, reject) => {
            axios(config).then(response => {
                resolve(response)
            }).catch(err => {
                if (err.response?.status === 401 || err.response?.status === 403) {
                    SafeStorage.getItem('refreshToken').then(refreshToken => {
                        axios({
                            method: 'post',
                            url: `${BASE_URL}/refresht-token`,
                            headers: {
                                "Content-Type": 'application/json',
                                Accept: 'application/json',
                                Authorization: `Bearer ${refreshToken}`
                            }
                        }).then(resp => {
                            let token = resp.data.token
                            config.headers.Authorization = `Bearer ${token}`
                            SafeStorage.setItem("token", token)
                            axios(config).then(response => {
                                resolve(response)
                            }).catch(e => reject(e))
                        }).catch(e => {
                            if (e.response.status === 401 || e.response.status === 403) {
                                reject("expired credentials")
                            } else {
                                reject(e)
                            }
                        })
                    })
                } else {
                    reject(err)
                }
            })
        })
    }


    /**
    * Makes a request and handles token expiration.
    * @param {object} config - Axios request configuration.
    * @returns {Promise<import('axios').AxiosResponse>} Axios response object.
    * @throws Will throw an error if the request fails.
    */
    async request2(config) {
        try {
            const response = await axios(config);
            return response;
        } catch (err) {
            if (err.response && (err.response.status === 401 || err.response.status === 404)) {
                try {
                    const refreshToken = SafeStorage.getItem('refreshToken');
                    const resp = await axios({
                        method: 'post',
                        url: `${BASE_URL}/refresh-token`,
                        headers: {
                            "Content-Type": 'application/json',
                            Accept: 'application/json',
                            Authorization: `Bearer ${refreshToken}`
                        }
                    });
                    const token = resp.data.token;
                    config.headers.Authorization = `Bearer ${token}`;
                    SafeStorage.setItem("token", token);
                    const finalResponse = await axios(config); // Burada isteği tekrar deneyip sonucunu bekliyoruz
                    return finalResponse; // ve sonucu döndürüyoruz
                } catch (e) {
                    if (e.response && (e.response.status === 401 || e.response.status === 404)) {
                        throw new Error("expired credentials");
                    } else {
                        throw e;
                    }
                }
            } else {
                throw err;
            }
        }
    }

    register(data) {
        const config = {
            method: "post",
            url: `${BASE_URL}/register`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            data: JSON.stringify(data)
        }

        return axios(config)
    }

    confirmEmail(company, verificationCode) {
        const config = {
            method: 'post',
            url: `${BASE_URL}/register/confirmation`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Verification-Code': verificationCode,
                'Authorization': 'Bearer BUjX?rW!FX$TRA3a5n7+umd!2c&&^-SPFK7ERUrRRF#NMBJDWPk?HGySsCXM@vPC'
            },
            data: JSON.stringify({ company_id: company })
        }
        return axios(config)
    }

    updateRegister(data) {
        const config = {
            method: "patch",
            url: `${BASE_URL}/register`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            data: JSON.stringify(data)
        }

        return axios(config)
    }

    login(userName, password) {
        const data = JSON.stringify({
            "user_name": userName,
            "password": password,
        });
        const config = {
            method: 'post',
            url: `${BASE_URL}/login`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            data: data
        }
        return axios(config)
    }

    logout() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'post',
            url: `${BASE_URL}/logout`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        return axios(config)
    }

    refreshToken(refreshToken) {
        const config = {
            method: 'post',
            url: `${BASE_URL}/refresh-token`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + refreshToken
            }
        }
        return axios(config)
    }


    validateToken(token) {
        const config = {
            method: 'get',
            url: `${BASE_URL}/login`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    startRefreshPasswordSMSFlow(phone, areaCode) {
        const data = JSON.stringify({
            "phone": phone,
            "area_code": areaCode
        });
        const config = {
            method: 'post',
            url: `${BASE_URL}/validate-phone`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer BUjX?rW!FX$TRA3a5n7+umd!2c&&^-SPFK7ERUrRRF#NMBJDWPk?HGySsCXM@vPC'
            },
            data: data
        }
        return axios(config)
    }

    resetPassword(verificationCode, secretKey, password, passwordAgain) {
        const data = JSON.stringify({
            password: password,
            password_again: passwordAgain
        })
        const config = {
            method: 'post',
            url: `${BASE_URL}/reset-password`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Verification-Code': verificationCode,
                'Authorization': 'Bearer BUjX?rW!FX$TRA3a5n7+umd!2c&&^-SPFK7ERUrRRF#NMBJDWPk?HGySsCXM@vPC',
                'Secret-Key': secretKey
            },
            data: data
        }
        return axios(config)
    }

    validateVerificationCode(verificationCode) {
        const config = {
            method: 'post',
            url: `${BASE_URL}/validate-otp`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Verification-Code': verificationCode,
                'Authorization': 'Bearer BUjX?rW!FX$TRA3a5n7+umd!2c&&^-SPFK7ERUrRRF#NMBJDWPk?HGySsCXM@vPC'
            }
        }
        return axios(config)
    }



    getUsers() {
        let token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/users`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    getUsers2() {
        let token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/users`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return this.request2(config)
    }


    getUserDetails(userId) {
        let token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/users/details/${userId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }

        return axios(config)
    }


    createUser(userInfo) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(userInfo)
        let config = {
            method: "put",
            url: `${BASE_URL}/users`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }


    updateUser(userId, fields) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(fields)
        let config = {
            method: "patch",
            url: `${BASE_URL}/users/${userId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    deleteUser(userId) {
        let token = SafeStorage.getItem("token")
        let config = {
            method: "delete",
            url: `${BASE_URL}/users/${userId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }
        return axios(config)
    }


    deleteUsers(userIds) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify({
            user_ids: userIds
        })
        let config = {
            method: "delete",
            url: `${BASE_URL}/users`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    getCompanies() {
        let token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/companies`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    getCompanies2() {
        let token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/companies`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return this.request2(config)
    }

    getCompanyDetails(id) {
        let token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/companies/details/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }

        return axios(config)
    }

    deleteCompanies(companyIds) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify({
            company_ids: companyIds
        })
        let config = {
            method: "delete",
            url: `${BASE_URL}/companies`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }


    createCompany(companyInfo) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(companyInfo)
        let config = {
            method: "put",
            url: `${BASE_URL}/companies`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }


    updateCompany(companyId, fields) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(fields)
        let config = {
            method: "patch",
            url: `${BASE_URL}/companies/${companyId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }



    getUserRoles() {
        let token = SafeStorage.getItem("token")
        let config = {
            method: "get",
            url: `${BASE_URL}/role-privileges`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    deleteUserRoles(ids) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify({
            role_ids: ids
        })
        let config = {
            method: "delete",
            url: `${BASE_URL}/user-roles`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    createUserRole(info) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify(info)
        let config = {
            method: "put",
            url: `${BASE_URL}/user-roles`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    getUserRoleRelationsDetailed() {
        let token = SafeStorage.getItem("token")
        let config = {
            method: "get",
            url: `${BASE_URL}/user-role-rel/details`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }
        return axios(config)
    }

    creataUserRoleRelation(info) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(info)
        const config = {
            method: "put",
            url: `${BASE_URL}/user-role-rel`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }



    deleteUserRoleRelations(info) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify({ data: info })
        const config = {
            method: "delete",
            url: `${BASE_URL}/user-role-rel/batch`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }


    updateUserRole(id, info) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify(info)
        let config = {
            method: "patch",
            url: `${BASE_URL}/user-roles/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    createUserRolePrivileges(info) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify(info)
        let config = {
            method: "put",
            url: `${BASE_URL}/role-privileges`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }


    updateUserRolePrivileges(info) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify(info)
        let config = {
            method: "patch",
            url: `${BASE_URL}/role-privileges`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    getPrivileges() {
        let token = SafeStorage.getItem("token")
        let config = {
            method: "get",
            url: `${BASE_URL}/privileges`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }


    getPresignedURL(fileName, fileType) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify({
            file_name: fileName,
            file_type: fileType
        })
        let config = {
            method: "post",
            url: `${BASE_URL}/presigned-url`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }


    uploadFile(file) {
        let token = SafeStorage.getItem("token")
        let data = new FormData()
        data.append("file", file)
        const config = {
            method: "post",
            url: `${BASE_URL}/upload-file`,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }


    getBuildings() {
        let token = SafeStorage.getItem("token")
        let config = {
            method: "get",
            url: `${BASE_URL}/buildings`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    createBuilding(buildingInfo) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(buildingInfo)
        let config = {
            method: "put",
            url: `${BASE_URL}/buildings`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }


    updateBuilding(buildingId, fields) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(fields)
        let config = {
            method: "patch",
            url: `${BASE_URL}/buildings/${buildingId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    deleteBuildings(ids) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify({
            building_ids: ids
        })
        let config = {
            method: "delete",
            url: `${BASE_URL}/buildings`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }


    getGates() {
        let token = SafeStorage.getItem("token")
        let config = {
            method: "get",
            url: `${BASE_URL}/gates`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }


    getGateUsers(gateId) {
        return this.request2({
            method: "get",
            url: `${BASE_URL}/gates/${gateId}/users`,
            headers: this.headers(SafeStorage.getItem("token"))
        })
    }

    getGateDetails(gateId) {
        let token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/gates/details/${gateId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }

        return axios(config)
    }

    createGate(gateInfo) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(gateInfo)
        let config = {
            method: "put",
            url: `${BASE_URL}/gates`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    updateGate(gateId, fields) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(fields)
        let config = {
            method: "patch",
            url: `${BASE_URL}/gates/${gateId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    deleteGates(ids) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify({
            gate_ids: ids
        })
        let config = {
            method: "delete",
            url: `${BASE_URL}/gates`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }



    getBranches() {
        let token = SafeStorage.getItem("token")
        let config = {
            method: "get",
            url: `${BASE_URL}/branches`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }


    queryBranch(query) {
        let token = SafeStorage.getItem("token")
        let config = {
            method: "post",
            url: `${BASE_URL}/branches/query`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: JSON.stringify({ query })
        }
        return axios(config)
    }

    getBranchDetails(branchId) {
        let token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/branches/details/${branchId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }

        return axios(config)
    }

    createBranch(branchInfo) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(branchInfo)
        let config = {
            method: "put",
            url: `${BASE_URL}/branches`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    updateBranch(branchId, fields) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(fields)
        let config = {
            method: "patch",
            url: `${BASE_URL}/branches/${branchId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    deleteBranches(ids) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify({
            branch_ids: ids
        })
        let config = {
            method: "delete",
            url: `${BASE_URL}/branches`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    getAccessPoints() {
        let token = SafeStorage.getItem("token")
        let config = {
            method: "get",
            url: `${BASE_URL}/access-points`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    getAccessPointDetails(id) {
        let token = SafeStorage.getItem("token")
        let config = {
            method: "get",
            url: `${BASE_URL}/access-points/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    createAccessPoint(info) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(info)
        let config = {
            method: "put",
            url: `${BASE_URL}/access-points`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    updateAccessPoint(id, fields) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(fields)
        let config = {
            method: "patch",
            url: `${BASE_URL}/access-points/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    deleteAccessPoints(ids) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify({
            access_point_ids: ids
        })
        let config = {
            method: "delete",
            url: `${BASE_URL}/access-points`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    getUserAccessPrivs() {
        let token = SafeStorage.getItem("token")
        let config = {
            method: 'get',
            url: `${BASE_URL}/access-privs`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    createUserAccessPriv(info) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify(info)
        let config = {
            method: 'put',
            url: `${BASE_URL}/access-privs`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    createMultipleUserAccessPrivs(data) {
        return this.request2({
            method: 'post',
            url: `${BASE_URL}/gate-privs/batch`,
            headers: this.headers(SafeStorage.getItem("token")),
            data: JSON.stringify(data)
        })
    }

    deleteAccessPrivsByGate(info) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify({ data: info })

        let config = {
            method: 'delete',
            url: `${BASE_URL}/gate-privs/batch`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    createAccessPrivsByGate(info) {
        let token = SafeStorage.getItem("token")
        let data = JSON.stringify(info)

        let config = {
            method: 'put',
            url: `${BASE_URL}/gate-privs`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }


    getUserActivities() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/user-activities`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    getUserActivitiesByUser(userId) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/user-activities/user/${userId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    deleteUserActivities(activityIds) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify({ user_activity_ids: activityIds })
        const config = {
            method: 'delete',
            url: `${BASE_URL}/user-activities/batch`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    createUserActivity(info) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(info)
        const config = {
            method: 'put',
            url: `${BASE_URL}/user-activities`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    getActivityStats(range, interval) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify({ range_type: range, interval: interval })
        const config = {
            method: 'post',
            url: `${BASE_URL}/activity-stats`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: data
        }
        return axios(config)
    }

    getApproachingBirthdays() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/approaching-birthdays`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    getGateActivity() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/activity-stats`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    getRecentActivity() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/recent-activities`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    getSummary() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/summary`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    getActivitiesOfUser(userId) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/user-activities/user/${userId}`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }

    getAnnouncements(id) {
        const token = SafeStorage.getItem("token")
        const url = id ? `${BASE_URL}/announcements/${id}` : `${BASE_URL}/announcements`
        const config = {
            method: 'get',
            url: url,
            headers: this.headers(token)
        }
        return this.request(config)
    }

    createAnnouncement(info) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(info)
        const config = {
            method: "put",
            url: `${BASE_URL}/announcements`,
            headers: this.headers(token),
            data: data
        }
        return this.request(config)
    }


    deleteAnnouncements(ids) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify({ announcement_ids: ids })
        const config = {
            method: "delete",
            url: `${BASE_URL}/announcements`,
            headers: this.headers(token),
            data: data
        }
        return this.request(config)
    }

    updateAnnouncement(id, fields) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(fields)
        const config = {
            method: "patch",
            url: `${BASE_URL}/announcements/${id}`,
            headers: this.headers(token),
            data: data
        }
        return this.request(config)
    }

    publishAnnouncement(announcementObject) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(announcementObject)
        const config = {
            method: "post",
            url: `${BASE_URL}/announcements/relation`,
            headers: this.headers(token),
            data: data
        }
        return this.request2(config)
    }
    


    getFeedbacks() {
        let token = SafeStorage.getItem("token")
        let config = {
            method: 'get',
            url: `${BASE_URL}/feedbacks`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }


    deleteFeedbacks(ids) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify({ feedback_ids: ids })
        const config = {
            method: "delete",
            url: `${BASE_URL}/feedbacks`,
            headers: this.headers(token),
            data: data
        }
        return this.request(config)
    }



    getComplaints() {
        let token = SafeStorage.getItem("token")
        let config = {
            method: 'get',
            url: `${BASE_URL}/complaints`,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(config)
    }


    deleteComplaints(ids) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify({ complaint_ids: ids })
        const config = {
            method: "delete",
            url: `${BASE_URL}/complaints`,
            headers: this.headers(token),
            data: data
        }
        return this.request(config)
    }


    getLatecomers(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'post',
            url: `${BASE_URL}/pacs/late-comers`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return this.request2(config)
    }

    getEarlyLeavers(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'post',
            url: `${BASE_URL}/pacs/early-leavers`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return this.request2(config)
    }

    getAttendance(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'post',
            url: `${BASE_URL}/pacs/attendance`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return this.request2(config)
    }


    getJobTitles() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/job-titles`,
            headers: this.headers(token)
        }
        return this.request(config)
    }

    createJobTitle(info) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(info)
        const config = {
            method: "put",
            url: `${BASE_URL}/job-titles`,
            headers: this.headers(token),
            data: data
        }
        return this.request(config)
    }

    updateJobTitle(id, fields) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(fields)
        const config = {
            method: "patch",
            url: `${BASE_URL}/job-titles/${id}`,
            headers: this.headers(token),
            data: data
        }
        return this.request(config)
    }

    deleteTitles(ids) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify({ title_ids: ids })
        const config = {
            method: "delete",
            url: `${BASE_URL}/job-titles/batch`,
            headers: this.headers(token),
            data: data
        }
        return this.request(config)
    }


    getUserGroups() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/user-groups`,
            headers: this.headers(token)
        }
        return this.request(config)
    }

    getUserGroups2() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: 'get',
            url: `${BASE_URL}/user-groups`,
            headers: this.headers(token)
        }
        return this.request2(config)
    }

    createUserGroup(info) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(info)
        const config = {
            method: "put",
            url: `${BASE_URL}/user-groups`,
            headers: this.headers(token),
            data: data
        }
        return this.request(config)
    }

    updateUserGroup(id, fields) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(fields)
        const config = {
            method: "patch",
            url: `${BASE_URL}/user-groups/${id}`,
            headers: this.headers(token),
            data: data
        }
        return this.request(config)
    }

    deleteUserGroups(ids) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify({ user_group_ids: ids })
        const config = {
            method: "delete",
            url: `${BASE_URL}/user-groups`,
            headers: this.headers(token),
            data: data
        }
        return axios(config)
    }

    getSurveys() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/surveys`,
            headers: this.headers(token),
        }
        return axios(config)
    }


    createSurvey(survey) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(survey)
        const config = {
            method: "put",
            url: `${BASE_URL}/surveys`,
            headers: this.headers(token),
            data: data
        }
        return axios(config)
    }

    updateSurvey(survey) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(survey)
        const config = {
            method: "patch",
            url: `${BASE_URL}/surveys`,
            headers: this.headers(token),
            data: data
        }
        return axios(config)
    }

    deleteSurveys(ids) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify({ survey_ids: ids })
        const config = {
            method: "delete",
            url: `${BASE_URL}/surveys`,
            headers: this.headers(token),
            data: data
        }
        return axios(config)
    }

    getSurveyStats(surveyId) {
        const token = SafeStorage.getItem("token")

        const config = {
            method: "get",
            url: `${BASE_URL}/surveys/stats/${surveyId}`,
            headers: this.headers(token),
        }
        return axios(config)
    }




    getQuizzes() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/quizzes`,
            headers: this.headers(token),
        }
        return axios(config)
    }


    createQuiz(quiz) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(quiz)
        const config = {
            method: "put",
            url: `${BASE_URL}/quizzes`,
            headers: this.headers(token),
            data: data
        }
        return axios(config)
    }

    updateQuiz(quiz) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(quiz)
        const config = {
            method: "patch",
            url: `${BASE_URL}/quizzes`,
            headers: this.headers(token),
            data: data
        }
        return axios(config)
    }

    deleteQuizzes(ids) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify({ quiz_ids: ids })
        const config = {
            method: "delete",
            url: `${BASE_URL}/quizzes`,
            headers: this.headers(token),
            data: data
        }
        return axios(config)
    }

    getQuizStats(quizId) {
        const token = SafeStorage.getItem("token")

        const config = {
            method: "get",
            url: `${BASE_URL}/quizzes/stats/${quizId}`,
            headers: this.headers(token),
        }
        return axios(config)
    }


    getFixedShifts() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/shifts/fixed-shifts`,
            headers: this.headers(token),
        }
        return this.request2(config)
    }

    getShifts() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/shifts`,
            headers: this.headers(token),
        }
        return axios(config)
    }

    deleteShifts(ids) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify({ shift_ids: ids })
        const config = {
            method: "delete",
            url: `${BASE_URL}/shifts`,
            headers: this.headers(token),
            data: data
        }
        return axios(config)
    }


    createShift(shift) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(shift)
        const config = {
            method: "put",
            url: `${BASE_URL}/shifts`,
            headers: this.headers(token),
            data: data
        }
        return axios(config)
    }

    updateShift(shiftId, shift) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(shift)
        const config = {
            method: "patch",
            url: `${BASE_URL}/shifts/${shiftId}`,
            headers: this.headers(token),
            data: data
        }
        return axios(config)
    }


    getSupervisorsBranches() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/shifts/supervisors/branches`,
            headers: this.headers(token)
        }
        return axios(config)
    }

    getBranchUsers(branchId) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/branches/${branchId}/users`,
            headers: this.headers(token)
        }
        return axios(config)
    }



    assignUsersToBranch(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "put",
            url: `${BASE_URL}/branches/users`,
            headers: this.headers(token),
            data: JSON.stringify({ data })
        }
        return axios(config)
    }

    removeUsersFromBranch(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "delete",
            url: `${BASE_URL}/branches/users`,
            headers: this.headers(token),
            data: JSON.stringify({ data })
        }
        return axios(config)
    }

    queryShiftAssignments(startDate, endDate, branchId) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify({
            start_date: startDate,
            end_date: endDate,
            branch: branchId
        })
        const config = {
            method: "post",
            url: `${BASE_URL}/shift-assignments/query`,
            headers: this.headers(token),
            data: data
        }
        return axios(config)
    }


    getShiftSupervisors() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/shifts/supervisors`,
            headers: this.headers(token),
        }
        return axios(config)
    }

    removeSupervisorAssignments(assignments) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "delete",
            url: `${BASE_URL}/shifts/supervisors`,
            headers: this.headers(token),
            data: JSON.stringify({ data: assignments })
        }
        return axios(config)
    }


    removeUserShiftOnDay(shiftId, userId, shiftDay) {
        const token = SafeStorage.getItem("token")
        const data = {
            shift_id: shiftId,
            user_id: userId,
            shift_day: shiftDay
        }
        const config = {
            method: "delete",
            url: `${BASE_URL}/shift-assignments/query`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return axios(config)
    }

    assignShiftToUserOnDay(shiftId, userId, shiftDay) {
        const token = SafeStorage.getItem("token")
        const data = {
            shift_id: shiftId,
            user_id: userId,
            shift_day: shiftDay
        }
        const config = {
            method: "put",
            url: `${BASE_URL}/shift-assignments`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return axios(config)
    }

    assignFixedShift(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "post",
            url: `${BASE_URL}/shift-assignments/fixed`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return this.request2(config)
    }

    assginShiftSupervisor(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "put",
            url: `${BASE_URL}/shifts/supervisors`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return axios(config)
    }


    getCompanyPrivileges() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/company-privileges`,
            headers: this.headers(token)
        }
        return this.request(config)
    }

    deleteCompanyPrivileges(ids) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "delete",
            url: `${BASE_URL}/company-privileges`,
            headers: this.headers(token),
            data: JSON.stringify({ company_privilege_ids: ids })
        }
        return this.request(config)
    }

    createCompanyPrivilege(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "put",
            url: `${BASE_URL}/company-privileges`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return this.request(config)
    }

    getAppModules() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/app-modules`,
            headers: this.headers(token),
        }
        return axios(config)
    }

    getCompanyModules(company) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: company ? `${BASE_URL}/company-privileges/by-module/company/${company}` : `${BASE_URL}/company-privileges/by-module`,
            headers: this.headers(token),
        }
        return axios(config)
    }


    assignModuleToCompany(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "put",
            url: `${BASE_URL}/company-privileges/by-module`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return axios(config)
    }


    revokeModuleFromCompany(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "delete",
            url: `${BASE_URL}/company-privileges/by-module`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return axios(config)
    }

    addUserToGroup(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "put",
            url: `${BASE_URL}/user-group-rel`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return axios(config)
    }

    addUsersToGroup(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "put",
            url: `${BASE_URL}/user-group-rel/batch`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return axios(config)
    }

    removeUserFromGroup(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "delete",
            url: `${BASE_URL}/user-group-rel/batch`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return axios(config)
    }

    getGroupUsers(groupId) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/users/by-group/${groupId}`,
            headers: this.headers(token)
        }
        return axios(config)
    }

    getOrganizationChart(companyId) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/organization/${companyId}`,
            headers: this.headers(token)
        }
        return axios(config)
    }


    getDepartments() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/departments`,
            headers: this.headers(token),
        }
        return axios(config)
    }

    createDepartment(department) {
        const token = SafeStorage.getItem("token")
        const data = JSON.stringify(department)
        const config = {
            method: "put",
            url: `${BASE_URL}/departments`,
            headers: this.headers(token),
            data: data
        }
        return axios(config)
    }

    deleteDepartments(ids) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "delete",
            url: `${BASE_URL}/departments`,
            headers: this.headers(token),
            data: JSON.stringify({ department_ids: ids })
        }
        return this.request(config)
    }

    updateDepartment(id, fields) {
        let token = SafeStorage.getItem("token")
        const data = JSON.stringify(fields)
        let config = {
            method: "patch",
            url: `${BASE_URL}/departments/${id}`,
            headers: this.headers(token),
            data: fields
        }
        return axios(config)
    }

    getDepartmentUsers(departmentId) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/departments/${departmentId}/users`,
            headers: this.headers(token),
        }
        return axios(config)
    }

    deleteDepartmentUsers(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "delete",
            url: `${BASE_URL}/departments/user-relations`,
            headers: this.headers(token),
            data: JSON.stringify({ data })
        }
        return axios(config)
    }


    createDepartmentUserRelations(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "put",
            url: `${BASE_URL}/departments/user-relations`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return axios(config)
    }

    getUsersOfCompany(companyId) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/companies/${companyId}/users`,
            headers: this.headers(token),
        }
        return axios(config)
    }

    getLeaveRecords() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/leave`,
            headers: this.headers(token),
        }
        return this.request2(config)
    }

    updateLeaveRecord(id, data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "patch",
            url: `${BASE_URL}/leave/${id}`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return this.request2(config)
    }

    createLeaveRecord(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "post",
            url: `${BASE_URL}/leave`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return this.request2(config)
    }

    getLeaveTypes() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/leave-types`,
            headers: this.headers(token)
        }
        return this.request2(config)
    }


    getWorkPlans() {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "get",
            url: `${BASE_URL}/work-plans`,
            headers: this.headers(token)
        }
        return this.request2(config)
    }

    cereateWorkPlan(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "post",
            url: `${BASE_URL}/work-plans`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return this.request2(config)
    }

    updateWorkPlan(id, data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "patch",
            url: `${BASE_URL}/work-plans/${id}`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return this.request2(config)
    }

    deleteWorkPlans(ids) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "delete",
            url: `${BASE_URL}/work-plans`,
            headers: this.headers(token),
            data: JSON.stringify({ work_plan_ids: ids })
        }
        return this.request2(config)
    }


    assignWorkPlan(data) {
        const token = SafeStorage.getItem("token")
        const config = {
            method: "post",
            url: `${BASE_URL}/work-plans/assignment`,
            headers: this.headers(token),
            data: JSON.stringify(data)
        }
        return this.request2(config)
    }

}


export default new API()