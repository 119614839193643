import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';
import { forwardRef, memo, useState } from "react";
import globalStyles from '../static/GlobalStyles';
import { Button } from '@mui/material';
import { DialogActions, DialogTitle, DialogContent, TextField } from '@mui/material';
import FlexButton from './FlexButton';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const MessageDialog = ({
    onSubmit = message => message,
    onCancel = () => null,
    title = "",
    open,
    onClose = () => null,
    labels = { ok: "OK", cancel: "Cancel" },
    maxMessageLength = 0
}) => {


    const [cursorOutside, setCursorOutside] = useState(true)
    const [message, setMessage] = useState("")



    const handleValueChanged = (e) => {
        if (maxMessageLength) {
            setMessage(e.target.value.slice(0, maxMessageLength))
        } else {
            setMessage(e.target.value)
        }
    }

    return (
        <div >
            <Dialog
                /*  onClick={() => {
                     if (cursorOutside) {
                         onCancel()
                     }
                 }} */

                open={open}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                onClose={onClose}
            >
                <DialogTitle>
                    <Title>
                        {title}
                    </Title>
                </DialogTitle>
                <DialogContent
                    sx={{ maxHeight: "400px", minWidth: '400px' }}
                    onMouseEnter={() => setCursorOutside(false)}
                    onMouseLeave={() => setCursorOutside(true)}
                >

                    <TextField
                        multiline
                        value={message}
                        onChange={e => handleValueChanged(e)}
                        fullWidth
                        autoFocus
                        rows={5}
                        InputProps={{
                            style: {
                                fontFamily: globalStyles.font,
                                fontSize: "16px",
                                color: globalStyles.textColor,
                            }
                        }}
                    />

                    {maxMessageLength &&
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end', paddingTop: "4px" }}>
                            <MaxLength>
                                {`${message.length}/${maxMessageLength}`}
                            </MaxLength>
                        </div>

                    }


                </DialogContent>
                <DialogActions>
                    <FlexButton
                        text={labels.cancel}
                        onClick={() => {
                            onCancel()
                            setMessage("")
                        }}
                        style={{
                            backgroundColor: globalStyles.secondColor,
                            ':hover': {
                                backgroundColor: "#08080a",
                            },
                            marginRight: 0
                        }}
                    />
                    <FlexButton
                        text={labels.ok}
                        onClick={() => {
                            onSubmit(message)
                            setMessage("")
                        }}
                        renderIcon={() => <CheckRoundedIcon fontSize='20px' />}
                    />

                </DialogActions>
            </Dialog>
        </div>
    )


}

export default MessageDialog;



const Title = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 16px;
    font-weight: bold;
`

const MaxLength = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 14px;
`

