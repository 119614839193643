import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../context/Context';
import API from '../../../static/API';
import t from '../../../static/Language';
import SafeStorage from '../../../static/SafeStorage';
import 'react-modern-drawer/dist/index.css'
import DataTable from '../../../components/DataTable';
import globalStyles from '../../../static/GlobalStyles';

import { toast, ToastContainer } from "react-toastify"
import DataSection from '../../../components/DataSection';
import Container from '../../../components/Container';



const FeedbacksPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const privileges = JSON.parse(SafeStorage.getItem("user")).privileges

    const [data, setData] = useState([])

    const [actionOptions, setActionOptions] = useState()

    const dateOptions = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', second: '2-digit' }


    useEffect(() => {
        document.title = t(lang, "feedbacksPage.title")
        prepareActionOptions()
        get()
    }, [])


    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteFeedbacks")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([row.id])
                }
            }
        }
        setActionOptions(_actionOptions)
    }








    const get = () => {
        API.getFeedbacks().then(response => { // cevap gelirse feedbackleri atıyoruz
            setData(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getFeedbacks().then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const _delete = (ids) => {
        API.deleteFeedbacks(ids).then(response => {
            toast(t(lang, "feedbacksPage.deleted"))
            get()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteFeedbacks(ids).then(resp => {
                        toast(t(lang, "feedbacksPage.deleted"))
                        get()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "feedbacksPage.deleteError"))
                    }).catch(e => {
                        if (e.response.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }





    const columns = [
       
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }

        },
        {
            name: "title",
            label: t(lang, "fields.title"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "description",
            label: t(lang, "fields.description"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "created_at",
            label: t(lang, "fields.creationTime"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <text>{new Date(value).toLocaleString(lang, dateOptions)}</text>
                    )
                },

                customFilterListOptions: {
                    render: v => {
                        return new Date(v).toLocaleString(lang, dateOptions)
                    },
                    update: (filterList, filterPos, index) => {
                        console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    renderValue: val => {
                        return new Date(val).toLocaleString(lang, dateOptions)
                    },

                }

            }
        },

    ]







    const handleMultipleDelete = (rows) => {
        let data = []
        rows.forEach(row => {
            data.push(row.id)
        });
        _delete(data)
    }




    return (

        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <DataTable
                    columns={columns}
                    data={data}
                    actionOptions={actionOptions}
                    onRowsDelete={(rows, ids) => handleMultipleDelete(rows)}
                    title={t(lang, "feedbacksPage.title")}
                    canCreateNewRecord={false}
                />


            </DataSection>
        </Container>
    )

}


export default FeedbacksPage;

