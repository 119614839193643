import { useContext, useEffect, useState } from "react";
import API from "../../../../static/API";
import { Context } from "../../../../context/Context";
import SafeStorage from "../../../../static/SafeStorage";
import { Box, Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import globalStyles from "../../../../static/GlobalStyles";
import t from "../../../../static/Language";
import Select, { createFilter } from "react-select";
import FlexButton from "../../../../components/FlexButton";
import styled from "styled-components";
import Loading from "../../../../states/Loading";
import Utils from '../../../../static/Utils';




const AssignWorkPlanPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const user = JSON.parse(SafeStorage.getItem("user"))


    const [userGroups, setUserGroups] = useState([])
    const [users, setUsers] = useState([])
    const [inUsers, setInUsers] = useState([])
    const [inGroups, setInGroups] = useState([])
    const [workPlans, setWorkPlans] = useState([])
    const [selectedWorkPlan, setSelectedWorkPlan] = useState()
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [breaks, setBreaks] = useState([])
    const [objInfo, setObjInfo] = useState({ is_fixed: true, company: user.company })
    const [color, setColor] = useState(Utils.randomColor())
    const [companies, setCompanies] = useState([])

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        document.title = t(lang, "menuNames.assignWorkPlan")
        const getAll = async () => {
            try {
                await getWorkPlans()
                await getUserGroups()
                await getUsers()
                if (Utils.isSystemAdmin()) {
                    await getCompanies()
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        getAll()
    }, [])


    const logout = () => {
        SafeStorage.removeItem("token")
        SafeStorage.removeItem("refreshToken")
        dispatch({ type: 'LOGIN', login: false })
    }


    const getCompanies = async () => {
        try {
            const response = await API.getCompanies2()
            setCompanies(response.data.data)
        } catch (e) {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }
    }


    const getWorkPlans = async () => {
        try {
            const response = await API.getWorkPlans()
            setWorkPlans(response.data.data)
        } catch (e) {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }
    }

    

    const getUserGroups = async () => {
        try {
            const response = await API.getUserGroups2()
            setUserGroups(response.data.data)
        } catch (e) {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }

    }


    const getUsers = async () => {
        try {
            const response = await API.getUsers2()
            setUsers(response.data.data)
        } catch (e) {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }
    }

   

    const assignWorkPlan = () => {

        const assignment = {
            work_plan_id: selectedWorkPlan.id,
            user_groups: inGroups.map(g => g.id),
            users: inUsers.map(u => u.id)
        }

        API.assignWorkPlan(assignment).then(response => {
            toast(t(lang, "shiftsPage.assignedFixedShift"))
        }).catch(e => {
            if (e.message === "invalid credentials") {
                logout()
            } else {
                console.log(e)
            }
        })
    }

    const filter = createFilter({ ignoreCase: true })


    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <Grid container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <Grid item flexDirection={'column'} display={'flex'} padding={'15px'} xs={12} md={6} justifyContent={'flex-start'}>
                <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                    <Title>{t(lang, "workPlanPage.title")}</Title>
                </div>
                <Select
                    options={workPlans}
                    getOptionValue={o => o.id}
                    getOptionLabel={o => `${o.name}`}
                    isSearchable
                    isClearable
                    closeMenuOnSelect={true}
                    placeholder={t(lang, "workPlanPage.title")}
                    filterOption={filter}
                    noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                    onChange={(value) => setSelectedWorkPlan(value)}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                            marginBottom: '15px'
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                        }),
                    }}
                />
            </Grid>

            <Grid item flexDirection={'column'} display={'flex'} padding={'15px'} xs={12} md={6} justifyContent={'flex-start'}>
                <div style={{ alignItems: 'stretch', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                    <Title>{t(lang, "surveysPage.included")}</Title>
                </div>
                <Select
                    options={userGroups}
                    defaultValue={inGroups}
                    getOptionValue={o => o.id}
                    getOptionLabel={o => o.name}
                    isMulti
                    isSearchable
                    isClearable
                    closeMenuOnSelect={false}
                    placeholder={t(lang, "surveysPage.toGroup")}
                    filterOption={filter}
                    noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                    onChange={(value) => setInGroups(value)}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                            marginBottom: '15px'
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                        }),
                    }}
                />
                <Select
                    options={users}
                    defaultValue={inUsers}
                    getOptionValue={o => o.id}
                    getOptionLabel={o => `${o.name} ${o.last_name}`}
                    isMulti
                    isSearchable
                    isClearable
                    closeMenuOnSelect={false}
                    placeholder={t(lang, "surveysPage.toUser")}
                    filterOption={filter}
                    noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                    onChange={(value) => setInUsers(value)}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                            marginBottom: '15px'
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                        }),
                    }}
                />
            </Grid >
            <Box sx={{ justifyContent: 'flex-end', display: 'flex', flex: 1 }}>
                <FlexButton
                    text={t(lang, "shiftsPage.assign")}
                    onClick={assignWorkPlan}
                    disabled={selectedWorkPlan && (inGroups.length > 0 || inUsers.length > 0) ? false : true}
                />
            </Box>
        </Grid>
    )
}

export default AssignWorkPlanPage;

const Title = styled.span`
    font-family: ${globalStyles.font};
    font-size: 18px;
    color: ${globalStyles.textColor};
    font-weight: bold;
    margin-bottom: 15px
`
