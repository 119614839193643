import { useContext } from "react"
import { Context } from "../../../../context/Context"
import Nodata from "../../../../states/Nodata"
import t from "../../../../static/Language"
import DataTable from "../../../../components/DataTable"
import Container from "../../../../components/Container"




const UserPrivsTab = ({ userPrivs }) => {
    const { state } = useContext(Context)
    const lang = state.appLang



    if (!userPrivs || userPrivs?.length === 0) {
        return (
            <Nodata msg={t(lang, "common.noData")} />
        )
    }


    const columns = [
        {
            name: "description",
            label: t(lang, "fields.description"),
            options: {
                filter: true,
                sort: true,
                draggable: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <span>{t(lang, `privileges.${userPrivs[rowIndex]?.name}`)}</span>
                    )
                }

            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }
        },

    ]


    return (
        <Container>
            <DataTable
                columns={columns}
                data={userPrivs}
            />
        </Container>
    )
}

export default UserPrivsTab

