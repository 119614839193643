import { useContext, useEffect, useState } from "react";
import { Context } from "../../../../context/Context";
import SafeStorage from "../../../../static/SafeStorage";
import API from "../../../../static/API";
import styled from "styled-components";
import DataTable from "../../../../components/DataTable";
import globalStyles from "../../../../static/GlobalStyles";
import { ToastContainer, toast } from "react-toastify";
import FormSelectInput from "../../../../components/FormSelectInput";
import t from "../../../../static/Language";
import DataSection from "../../../../components/DataSection";
import Container from "../../../../components/Container";
import NewRecordDrawer from "../../../../components/NewRecordDrawer";



const CompanyPrivilegesPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const privileges = JSON.parse(SafeStorage.getItem("user")).privileges

    const [data, setData] = useState([])
    const [companies, setCompanies] = useState([])
    const [actionOptions, setActionOptions] = useState()
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [objInfo, setObjInfo] = useState({})
    const [appModules, setAppModules] = useState([])




    useEffect(() => {
        document.title = t(lang, "companyPrivilegesPage.title")
        prepareActionOptions()
        get()
        getOthers()
    }, [])


    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete({ company: row.company_id, modules: [row.module_id] })
                }
            }
        }
        setActionOptions(_actionOptions)
    }

    const getOthers = () => {
        getCompanies()
        getModules()
    }


    const getModules = () => {
        API.getAppModules().then(response => { // cevap gelirse şirketleri atıyoruz

            setAppModules(response.data.data)
        }).catch(err => { // hata alınırsa
            console.log(err)
        })
    }

    const getCompanies = () => {
        API.getCompanies().then(response => { // cevap gelirse şirketleri atıyoruz
            setCompanies(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getCompanies().then(resp => { // şirketleri tekrar çekiyoruz
                        setCompanies(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }





    const get = () => {
        API.getCompanyModules().then(response => { // cevap gelirse feedbackleri atıyoruz
            setData(response.data.data)
            console.log(response.data.data)
        }).catch(err => {
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getCompanyModules().then(resp => { // userları tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const create = () => {
        API.assignModuleToCompany(objInfo).then(response => {
            get()
            toast(t(lang, "companyPrivilegesPage.created"))
        }).catch(err => { // hata alınırsa
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.API.assignModuleToCompany(objInfo).then(resp => { // userları tekrar çekiyoruz
                        get()
                        toast(t(lang, "companyPrivilegesPage.created"))
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const _delete = (data) => {
        API.revokeModuleFromCompany(data).then(() => {
            toast(t(lang, "companyPrivilegesPage.deleted"))
            get()
        }).catch(err => {
            if (err.response?.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.revokeModuleFromCompany(data).then(resp => {
                        toast(t(lang, "companyPrivilegesPage.deleted"))
                        get()
                    }).catch(er => console.log(er))
                }).catch(e => {
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }




    const columns = [
        {
            name: "company_name",
            label: t(lang, "fields.company"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "name",
            label: t(lang, "fields.module"),
            options: {
                filter: true,
                sort: false
            }
        },
        /* {
            name: "is_default",
            label: t(lang, "fields.isDefault"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <BeasyChechbox
                            readOnly={true}
                            defaultChecked={value}
                            size={"large"}
                        />
                    )
                }
            }
        } */
    ]


    const handleMultipleDelete = (rows) => {
        const uniquCompanies = [...new Set(rows.map(r => r.company_id))];
        uniquCompanies.forEach(c => {
            let modules = rows.filter(r => r.company_id === c).map(r => r.module_id)
            console.log("modules", modules)
            _delete({ company: c, modules: modules })
        })

    }


    const validate = () => {
        console.log(objInfo)
        if (objInfo.company && objInfo.company === -1 || objInfo.modules && objInfo.modules.length === 0) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!validate()) { //
            toast(t(lang, "announcementsPage.requiredFields"))
            return
        }
        create()
    }




    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "companyPrivilegesPage.new")}
                    onSaveClick={handleSaveClicked}
                >
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.company")}
                            onSelect={(item) => setObjInfo({ ...objInfo, company: item.id })}
                            data={companies}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{`${item.name}`}</ItemText>
                                    </div>
                                )
                            }}
                        />
                    </Row>
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.privilege")}
                            onSelect={(item) => setObjInfo({ ...objInfo, modules: [item.module_id] })}
                            data={appModules}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{`${item.module_name}`}</ItemText>
                                    </div>
                                )
                            }}
                            disablePredictor={(item, index) => item.is_default}
                        />
                    </Row>
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    onRowsDelete={(rows, ids) => handleMultipleDelete(rows)}
                    title={t(lang, "companyPrivilegesPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin")}
                    customDeleteRowFields={["module_id", "company_id"]}
                />
            </DataSection>
        </Container>
    )

}

export default CompanyPrivilegesPage;





const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`


const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`