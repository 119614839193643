import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../../../context/Context';
import API from '../../../../static/API';
import t from '../../../../static/Language';
import SafeStorage from '../../../../static/SafeStorage';
import 'react-modern-drawer/dist/index.css'
import DataTable from '../../../../components/DataTable';
import globalStyles from '../../../../static/GlobalStyles';
import FormInput from '../../../../components/FormInput';
import FormSelectInput from '../../../../components/FormSelectInput';
import Utils from '../../../../static/Utils';
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from 'react-router-dom';
import Container from '../../../../components/Container';
import DataSection from '../../../../components/DataSection';
import NewRecordDrawer from '../../../../components/NewRecordDrawer';



const BranchesPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const currentUser = JSON.parse(SafeStorage.getItem("user"))
    const privileges = currentUser.privileges
    const userCompany = currentUser.company

    const [data, setData] = useState([])
    const [companies, setCompanies] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [objInfo, setObjInfo] = useState(privileges.includes("superAdmin") ? {} : { company: userCompany })
    const [objToEdit, setObjToEdit] = useState(null)
    const [updateInfo, setUpdateInfo] = useState({})
    const [initialCompany, setInitialCompany] = useState()
    const [actionOptions, setActionOptions] = useState()

    const navigate = useNavigate()


    useEffect(() => {
        document.title = t(lang, "branchesPage.title")
        getCompanies()
        get()
        prepareActionOptions()
    }, [])

    let _comps = []

    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canEditBranches")) {
            _actionOptions["edit"] = {
                onEdit: (row, id) => {
                    setObjToEdit(row)
                    let comp = _comps.find(c => c.id === row.company)
                    setInitialCompany(comp)
                    setEditOpen(true)
                }
            }
        }
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteBranches")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([id])
                }
            }
        }
        _actionOptions.onShowDetail = (row, id) => {
            //alert(`${row} ${id} detay görüntülenecek`)
            navigate(`/branches/${id}`, { state: row })
        }

        setActionOptions(_actionOptions)
    }




    const getCompanies = () => {
        API.getCompanies().then(response => {
            setCompanies(response.data.data)
            _comps = response.data.data
        })
    }

    const get = () => {
        API.getBranches().then(response => { // cevap gelirse şirketleri atıyoruz
            setData(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getBranches().then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const create = () => {
        API.createBranch(objInfo).then(response => {
            get()
            toast(t(lang, "branchesPage.created"))
        }).catch(err => { // hata alınırsa
            if (err.response?.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.createBranch(objInfo).then(resp => {
                        get()
                        toast(t(lang, "branchesPage.created"))
                    }).catch(er => {
                        toast(t(lang, "branchesPage.createError"))
                    })
                }).catch(e => {
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            } else {
                toast(t(lang, "branchesPage.createError"))
            }

        })
    }

    const update = () => {
        API.updateBranch(objToEdit.id, updateInfo).then(response => {
            get()
            toast(t(lang, "branchesPage.updated"))
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.updateBranch(objToEdit.id, updateInfo).then(resp => {
                        get()
                        toast(t(lang, "branchesPage.updated"))
                    }).catch(err => {
                        toast(t(lang, "branchesPage.updateError"))
                    })
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const _delete = (ids) => {
        API.deleteBranches(ids).then(response => {
            toast(t(lang, "branchesPage.deleted"))
            get()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteBranches(ids).then(resp => {
                        toast(t(lang, "branchesPage.deleted"))
                        get()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "branchesPage.deleteError"))
                    }).catch(e => {
                        if (e.response.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }





    const columns = [
        {
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: true,
                draggable: true,

            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "company_name",
            label: t(lang, "fields.company"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "address",
            label: t(lang, "fields.address"),
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "manager",
            label: t(lang, "fields.responsiblePerson"),
            options: {
                filter: true,
                sort: true
            }
        }

    ]




    const validate = () => {
        if (Utils.nullOrEmpty(objInfo.name) ||
            Utils.nullOrEmpty(objInfo.company) ||
            Utils.nullOrEmpty(objInfo.address)
        ) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!validate()) { //
            toast(t(lang, "branchesPage.requiredFields"))
            return
        }
        create()
    }



    const validateUpdate = () => {
        if ((updateInfo.name && Utils.nullOrEmpty(updateInfo.name)) ||
            (updateInfo.company && Utils.nullOrEmpty(updateInfo.company)) ||
            (updateInfo.address && Utils.nullOrEmpty(updateInfo.address))
        ) {
            return false
        }
        return true
    }


    const handleUpdateClicked = () => {
        if (!validateUpdate()) { //
            toast(t(lang, "branchesPage.requiredFields"))
            return
        }
        update()
    }


    return (

        <Container >
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    title={t(lang, "branchesPage.new")}
                    onSaveClick={handleSaveClicked}
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(val) => setObjInfo({ ...objInfo, name: val })}
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.address")}
                            onChange={(val) => setObjInfo({ ...objInfo, address: val })}
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Row>
                    {Utils.isSystemAdmin() &&
                        <Row>
                            <FormSelectInput
                                label={t(lang, "fields.company")}
                                onSelect={(item) => setObjInfo({ ...objInfo, company: item.id === -1 ? null : item.id })}
                                data={companies}
                                itemComponent={(item, index) => {
                                    return (
                                        <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                            <ItemText>{`${item.name}`}</ItemText>
                                        </div>
                                    )
                                }}
                            />
                        </Row>
                    }

                </NewRecordDrawer>
                <NewRecordDrawer
                    title={`${objToEdit?.name}`}
                    onSaveClick={handleUpdateClicked}
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                >
                    <Row>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, name: val })}
                            helperText="*"
                            helperTextType={"error"}
                            initialValue={objToEdit?.name}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label={t(lang, "fields.address")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, address: val })}
                            initialValue={objToEdit?.address}
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Row>
                    {Utils.isSystemAdmin() &&
                        <Row>
                            <FormSelectInput
                                label={t(lang, "fields.company")}
                                onSelect={(item) => setUpdateInfo({ ...updateInfo, company: item.id === -1 ? null : item.id })}
                                data={companies}
                                itemComponent={(item, index) => {
                                    return (
                                        <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                            <ItemText>{`${item.name}`}</ItemText>
                                        </div>
                                    )
                                }}
                                initialValue={initialCompany}
                            />
                        </Row>
                    }
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    onRowsDelete={(rows, ids) => _delete(ids)}
                    title={t(lang, "branchesPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateBranches")}
                />
            </DataSection>
        </Container>
    )

}


export default BranchesPage;





const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`



const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`