
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import { Button } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import globalStyles from '../static/GlobalStyles';


const BeasyChechbox = forwardRef(({ size, label, onChange, defaultChecked, readOnly = false }, ref) => {

    const [checked, setChecked] = useState(false)

    useImperativeHandle(ref, () => ({
        check: (val) => setChecked(val),
    }));

    useEffect(() => {
        setChecked(defaultChecked)
    }, [defaultChecked])

    const handleClick = () => {
        const _c = checked
        setChecked(!_c)
        if (onChange) {
            onChange(!_c)
        }
    }

    return (
        <Button
            onClick={handleClick}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                textTransform: 'none'
            }}
            disabled={readOnly}
        >
            {checked ? (
                <CheckBoxRoundedIcon style={{ color: '#fcba03', fontSize: size ?? 32 }} />
            ) : (
                <CheckBoxOutlineBlankRoundedIcon style={{ fontSize: size ?? 32 }} />
            )}
            <Text>{label}</Text>
        </Button>

    )

})

export default BeasyChechbox;

const Text = styled.text`
    font-family: ${globalStyles.font};
    font-size: 16px;
    margin-left: 4px;
    color: ${globalStyles.textColor}
`