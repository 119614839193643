import { useContext, useEffect, useState } from "react"
import { Context } from "../../../../context/Context"
import t from "../../../../static/Language"
import DataTable from "../../../../components/DataTable"
import Container from "../../../../components/Container"
import SafeStorage from "../../../../static/SafeStorage"
import API from "../../../../static/API"
import Loading from "../../../../states/Loading"
import NewRecordDrawer from "../../../../components/NewRecordDrawer"
import styled from "styled-components"
import FormSelectInput from "../../../../components/FormSelectInput"
import globalStyles from "../../../../static/GlobalStyles"
import Utils from "../../../../static/Utils"
import { toast, ToastContainer } from "react-toastify"



const AccessPrivsTab = ({ accessPrivs }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const user = JSON.parse(SafeStorage.getItem("user"))
    const privileges = user.privileges

    const [actionOptions, setActionOptions] = useState()
    const [gates, setGates] = useState([])

    const [loading, setLoading] = useState(true)

    const [accessPrivileges, setAccessPrivilegess] = useState(accessPrivs)
    const [newRecordOpen, setNewRecordOpen] = useState(false)

    const [selectedGate, setSelectedGate] = useState(null)


    useEffect(() => {
        prepareActionOptions()
        getGates()
    }, [])


    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteAccessPrivs")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([{
                        user_id: row.user_id,
                        gate_id: row.gate_id
                    }])
                }
            }
        }
        setActionOptions(_actionOptions)
    }






    const columns = [
        {
            name: "gate_id",
            label: t(lang, "fields.id"),
            options: {
                filter: true,
                sort: true,
                display: 'excluded'
            }
        },

        {
            name: "gate_name",
            label: t(lang, "fields.gate"),
            options: {
                filter: true,
                sort: true,

            }
        },
        {
            name: "building_name",
            label: t(lang, "fields.buildingName"),
            options: {
                filter: true,
                sort: true,

            }
        },
        {
            name: "latitude",
            label: t(lang, "fields.latitude"),
            options: {
                filter: true,
            }
        },
        {
            name: "longitude",
            label: t(lang, "fields.longitude"),
            options: {
                filter: true,
            }
        },

    ]


    const get = () => {
        API.getUserAccessPrivs().then(response => { // cevap gelirse şirketleri atıyoruz
            setAccessPrivilegess(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getUserAccessPrivs().then(resp => { // şirketleri tekrar çekiyoruz
                        setAccessPrivilegess(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const handleSaveClicked = () => {
        if (Utils.nullOrEmpty(selectedGate)) {
            return
        }
        create()
    }

    const getGates = () => {
        API.getGates().then(response => {
            setGates(response.data.data)
            setLoading(false)
        })
    }

    const create = () => {
        API.createAccessPrivsByGate({ user_id: user.id, gate_id: selectedGate }).then(response => {
            get()
            toast(t(lang, "accessPrivsPage.created"))
        }).catch(err => { // hata alınırsa
            if (err.response?.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.createAccessPrivsByGate({ user_id: user.id, gate_id: selectedGate }).then(resp => {
                        get()
                        toast(t(lang, "accessPrivsPage.created"))
                    }).catch(er => {
                        toast(t(lang, "accessPrivsPage.createError"))
                    })
                }).catch(e => {
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            } else {
                if (err.code === 'ERR_NETWORK') {
                    toast(t(lang, "common.networkError"))
                } else {
                    toast(t(lang, "common.error"))
                }
               
            }
        })
    }

    const _delete = (ids) => {
        API.deleteAccessPrivsByGate(ids).then(response => {
            toast(t(lang, "accessPrivsPage.deleted"))
            get()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteAccessPrivsByGate(ids).then(resp => {
                        toast(t(lang, "accessPrivsPage.deleted"))
                        get()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "accessPrivsPage.deleteError"))
                    }).catch(e => {
                        if (e.response.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }



    const handleMultipleDelete = (rows) => {
        let data = []
        rows.forEach(row => {
            data.push({
                user_id: row.user_id,
                gate_id: row.gate_id
            })
        });
        _delete(data)
    }




    if (loading) {
        return <Loading />
    }

    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <NewRecordDrawer
                open={newRecordOpen}
                onClose={() => setNewRecordOpen(false)}
                saveButtonTitle={t(lang, "common.save")}
                title={t(lang, "accessPrivsPage.new")}
                onSaveClick={handleSaveClicked}
            >
                <Row>
                    <FormSelectInput
                        label={t(lang, "fields.gate")}
                        onSelect={(item) => setSelectedGate(item.id === -1 ? null : item.id)}
                        data={gates}
                        itemComponent={(item, index) => {
                            return (
                                <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                    <ItemText>{item.name}</ItemText>
                                </div>
                            )
                        }}
                        helperText='*'
                    />
                </Row>
            </NewRecordDrawer>
            <DataTable
                columns={columns}
                data={accessPrivileges}
                actionOptions={actionOptions}
                onNewButtonClick={() => setNewRecordOpen(true)}
                onRowsDelete={(rows, ids) => handleMultipleDelete(rows)}
                customIdSelector="user_id"
                customDeleteRowFields={["user_id", "gate_id"]}
                canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateAccessPrivs")}
            />
        </Container>
    )
}


export default AccessPrivsTab

const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`


const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`