import { useContext, useEffect, useState } from "react";
import API from "../../../static/API";
import { Context } from "../../../context/Context";
import SafeStorage from "../../../static/SafeStorage";
import { Box, Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import globalStyles from "../../../static/GlobalStyles";
import t from "../../../static/Language";
import Select, { createFilter } from "react-select";
import FlexButton from "../../../components/FlexButton";
import styled from "styled-components";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Loading from "../../../states/Loading";
import Drawer from 'react-modern-drawer'
import { isMobile } from "react-device-detect";
import NewRecordHeader from "../../../components/NewRecordHeader";
import FormInput from '../../../components/FormInput';
import { MuiColorInput } from 'mui-color-input'
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Utils from '../../../static/Utils';
import dayjs from 'dayjs';
import BreakDescriber from '../../../components/shift-components/BreakDescriber';
import { InputAdornment } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BeasyChechbox from "../../../components/BeasyCheckbox";



const AssignFixedShiftPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const user = JSON.parse(SafeStorage.getItem("user"))


    const [userGroups, setUserGroups] = useState([])
    const [users, setUsers] = useState([])
    const [inUsers, setInUsers] = useState([])
    const [inGroups, setInGroups] = useState([])
    const [fixedShifts, setFixedShifts] = useState([])
    const [selectedShift, setSelectedShift] = useState()
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [breaks, setBreaks] = useState([])
    const [objInfo, setObjInfo] = useState({ is_fixed: true, company: user.company })
    const [color, setColor] = useState(Utils.randomColor())
    const [companies, setCompanies] = useState([])

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        document.title = t(lang, "menuNames.assignFixedShift")
        const getAll = async () => {
            try {
                await getFixedShifts()
                await getUserGroups()
                await getUsers()
                if (Utils.isSystemAdmin()) {
                    await getCompanies()
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        getAll()
    }, [])


    const logout = () => {
        SafeStorage.removeItem("token")
        SafeStorage.removeItem("refreshToken")
        dispatch({ type: 'LOGIN', login: false })
    }


    const getCompanies = async () => {
        try {
            const response = await API.getCompanies2()
            setCompanies(response.data.data)
        } catch (e) {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }
    }

    const getFixedShifts = async () => {
        try {
            const response = await API.getFixedShifts()
            setFixedShifts(response.data.data)
        } catch (e) {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }

    }

    const getUserGroups = async () => {
        try {
            const response = await API.getUserGroups2()
            setUserGroups(response.data.data)
        } catch (e) {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }

    }

    const createShift = (obj) => {
        const _obj = obj ?? objInfo
        API.createShift(_obj).then(response => {
            getFixedShifts()
            toast(t(lang, "shiftsPage.created"))
        }).catch(err => { // hata alınırsa
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.createShift(_obj).then(resp => { // userları tekrar çekiyoruz
                        getFixedShifts()
                        toast(t(lang, "shiftsPage.created"))
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        logout()
                    }
                })
            }
        })
    }


    const getUsers = async () => {
        try {
            const response = await API.getUsers2()
            setUsers(response.data.data)
        } catch (e) {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }
    }

    const parseTime = (time) => {
        const parts = time.split(':')
        const hour = parseInt(parts[0])
        const minute = parseInt(parts[1])
        const now = new Date()
        now.setHours(hour)
        now.setMinutes(minute)
        now.setSeconds(0)
        now.setMilliseconds(0)
        return dayjs(now)
    }

    const theme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor
                    },

                }
            }
        }
    })

    const handleSaveClicked = () => {
        const obj = { ...objInfo, color_code: color, breaks: breaks }
        if (!validateTimes(obj)) {
            toast(t(lang, "shiftsPage.invalidTimes"))
            return
        }
        if (!validate(obj)) {
            toast(t(lang, "shiftsPage.requiredFields"))
            return
        }
        createShift(obj)
    }


    const validate = (obj) => {
        if (Utils.nullOrEmpty(obj.start_time)
            || Utils.nullOrEmpty(obj.end_time)
            || Utils.nullOrEmpty(obj.short_code)
            || Utils.nullOrEmpty(obj.shift_name)
            || Utils.nullOrEmpty(obj.color_code)
        ) {
            return false
        }
        return true
    }


    const validateTimes = (obj) => {
        try {
            const endMinutes = parseInt(obj.end_time.split(':')[0]) * 60 + parseInt(obj.end_time.split(':')[1])
            const startMinutes = parseInt(obj.start_time.split(':')[0]) * 60 + parseInt(obj.start_time.split(':')[1])
            if (endMinutes - startMinutes <= 0) {
                return false
            }
            for (let index = 0; index < breaks.length; index++) {
                const b = breaks[index];
                if (!b.break_start_time || !b.break_end_time) {
                    return false
                }
            }
            return true
        } catch (ex) {
            return false
        }
    }

    const assignShift = () => {

        const assignment = {
            shift_id: selectedShift.id,
            user_groups: inGroups.map(g => g.id),
            users: inUsers.map(u => u.id)
        }


        API.assignFixedShift(assignment).then(response => {
            toast(t(lang, "shiftsPage.assignedFixedShift"))
        }).catch(e => {
            if (e.message === "invalid credentials") {
                logout()
            } else {
                console.log(e)
            }
        })
    }

    const filter = createFilter({ ignoreCase: true })


    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <Grid container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <Drawer
                open={newRecordOpen}
                onClose={() => setNewRecordOpen(false)}
                direction='right'
                overlayOpacity={0.1}
                style={{
                    width: isMobile ? '100%' : '40%',
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8
                }}
                duration={200}
            >
                <NewRecordHeader title={t(lang, "shiftsPage.new")} onSave={handleSaveClicked} onClose={() => setNewRecordOpen(false)} />
                <DrawerBody>
                    <Grid container spacing={0} >
                        <ThemeProvider theme={theme}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormInput
                                    label={t(lang, "fields.description")}
                                    onChange={(text) => setObjInfo({ ...objInfo, shift_name: text })}
                                    helperText={'*'}
                                    helperTextType={'error'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormInput
                                    label={t(lang, "fields.shortCode")}
                                    onChange={(text) => setObjInfo({ ...objInfo, short_code: text })}
                                    helperText={'*'}
                                    helperTextType={'error'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} margin={'15px'}>
                                <ColorPicker
                                    value={color}
                                    onChange={(color) => setColor(color)}
                                    format='hex'
                                    size="small"
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    isAlphaHidden
                                    label={t(lang, "fields.colorCode")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: '15px', paddingBottom: "0px" }} >
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang} >
                                    <TimeField
                                        label={t(lang, "fields.startTime")}
                                        size='small'
                                        format='HH:mm'
                                        helperText='*'
                                        FormHelperTextProps={{
                                            style: {
                                                color: 'red',
                                                fontFamily: globalStyles.font
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <AccessTimeIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(value) => setObjInfo({ ...objInfo, start_time: value.toDate().toLocaleTimeString() })}
                                        ampm={false}
                                        sx={{ display: 'flex', flex: 1 }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: '15px', paddingBottom: "0px" }} >
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
                                    <TimeField
                                        size='small'
                                        label={t(lang, "fields.endTime")}
                                        format='HH:mm'
                                        helperText='*'
                                        FormHelperTextProps={{
                                            style: {
                                                color: 'red',
                                                fontFamily: globalStyles.font
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <AccessTimeIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(value) => setObjInfo({ ...objInfo, end_time: value.toDate().toLocaleTimeString() })}
                                        ampm={false}
                                        sx={{ display: 'flex', flex: 1 }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </ThemeProvider>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <FormInput
                                label={t(lang, "shiftsPage.lateEntryTolerance")}
                                onChange={(text) => setObjInfo({ ...objInfo, late_entry_tolerance: text })}
                                type={"number"}
                                max={60}
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <FormInput
                                label={t(lang, "shiftsPage.earlyLeaveTolerance")}
                                onChange={(text) => setObjInfo({ ...objInfo, early_leave_tolerance: text })}
                                type={"number"}
                                max={60}
                                min={0}
                            />
                        </Grid>

                        {Utils.isSystemAdmin() &&
                            <Grid item xs={12} sm={12} md={12} lg={12} margin={'15px'}>
                                <Select
                                    options={companies}
                                    getOptionValue={o => o.id}
                                    getOptionLabel={o => `${o.name}`}
                                    isSearchable
                                    isClearable
                                    closeMenuOnSelect={true}
                                    placeholder={t(lang, "fields.company")}
                                    filterOption={filter}
                                    noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                                    onChange={(value) => setObjInfo({ ...objInfo, company: value })}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontFamily: globalStyles.font,
                                            marginBottom: '15px'
                                        }),
                                        menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontFamily: globalStyles.font,
                                        }),
                                    }}
                                />
                            </Grid>
                        }


                        <Grid item xs={12} sm={12} md={12} lg={12} marginLeft={"15px"}>
                            <BeasyChechbox
                                label={t(lang, "shiftsPage.isFixed")}
                                onChange={val => setObjInfo({ ...objInfo, is_fixed: val })} //
                                size="large"
                                defaultChecked={true}
                                readOnly={true}
                            />
                        </Grid>


                    </Grid>
                    {breaks.map((b, i) => {
                        return (
                            <div key={i} style={{ flex: 1, margin: 15 }}>
                                <BreakDescriber
                                    lang={lang}
                                    start={b.break_start_time ? parseTime(b.break_start_time) : null}
                                    end={b.break_end_time ? parseTime(b.break_end_time) : null}
                                    id={b.id}
                                    title={`${t(lang, "shiftsPage.break")} ${i + 1}`}
                                    onChange={(id, start, end) => {
                                        const index = breaks.findIndex((_q) => _q.id === id);
                                        const updated = { id: id, break_start_time: start?.toDate().toLocaleTimeString(), break_end_time: end?.toDate().toLocaleTimeString() };
                                        const newBreaks = [...breaks];
                                        newBreaks[index] = updated
                                        setBreaks(newBreaks)
                                    }}
                                    onDelete={(id) => {
                                        const newBreaks = [...breaks]
                                        newBreaks.splice(i, 1)
                                        setBreaks(newBreaks)
                                    }}
                                />
                            </div>
                        )
                    })}
                    <Box sx={{ justifyContent: 'flex-start', display: 'flex' }}>
                        <FlexButton
                            variant='text'
                            onClick={() => {
                                let bs = [
                                    ...breaks,
                                    { id: Utils.uuid4() }
                                ]
                                setBreaks(bs)
                            }}
                            size="small"
                            renderIcon={() => <AddRoundedIcon />}
                            text={t(lang, "shiftsPage.newBreak")}
                        />

                    </Box>

                    <div style={{ height: '100px' }} />
                </DrawerBody>
            </Drawer>

            <Grid item flexDirection={'column'} display={'flex'} padding={'15px'} xs={12} md={6} justifyContent={'flex-start'}>
                <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                    <Title>{t(lang, "shiftsPage.isFixed")}</Title>
                    <FlexButton
                        variant="text"
                        size="small"
                        text={t(lang, "common.new")}
                        style={{
                            margin: 0
                        }}
                        renderIcon={() => <AddRoundedIcon sx={{ fontSize: "20px" }} />}
                        onClick={() => setNewRecordOpen(true)}
                    />
                </div>
                <Select
                    options={fixedShifts}
                    getOptionValue={o => o.id}
                    getOptionLabel={o => `${o.short_code} / ${o.start_time.slice(0, 5)}-${o.end_time.slice(0, 5)}`}
                    isSearchable
                    isClearable
                    closeMenuOnSelect={true}
                    placeholder={t(lang, "shiftsPage.isFixed")}
                    filterOption={filter}
                    noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                    onChange={(value) => setSelectedShift(value)}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                            marginBottom: '15px'
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                        }),
                    }}
                />
            </Grid>

            <Grid item flexDirection={'column'} display={'flex'} padding={'15px'} xs={12} md={6} justifyContent={'flex-start'}>
                <div style={{ alignItems: 'stretch', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                    <Title>{t(lang, "surveysPage.included")}</Title>
                </div>
                <Select
                    options={userGroups}
                    defaultValue={inGroups}
                    getOptionValue={o => o.id}
                    getOptionLabel={o => o.name}
                    isMulti
                    isSearchable
                    isClearable
                    closeMenuOnSelect={false}
                    placeholder={t(lang, "surveysPage.toGroup")}
                    filterOption={filter}
                    noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                    onChange={(value) => setInGroups(value)}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                            marginBottom: '15px'
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                        }),
                    }}
                />
                <Select
                    options={users}
                    defaultValue={inUsers}
                    getOptionValue={o => o.id}
                    getOptionLabel={o => `${o.name} ${o.last_name}`}
                    isMulti
                    isSearchable
                    isClearable
                    closeMenuOnSelect={false}
                    placeholder={t(lang, "surveysPage.toUser")}
                    filterOption={filter}
                    noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                    onChange={(value) => setInUsers(value)}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                            marginBottom: '15px'
                        }),
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: globalStyles.font,
                        }),
                    }}
                />
            </Grid >
            <Box sx={{ justifyContent: 'flex-end', display: 'flex', flex: 1 }}>
                <FlexButton
                    text={t(lang, "shiftsPage.assign")}
                    onClick={assignShift}
                    disabled={selectedShift && (inGroups.length > 0 || inUsers.length > 0) ? false : true}
                />
            </Box>
        </Grid>
    )
}

export default AssignFixedShiftPage;

const Title = styled.text`
    font-family: ${globalStyles.font};
    font-size: 18px;
    color: ${globalStyles.textColor};
    font-weight: bold;
    margin-bottom: 15px
`

const DataSection = styled.div`
    margin: 5px;
`
const Container = styled.div`
    flex-direction: column;
`
const DrawerBody = styled.div`	
    height: 100%;
    border-radius: 10px;
    flex-direction: column;
    overflow-y: scroll;
`


const ColorPicker = styled(MuiColorInput)`
  & .MuiColorInput-TextField {
    font-family: ${globalStyles.font};
    backgroud-color: green;
  }
`