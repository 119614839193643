import styled from "styled-components"
import globalStyles from "../static/GlobalStyles";





const Footer = () => {


    return (
        <FooterBody >
            <Text>
                © All rights are reserved. Flexbee Corp.
            </Text>
        </FooterBody>

    )
}

export default Footer;

const FooterBody = styled.div`
    border-top: 1px solid #CCCCCC;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px;
    background-color: white;

`

const Text = styled.text`
    font-family: ${globalStyles.font};
    font-size: 12px;
    color: grey
`