import { Breadcrumbs, Card, Link, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from "styled-components";
import { Context } from "../../../context/Context";
import API from "../../../static/API";
import globalStyles from "../../../static/GlobalStyles";
import t from "../../../static/Language";
import SafeStorage from "../../../static/SafeStorage";
import UserActivityTab from "./tab-panels/UserActivityTab";
import UserGeneralTab from "./tab-panels/UserGeneralTab";
import UserPrivsTab from "./tab-panels/UserPrivsTab";
import UserStaffTab from "./tab-panels/UserStaffTab";

import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import TabsContainer from "../../../components/dashboard-components/TabsContainer";
import AccessPrivsTab from "./tab-panels/AccessPrivsTab";




const UserDetailsPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const navigate = useNavigate()

    const { id } = useParams()

    const [userDetails, setUserDetails] = useState()


    useEffect(() => {
        getUserDetails()
    }, [])



    const getUserDetails = () => {
        API.getUserDetails(id).then(response => { // cevap gelirse userları atıyoruz
            setUserDetails(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getUserDetails(id).then(resp => { // userları tekrar çekiyoruz
                        setUserDetails(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }




    return (
        <TabsContainer>
            <Breadcrumbs separator={<ArrowForwardIosRoundedIcon style={{ color: "#639ef7" }} />}>
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} onClick={() => navigate("/users")}>
                    <CrumbText>{t(lang, "menuNames.userList")}</CrumbText>
                </Link>
                <CurrentCrumbText>{t(lang, "usersPage.userDetails")}</CurrentCrumbText>
            </Breadcrumbs>
            <TabsSection>
                <Tabs style={{ height: 'calc(100% - 35px)'}}>
                    <TabList >
                        <Tab>
                            <TabTitle>{t(lang, "common.general")}</TabTitle>
                        </Tab>
                        <Tab>
                            <TabTitle>{t(lang, "menuNames.appPrivileges")}</TabTitle>
                        </Tab>
                        <Tab>
                            <TabTitle>{t(lang, "menuNames.accessPrivileges")}</TabTitle>
                        </Tab>
                        <Tab>
                            <TabTitle>{t(lang, "usersPage.activity")}</TabTitle>
                        </Tab>
                        <Tab>
                            <TabTitle>{t(lang, "usersPage.staff")}</TabTitle>
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <UserGeneralTab userDetails={userDetails} />
                    </TabPanel>
                    <TabPanel style={{ height: '100%' }}>
                        <UserPrivsTab userPrivs={userDetails?.privileges} />
                    </TabPanel>
                    <TabPanel style={{ height: '100%' }}>
                        <AccessPrivsTab accessPrivs={userDetails?.access_privileges} />
                    </TabPanel>
                    <TabPanel style={{ height: '100%' }}>
                        <UserActivityTab userId={id} />
                    </TabPanel>
                    <TabPanel style={{ height: '100%' }}>
                        <UserStaffTab staff={userDetails?.staff} />
                    </TabPanel>
                </Tabs>
            </TabsSection>

        </TabsContainer>
    )


}

export default UserDetailsPage


const TabsSection = styled.div`
    margin-top: 15px;
    display: flex;
	flex-direction: column;
	height: 100%;
    overflow: auto
`

const TabTitle = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
`

const CrumbText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 12px;
    cursor: pointer
`

const CurrentCrumbText = styled.span`
    font-family: ${globalStyles.font};
    color: blue;
    font-size: 14px;
    cursor: default
`
