import { Button, ThemeProvider, Tooltip, createTheme } from "@mui/material"
import globalStyles from "../static/GlobalStyles";



const OptionButton = ({
    renderIcon = () => null,
    onClick = () => null,
    toolTip = ""
}) => {

    const theme = createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontFamily: globalStyles.font
                    }
                }
            }
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <Tooltip title={toolTip}
                sx={{
                    typography: 'h1',
                    '& .MuiTooltip-tooltip': {
                        fontSize: '1em',
                        fontFamily: '"Nunito, sans-serif"',
                        fontWeight: 'bold',
                    }
                }}
            >
                <Button
                    style={{
                        maxWidth: '40px',
                        maxHeight: '40px',
                        minWidth: '40px',
                        minHeight: '40px',
                        borderRadius: "30px"
                    }}
                    aria-label='tezek'
                    onClick={onClick}
                >
                    {renderIcon()}
                </Button>
            </Tooltip>
        </ThemeProvider>
    )

}

export default OptionButton;