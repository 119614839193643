import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../../../context/Context';
import API from '../../../../static/API';
import t from '../../../../static/Language';
import SafeStorage from '../../../../static/SafeStorage';
import 'react-modern-drawer/dist/index.css'
import DataTable from '../../../../components/DataTable';
import globalStyles from '../../../../static/GlobalStyles';
import FormSelectInput from '../../../../components/FormSelectInput';
import Utils from '../../../../static/Utils';
import { toast, ToastContainer } from "react-toastify"
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import SwapHorizontalCircleRoundedIcon from '@mui/icons-material/SwapHorizontalCircleRounded';
import DateRangePicker from '../../../../components/DateRangePicker';
import DateTimePicker from '../../../../components/DateTimePicker';
import { Grid } from '@mui/material';
import Avatar from '../../../../components/Avatar';
import DataSection from '../../../../components/DataSection';
import Container from '../../../../components/Container';
import NewRecordDrawer from '../../../../components/NewRecordDrawer';



const UserActivitiesPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const privileges = JSON.parse(SafeStorage.getItem("user")).privileges




    const [data, setData] = useState([])
    const [accessPoints, setAccessPoints] = useState([])
    const [users, setUsers] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [objInfo, setObjInfo] = useState({})

    const [actionOptions, setActionOptions] = useState()

    const dateOptions = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', second: '2-digit' }

    const dateRef = useRef()

    useEffect(() => {
        document.title = t(lang, "userActivitiesPage.title")
        prepareActionOptions()
        get()
        getOthers()
    }, [])



    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteUserActivity")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([id])
                }
            }
        }
        setActionOptions(_actionOptions)
    }



    const getOthers = () => {
        API.getAccessPoints().then(response => {
            setAccessPoints(response.data.data)
        })
        API.getUsers().then(response => {
            setUsers(response.data.data)
        })
    }

    const get = () => {
        API.getUserActivities().then(response => { // cevap gelirse şirketleri atıyoruz
            setData(response.data.data)

        }).catch(err => { // hata alınırsa
            alert(err)
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getAccessPoints().then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const create = () => {

        API.createUserActivity(objInfo).then(response => {
            get()
            toast(t(lang, "userActivitiesPage.created"))
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.createUserActivity(objInfo).then(resp => {
                        get()
                        toast(t(lang, "userActivitiesPage.created"))
                    }).catch(er => {
                        toast(t(lang, "userActivitiesPage.createError"))
                    })
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const _delete = (ids) => {
        API.deleteUserActivities(ids).then(response => {
            toast(t(lang, "userActivitiesPage.deleted"))
            get()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteUserActivities(ids).then(resp => {
                        toast(t(lang, "userActivitiesPage.deleted"))
                        get()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "userActivitiesPage.deleteError"))
                    }).catch(e => {
                        if (e.response.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }




    const columns = [
        {
            name: "user_name",
            label: t(lang, "fields.user"),
            options: {
                filter: true,

                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                            <Avatar
                                name={value}
                                lastName={value.split(' ').at(-1)}
                                size={32}
                                avatar={data[tableMeta.rowIndex]?.avatar}
                                isSquare
                            />
                            <span style={{ marginLeft: '8px' }}>{value}</span>
                        </div>
                    )
                },

            }
        },
        {
            name: "user_id",
            label: t(lang, "fields.userId"),
            options: {
                filter: true,
                sort: false
            }

        },

        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false
            }
        },
        {
            name: "building_name",
            label: t(lang, "fields.buildingName"),
            options: {
                filter: true,
                sort: false
            }
        },

        {
            name: "gate_name",
            label: t(lang, "fields.gate"),

            options: {
                filter: false,
                sort: false,

            }
        },
        {
            name: "activity_time",
            label: t(lang, "fields.date"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <text>{new Date(value).toLocaleString(lang, dateOptions)}</text>
                    )
                },

                filter: true,
                filterType: 'custom',
                filterOptions: {

                    display: (filterList, onChange, index, column) => (
                        <DateRangePicker
                            locale={lang}
                            onChange={(start, end) => {
                                const offset = start?.getTimezoneOffset() ?? end?.getTimezoneOffset() ?? 0
                                const localeStart = start ? new Date(start.getTime() - (offset * 60000)) : null
                                const localeEnd = end ? new Date(end.getTime() - (offset * 60000)) : null
                                filterList[index][0] = `${t(lang, "fields.startTime")}: ${localeStart?.toISOString().split("T")[0] ?? t(lang, "common.allTimes")} | ${t(lang, "fields.endTime")}: ${localeEnd?.toISOString().split("T")[0] ?? t(lang, "common.allTimes")}`  //start?.toISOString()
                                onChange(filterList[index], index, column)
                            }}
                            ref={dateRef}
                        />
                    ),
                    logic: (prop, filters) => {

                        //let startDate = filters[0] ? new Date(filters[0]) : null;
                        let parts = filters[0]?.split("|")
                        let startParts = parts?.[0].split(":")
                        let startDate = startParts?.length > 0 ? new Date(startParts[1]) : null
                        let endParts = parts?.[1].split(":")
                        let endDate = endParts?.length > 0 ? new Date(endParts[1]) : null
                        let propDate = new Date(prop);

                        if (startDate && endDate) {
                            return propDate < startDate || propDate > endDate;
                        }
                        if (startDate) {
                            return propDate < startDate;
                        }
                        if (endDate) {
                            return propDate > endDate;
                        }
                        return false;
                    },
                }
            }
        },
        {
            name: "direction",
            label: t(lang, "fields.direction"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            {value == 1 ? (
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                                    <ArrowCircleRightRoundedIcon style={{ color: "#3fd473" }} />
                                    <text>{t(lang, "gatesPage.entry")}</text>
                                </div>
                            ) : (value == 2 ? (
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                                    <ArrowCircleLeftRoundedIcon style={{ color: "#d43f3f" }} />
                                    <text>{t(lang, "gatesPage.exit")}</text>
                                </ div>
                            ) : (
                                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                                    <SwapHorizontalCircleRoundedIcon style={{ color: "#3f8cd4" }} />
                                    <text>{t(lang, "gatesPage.bidirectional")}</text>
                                </ div>
                            ))}
                        </div>
                    )
                }
            }
        }
    ]




    const validate = () => {
        if (Utils.nullOrEmpty(objInfo.user_id) ||
            Utils.nullOrEmpty(objInfo.access_point_id) ||
            Utils.nullOrEmpty(objInfo.check_time)
        ) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!validate()) { //
            toast(t(lang, "gatesPage.requiredFields"))
            return
        }
        create()
    }



    const handleAccessPointSelected = (accessPoint) => {
        setObjInfo({
            ...objInfo,
            access_point_id: accessPoint.id === -1 ? null : accessPoint.id,
            check_type: accessPoint.id === -1 ? null : accessPoint.direction,
            gate_id: accessPoint.id === -1 ? null : accessPoint.gate_id
        })
    }

    return (

        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "userActivitiesPage.new")}
                    onSaveClick={handleSaveClicked}
                >
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.user")}
                            onSelect={(item) => setObjInfo({ ...objInfo, user_id: item.id === -1 ? null : item.id })}
                            data={users}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <Avatar avatar={item.avatar} lastName={item.last_name} size={32} name={item.name} isSquare />
                                        <ItemText>{`${item.name} ${item.last_name}`}</ItemText>
                                    </div>
                                )
                            }}
                            helperText="*"
                        />
                    </Row>
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.accessPoint")}
                            onSelect={(item) => handleAccessPointSelected(item)}
                            data={accessPoints}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{`${item.building_name} - ${item.gate_name}`}</ItemText>
                                    </div>
                                )
                            }}
                            helperText="*"
                        />
                    </Row>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingRight: '12px', paddingLeft: '12px' }}>
                        <DateTimePicker
                            label={t(lang, "userActivitiesPage.activityTime")}
                            onChange={(value) => setObjInfo({ ...objInfo, check_time: value })}
                            locale={lang}
                        />
                    </Grid>
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    onRowsDelete={(rows, ids) => _delete(ids)}
                    title={t(lang, "userActivitiesPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateUserActivity")}
                    onFilterChange={(changedColumn, filterList, type, changedColumnIndex, displayData) => {
                        if (type === "reset") {
                            dateRef.current.reset()
                        }
                    }}
                />
            </DataSection>
        </Container>
    )

}


export default UserActivitiesPage;




const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`


const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`