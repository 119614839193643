import { useContext } from "react";
import styled from "styled-components";
import FormInput from "../../../../components/FormInput";
import { Context } from "../../../../context/Context";
import t from "../../../../static/Language";





const AnnouncementGeneralTab = ({ details }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang


    const dateOptions = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }


    return (
        <Container>
            <Row>
                <ProfilePicture src={details?.banner} />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.title")}
                    type="text"
                    initialValue={details?.title}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.description")}
                    type="text"
                    initialValue={details?.description}
                    readOnly
                    multiline
                />
            </Row>
            <Row>
                <FormInput
                    label={"ID"}
                    type="text"
                    initialValue={details?.id}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.created_at")}
                    type="text"
                    initialValue={details?.created_at ?
                        new Date(details.created_at).toLocaleString(lang, dateOptions) : ''}
                    readOnly
                />
            </Row>
            <Row>
                <FormInput
                    label={t(lang, "fields.createdBy")}
                    type="text"
                    initialValue={details?.created_user_name}
                    readOnly
                />
            </Row>
        </Container>
    )
}


export default AnnouncementGeneralTab;

const ProfilePicture = styled.img`
    height: 150px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 15px;
`

const Container = styled.div`
	flex-direction: column;
`

const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`