import tr from "../lang/tr.json"
import en from "../lang/en.json"


const langs = {
    tr: tr,
    en: en
}



const t = (lang, key) => {
    let sptlittedKey = key.split('.')
    return langs[lang][sptlittedKey[0]][sptlittedKey[1]]

}

export default t;