import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../../context/Context';
import API from '../../../static/API';
import t from '../../../static/Language';
import SafeStorage from '../../../static/SafeStorage';
import DataTable from '../../../components/DataTable';
import globalStyles from '../../../static/GlobalStyles';
import FormSelectInput from '../../../components/FormSelectInput';
import Utils from '../../../static/Utils';
import { toast, ToastContainer } from "react-toastify"
import Avatar from '../../../components/Avatar';
import Container from '../../../components/Container';
import DataSection from '../../../components/DataSection';
import NewRecordDrawer from '../../../components/NewRecordDrawer';



const UserAccessPrivsPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const privileges = JSON.parse(SafeStorage.getItem("user")).privileges

    const [data, setData] = useState([])
    const [gates, setGates] = useState([])
    const [users, setUsers] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [objInfo, setObjInfo] = useState({})
    const [actionOptions, setActionOptions] = useState()



    useEffect(() => {
        document.title = t(lang, "accessPrivsPage.title")
        prepareActionOptions()
        get()
        getOthers()
    }, [])


    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteAccessPrivs")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([{
                        user_id: row.user_id,
                        gate_id: row.gate_id
                    }])
                }
            }
        }
        setActionOptions(_actionOptions)
    }




    const validate = () => {
        if (Utils.nullOrEmpty(objInfo.user_id) ||
            Utils.nullOrEmpty(objInfo.gate_id)
        ) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!validate()) { //
            toast(t(lang, "gatesPage.requiredFields"))
            return
        }
        create()
    }

    const getOthers = () => {
        API.getGates().then(response => {
            setGates(response.data.data)
            // console.log(response.data.data)
        })
        API.getUsers().then(response => {
            setUsers(response.data.data)
        })
    }

    const get = () => {
        API.getUserAccessPrivs().then(response => { // cevap gelirse şirketleri atıyoruz
            setData(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getUserAccessPrivs().then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const create = () => {
        API.createAccessPrivsByGate(objInfo).then(response => {
            get()
            toast(t(lang, "accessPrivsPage.created"))
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.createAccessPrivsByGate(objInfo).then(resp => {
                        get()
                        toast(t(lang, "accessPrivsPage.created"))
                    }).catch(er => {
                        toast(t(lang, "accessPrivsPage.createError"))
                    })
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const _delete = (ids) => {
        API.deleteAccessPrivsByGate(ids).then(response => {
            toast(t(lang, "accessPrivsPage.deleted"))
            get()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteAccessPrivs(ids).then(resp => {
                        toast(t(lang, "accessPrivsPage.deleted"))
                        get()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "accessPrivsPage.deleteError"))
                    }).catch(e => {
                        if (e.response.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }





    const columns = [
        {
            name: "user_name",
            label: t(lang, "fields.user"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                            <Avatar
                                name={value}
                                lastName={value.split(' ').at(-1)}
                                size={32}
                                avatar={data[tableMeta.rowIndex]?.avatar}
                                isSquare
                            />
                            <span style={{ marginLeft: '8px' }}>{value}</span>
                        </div>
                    )
                }
            }
        },
        {
            name: "user_id",
            label: t(lang, "fields.userId"),
            options: {
                filter: true,
                sort: false
            }

        },
        {
            name: "gate_name",
            label: t(lang, "fields.gate"),
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "building_name",
            label: t(lang, "fields.buildingName"),
            options: {
                filter: true,
                sort: false
            }
        },

    ]







    const handleMultipleDelete = (rows) => {
        let data = []
        //console.log(rows)
        rows.forEach(row => {
            //let record = data.find(d => d.user_id === id)
            data.push({
                user_id: row.user_id,
                gate_id: row.gate_id
            })
        });
        _delete(data)
    }




    return (

        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "accessPrivsPage.new")}
                    onSaveClick={handleSaveClicked}
                >
                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.user")}
                            onSelect={(item) => setObjInfo({ ...objInfo, user_id: item.id === -1 ? null : item.id })}
                            data={users}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        {/* <PP src={item.avatar} alt="image" /> */}
                                        <Avatar
                                            name={item.name}
                                            lastName={item.last_name}
                                            size={32}
                                            avatar={item.avatar}
                                            isSquare
                                        />
                                        <ItemText>{`${item.name} ${item.last_name}`}</ItemText>
                                    </div>
                                )
                            }}
                            helperText='*'
                        />
                    </Row>

                    <Row>
                        <FormSelectInput
                            label={t(lang, "fields.gate")}
                            onSelect={(item) => setObjInfo({ ...objInfo, gate_id: item.id === -1 ? null : item.id })}
                            data={gates}
                            itemComponent={(item, index) => {
                                return (
                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                        <ItemText>{item.name}</ItemText>
                                    </div>
                                )
                            }}
                            helperText='*'
                        />
                    </Row>
                </NewRecordDrawer>
                
                <DataTable
                    columns={columns}
                    data={data}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    onRowsDelete={(rows, ids) => handleMultipleDelete(rows)}
                    title={t(lang, "accessPrivsPage.title")}
                    customIdSelector="user_id"
                    customDeleteRowFields={["user_id", "gate_id"]}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateAccessPrivs")}
                />
            </DataSection>
        </Container>
    )

}


export default UserAccessPrivsPage;



const Row = styled.div`
	flex-direction: row; 
	display: flex;
	width: 100%
`



const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`