import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import globalStyles from '../../static/GlobalStyles';
import { Player } from "@lottiefiles/react-lottie-player"
import FormInput from '../../components/FormInput';
import FlexButton from '../../components/FlexButton';
import FormImageInput from '../../components/FormImageInput';
import { Alert, Breadcrumbs, MenuItem, Select, Snackbar } from '@mui/material';
import t from '../../static/Language';
import { Context } from '../../context/Context';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useNavigate } from "react-router-dom"
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import Utils from '../../static/Utils';
import SafeStorage from '../../static/SafeStorage';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';



const Layout = ({ children }) => {
    const { state } = useContext(Context)
    const lang = state.appLang

    return (
        <LayoutContainer>
            <BackgroundSection >
                <Player
                    src={require('../../assets/lottie-animations/business.json')}
                    autoplay
                    loop
                    style={{ width: '70%' }}
                />
                <span
                    style={{
                        fontFamily: globalStyles.font,
                        fontSize: '24px',
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '30px'
                    }}
                >
                    {t(lang, "login.slogan")}
                </span>
                <span
                    style={{
                        fontFamily: globalStyles.font,
                        fontSize: '18px',
                        color: 'white',
                        textAlign: 'center',
                        marginTop: '20px'
                    }}
                >
                    {t(lang, "login.advertise")}
                </span>
            </BackgroundSection>
            <FormSection>
                {children}
            </FormSection>
        </LayoutContainer>
    );
};



const UserFormPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const navigate = useNavigate()

    const [user, setUser] = useState({})

    const [messageOpen, setMessageOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("success")


    const logout = () => {
        SafeStorage.removeItem("token")
        SafeStorage.removeItem("refreshToken")
        dispatch({ type: 'LOGIN', login: false })
    }

    useEffect(() => {
        logout()
    }, [])

    const correctPhone = (phone, areaCode) => {
        try {
            return phone.substr(areaCode.length, phone.length)
        } catch (ex) { console.log(ex); return phone; }
    }

    const handleNext = () => {
        if (Utils.nullOrEmpty(user.name) ||
            Utils.nullOrEmpty(user.last_name) ||
            Utils.nullOrEmpty(user.email) ||
            Utils.nullOrEmpty(user.phone)
        ) {
            setSeverity("error")
            setMessage(t(lang, "usersPage.requiredFields"))
            setMessageOpen(true)
            return
        }
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email) === false) {
            setSeverity("error")
            setMessage(t(lang, "common.invalidEmail"))
            setMessageOpen(true)
            return
        }
        if (user.password?.length < 8 || user.password !== user.password_again) {
            setSeverity("error")
            setMessage(t(lang, "login.passwordError"))
            setMessageOpen(true)
            return
        }
        navigate('/register/company-form', { state: { user } })
    }

    return (
        <Layout>
            <Snackbar
                open={messageOpen}
                autoHideDuration={5000}
                onClose={() => setMessageOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert sx={{ width: '100%', fontFamily: globalStyles.font }} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                <Select
                    variant="standard"
                    defaultValue={lang}
                    style={{
                        width: '30px',
                        fontFamily: globalStyles.font,
                        fontSize: "11px",
                        position: 'absolute',
                        right: 20,
                        top: 20
                    }}

                    IconComponent={() => null}
                    onChange={(e) => {
                        dispatch({ type: "LANG", appLang: e.target.value })
                        SafeStorage.setItem("appLang", e.target.value)
                    }}

                >
                    <MenuItem style={{ ontFamily: globalStyles.font, fontSize: "11px" }} value={"tr"}>TR</MenuItem>
                    <MenuItem style={{ ontFamily: globalStyles.font, fontSize: "11px" }} value={"en"}>EN</MenuItem>
                </Select>
                <img
                    src={require('../../assets/icon/flexbee-no-bg-dark.webp')}
                    style={{
                        width: '200px',
                        alignSelf: 'center'
                    }}
                />
                <span
                    style={{
                        fontFamily: globalStyles.font,
                        color: globalStyles.textColor,
                        alignSelf: 'center',
                        color: globalStyles.firstColor,
                        fontSize: '16px',
                        marginBottom: '20px'
                    }}
                >
                    {t(lang, "login.registrationForm")}
                </span>
                <Breadcrumbs
                    separator={<ArrowForwardIosRoundedIcon style={{ color: "#639ef7" }} />}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Person2RoundedIcon />
                        <CurrentCrumbText>{t(lang, "login.userInfo")}</CurrentCrumbText>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <ApartmentRoundedIcon />
                        <CrumbText>{t(lang, "login.companyInfo")}</CrumbText>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <HowToRegRoundedIcon />
                        <CrumbText>{t(lang, "common.mailConfirmation")}</CrumbText>
                    </div>
                </Breadcrumbs>

                <FormContainer>
                    <FormInput
                        label={t(lang, "fields.name")}
                        style={{ margin: 0 }}
                        onChange={(val) => setUser({ ...user, name: val })}
                    />
                    <FormInput
                        label={t(lang, "fields.lastName")}
                        style={{ margin: 0 }}
                        onChange={(val) => setUser({ ...user, last_name: val })}
                    />
                    <FormInput
                        label={t(lang, "fields.phone")}
                        type={"phone"}
                        style={{ margin: 0 }}
                        onChange={(val, country, e, formattedValue) => setUser({ ...user, phone: correctPhone(val, country.dialCode), area_code: country.dialCode })}
                        language={lang}
                    />
                    <FormInput
                        label={t(lang, "fields.email")}
                        style={{ margin: 0 }}
                        onChange={(val) => setUser({ ...user, email: val })}
                    />
                    <FormInput
                        label={t(lang, "login.passwordPlaceholder")}
                        style={{ margin: 0 }}
                        type={"password"}
                        onChange={(val) => setUser({ ...user, password: val })}
                    />
                    <FormInput
                        label={t(lang, "login.passwordAgainPlaceholder")}
                        style={{ margin: 0 }}
                        type={"password"}
                        onChange={(val) => setUser({ ...user, password_again: val })}
                    />
                    <FormImageInput
                        label={t(lang, "fields.profilePicture")}
                        style={{ margin: 0, height: '50px' }}
                        tip={t(lang, "usersPage.ppTip")}
                        onFileSelect={(file) => setUser({ ...user, avatar_file: file })}
                    />
                    <FlexButton
                        text={`${t(lang, "common.next")}  >`}
                        style={{ margin: 0 }}
                        onClick={handleNext}
                    />
                    <span style={{
                        fontFamily: globalStyles.font,
                        color: globalStyles.firstColor,
                        cursor: 'pointer',
                        alignSelf: 'center',
                        fontSize: '11px'
                    }}
                        onClick={() => (navigate('/login', { replace: true }))}
                    >{t(lang, "login.loginScreen")}</span>
                </FormContainer>
            </div>
        </Layout>


    )
}

export default UserFormPage






const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
 
  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
`;



const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  background-color: ${globalStyles.firstColor};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BackgroundSection = styled.div`
  flex: 1;
  background-color: ${globalStyles.firstColor};
 
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 70px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px 0px 0px 15px;
  background-color: white;
  box-shadow: -12px -16px 21px -7px rgba(72, 77, 77, 0.5);
`;



const CrumbText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 12px;
`

const CurrentCrumbText = styled.span`
    font-family: ${globalStyles.font};
    color: black;
    font-size: 14px;
    cursor: default
`