import { Breadcrumbs, Card, Link } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from "styled-components";
import { Context } from "../../../context/Context";
import API from "../../../static/API";
import globalStyles from "../../../static/GlobalStyles";
import t from "../../../static/Language";
import SafeStorage from "../../../static/SafeStorage";

import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CompanyGeneralTab from "./tab-panles/CompnayGeneralTab";



const CompanyDetailsPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const navigate = useNavigate()

    const { id } = useParams()

    const [details, setDetails] = useState()


    useEffect(() => {
        getDetails()
    }, [])



    const getDetails = () => {
        API.getCompanyDetails(id).then(response => { // cevap gelirse userları atıyoruz
            //console.log(response.data.data)
            setDetails(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getCompanyDetails(id).then(resp => { // userları tekrar çekiyoruz
                        setDetails(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }




    return (
        <div style={{ flex: 1, padding: 15, overflowY: "auto" }}>
            <Breadcrumbs
                separator={<ArrowForwardIosRoundedIcon style={{ color: "#639ef7" }} />}
            >
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} onClick={() => navigate("/companies")}>
                    <CrumbText>{t(lang, "companiesPage.title")}</CrumbText>
                </Link>
                <CurrentCrumbText>{t(lang, "companiesPage.details")}</CurrentCrumbText>

            </Breadcrumbs>
            <TabsSection elevation={0}>
                <Tabs >
                    <TabList>
                        <Tab>
                            <TabTitle>{t(lang, "common.general")}</TabTitle>
                        </Tab>

                    </TabList>
                    <TabPanel>
                        <CompanyGeneralTab details={details} />
                    </TabPanel>

                </Tabs>
            </TabsSection>

        </div>
    )


}

export default CompanyDetailsPage


const TabTitle = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
`

const TabsSection = styled(Card)`
    margin-top: 15px;
    
`

const CrumbText = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 12px;
    cursor: pointer
`

const CurrentCrumbText = styled.text`
    font-family: ${globalStyles.font};
    color: black;
    font-size: 14px;
    cursor: default
`
