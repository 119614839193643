import { Player } from "@lottiefiles/react-lottie-player";
import { Card, Grid, IconButton, Tooltip } from "@mui/material";
import styled from "styled-components";

import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import t from "../../static/Language";
import { Context } from "../../context/Context";
import { useContext } from "react";
import globalStyles from "../../static/GlobalStyles";


const FONT_COLOR = "white"


const StatsCard = ({ data,
    onDetailsClick = () => null,
    icon,
    background = "linear-gradient(to right top, #c63232, #d1444c, #da5765, #e2697c, #e77c93);",
    mainStatistic
}) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang



    return (
        <Grid item xs={12} sm={6} md={6} lg={3}>
            <Card elevation={8} sx={{ padding: "10px", background: background }}>
                {data ? (
                    <div>
                        <div style={{ float: 'right' }}>
                            <Tooltip title={t(lang, "common.details")} >
                                <IconButton
                                    onClick={onDetailsClick}
                                >
                                    <OpenInNewRoundedIcon style={{ color: FONT_COLOR, fontSize: 18 }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <MainInfoSection>
                            <div style={{ flex: 1, flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                                {icon}
                                <KeyText>{mainStatistic?.key}</KeyText>
                            </div>
                        </MainInfoSection>
                        <MainValueText>{mainStatistic?.value}</MainValueText>
                    </div>
                ) : (
                    <Body>
                        <Player
                            src={require('../../assets/lottie-animations/loading.json')}
                            autoplay
                            loop
                            style={{ width: 60 }}
                        />
                    </Body>
                )}
            </Card>
        </Grid >
    )

}

export default StatsCard;


const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`


const MainInfoSection = styled.div`
    flex-direction: row;
    display: flex;
`

const KeyText = styled.text`
    font-family: ${globalStyles.font};
    color: ${FONT_COLOR};
    font-size: 18px;
    margin-left: 10px
`

const MainValueText = styled.text`
    font-family: ${globalStyles.font};
    color: ${FONT_COLOR};
    font-size: 72px;
    float: right;
    margin-right: 5px
`
