import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import t from '../static/Language';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';
import globalStyles from '../static/GlobalStyles';



const DateRangePicker = forwardRef(({ onChange = (start, end) => null, locale = "tr" }, ref) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const theme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiPickersYear: {
                styleOverrides: {
                    yearButton: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiDayCalendar: {
                styleOverrides: {
                    weekDayLabel: {
                        fontFamily: globalStyles.font
                    },
                    weekNumber: {
                        fontFamily: globalStyles.font
                    }
                }
            },
            MuiPickersCalendarHeader: {
                styleOverrides: {
                    label: {
                        fontFamily: globalStyles.font
                    }
                }
            }
        }
    })


    useImperativeHandle(ref, () => ({
        reset() {
            setStartDate(null);
            setEndDate(null);
        },
    }));


    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <DatePicker
                        label={t(locale, "fields.startTime")}
                        value={startDate}
                        onChange={(newValue) => {
                            setStartDate(newValue);
                            onChange(newValue?.toDate(), endDate?.toDate());
                        }}

                        renderInput={(params) => <TextField variant="filled" {...params} />}
                        maxDate={endDate || undefined} // Bitiş tarihini başlangıç tarihinin maksimum değeri olarak ayarla
                        slotProps={{
                            textField: {
                                variant: "standard"
                            },
                            openPickerIcon: { fontSize: "small" }

                        }}
                        sx={{ display: 'flex', margin: 1 }}
                    />
                    <DatePicker
                        label={t(locale, "fields.endTime")}
                        value={endDate}
                        onChange={(newValue) => {
                            setEndDate(newValue);
                            onChange(startDate?.toDate(), newValue?.toDate());
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        minDate={startDate || undefined} // Başlangıç tarihini bitiş tarihinin minimum değeri olarak ayarla
                        slotProps={{
                            textField: {
                                variant: "standard"
                            },
                            openPickerIcon: { fontSize: "small" }
                        }}
                        sx={{ display: 'flex', margin: 1 }}
                    />
                </div>

            </LocalizationProvider>
        </ThemeProvider>

    );
})

export default DateRangePicker;
