import SafeStorage from "./SafeStorage";




class Utils {
    getLanguage() {
        try {
            let lang = SafeStorage.getItem("appLang")
            return lang ?? "tr"
        } catch (error) {
            return "tr"
        }
    }


    nullOrEmpty(str) {
        return (!str || str.length === 0);
    }

    privileges() {
        return JSON.parse(SafeStorage.getItem("user")).privileges
    }

    isSystemAdmin() {
        return JSON.parse(SafeStorage.getItem("user")).privileges.includes("superAdmin")
    }


    uuid4() {
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }



    getCurrentWeekDays(locale) {
        const week = []
        const now = new Date()
        const currentDate = now.getDate()
        const currentMonth = now.getMonth()
        //const dayOfMonth = now.getDate() // ayın gününü döndürüyor 
        const dayOfWeek = now.getDay() // haftanın kaçıncı gününde olduğumuzu döndürüyor. pazar: 0 pazartesi: 1 ...
        const mondayOfWeek = new Date(now.setDate(now.getDate() - dayOfWeek + 1)) // +1 haftayı pazartesiden başlatmak için. yoksa pazardan başlıyor

        for (let i = 0; i < 7; i++) {
            const day = new Date()
            day.setDate(mondayOfWeek.getDate() + i)
            week.push({
                date: day,
                localeString: day.toLocaleDateString(locale),
                localeStringShort: day.toLocaleDateString(locale, { day: 'numeric', month: 'short' }),
                dayOfMonth: day.getDate(),
                dayOfWeekName: day.toLocaleDateString(locale, { weekday: 'long' }),
                isCurrentDay: day.getDate() === currentDate && day.getMonth() === currentMonth
            })
        }
        return week
    }


    getWeekDaysOfGivenDate(locale, date, startFromSunday = false) {
        let startDate = new Date(date);
        startDate.setDate(startDate.getDate() - startDate.getDay() + (startFromSunday ? 0 : (startDate.getDay() === 0 ? -6 : 1)));
        const weekDays = [];
        for (let i = 0; i < 7; i++) {
            const day = new Date(startDate);
            day.setDate(startDate.getDate() + i);
            const isCurrentDay = day.toDateString() === new Date().toDateString();
            const dayObject = {
                date: day, // YYYY-MM-DD formatında
                localeString: day.toLocaleDateString(locale),
                localeStringShort: day.toLocaleDateString(locale, { day: 'numeric', month: 'short' }),
                dayOfMonth: day.getDate(),
                dayOfWeekName: day.toLocaleDateString(locale, { weekday: 'long' }),
                isCurrentDay
            };

            weekDays.push(dayObject);
        }
        return weekDays;
    }


    getWeekRangeOfGivenDate(locale, date) {
        const dayOfWeek = date.getDay() // haftanın kaçıncı gününde olduğumuzu döndürüyor. pazar: 0 pazartesi: 1 ...
        const mondayOfWeek = new Date(date.setDate(date.getDate() - dayOfWeek + 1)) // +1 haftayı pazartesiden başlatmak için. yoksa pazardan başlıyor
        const sundayOfWeek = new Date(date.setDate(mondayOfWeek.getDate() + 6))
        return {
            start: {
                date: mondayOfWeek,
                localeString: mondayOfWeek.toLocaleDateString(locale),
                localeStringShort: mondayOfWeek.toLocaleDateString(locale, { day: 'numeric', month: 'short' }),
            },
            end: {
                date: sundayOfWeek,
                localeString: sundayOfWeek.toLocaleDateString(locale),
                localeStringShort: sundayOfWeek.toLocaleDateString(locale, { day: 'numeric', month: 'short' }),
            }
        }

    }


    removeProperty(obj, propertyName) {
        // Eğer structuredClone kullanılabilirse, bu yöntemle objeyi derin kopyala.
        // Değilse, JSON stringify/parse yöntemini kullan.
        let copy = typeof structuredClone === "function" ? structuredClone(obj) : JSON.parse(JSON.stringify(obj));

        function removeProperty(obj) {
            if (Array.isArray(obj)) {
                obj.forEach(item => removeProperty(item));
            } else if (obj !== null && typeof obj === 'object') {
                if (obj.hasOwnProperty(propertyName)) {
                    delete obj[propertyName];
                }
                Object.values(obj).forEach(value => {
                    if (typeof value === 'object') {
                        removeProperty(value);
                    }
                });
            }
        }

        removeProperty(copy);
        return copy;
    }

    isDarkColor = (hexColor) => {
        try {
            var hexColor = hexColor.substring(1);      // strip #
            var rgb = parseInt(hexColor, 16);   // convert rrggbb to decimal
            var r = (rgb >> 16) & 0xff;  // extract red
            var g = (rgb >> 8) & 0xff;  // extract green
            var b = (rgb >> 0) & 0xff;  // extract blue

            var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
            return luma < 200
        } catch (error) {
            //console.log(error)
            return false
        }

    }


    randomColor() {
        return "#000000".replace(/0/g, () => {
            return (~~(Math.random() * 16)).toString(16);
        });
    }
}

export default new Utils();