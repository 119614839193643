


const Reducer = (prevState, action) => {
    switch (action.type) {
        case 'LANG':
            return {...prevState, appLang: action.appLang}
        case 'LOGIN':
            return {...prevState, login: action.login}    
        default:
            return { ...prevState }
    }
}


export default Reducer;