import { useParams } from "react-router-dom"



const EditSurveyPage = () => {

    const { id } = useParams()


    return (
        <div style={{ flex: 1, padding: 15, overflowY: "auto", justifyContent: 'center', alignItems: 'center' }}>
            <text>{`${id} survey will be edited`}</text>
        </div>
    )

}


export default EditSurveyPage