import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';
import globalStyles from '../static/GlobalStyles';
import FlexButton from './FlexButton';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export default function MessageBox({ open, actions, title, message }) {
    //const [open, setOpen] = React.useState(false);




    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Title>
                        {title}
                    </Title>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Message>
                            {message}
                        </Message>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {actions?.map((action, i) => {
                        return (
                            <FlexButton 
                                text={action.name}
                                key={i}
                                onClick={action.onClick}
                                style={{
                                   
                                    marginRight: i !== actions.length - 1 ? 0 : undefined,
                                    ...action.style

                                }}
                            />
                            
                        )

                    })}

                </DialogActions>
            </Dialog>
        </div>
    );
}


const Title = styled.text`
    font-family: ${globalStyles.font};
    font-weight: bold;
    color: black;
    font-size: 20px;
`

const ActionText = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 16px;
`

const Message = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 16px;
`

const Action = styled(Button)`
    border: solid 1px wheat !important;
`