import { MenuItem } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import globalStyles from "../static/GlobalStyles";
//import Select from 'react-select';
import t from "../static/Language";
import { Context } from "../context/Context";



const FormSelectInput = ({
    data,
    itemComponent,
    onSelect = (item) => null,
    label,
    initialValue,
    helperText,
    variant = 'outlined',
    disablePredictor = (item, index) => { return false }
}) => {
    const { state } = useContext(Context)
    const language = state.appLang

    const [value, setValue] = useState(null)
    const [shrink, setShrink] = useState()

    useEffect(() => {
        setValue(initialValue)
        setShrink(initialValue ? true : false)
    }, [initialValue])

    const _data = [{ id: -1 }, ...data]
    const handleChange = (item) => {
        setValue(item)
        setShrink(item.id !== -1)
        onSelect(item)
    }





    return (
        <div style={{ margin: 15, flex: 1 }}>
            <FormControl fullWidth>
                <InputLabel
                    id="demo-simple-select-label"
                    style={{ backgroundColor: 'white', paddingRight: 12, paddingLeft: 8, left: -5, top: shrink ? 0 : -7, fontFamily: globalStyles.font, color: globalStyles.textColor }}
                    shrink={shrink}
                >
                    {label}
                </InputLabel>

                <Select
                    labelId="demo-simple-select-label"
                    onChange={(e) => handleChange(e.target.value)}
                    SelectDisplayProps={{ style: { fontFamily: globalStyles.font } }}
                    value={value}
                    variant={variant}
                    size="small"

                >
                    {_data.map((item, index) => {
                        return (
                            <MenuItem disabled={disablePredictor(item, index)} value={item}>
                                {item.id === -1 ? (
                                    <text style={{ fontFamily: globalStyles.font }}>{t(language, "common.none")}</text>
                                ) : (
                                    itemComponent(item, index) //`${item.name} ${item.lastName}`
                                )}
                            </MenuItem>
                        )
                    })}
                </Select>
                {helperText &&
                    <text style={{ color: 'red', marginLeft: 14, marginTop: 5, fontFamily: globalStyles.font, fontSize: 12 }}>
                        {helperText}
                    </text>
                }
            </FormControl>
        </div >
    )
}

export default FormSelectInput

