import { useContext, useEffect, useState } from "react";
import Select, { createFilter } from 'react-select';
import { Context } from "../../../../../context/Context";
import t from "../../../../../static/Language";

import DataTable from "../../../../../components/DataTable";
import { toast, ToastContainer } from "react-toastify"
import globalStyles from "../../../../../static/GlobalStyles";
import SafeStorage from "../../../../../static/SafeStorage";
import API from "../../../../../static/API";
import Container from "../../../../../components/Container";
import DataSection from "../../../../../components/DataSection";
import NewRecordDrawer from "../../../../../components/NewRecordDrawer";
import Avatar from "../../../../../components/Avatar";
import Loading from "../../../../../states/Loading";
import { Grid } from "@mui/material";





const GateAccessPrivsTab = ({ gateId }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [actionOptions, setActionOptions] = useState()
    const [objInfo, setObjInfo] = useState({})
    const [data, setData] = useState([])
    const [_gate, setGate] = useState(gateId)
    const [users, setUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getUsers()
        getGateUsers()
        prepareActionOptions()
    }, [])



    const columns = [
        {
            name: "avatar",
            label: t(lang, "usersPage.profilePicture"),
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                            <Avatar
                                avatar={data[rowIndex]?.avatar}
                                name={data[rowIndex]?.name}
                                lastName={data[rowIndex]?.last_name}
                                size={40}
                                isSquare
                            />
                        </div>
                    )
                },
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                display: 'excluded'
            }
        },
        {
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "last_name",
            label: t(lang, "fields.lastName"),
            options: {
                filter: true,
                sort: true,
            }
        }
    ]


    const logout = () => {
        SafeStorage.removeItem("token")
        SafeStorage.removeItem("refreshToken")
        dispatch({ type: 'LOGIN', login: false })
    }




    const getGateUsers = () => {
        API.getGateUsers(gateId).then(response => {
            setData(response.data.data)
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        })
    }

    const getUsers = () => {

        API.getUsers2().then(response => {
            setUsers(response.data.data)
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        }).finally(() => setLoading(false))
    }

    const prepareActionOptions = () => {
        let _actionOptions = {}
        _actionOptions["delete"] = {
            onDelete: (row, id) => {
                _delete([{ user_id: id, gate_id: gateId }])
            }
        }
        setActionOptions(_actionOptions)
    }



    const create = () => {
        const _data = {
            gate_id: gateId,
            user_ids: selectedUsers.map(u => u.id)
        }

        API.createMultipleUserAccessPrivs(_data).then(response => {
            getGateUsers()
            toast(t(lang, "accessPrivsPage.created"))
        }).catch(e => {
            if (e.message === "expired credentials") {
                logout()
            } else {
                console.log(e)
            }
        })
    }

    const _delete = (data) => {
        API.deleteAccessPrivsByGate(data).then(response => {
            toast(t(lang, "accessPrivsPage.deleted"))
            getGateUsers()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteAccessPrivsByGate(data).then(resp => {
                        toast(t(lang, "accessPrivsPage.deleted"))
                        getGateUsers()
                    }).catch(er => {
                        console.log(er)
                        toast(t(lang, "accessPrivsPage.deleteError"))
                    }).catch(e => {
                        if (e.response.status === 401) {
                            logout()
                        }
                    })
                })
            }
        })
    }


    const filter = createFilter({ ignoreCase: true })


    if (loading) {
        return <Loading />
    }

    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />

            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "menuNames.accessPrivileges")}
                    onSaveClick={create}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} margin={'15px'}>
                        <Select
                            options={users}
                            getOptionValue={o => o.id}
                            getOptionLabel={o => `${o.name} ${o.last_name}`}
                            isMulti
                            isSearchable
                            isClearable
                            closeMenuOnSelect={false}
                            placeholder={t(lang, "usersPage.title")}
                            filterOption={filter}
                            noOptionsMessage={() => t(lang, "surveysPage.allSelected")}
                            onChange={(value) => setSelectedUsers(value)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: globalStyles.font,
                                    marginBottom: '15px'
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: globalStyles.font,
                                }),
                            }}
                        />
                    </Grid>

                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    canCreateNewRecord={true}
                    onNewButtonClick={() => {
                        setNewRecordOpen(true)
                    }}
                    actionOptions={actionOptions}
                    onRowsDelete={(rows, ids) => {
                        let data = []
                        ids.forEach(id => {
                            data.push({
                                user_id: id,
                                gate_id: gateId
                            })
                        });
                        _delete(data)
                    }}
                />
            </DataSection>

        </Container>
    )
}


export default GateAccessPrivsTab;

