import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import globalStyles from "../static/GlobalStyles"
import SafeStorage from "../static/SafeStorage"
import { Menu, MenuItem, MenuDivider } from '@szhsin/react-menu';
import { Button } from "@mui/material";
import "@szhsin/react-menu/dist/index.css";
import { Context } from "../context/Context";
import API from "../static/API";
import { LogoutRounded } from "@mui/icons-material";
import { menuItemSelector } from "@szhsin/react-menu/style-utils";
import t from "../static/Language";
import { useNavigate } from "react-router-dom";
import Avatar from "./Avatar";


const StyledMenuItem = ({ text, onClick, icon }) => {
    const handleOnClick = () => {
        if (onClick) {
            onClick()
        }
    }
    return (
        <MenuItem onClick={handleOnClick}>
            <MenuItemText>{text}</MenuItemText>
            {icon &&
                <div style={{ flex: 1, marginLeft: 35 }}>
                    {icon}
                </div>
            }
        </MenuItem>
    )
}


const UserMenu = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang

    const [userInfo, setUserInfo] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        const ui = SafeStorage.getItem("user")
        const _ui = JSON.parse(ui)
        setUserInfo(_ui)
    }, [])



    const logout = () => {
        API.logout()
        SafeStorage.removeItem("token")
        SafeStorage.removeItem("refreshToken")
        dispatch({ type: "LOGIN", login: false })
    }


    if (!userInfo) {
        return null
    }

    return (

        <StyledMenu menuButton={(
            <MenuButton style={{ textTransform: 'none' }} >
                <Avatar avatar={userInfo.avatar} name={userInfo?.name} lastName={userInfo?.last_name} size={44} isSquare={true}/>
                <NameText>{`${userInfo.name} ${userInfo.last_name}`}</NameText>
            </MenuButton>
        )}>
            <StyledMenuItem
                text={t(lang, "common.profile")}
                onClick={() => navigate("/profile")}
            />
            <MenuDivider />
            <StyledMenuItem text={"Logout"}
                onClick={logout}
                icon={<LogoutRounded style={{ color: globalStyles.textColor }} />}
            />
        </StyledMenu>
    )
}

export default UserMenu


const StyledMenu = styled(Menu)`
    ${menuItemSelector.hover} {
        color: #fff;
        background-color: ${globalStyles.firstColor};
    }
`

const MenuButton = styled(Button)`
    flex-direction: row;
    display: flex;
    align-items: center;
`

const NameText = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    margin-right: 15px;
    margin-left: 15px;
`

const MenuItemText = styled.text`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    font-size: 14;
`

