import { useContext, useEffect, useState } from "react";
import DataTable from "../../../../../components/DataTable";
import { Context } from "../../../../../context/Context";
import globalStyles from "../../../../../static/GlobalStyles";
import SafeStorage from "../../../../../static/SafeStorage";
import t from "../../../../../static/Language";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { isMobile } from 'react-device-detect';
import API from "../../../../../static/API";
import NewRecordHeader from "../../../../../components/NewRecordHeader";
import { Grid } from '@mui/material';
import { toast, ToastContainer } from "react-toastify"
import styled from "styled-components";
import FormMultiSelectInput from "../../../../../components/FormMultiSelectInput";
import Container from "../../../../../components/Container";
import DataSection from "../../../../../components/DataSection";
import Avatar from "../../../../../components/Avatar";
import NewRecordDrawer from "../../../../../components/NewRecordDrawer";





const UserGroupRelationTab = ({ groupId }) => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang
    const currentUser = JSON.parse(SafeStorage.getItem("user"))
    const company = currentUser.company

    const [data, setData] = useState([])
    const [actionOptions, setActionOptions] = useState()
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [users, setUsers] = useState([])
    const [selectedUserIds, setSelectedUserIds] = useState([])


    useEffect(() => {
        get()
        prepareActionOptions()
        getOthers()
    }, [])


    const prepareActionOptions = () => {
        let _actionOptions = {}
        _actionOptions["delete"] = {
            onDelete: (row, id) => {
                _delete([{
                    group_id: groupId,
                    user_id: row.id
                }])
            }
        }
        setActionOptions(_actionOptions)
    }

    const getOthers = () => {
        API.getUsers().then(response => {
            setUsers(response.data.data)
        })
    }

    const get = () => {
        API.getGroupUsers(groupId).then(response => { // cevap gelirse datayı atıyoruz
            setData(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getGroupUsers(groupId).then(resp => { // şirketleri tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response?.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            } else {
                console.log(err)
            }
        })
    }


    const create = (userIds) => {
        const _data = {
            group_id: groupId,
            users: userIds
        }
        API.addUsersToGroup(_data).then(response => {
            toast(t(lang, "groupUsersPage.created"))
            get()
        }).catch(err => {
            if (err.response?.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.addUsersToGroup(_data).then(resp => { // şirketleri tekrar çekiyoruz
                        toast(t(lang, "groupUsersPage.created"))
                        get()
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
            toast(t(lang, "groupUsersPage.createError"))
        })

    }

    // rows user_id, group_id çiflerinden oluşan obje listesi
    const _delete = (rows) => {
        API.removeUserFromGroup(rows).then(response => {
            toast(t(lang, "groupUsersPage.deleted"))
            get()
        }).catch(err => {
            if (err.response?.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.removeUserFromGroup(rows).then(resp => {
                        toast(t(lang, "groupUsersPage.deleted"))
                        get()
                    }).catch(er => {
                        toast(t(lang, "groupUsersPage.deleteError"))
                    }).catch(e => {
                        if (e.response?.status === 401) {
                            SafeStorage.removeItem("token")
                            SafeStorage.removeItem("refreshToken")
                            dispatch({ type: 'LOGIN', login: false })
                        }
                    })
                })
            }
        })
    }


    const handleMultipleDelete = (rows) => {
        let data = []
        rows.forEach(row => {
            data.push({
                group_id: groupId,
                user_id: row.id
            })
        });
        _delete(data)
    }


    const handleSaveClicked = () => {
        if (selectedUserIds.length === 0) { //
            toast(t(lang, "branchUsersPage.requiredFields"))
            return
        }

        create(selectedUserIds)
    }

    const columns = [
        {
            name: "name",
            label: t(lang, "fields.user"),
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return (
                        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                            <Avatar
                                avatar={data[rowIndex]?.avatar}
                                name={data[rowIndex]?.name}
                                lastName={data[rowIndex]?.last_name}
                                size={40}
                                isSquare
                            />
                            <span style={{ marginLeft: '8px' }}>{`${data[rowIndex]?.name} ${data[rowIndex]?.last_name}`}</span>
                        </div>
                    )
                },
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false,
                display: false,

            }
        }

    ]

    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "groupUsersPage.new")}
                    onSaveClick={handleSaveClicked}
                >
                    <FormMultiSelectInput
                        language={lang}
                        values={users}
                        onChange={ids => setSelectedUserIds(ids)}
                        valueSelector={item => `${item.name} ${item.last_name}`}
                    />
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    canCreateNewRecord={true}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    actionOptions={actionOptions}
                    onRowsDelete={(rows, ids) => handleMultipleDelete(rows)}
                    customDeleteRowFields={["id"]}
                />
            </DataSection>
        </Container>
    )


}

export default UserGroupRelationTab;


const DrawerBody = styled.div`	
	height: 100%;
	border-radius: 10px;
	flex-direction: column;
`
