
const globalStyles = {
    font: "Nunito, sans-serif" ,//"Ubuntu, sans-serif",
    firstColor: "#3c8efa",
    secondColor: "#004242",
    headerColor: "white",
    bodyColor: "white",
    textColor: "#004242",
    sidebarBackColor: "white",//"#f3d548", //"#1b1633"  
    sidebarTextColor: "#004242",
    sidebarSelectColor: "#004242" //"#ebb434"
}

export default globalStyles