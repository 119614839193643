import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import globalStyles from "../static/GlobalStyles"
import { Menu, MenuItem, MenuDivider } from '@szhsin/react-menu';
import "@szhsin/react-menu/dist/index.css";
import { menuItemSelector } from "@szhsin/react-menu/style-utils";
import TranslateIcon from '@mui/icons-material/Translate';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Context } from "../context/Context";


const StyledMenuItem = ({ isSelected, onClick, flag }) => {
    const handleOnClick = () => {
        if (onClick) {
            onClick()
        }
    }
    return (
        <MenuItem onClick={handleOnClick} style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }} >
            <img
                src={flag}
                style={{ width: '30px' }}
            />
            {isSelected &&
                <CheckCircleRoundedIcon style={{ width: '20px', color: "#32a852" }} />
            }
        </MenuItem>
    )
}


const flagLinks = {
    tr: "http://purecatamphetamine.github.io/country-flag-icons/3x2/TR.svg",
    en: "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
}


const LanguagePicker = ({
    languages = ["en", "tr"],
    onSelect = (lang) => null,
}) => {

    const { state } = useContext(Context)
    const lang = state.appLang


    const [selectedLanguage, setSelectedLanguage] = useState(lang)



    return (

        <StyledMenu menuButton={(
            <MenuButton  >
                <TranslateIcon style={{ color: globalStyles.textColor }} />
            </MenuButton>
        )}>
            {languages.map((l, i) => {
                return (
                    <StyledMenuItem
                        key={i}
                        flag={flagLinks[l]}
                        onClick={() => {
                            setSelectedLanguage(l)
                            onSelect(l)
                        }}
                        isSelected={l === lang}
                    />
                )
            })}
        </StyledMenu>
    )



}


export default LanguagePicker;



const StyledMenu = styled(Menu)`
    ${menuItemSelector.hover} {
        color: #fff;
        background-color: ${globalStyles.firstColor};
    }
`

const MenuButton = styled.button`
    flex-direction: row;
    display: flex;
    align-items: center;
    height: 100%;
    width: 60px;
    justify-content: center;
    cursor: pointer;
    border: 0px;
    background-color: transparent;
    margin-right: 10px;
    &:hover {
        background-color: #f7fafa; 
    }
`


