import { Button, Card, Grid, TextField } from "@mui/material";
import FormInput from "../FormInput";
import t from "../../static/Language";
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import globalStyles from "../../static/GlobalStyles";
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { useEffect, useState } from "react";


const QuizChoice = ({
    description = "",
    value = 0,
    onDescriptionChange = (description) => null,
    onValueChange = (value) => null,
    onDelete = () => null,
    lang = "tr"
}) => {

    const [_value, setValue] = useState(value)
    const [_description, setDescription] = useState(description)


    useEffect(() => {
        setValue(value)
    }, [value])


    return (
        <Grid sx={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '15px', marginRight: '15px' }}>
            <FiberManualRecordRoundedIcon sx={{ width: '12px', color: globalStyles.secondColor }} />
            <FormInput
                initialValue={_description}
                label={t(lang, "surveysPage.choice")}
                onChange={(text) => {
                    setDescription(text)
                    onDescriptionChange(text)
                }}
                variant="standard"
                autoFocus={true}
            />
            <Grid width={"62px"} sx={{ marginTop: '7px' }}>
                <TextField
                    value={_value}
                    type="tel"
                    size="small"
                    label={t(lang, "common.value")}
                    InputLabelProps={{ style: { fontFamily: globalStyles.font } }}
                    inputProps={{ style: { fontFamily: globalStyles.font, color: globalStyles.textColor, textAlign: 'right' } }}
                    onBlur={(e) => {
                        if (e.target.value === "") {
                            setValue(0)
                        }
                    }}
                    onChange={(e) => {
                        const val = parseInt(e.target.value.replace(/\D/g, ''))
                        setValue(val)
                        onValueChange(val)
                    }}

                />
            </Grid>
            <Button
                sx={{ color: 'red', marginTop: '7px' }}
                onClick={onDelete}
            >
                <RemoveCircleRoundedIcon sx={{ width: '20px' }} />
            </Button>

        </Grid>
    )

}

export default QuizChoice;