import { Player } from "@lottiefiles/react-lottie-player"
import { Card, Grid, IconButton, Tooltip } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Context } from "../../context/Context"
import API from "../../static/API"
import t from "../../static/Language"
import ChartHeader from "./ChartHeader"
import globalStyles from "../../static/GlobalStyles"

import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import SwapHorizontalCircleRoundedIcon from '@mui/icons-material/SwapHorizontalCircleRounded';
import Avatar from "../Avatar"


const ActivityRow = ({ item, lang }) => {

    const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
    return (
        <div style={{
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderBottomColor: 'wheat',
            padding: 4,
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            marginBottom: '5px'
        }}>
            <Avatar avatar={item.avatar} isSquare name={item.user_name.split()[0]} lastName={item.user_name.split()[1]} size={32} />

            <NameText>{`${item.user_name}`}</NameText>
            <BirthdayText>{item.building_name}</BirthdayText>
            <BirthdayText>{item.gate_name}</BirthdayText>
            {item.check_type == 1 ? (
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', flex: 1, justifyContent: 'flex-end', }}>
                    <BirthdayText>{new Date(item.check_time).toLocaleString(lang, options)}</BirthdayText>
                    <ArrowCircleRightRoundedIcon style={{ color: "#3fd473", marginLeft: 15 }} />
                </div>
            ) : (item.check_type == 2 ? (
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', flex: 1, justifyContent: 'flex-end' }}>
                    <BirthdayText>{new Date(item.check_time).toLocaleString(lang, options)}</BirthdayText>
                    <ArrowCircleLeftRoundedIcon style={{ color: "#d43f3f", marginLeft: 15 }} />
                </ div>
            ) : (
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', flex: 1, justifyContent: 'flex-end' }}>
                    <BirthdayText>{new Date(item.check_time).toLocaleString(lang, options)}</BirthdayText>
                    <SwapHorizontalCircleRoundedIcon style={{ color: "#3f8cd4", marginLeft: 15 }} />
                </ div>
            ))}
        </div>
    )
}


const RecentActivitiesChart = () => {


    const { state } = useContext(Context)
    const lang = state.appLang


    const [data, setData] = useState()
    const navigate = useNavigate()



    useEffect(() => {
        get()
    }, [])

    const get = () => {
        API.getRecentActivity().then(response => { // cevap gelirse şirketleri atıyoruz
            setData(response.data.data)
        })
    }


    return (
        <Grid item xs={12} sm={12} md={8} lg={8}>
            <Card elevation={4} sx={{ padding: "10px", marginBottom: '10px', height: 300, overflowY: 'hidden' }}>
                {data ? (
                    <div style={{ height: '100%' }}>
                        <ChartHeader
                            title={t(lang, "dashboard.recentActivity")}
                            detailsComponent={
                                <Tooltip title={t(lang, "common.details")}>
                                    <IconButton
                                        style={{ alignSelf: 'center' }}
                                        onClick={() => navigate("/user-activities")}
                                    >
                                        <OpenInNewRoundedIcon style={{ color: "grey", fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            }

                        />
                        <div style={{ overflowY: 'auto', display: 'flex', height: '100%', flexDirection: 'column' }}>
                            {data.map((item, i) => {
                                return (
                                    <ActivityRow key={i} item={item} lang={lang} />
                                )
                            })}
                        </div>

                    </div>
                ) : (
                    <Body>
                        <Player
                            src={require('../../assets/lottie-animations/loading.json')}
                            autoplay
                            loop
                            style={{ width: 60 }}
                        />
                    </Body>
                )}
            </Card>
        </Grid>
    )

}

export default RecentActivitiesChart

const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`


const NameText = styled.span`
    font-family: ${globalStyles.font};
    color: ${globalStyles.textColor};
    margin-left: 8px
`

const BirthdayText = styled.span`
    font-family: ${globalStyles.font};
    color: grey;
    font-size: 14px;
    margin-left: 8px

`
