import { Button } from "@mui/material"
import globalStyles from "../static/GlobalStyles"



const FlexButton = ({
    onClick = () => null,
    text = "FlexButton",
    size = "small",
    renderIcon = () => null,
    style = {},
    variant = "contained",
    disabled = false
}) => {
    return (
        <Button
            variant={variant}
            sx={{
                textTransform: 'none',
                margin: '15px',
                backgroundColor: variant === "text" ? undefined : globalStyles.firstColor,
                borderRadius: "3px",
                color: variant === "text" ? globalStyles.firstColor : "white",
                ':hover': {
                    color: variant !== "text" ? "white" : globalStyles.firstColor,
                },
                ...style
            }}
            onClick={onClick}
            size={size}
            disabled={disabled}
        >
            {renderIcon()}
            <text
                style={{
                    fontFamily: globalStyles.font,
                }}
            >
                {text}
            </text>
        </Button>
    )

}

export default FlexButton