import { Card, Grid, IconButton, Tooltip } from "@mui/material";
import { Line } from "react-chartjs-2";
import { Player } from "@lottiefiles/react-lottie-player"
import styled from "styled-components";
import { Chart as ChartJS, defaults } from 'chart.js/auto'
import globalStyles from "../../static/GlobalStyles";
import { useContext, useEffect, useState } from "react";
import API from "../../static/API";
import { Context } from "../../context/Context";
import SafeStorage from "../../static/SafeStorage";
import ChartHeader from "./ChartHeader";
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import t from '../../static/Language'
import { useNavigate } from "react-router-dom";

import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';

defaults.font.family = globalStyles.font



const ActivityChart = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang


    const [labels, setLabels] = useState([])
    const [data, setData] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        get()
    }, [])


    const get = () => {
        API.getActivityStats("daily", 7).then(response => { // cevap gelirse şirketleri atıyoruz
            prepareData(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getActivityStats("weekly", 7).then(resp => { // şirketleri tekrar çekiyoruz
                        prepareData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }

    const prepareData = (list) => {
        try {
            let _data = []
            let _labels = []
            list.forEach(d => {
                _data.push(d.count)
                _labels.push(d.date.split('T')[0])
            });
            setData(_data)
            setLabels(_labels)
        } catch (error) {
            alert(error)
        }

    }


    return (
        <Grid item xs={12} sm={12} md={8} lg={8} >
            <Card elevation={4} sx={{ padding: "10px", height: 400 }}>
                {data ? (
                    <>
                        <ChartHeader
                            title={t(lang, "dashboard.activityStats")}
                            detailsComponent={
                                <Tooltip title={t(lang, "common.details")} >
                                    <IconButton
                                        style={{ alignSelf: 'center' }}
                                        onClick={() => navigate("/user-activities")}
                                    >
                                        <OpenInNewRoundedIcon style={{ color: "grey", fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            }
                            icon={<CompareArrowsRoundedIcon style={{ fontSize: 30, color: globalStyles.textColor }} />}
                        />
                        <div style={{ height:'80%' }}>
                            <Line
                            
                                options={{
                                    maintainAspectRatio:false,        
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    }

                                }}
                                data={{
                                    labels: labels,
                                    datasets: [{
                                        data: data,
                                        
                                        tension:.3
                                    }]
                                }}
                            />
                        </div>


                    </>
                ) : (
                    <Body>
                        <Player
                            src={require('../../assets/lottie-animations/loading.json')}
                            autoplay
                            loop
                            style={{ width: 60 }}
                        />
                    </Body>
                )}
            </Card>
        </Grid>
    )


}

export default ActivityChart;


const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`