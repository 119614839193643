import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import DataTable from '../../../../components/DataTable';
import globalStyles from '../../../../static/GlobalStyles';
import FormInput from '../../../../components/FormInput';
import Utils from '../../../../static/Utils';
import { toast, ToastContainer } from "react-toastify"
import API from '../../../../static/API';
import { Context } from '../../../../context/Context';
import t from '../../../../static/Language';
import SafeStorage from '../../../../static/SafeStorage';
import FormSelectInput from '../../../../components/FormSelectInput';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DataSection from '../../../../components/DataSection';
import Container from '../../../../components/Container';
import NewRecordDrawer from '../../../../components/NewRecordDrawer';



const UserGroupsPage = () => {
    const { state, dispatch } = useContext(Context)
    const lang = state.appLang


    const user = JSON.parse(SafeStorage.getItem("user"))
    const privileges = user.privileges

    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [newRecordOpen, setNewRecordOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [objInfo, setObjInfo] = useState({})
    const [objectToEdit, setObjectToEdit] = useState(null)
    const [updateInfo, setUpdateInfo] = useState({})
    const [actionOptions, setActionOptions] = useState()

    const [companies, setCompanies] = useState([])
    const [initialCompValue, setInitialCompValue] = useState()

    const [columns, setColumns] = useState([
        {
            name: "name",
            label: t(lang, "fields.name"),
            options: {
                filter: true,
                sort: true,
                draggable: true
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: false
            }
        }
    ])





    useEffect(() => {
        if (privileges.includes("superAdmin")) {
            setColumns([
                ...columns,
                {
                    name: "company_name",
                    label: t(lang, "fields.company"),
                    options: {
                        filter: true,
                        sort: false
                    }
                }
            ])
        }
        document.title = t(lang, "userGroupsPage.title")
        prepareActionOptions()
        get()
        getOthers()
    }, [])


    const prepareActionOptions = () => {
        let _actionOptions = {}
        if (privileges.includes("superAdmin") || privileges.includes("canEditUserGroups")) {
            _actionOptions["edit"] = {
                onEdit: (row, id) => {
                    console.log(row)
                    setObjectToEdit(row)
                    let comp = companies.find(c => c.id === row.company)
                    setInitialCompValue(comp)
                    setEditOpen(true)
                }
            }
        }
        if (privileges.includes("superAdmin") || privileges.includes("canDeleteUserGroups")) {
            _actionOptions["delete"] = {
                onDelete: (row, id) => {
                    _delete([id])
                }
            }
        }
        _actionOptions.onShowDetail = (row, id) => {
            //alert(`${row} ${id} detay görüntülenecek`)
            //alert("dsadasd")
            navigate(`/user-groups/${id}`, { state: row })
        }

        setActionOptions(_actionOptions)
    }


    const get = () => {
        API.getUserGroups().then(response => { // cevap gelirse datayı atıyoruz
            setData(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getUserGroups().then(resp => { // userları tekrar çekiyoruz
                        setData(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const create = () => {
        API.createUserGroup(objInfo).then(response => {
            toast(t(lang, "userGroupsPage.created"))
            get()
        }).catch(err => {
            toast(t(lang, "userGroupsPage.createError"))
        })

    }

    const update = () => {
        API.updateUserGroup(objectToEdit.id, updateInfo).then(response => {
            toast(t(lang, "userGroupsPage.updated"))
            get()
        }).catch(err => {
            toast(t(lang, "userGroupsPage.updateError"))
        })

    }

    const _delete = (ids) => {
        API.deleteUserGroups(ids).then(response => {
            get()
        }).catch(err => {
            if (err.response.status === 401) {
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => {
                    SafeStorage.setItem("token", r.data.token)
                    API.deleteUserGroups(ids).then(resp => {
                        get()
                    }).catch(er => console.log(er))
                }).catch(e => {
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }


    const getOthers = () => {
        // eğer sistem admini ise şirketleri çekeceğiz
        if (!Utils.isSystemAdmin()) {
            return
        }
        API.getCompanies().then(response => {
            setCompanies(response.data.data)
        }).catch(err => { // hata alınırsa
            if (err.response.status === 401) { // eğer token expired olmuşsa refresh token yapıyoruz
                API.refreshToken(SafeStorage.getItem("refreshToken")).then(r => { // eğer token refresh olursa:
                    SafeStorage.setItem("token", r.data.token)
                    API.getCompanies().then(resp => { // userları tekrar çekiyoruz
                        setCompanies(resp.data.data)
                    }).catch(er => console.log(er))
                }).catch(e => { // eğer token refresh olmazsa kullanıcıyı login ekranına atıyoruz
                    if (e.response.status === 401) {
                        SafeStorage.removeItem("token")
                        SafeStorage.removeItem("refreshToken")
                        dispatch({ type: 'LOGIN', login: false })
                    }
                })
            }
        })
    }







    const validate = () => {
        if (Utils.nullOrEmpty(objInfo.name)) {
            return false
        }
        return true
    }

    const handleSaveClicked = () => {
        if (!validate()) { //
            toast(t(lang, "titlesPage.requiredFields"))
            return
        }
        if (!Utils.isSystemAdmin()) { // eğer system admin değilse şirket biligisi olarak kullanıcının şirketini giriyoruz
            setObjInfo({ ...objInfo, company: user.company })
        }
        create()
    }

    const validateUpdate = () => {
        if ((updateInfo.name && Utils.nullOrEmpty(updateInfo.name))) {
            return false
        }
        return true
    }

    const handleUpdateClicked = () => {
        if (!validateUpdate()) {
            toast(t(lang, "userGroupsPage.requiredFields"))
            return
        }
        update()
    }

    return (
        <Container>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
                toastStyle={{ fontFamily: globalStyles.font, color: globalStyles.textColor }}
            />
            <DataSection>
                <NewRecordDrawer
                    open={newRecordOpen}
                    onClose={() => setNewRecordOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={t(lang, "userGroupsPage.new")}
                    onSaveClick={handleSaveClicked}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(val) => setObjInfo({ ...objInfo, name: val })}
                            helperText="*"
                            helperTextType={"error"}
                        />
                    </Grid>
                    {Utils.isSystemAdmin() &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormSelectInput
                                label={t(lang, "fields.company")}
                                onSelect={(item) => setObjInfo({ ...objInfo, company: item.id === -1 ? null : item.id })}
                                data={companies}
                                itemComponent={(item, index) => {
                                    return (
                                        <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                            <ItemText>{`${item.name}`}</ItemText>
                                        </div>
                                    )
                                }}
                            />
                        </Grid>
                    }
                </NewRecordDrawer>
                <NewRecordDrawer
                    open={editOpen}
                    onClose={() => setEditOpen(false)}
                    saveButtonTitle={t(lang, "common.save")}
                    title={`${objectToEdit?.name}`}
                    onSaveClick={handleUpdateClicked}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormInput
                            label={t(lang, "fields.name")}
                            onChange={(val) => setUpdateInfo({ ...updateInfo, name: val })}
                            helperText="*"
                            helperTextType={"error"}
                            initialValue={objectToEdit?.name}

                        />
                    </Grid>
                    {Utils.isSystemAdmin() &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormSelectInput
                                label={t(lang, "fields.company")}
                                onSelect={(item) => setUpdateInfo({ ...updateInfo, company: item.id === -1 ? null : item.id })}
                                data={companies}
                                initialValue={initialCompValue}
                                itemComponent={(item, index) => {
                                    return (
                                        <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                            <ItemText>{`${item.name}`}</ItemText>
                                        </div>
                                    )
                                }}
                            />
                        </Grid>
                    }
                </NewRecordDrawer>
                <DataTable
                    columns={columns}
                    data={data}
                    actionOptions={actionOptions}
                    onNewButtonClick={() => setNewRecordOpen(true)}
                    onRowsDelete={(rows, ids) => _delete(ids)}
                    title={t(lang, "userGroupsPage.title")}
                    canCreateNewRecord={privileges.includes("superAdmin") || privileges.includes("canCreateUserGroups")}
                />
            </DataSection>
        </Container>
    )
}

export default UserGroupsPage;





const ItemText = styled.span`
	font-family: ${globalStyles.font};
	font-size: 14px;
	color: ${globalStyles.textColor};
	margin-left: 10px
`