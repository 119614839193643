import styled from "styled-components";
import globalStyles from "../static/GlobalStyles";
import UserMenu from "./UserMenu";
import LanguagePicker from "./LanguagePicker";
import { useContext } from "react";
import { Context } from "../context/Context";
import SafeStorage from "../static/SafeStorage";



const Header = ({ title }) => {

    const { dispatch } = useContext(Context)


    const handleLanguageSelect = (lang) => {
        dispatch({ type: "LANG", appLang: lang })
        SafeStorage.setItem("appLang", lang)
    }

    return (
        <HeaderBody>
            <Title>{title}</Title>
            <LanguagePicker onSelect={handleLanguageSelect} />
            <UserMenu />
        </HeaderBody>
    )
}

export default Header;


const HeaderBody = styled.div`
    background-color: ${globalStyles.headerColor};
    border-bottom: 1px solid #CCCCCC;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
`

const Title = styled.span`
    font-family: ${globalStyles.font};
    font-size: 16px;
    font-weight: bold;
    color: ${globalStyles.textColor}
`