const CUY29OLX9_ = "LRmK4YezhwYqtRS4xF5AEj7t2vPPunfCW2HcR8pGVrDJMCqCQe4VVCgXQLL3kAg4kNJpGXfL"


/* const encrypt = (text) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(CUY29OLX9_).reduce((a, b) => a ^ b, code);
    return text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
} */


/* const decrypt = (encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(CUY29OLX9_).reduce((a, b) => a ^ b, code);
    return encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
} */



function encrypt(text) {
    let encryptedText = '';
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i) ^ CUY29OLX9_.charCodeAt(i % CUY29OLX9_.length);
        encryptedText += String.fromCharCode(charCode);
    }
    return encryptedText;
}


function decrypt(text) {
    let encryptedText = '';
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i) ^ CUY29OLX9_.charCodeAt(i % CUY29OLX9_.length);
        encryptedText += String.fromCharCode(charCode);
    }
    return encryptedText;
}

class SafeStorage {

    setItem(key, value) {
        let _i = encrypt(key)
        let _v = encrypt(value)
        localStorage.setItem(_i, _v)
    }


    getItem(key) {
        try {
            let _i = encrypt(key) //aes.encrypt(key, CUY29OLX9_)
            let _v = localStorage.getItem(_i)
            let r = decrypt(_v) //aes.decrypt(_v, CUY29OLX9_).toString(enc.Utf8)
            return r


        } catch (error) {
            return null
        }
    }


    clear() {
        localStorage.clear()
    }


    removeItem(key) {
        let _i = encrypt(key) //aes.encrypt(key)
        localStorage.removeItem(_i)
    }


    allKeys() {
        var result = []
        Object.keys(localStorage).forEach(k => {
            result.push(decrypt(k))
        })
        return result
    }


    asObject() {
        var result = {}
        Object.keys(localStorage).forEach(k => {
            let _k = decrypt(k)
            let _v = decrypt(localStorage.getItem(k))
            result[_k] = _v
        })
        return result
    }
}


export default new SafeStorage();