
import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Alert, FormControl, IconButton, Input, InputAdornment, MenuItem, Select, Snackbar } from '@mui/material';
import styled from 'styled-components';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';

import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import bg from '../../assets/media/bg.jpg'
import globalStyles from '../../static/GlobalStyles';
import t from '../../static/Language';
import { Context } from '../../context/Context';
import API from '../../static/API';
import SafeStorage from '../../static/SafeStorage';



const LoginPage = () => {
    const context = useContext(Context)
    const lang = context.state.appLang
    const dispatch = context.dispatch


    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [messageOpen, setMessageOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("success")

    const navigate = useNavigate()

    useEffect(() => {
        document.title = `Flexbee - ${t(lang, "common.login")}`

    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        API.login(name, password).then(response => {

            const userId = response.data.user.id
            const user = response.data.user
            const token = response.data.token
            const refreshToken = response.data.refresh_token


            SafeStorage.setItem("userId", userId)
            SafeStorage.setItem("user", JSON.stringify(user))
            SafeStorage.setItem("token", token)
            SafeStorage.setItem("refreshToken", refreshToken)

            dispatch({ type: "LOGIN", login: true })


        }).catch(err => {
            setMessage(err.response?.status === 401 ? t(lang, "login.wrongCredentials") : err.message)
            setSeverity("error")
            setMessageOpen(true)
        })
        //alert(`${name} ${password}`)
    }


    return (
        <Container onSubmit={e => handleSubmit(e)}>
            <Snackbar
                open={messageOpen}
                autoHideDuration={3000}
                onClose={() => setMessageOpen(false)}
            >
                <Alert sx={{ width: '100%' }} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <LoginCard>
                <Logo src={require('../../assets/icon/flexbee-no-bg-dark.webp')} />
                <Select
                    variant="standard"
                    defaultValue={lang}
                    style={{
                        position: 'absolute',
                        top: 5,
                        right: 15,
                        fontFamily: globalStyles.font,
                        fontSize: "11px",
                    }}
                    IconComponent={() => null}
                    onChange={(e) => {
                        dispatch({ type: "LANG", appLang: e.target.value })
                        SafeStorage.setItem("appLang", e.target.value)
                    }}

                >
                    <MenuItem style={{ ontFamily: globalStyles.font, fontSize: "11px" }} value={"tr"}>TR</MenuItem>
                    <MenuItem style={{ ontFamily: globalStyles.font, fontSize: "11px" }} value={"en"}>EN</MenuItem>
                </Select>
                <WelcomeText>{t(lang, "common.welcome")}</WelcomeText>

                <FormControl variant="standard" style={{ marginBottom: 24, marginTop: 24 }}>
                    <Input
                        onChange={e => setName(e.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <AccountCircleRoundedIcon style={{ color: '#353535' }} />
                            </InputAdornment>
                        }
                        inputProps={{
                            style: {
                                color: '#353535',
                                fontFamily: 'Nunito'
                            }
                        }}
                        type={'text'}
                        placeholder={t(lang, "login.namePlaceholder")}
                    />
                </FormControl>

                <FormControl variant="standard">
                    <Input
                        onChange={e => setPassword(e.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <VpnKeyRoundedIcon style={{ color: '#353535' }} />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                >
                                    {passwordVisible ? (
                                        <VisibilityRoundedIcon style={{ color: '#353535' }} />
                                    ) : (
                                        <VisibilityOffRoundedIcon style={{ color: '#353535' }} />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        inputProps={{
                            style: {
                                color: '#353535',
                                fontFamily: 'Nunito'
                            }
                        }}
                        type={passwordVisible ? 'text' : 'password'}
                        placeholder={t(lang, "login.passwordPlaceholder")}
                    />
                </FormControl>

                <LinkButton onClick={() => navigate("/phone")}>
                    <LinkText>{t(lang, "common.forgotPassword")}</LinkText>
                </LinkButton>


                <SubmitButton type="submit">
                    <LoginText>{t(lang, "common.login")}</LoginText>
                    <ArrowForwardIosRoundedIcon style={{ width: 16, color: 'white' }} />
                </SubmitButton>

                <span style={{
                    fontFamily: globalStyles.font,
                    color: globalStyles.textColor,
                    fontSize: "12px",
                    alignSelf: 'center',
                    marginTop: '24px'
                }}>{t(lang, "common.or")}</span>

                <LinkButton onClick={() => navigate("/register/user-form")}>
                    <LinkText>{t(lang, "login.register")}</LinkText>
                </LinkButton>


            </LoginCard>
        </Container>
    )
}


export default LoginPage


const SubmitButton = styled(IconButton)`
	align-self: center;
	margin-top: 24px !important;
	border-radius: 50px !important;
	border-width: 0px;
	background-color: ${globalStyles.firstColor} !important;
	padding: 5px !important;
	padding-right: 15px !important;
	padding-left: 15px !important;
    width: 100%;
	
`

const LinkButton = styled(IconButton)`
	align-self: center;
	margin-top: 16px !important;
	border-radius: 15px !important;
	border-width: 0px;
	background-color: none !important;
	padding: 5px !important;
	padding-right: 15px !important;
	padding-left: 15px !important;
	
`


const Container = styled.form`
	display: flex;
	height: 100vh;
	justify-content: center;
	align-items: center;
	background-color: #FFE88C;
	background-image: url(${bg});
	background-size: cover
`

const LoginCard = styled.div`
	display: flex;
	padding: 20px;
	border-radius: 24px;
	flex-direction: column;
	padding-top: 30px;
    background-color: transparent;
    border: solid 2px white;
    backdrop-filter: blur(15px);
`

const Logo = styled.img`
    width: 200px;
	border-radius: 12px;
    align-self: center;
`

const WelcomeText = styled.span`
	color: #353535;
	font-size: 24px;
	margin-top: 15px;
	font-family: ${globalStyles.font};
	font-weight: bold
`

const LoginText = styled.span`
	color: white;
	font-size: 16px;
	font-family: ${globalStyles.font};
`

const LinkText = styled.span`
	color: #4287f5;
	font-size: 12px;
	font-family: ${globalStyles.font};
`
