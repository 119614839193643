import { Player } from "@lottiefiles/react-lottie-player/dist/lottie-react"
import styled from "styled-components"



const RobotButton = ({
    onClick = () => null,
}) => {



    return (
        <Button
            onClick={onClick}
        >
            <Player
                src={require('../assets/lottie-animations/assistant.json')}
                autoplay
                loop
                style={{ width: "70px", height: "70px" }}
            />

        </Button>
    )
}

export default RobotButton

const Button = styled.button`
    width: 80px;
    height: 80px;
    justifiy-content: center;
    align-items: center;
    background-color: transparent;
    border-width: 0
`

